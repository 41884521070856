import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { Header } from "./Header";
import * as oidc from "oidc-client";
import * as routes from "../routes";
import { LoadingScreen } from "./LoadingScreen";
import { ClientsScreen } from "../Clients/ClientsScreen";
import { ClientScreen } from "../Clients/ClientScreen";
import { SessionState, SignProvider } from "@ist-group-private-scope/web-skolid";
import { ErrorScreen } from "./ErrorScreen";
import { LoggedOutScreen } from "./LoggedOutScreen";
import { settings } from "../settings";
import { ClientProvider } from "./ClientProvider";
import { ImportClientScreen } from "../Clients/ImportClientScreen";
import { ScopesScreen } from "../Scopes/ScopesScreen";
import { ApiResourceScreen } from "../Scopes/ApiResourceScreen";
import { IdentityResourceScreen } from "../Scopes/IdentityResourceScreen";
import { UsersScreen } from "../Users/UsersScreen";
import { UserScreen } from "../Users/UserScreen";
import { NotAuthorizedScreen } from "./NotAuthorizedScreen";
import { Footer } from "./Footer";
import { NeedsReauthenticationScreen } from "./NeedsReauthenticationScreen";
import { OrganizationsScreen } from "../Organizations/OrganizationsScreen";
import { OrganizationScreen } from "../Organizations/OrganizationScreen";
import { DomainListScreen } from "../Permissions/DomainListScreen";
import { DomainScreen } from "../Permissions/DomainScreen";
import { IdpsRouter } from "../Idps/IdpsRouter";
import { withTranslation } from 'react-i18next';

export interface AppProps {
  sessionState: SessionState;
}

interface AppState {
  user: oidc.User | null;
  isAuthenticating: boolean;
  isInitialized?: boolean;
  isNotAuthorized?: boolean;
  needsReauthentication?: boolean;
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: any) {
    super(props);
    this.state = { isAuthenticating: false, user: null };
  }

  public render() {
    const sessionState = this.props.sessionState;

    return (
      <div className="app">
        <Header
          user={sessionState.user}
          onLoginClick={sessionState.login}
          onLogoutClick={sessionState.logout}
        />
        <div className="container main">{this.renderInner()}</div>
        <Footer />
      </div>
    );
  }

  public renderInner() {
    const sessionState = this.props.sessionState;

    if (sessionState.loading) {
      return <LoadingScreen />;
    }

    if (sessionState.error) {
      return <ErrorScreen />;
    }

    if (!sessionState.user) {
      return (
        <LoggedOutScreen
          loggedOurReason={sessionState.loggedOutReason}
          onLoginClick={sessionState.login}
        />
      );
    }

    if (this.state.needsReauthentication) {
      return <NeedsReauthenticationScreen />;
    }

    if (this.state.isNotAuthorized) {
      return <NotAuthorizedScreen />;
    }

    return (
      <SignProvider user={sessionState.user} environment={settings.skolidEnvironment}>
        <ClientProvider
          user={sessionState.user}
          onNotAuthorized={() => this.setState({ isNotAuthorized: true })}
          onNeedsReauthentication={() => this.setState({ needsReauthentication: true })}
        >
          <Route exact path="/" render={() => <Redirect to={routes.clientsPath} />} />
          <Route exact path={routes.permDomains} component={DomainListScreen} />
          <Route exact path={routes.clientsPath} component={ClientsScreen} />
          <Route exact path={routes.usersPath} component={UsersScreen} />
          <Route exact path={routes.scopesPath} component={ScopesScreen} />
          <Route exact path={routes.organizationsPath} component={OrganizationsScreen} />
          <Route path={routes.idpsPath} component={IdpsRouter} />
          <Route path={routes.permDomainRoute} component={DomainScreen} />
          <Route path={routes.apiResourceRoute} component={ApiResourceScreen} />
          <Route path={routes.identityResourceRoute} component={IdentityResourceScreen} />
          <Route path={routes.clientRoute} component={ClientScreen} />
          <Route path={routes.importClientRoute} component={ImportClientScreen} />
          <Route path={routes.userRoute} component={UserScreen} />
          <Route path={routes.organizationRoute} component={OrganizationScreen} />
        </ClientProvider>
      </SignProvider>
    );
  }
}
export default withTranslation()(App);
