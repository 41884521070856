import * as React from "react";
import { Query, Mutation } from "react-apollo";
import permGql from "graphql-tag";
import {
  GetResources,
  GetResources_domain_resources_nodes,
  GetResourcesVariables,
  DeleteResource,
  DeleteResourceVariables
} from "../permissons-gql";
import { LoadingScreen } from "../App/LoadingScreen";
import * as routes from "../routes";
import { Link, RouteComponentProps } from "react-router-dom";
import { SaveResourceModal } from "./SaveResourceModal";
import { ConfirmModal } from "./DeleteModal";
import { AsyncOperation, TypedQuery, TypedMutation } from "../types";

const fetchResourcesGql = permGql`
  query GetResources($domainId: String!) {
    domain(id: $domainId) {
      globalId
      id
      displayName
      resources {
        nodes {
          globalId
          id
          displayName
          type {
            globalId
            id
            displayName
          }
        }
      }
    }
  }
`;

const DomainPermissionsQuery: TypedQuery<GetResources, GetResourcesVariables> = Query;

const removeResourceGql = permGql`
            mutation DeleteResource($id: String!, $domainId: String!, $typeId: String!) {
              deleteResource(id: $id, typeId: $typeId, domainId: $domainId)
            }`;

const RemoveDomainMutation : TypedMutation<DeleteResource, DeleteResourceVariables> = Mutation;

interface ResourcesViewState {
  showAddResource?: boolean;
  removeResource?: GetResources_domain_resources_nodes | null;
  removeResourceOperation?: AsyncOperation<any> | null;
  filterText: string;
}

export class ResourcesView extends React.Component<
  RouteComponentProps<routes.PermDomainRouteParams>,
  ResourcesViewState
> {
  public state: ResourcesViewState = { filterText: "" };

  public render() {
    const props = this.props;
    const domainId = props.match.params.domainId;
    const fetchResourcesQueryProps = { query: fetchResourcesGql, variables: { domainId } };

    return (
      <div className="flex-fill">
        <DomainPermissionsQuery {...fetchResourcesQueryProps} fetchPolicy="cache-and-network">
          {response => (
            <>
              <div className="box">
                <div className="box-header">Resources</div>
                <div className="box-body">
                  {response.loading ? <LoadingScreen /> : null}
                  {response.error ? <div>Something went wrong!?</div> : null}
                  {!response.loading && response.data && !response.data.domain ? <div>No such domain</div> : null}
                  {response.data && response.data.domain ? (
                    <>
                      <div className="text-right">
                        <button className="btn btn-primary" onClick={() => this.setState({ showAddResource: true })}>
                          New resource
                        </button>
                      </div>

                      <table className="table table-fixed">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Id</th>
                            <th>Type</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {response.data.domain.resources.nodes
                            .map(x => x!)
                            .map(r => (
                              <ResourceRow
                                domainId={domainId}
                                resource={r}
                                key={r.id + r.type.id}
                                onRemove={this.handleRemoveResource}
                              />
                            ))}
                        </tbody>
                      </table>
                    </>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </DomainPermissionsQuery>

        <SaveResourceModal
          domainId={domainId}
          isOpen={!!this.state.showAddResource}
          onClose={() => this.setState({ showAddResource: false })}
          refetchQueries={[fetchResourcesQueryProps]}
        />

        <RemoveDomainMutation
          mutation={removeResourceGql}
          variables={
            this.state.removeResource
              ? {
                  domainId,
                  id: this.state.removeResource.id,
                  typeId: this.state.removeResource.type.id
                }
              : ({} as any)
          }
          {...{ awaitRefetchQueries: true }}
        >
          {(mutate, result) => (
            <ConfirmModal
              isOpen={!!this.state.removeResource}
              operation={{ error: result.error, running: result.loading }}
              operationName="Remove"
              onClose={() => this.setState({ removeResource: null })}
              onConfirm={async () => {
                await mutate({ refetchQueries: [fetchResourcesQueryProps] });
                this.setState({ removeResource: null });
              }}
            />
          )}
        </RemoveDomainMutation>
      </div>
    );
  }

  private handleRemoveResource = (resource: GetResources_domain_resources_nodes) => {
    this.setState({ removeResource: resource });
  };
}

interface ResourceRowProps {
  domainId: string;
  resource: GetResources_domain_resources_nodes;
  onRemove: (resource: GetResources_domain_resources_nodes) => void;
}

class ResourceRow extends React.Component<ResourceRowProps> {
  public render() {
    const r = this.props.resource;
    return (
      <>
        <tr>
          <td>
            <Link
              to={routes.generatePermDomainResourcePath({
                domainId: this.props.domainId,
                resourceId: this.props.resource.id,
                resourceTypeId: this.props.resource.type.id
              })}
            >
              {r.displayName || r.id}
            </Link>
          </td>
          <td>{r.id}</td>
          <td title={"type-id: " + r.type.id}>{r.type.displayName || r.type.id}</td>
          <td className="text-right">
            <button className="no-btn" onClick={this.handleRemoveClick}>
              <i className="fa fa-trash-alt" />
            </button>
          </td>
        </tr>
      </>
    );
  }

  private handleRemoveClick = () => {
    this.props.onRemove(this.props.resource);
  };
}
