import * as React from "react";
import { ClientType } from "./types";
import { Input, UrisControl } from "@ist-group-private-scope/skolid-client-components";
import { validators } from "./validation";
import { CreateClientInput, Loa } from "../admin-gql";
import { SelectOrganization } from "../Common/SelectOrganization";
import { SelectOrganizationMulti } from "../Common/SelectOrganizationMulti";
import { SelectScopes } from "../Common/SelectScopes";
import { SelectLoa } from "../Common/SelectLoa";
import { SelectAuthMethods } from "../Common/SelectLoginMethods";
import { nameToId } from "../Utils/misc";
import { Checkbox } from "../Common/Checkbox";

interface AddClientFormProps {
  onClientUpdate: (partialUpdate: Partial<CreateClientInput>) => void;
  client: CreateClientInput;
  loading?: boolean;
  forceShowValidationErrors?: boolean;
  clientType: ClientType | undefined;
  onClientTypeChange: (newType: ClientType | undefined) => void;
}

export const AddClientForm = (props: AddClientFormProps) => {
  const [keycloakBaseUrl, setKeycloakBaseUrl] = React.useState("");
  const client = props.client;
  const disabled = false;
  const commonProps = {
    disabled,
    forceShowValidationError: props.forceShowValidationErrors,
  };
  const clientType = props.clientType;

  const updateClient = props.onClientUpdate;
  const updateOidc = (partial: Partial<CreateClientInput["oidcSettings"]>) =>
    updateClient({ oidcSettings: { ...client.oidcSettings, ...partial } });

  const oidc = client!.oidcSettings!;

  const [selectedOrgid, setSelectedOrgId] = React.useState<string | null>(null);
  React.useEffect(
    () => {
      updateClient({ organizationId: selectedOrgid });
    },
    // eslint-disable-next-line
    [selectedOrgid]
  );

  const [useCustomId, setUseCustomId] = React.useState(false);
  React.useEffect(() => {
    if (!useCustomId) {
      updateClient({ clientId: nameToId(client.displayName) });
    }
  }, [client.displayName, useCustomId]);

  return (
    <>
      <div className="form-row">
        <div className="form-group col">
          <label className="">Client type</label>
          <select
            className="form-control"
            value={clientType}
            onChange={(ev) => {
              const newClientType = ev.target.value as ClientType;
              props.onClientTypeChange(newClientType);
              updateOidc({
                requireClientSecret: newClientType !== ClientType.PublicClient,
                requirePkce: newClientType === ClientType.PublicClient,
              });
              updateClient({
                allowedScopes:
                  newClientType !== ClientType.ClientCred
                    ? [
                        "openid",
                        "personnummer",
                        "national-id",
                        "email",
                        "profile",
                      ]
                    : [],
              });
            }}
          >
            <option value="">- Client type -</option>
            <option value={ClientType.PublicClient}>
              Web app or mobile app (public client)
            </option>
            <option value={ClientType.ConfidentialClient}>
              Classic client with dedicated backend (confidential client)
            </option>
            <option value={ClientType.Keycloak}>Keycloak client</option>
            <option value={ClientType.ClientCred}>
              Server-to-server client
            </option>
          </select>
        </div>
      </div>

      {clientType ? (
        <>
          {clientType !== ClientType.PublicClient ? (
            <p className="alert alert-info">
              You will receive a client id and a client secret after the client
              has been created.
            </p>
          ) : null}

          <h3>Basic settings</h3>

          <div className="form-row">
            <div className="form-group col">
              <label className="">Organization</label>
              <SelectOrganization
                onChange={setSelectedOrgId}
                organizationId={selectedOrgid}
              />
              <div className="mt-1 small">
                This setting will NOT limit authentication to users in a
                specific organization. See the "User Organization" setting under
                the authentication section to limit access.
              </div>
            </div>
          </div>

          {clientType !== ClientType.ClientCred ? (
            <div className="form-row">
              <div className="form-group col">
                <Input
                  label="URI"
                  value={client.clientUri}
                  onChange={(x) => props.onClientUpdate({ clientUri: x })}
                  validationErrorMessage={validators.uri(client)}
                  {...commonProps}
                />
              </div>
            </div>
          ) : null}

          <div className="form-row">
            <div className="form-group col">
              <Input
                label="Name"
                value={client.displayName}
                onChange={(x) => props.onClientUpdate({ displayName: x })}
                validationErrorMessage={validators.displayName(client)}
                {...commonProps}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label>Id</label>

              <Input
                value={client.clientId}
                readonly={!useCustomId}
                onChange={(x) => props.onClientUpdate({ clientId: x })}
                {...commonProps}
              />
              <Checkbox
                checked={useCustomId}
                onChange={setUseCustomId}
                label="Custom Id"
                {...commonProps}
              ></Checkbox>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <Input
                label="Description"
                value={client.description}
                onChange={(x) => props.onClientUpdate({ description: x })}
                multiline
                validationErrorMessage={validators.description(client)}
                {...commonProps}
              />
            </div>

            <div className="form-group col-md-6">
              <Input
                label="Administrative contact"
                value={client.contact}
                onChange={(x) => props.onClientUpdate({ contact: x })}
                multiline
                validationErrorMessage={validators.contact(client)}
                {...commonProps}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label>Allowed Scopes</label>
              <SelectScopes
                selectedScopes={client.allowedScopes || []}
                onChange={(selectedScopes) =>
                  updateClient({ allowedScopes: selectedScopes })
                }
                disabled={disabled}
              />
            </div>
          </div>

          {clientType !== ClientType.ClientCred ? (
            <>
              <h3 className="my-3">Authentication requirements</h3>
              <div className="form-row">
                <div className="col form-group">
                  <label>User Organizations</label>
                  <SelectOrganizationMulti
                    organizationIds={props.client.userOrganizationIds}
                    disabled={disabled}
                    onChange={(userOrganizationIds) =>
                      updateClient({
                        userOrganizationIds,
                      })
                    }
                  />
                  <div className="mt-1 small">
                    Limits authentication to users in the given organizations.
                    Leave empty to allow users from all organizations to login.
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col">
                  <label>LOA requirement</label>
                  <SelectLoa
                    onChange={(newLoaRequirement) =>
                      updateClient({ loaRequirement: newLoaRequirement })
                    }
                    loa={props.client.loaRequirement || Loa.ZERO}
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col">
                  <label>Login methods</label>
                  <SelectAuthMethods
                    authSettings={client}
                    onChange={(authSettings) => updateClient(authSettings)}
                  />
                </div>
              </div>
            </>
          ) : null}

          {[ClientType.PublicClient, ClientType.ConfidentialClient].includes(
            clientType
          ) ? (
            <>
              <h3 className="my-3">URI:s</h3>
              {clientType === ClientType.ConfidentialClient ? (
                <>
                  <div className="form-row">
                    <div className="form-group col">
                      <Input
                        value={oidc.frontChannelLogoutUri}
                        label="Front channel logout URI"
                        onChange={(uri) =>
                          updateOidc({ frontChannelLogoutUri: uri })
                        }
                      />
                    </div>
                  </div>
                </>
              ) : null}

              <div className="form-row">
                <div className="form-group col">
                  {oidc.redirectUris && oidc.redirectUris.length > 0 ? (
                    <h4>Redirect URI:s</h4>
                  ) : null}
                  <UrisControl
                    disabled={disabled}
                    updateUris={(newUris) =>
                      updateOidc({ redirectUris: newUris })
                    }
                    uriName="Redirect URI"
                    uris={oidc.redirectUris!}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col">
                  {oidc.postLogoutRedirectUris &&
                  oidc.postLogoutRedirectUris.length > 0 ? (
                    <h4>Post logout redirect URI:s</h4>
                  ) : null}
                  <UrisControl
                    disabled={disabled}
                    updateUris={(newUris) =>
                      updateOidc({ postLogoutRedirectUris: newUris })
                    }
                    uriName="Post logout redirect URI"
                    uris={oidc.postLogoutRedirectUris!}
                  />
                </div>
              </div>

              {[ClientType.PublicClient].includes(clientType) ? (
                <div className="form-row">
                  <div className="form-group col">
                    {oidc.allowedCorsOrigins &&
                    oidc.allowedCorsOrigins.length > 0 ? (
                      <h4>CORS Origins</h4>
                    ) : null}
                    <UrisControl
                      disabled={disabled}
                      updateUris={(newUris) =>
                        updateOidc({ allowedCorsOrigins: newUris })
                      }
                      uriName="CORS Origin URI"
                      uris={oidc.allowedCorsOrigins || []}
                    />
                  </div>
                </div>
              ) : null}
            </>
          ) : null}

          {clientType === ClientType.Keycloak ? (
            <>
              <h3 className="my-3">Keycloak settings</h3>

              <div className="form-group">
                <Input
                  disabled={disabled}
                  onChange={(newUrl) => {
                    setKeycloakBaseUrl(newUrl);
                    updateOidc({
                      redirectUris: [newUrl],
                      postLogoutRedirectUris: [newUrl + "/logout_response"],
                      frontChannelLogoutUri: (newUrl || "").replace(
                        /\/broker\/.+/,
                        "/protocol/openid-connect/logout"
                      ),
                    });
                  }}
                  label="Keycloak redirect URI"
                  value={keycloakBaseUrl}
                />
              </div>

              <div className="form-group">
                <Input
                  readonly
                  label="Post logout redirect URI"
                  value={
                    client.oidcSettings!.postLogoutRedirectUris!.length > 0
                      ? client.oidcSettings!.postLogoutRedirectUris![0]
                      : ""
                  }
                />
              </div>

              <div className="form-group">
                <Input
                  readonly
                  label="Front channel logout URI"
                  value={client.oidcSettings!.frontChannelLogoutUri}
                />
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};
