import * as React from 'react';
import { LoggedOutReasons } from '@ist-group-private-scope/web-skolid';
import { LoggedOutReasons as AppLoggedOutReasons } from '../constants';
import i18next from 'i18next';

export const LoggedOutScreen = (props: {
  onLoginClick?: () => void;
  loggedOurReason: any;
}) => (
  <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
    <div className="container row justify-content-center">
      <div className="col-md-6">
        <div className="box">
          <div className="box-header">
            {i18next.t('logged.out.screen.title')}
          </div>
          <div className="box-body">
            <LoggedOutDescription loggedOurReason={props.loggedOurReason} />
            <button className="btn btn-link p-0" onClick={props.onLoginClick}>
              {i18next.t('logged.out.screen.signIn')}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const LoggedOutDescription = (props: { loggedOurReason: any }) => {
  if (props.loggedOurReason === LoggedOutReasons.Timeout) {
    return <p>{i18next.t('logged.out.screen.loggedOutInformation')}</p>;
  }

  if (props.loggedOurReason === AppLoggedOutReasons.NotAuthorized) {
    return (
      <p>
        {i18next.t('logged.out.screen.noPermission')}
      </p>
    );
  }

  return <p>{i18next.t('logged.out.screen.information')}</p>;
};
