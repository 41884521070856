import gql from "graphql-tag";

export const clientFragmentGql = gql`
  fragment ClientFragment on Client {
    id
    displayName
    description
    clientUri
    enabled
    contact

    protocolType
    allowedScopes
    requireConsent
    allowRememberConsent
    consentLifetime
    loaRequirement
    sessionMaxAge
    disableBankIdLogin
    disableSkolidLogin
    disableGoogleLogin
    disableMicrosoftLogin
    disableFrejaLogin
    disableIdportenLogin
    useTfNumberAsNationalIdFallback
    assumeSkolidExtensionOnChromeOs
    allowedIdps {
      id
    }

    properties {
      key
      value
    }

    organization {
      id
      name
    }

    userOrganizations {
      id
      name
    }

    samlSettings {
      entityId
      assertionConsumerServiceUrl
      assertionConsumerServiceBinding
      signAssertion
      signSamlResponse
      certificates {
        data
        use
      }
      validAssertionConsumerServiceUrls
      singleLogoutServiceUrl
      singleLogoutServiceBinding
      singleLogoutServiceResponseUrl
      mappingRules {
        type
        targetAttribute
        value
      }
      nameIdFormat
      signatureAlgorithm
      digestAlgorithm
      wantAuthnRequestSigned
      localSigningCertificateThumbprintHint
    }

    oidcSettings {
      redirectUris
      allowedCorsOrigins
      postLogoutRedirectUris

      clientSecrets {
        id
        value
      }

      allowedGrantTypes
      autoRedirectAfterLogout
      includeNotBeforeClaim
      requireClientSecret
      alwaysIncludeUserClaimsInIdToken
      requirePkce
      allowPlainTextPkce
      allowAccessTokensViaBrowser
      frontChannelLogoutUri
      frontChannelLogoutSessionRequired
      backChannelLogoutUri
      backChannelLogoutSessionRequired
      allowOfflineAccess

      accessTokenLifetime
      identityTokenLifetime
      authorizationCodeLifetime
      absoluteRefreshTokenLifetime
      slidingRefreshTokenLifetime

      refreshTokenUsage
      refreshTokenExpiration
      updateAccessTokenClaimsOnRefresh
      accessTokenType
      pairWiseSubjectSalt
    }
  }
`;
