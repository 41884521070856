import * as React from "react";
import { Query } from "react-apollo";
import permGql from "graphql-tag";
import { GetSelectResources } from "../permissons-gql";
import Select from "react-select";
import { TypedQuery } from "../types";

const fetchResourcesGql = permGql`
  query GetSelectResources($domainId: String!) {
    domain(id: $domainId) {
      globalId
      id
      resources {
        nodes {
          globalId
          id
          displayName
          type {
            globalId
            id
            displayName
          }
        }
      }
    }
  }
`;

export interface SelectResourceValue {
  id: string;
  typeId: string;
}

const ResourcesQuery: TypedQuery<GetSelectResources> = Query;

export const SelectResource = (props: {
  domainId: string;
  selectedResource?: SelectResourceValue | null;
  onSelect: (resource: SelectResourceValue | null | undefined) => void;
  disabled?: boolean;
}) => {
  return (
    <ResourcesQuery query={fetchResourcesGql} variables={{ domainId: props.domainId }}>
      {result => (
        <Select
          noOptionsMessage={() => "No resources"}
          options={result.data && !result.loading ? result.data.domain!.resources.nodes : []}
          getOptionValue={(o: any) => o.id + ":" + o.type.id}
          getOptionLabel={(o: any) => (o.displayName || o.id) + " (" + (o.type.displayName || o.type.id) + ")"}
          isLoading={result.loading}
          isDisabled={result.loading || props.disabled}
          value={
            result.data && !result.loading && result.data.domain && props.selectedResource
              ? result.data!.domain!.resources.nodes.filter(
                  x => x!.id === props.selectedResource!.id && x!.type.id === props.selectedResource!.typeId
                )[0]
              : null
          }
          onChange={(option: any) => props.onSelect(option ? { id: option.id, typeId: option.type.id } : null)}
        />
      )}
    </ResourcesQuery>
  );
};
