import * as React from "react";
import { ModalHeader, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Spinner } from "../Common/Spinner";
import { AsyncOperation } from "../types";

export const ConfirmModal = (props: {
  isOpen: boolean;
  operation: AsyncOperation<any>;
  onClose: () => void;
  onConfirm: () => void;
  operationName?: string;
}) => (
  <Modal isOpen={props.isOpen} size="sm">
    <ModalHeader>Remove</ModalHeader>
    <ModalBody>
      <div className="form-group">
        <p>Are you sure?</p>
        {props.operation.error ? (
          <div className="alert alert-danger">{props.operation.error}</div>
        ) : null}
      </div>
    </ModalBody>
    <ModalFooter>
      <button
        className="btn btn-secondary"
        onClick={props.onClose}
        disabled={props.operation.running}
      >
        Cancel
      </button>
      <button
        className="btn btn-primary"
        onClick={props.onConfirm}
        disabled={props.operation.running}
      >
        {props.operation.running ? <Spinner light /> : props.operationName || "Remove"}
      </button>
    </ModalFooter>
  </Modal>
);
