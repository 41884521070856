import { useForm } from "@ist-group/react-form-hook";
import admin2Gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  CreateIdp,
  CreateIdpFromMetadataInput,
  CreateIdpVariables,
  CreateIdpFromMetadata,
  CreateIdpFromMetadataVariables,
  UpdateIdpFromMetadata,
  UpdateIdpFromMetadataVariables
} from "../admin-gql-2";
import { useAdmin2Client } from "../App/ClientProvider";
import { Alert } from "../Common/Alert";
import { Button } from "../Common/Button";
import { FieldInput } from "../Common/FieldInput";
import { waitMinDelay } from "../Common/functions";
import { SelectOrganization } from "../Common/SelectOrganization";
import { nameToId } from "../Utils/misc";
import { SelectSchoolRelationRoles } from "../Common/SelectSchoolRelationRole";
import _ from "lodash";
import { idpDetailsFragmentGql } from "./fragments";
import { Input } from "@ist-group-private-scope/skolid-client-components";

interface CreateIdpModalProps {
  idpId: string;
  onClose: () => void;
  show?: boolean;
}

const updateIdpFromMetadataGql = admin2Gql`
  mutation UpdateIdpFromMetadata($input: UpdateIdpFromMetadataInput!) {
    updateIdpFromMetadata(input: $input) {
      __typename

      ...on UpdateIdpFromMetadataSuccess {
        idp {
          ...IdpDetails
        }
      }

      ...on MutationError {
        errorMessage
      }
    }
  }

  ${idpDetailsFragmentGql}
`;

export const UpdateIdpFromMetadataModal = (props: CreateIdpModalProps) => {
  return (
    <Modal onClosed={props.onClose} isOpen={props.show}>
      <Content {...props} />
    </Modal>
  );
};

const Content = (props: CreateIdpModalProps) => {
  const client = useAdmin2Client();
  const [metadata, setMetadata] = React.useState("");
  const [update, updateState] = useMutation<UpdateIdpFromMetadata, UpdateIdpFromMetadataVariables>(
    updateIdpFromMetadataGql,
    {
      client,
      onCompleted: result => {
        if (result.updateIdpFromMetadata.__typename === "UpdateIdpFromMetadataSuccess") {
          props.onClose();
        }
      }
    }
  );

  return (
    <>
      <ModalHeader>Create Identity Provider</ModalHeader>
      <ModalBody>
        <div className="form-group">
          <Input value={metadata} label="Metadata" multiline onChange={setMetadata} />
        </div>
        <div className="form-group ">
          <Alert>
            {updateState.data?.updateIdpFromMetadata.__typename === "UpdateIdpFromMetadataError" &&
              updateState.data.updateIdpFromMetadata.errorMessage}
          </Alert>
        </div>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-secondary" onClick={() => props.onClose()}>
          Cancel
        </button>

        <Button
          onClick={() => update({ variables: { input: { id: props.idpId, metadata } } })}
          disabled={updateState.loading}
          loading={updateState.loading}
        >
          Update
        </Button>
      </ModalFooter>
    </>
  );
};
