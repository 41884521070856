import permGql from "graphql-tag";

export const subjectFragmentGql = permGql`
  fragment Subject on Subject {
    globalId
    id
    displayName
    type
    organization {
      id
      name
    }
  }
`;

export const resourceFragmentGql = permGql`
  fragment Resource on Resource {
    globalId
    id
    displayName
    type {
      globalId
      id
      displayName
    }
  }
`;

export const roleAssignmentFragmentGql = permGql`
fragment RoleAssignment on RoleAssignment {
  globalId
  subject {
    globalId
    id
    displayName
    type
    organization {
      id
      name
    }
  }
  role {
    globalId
    id
    displayName
    highSecurity
  }
}`;
