import * as React from "react";
import { Query, ApolloConsumer, QueryResult } from "react-apollo";
import gql from "graphql-tag";
import {
  FetchApiResourceVariables,
  ApiResourceFragment,
  CreateApiResource,
  CreateApiResourceInput,
  UpdateApiResourceInput,
  FetchApiResource
} from "../admin-gql";
import { Spinner } from "../Common/Spinner";
import { RouteComponentProps } from "react-router";
import { ResourceRouteParams, generateApiResourcePath, scopesPath } from "../routes";
import { ApolloClient } from "apollo-client";
import { apiResourceFragmentGql } from "./fragments";
import { History } from "history";
import { FormApiResource } from "./types";
import { LoadingScreen } from "../App/LoadingScreen";
import { AsyncOperation, TypedQuery } from "../types";
import { Checkbox } from "../Common/Checkbox";
import { Input } from "@ist-group-private-scope/skolid-client-components";
import { SelectClaims } from "../Common/SelectClaims";
import { removeTypename } from "../Utils/graphql";

const fetchApiResourceGql = gql`
  query FetchApiResource($name: String!) {
    apiResource(name: $name) {
      ...ApiResourceFragment
    }
  }

  ${apiResourceFragmentGql}
`;

const FetchApiResourceQuery: TypedQuery<FetchApiResource, FetchApiResourceVariables> = Query;

export class ApiResourceScreen extends React.Component<RouteComponentProps<ResourceRouteParams>, {}> {
  public render() {
    if (this.props.match.params.name.toString() === "-1") {
      return (
        <ApolloConsumer>
          {client => <ApiResourceForm history={this.props.history} apolloClient={client} apiResource={null} />}
        </ApolloConsumer>
      );
    }

    return (
      <FetchApiResourceQuery query={fetchApiResourceGql} variables={{ name: this.props.match.params.name }}>
        {this.renderApolloQueryResult}
      </FetchApiResourceQuery>
    );
  }

  private renderApolloQueryResult = (result: QueryResult<FetchApiResource, FetchApiResourceVariables>) => {
    if (result.loading) {
      return <LoadingScreen />;
    }

    if (result.error) {
      return <div>Something went wrong!?</div>;
    }

    if (!result.data || !result.data.apiResource) {
      return <h1>API resource does not exist</h1>;
    }

    return (
      <ApiResourceForm
        history={this.props.history}
        apolloClient={result.client}
        apiResource={result.data!.apiResource!}
      />
    );
  };
}

interface ApiResourceFormProps {
  apiResource: ApiResourceFragment | null;
  apolloClient: ApolloClient<any>;
  history: History;
}

interface ApiResourceFormState {
  apiResource: FormApiResource;
  lastPropsApiResource: ApiResourceFragment | null;
  saveOperation: AsyncOperation<void>;
  deleteOperation: AsyncOperation<void>;
  showValidationErrors?: boolean;
}

class ApiResourceForm extends React.Component<ApiResourceFormProps, ApiResourceFormState> {
  public static getDerivedStateFromProps(
    nextProps: ApiResourceFormProps,
    prevState: ApiResourceFormState
  ): Partial<ApiResourceFormState> | null {
    if (nextProps.apiResource !== prevState.lastPropsApiResource && nextProps.apiResource) {
      return {
        apiResource: nextProps.apiResource,
        lastPropsApiResource: nextProps.apiResource
      };
    } else {
      return null;
    }
  }

  constructor(props: ApiResourceFormProps) {
    super(props);
    this.state = {
      saveOperation: {},
      deleteOperation: {},
      lastPropsApiResource: props.apiResource,
      apiResource: props.apiResource || {
        enabled: true,
        name: "",
        claims: [],
        description: "",
        displayName: "",
        scopes: [],
        secrets: []
      }
    };
  }

  public render() {
    const apiResource = this.state.apiResource;
    const disabled = this.state.saveOperation.running || this.state.deleteOperation.running;
    const update = this.updateApiResource;

    const nameValidationError = !apiResource.name ? "Name required" : null;
    const displayNameValidationError = !apiResource.displayName ? "Display name required" : null;
    const hasValidationError = !!(nameValidationError || displayNameValidationError);
    const isNew = !this.props.apiResource;

    return (
      <div className="flex-fill">
        <div className="row">
          <div className="col">
            <h1>{!this.props.apiResource ? "Create" : "Update"} API resource</h1>
            {this.state.saveOperation.error ? (
              <div className="alert alert-danger">{this.state.saveOperation.error}</div>
            ) : null}
          </div>
        </div>

        <div className="box">
          <div className="box-body">
            <div className="form-group">
              <Checkbox
                checked={apiResource.enabled}
                disabled={disabled}
                onChange={checked => update({ enabled: checked })}
                label="Enabled"
              />
            </div>

            <div className="form-row">
              <div className="form-group col">
                <Input
                  value={apiResource.name}
                  label="Name"
                  onChange={text => update({ name: text })}
                  disabled={disabled || !isNew}
                  validationErrorMessage={nameValidationError}
                  forceShowValidationError={this.state.showValidationErrors}
                />
              </div>

              <div className="form-group col">
                <Input
                  value={apiResource.displayName}
                  label="Display Name"
                  onChange={text => update({ displayName: text })}
                  disabled={disabled}
                  validationErrorMessage={displayNameValidationError}
                  forceShowValidationError={this.state.showValidationErrors}
                />
              </div>
            </div>

            <div className="form-group">
              <Input
                value={apiResource.description}
                label="Description"
                onChange={text => update({ description: text })}
                disabled={disabled}
              />
            </div>

            <div className="form-group">
              <label>Claims</label>
              <SelectClaims
                selectedClaims={apiResource.claims}
                disabled={disabled}
                onChange={selectedClaims => update({ claims: selectedClaims })}
              />
            </div>
          </div>
        </div>

        {apiResource.scopes.length > 0 ? <h2>Scopes</h2> : null}

        {apiResource.scopes.map((scope, scopeIndex) => {
          const updateScope = (partialScope: Partial<FormApiResource["scopes"][0]>) =>
            update({
              scopes: apiResource.scopes.map((x, i) => (i === scopeIndex ? { ...x, ...partialScope } : x))
            });

          return (
            <div className="box" key={scopeIndex}>
              <div className="box-body position-relative">
                <div className="form-row">
                  <button
                    className="close position-absolute"
                    style={{ right: 20, top: 10, zIndex: 1000 }}
                    onClick={() => update({ scopes: apiResource.scopes.filter((x, i) => i !== scopeIndex) })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  <div className="form-group col">
                    <Input
                      value={scope.name}
                      disabled={disabled}
                      label="Name"
                      onChange={text => updateScope({ name: text })}
                    />
                  </div>

                  <div className="form-group col">
                    <Input
                      value={scope.displayName}
                      disabled={disabled}
                      label="Display Name"
                      onChange={text => updateScope({ displayName: text })}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <Input
                    value={scope.description}
                    disabled={disabled}
                    label="Description"
                    onChange={text => updateScope({ description: text })}
                  />
                </div>

                <div className="form-group">
                  <Checkbox
                    inline
                    checked={scope.required}
                    disabled={disabled}
                    label="Required"
                    onChange={checked => updateScope({ required: checked })}
                  />

                  <Checkbox
                    inline
                    checked={scope.emphasize}
                    disabled={disabled}
                    label="Emphasize"
                    onChange={checked => updateScope({ emphasize: checked })}
                  />

                  <Checkbox
                    inline
                    checked={scope.showInDiscoveryDocument}
                    disabled={disabled}
                    label="Show in metadata"
                    onChange={checked => updateScope({ showInDiscoveryDocument: checked })}
                  />
                </div>

                <div className="form-group">
                  <label>Claims</label>
                  <SelectClaims
                    selectedClaims={scope.claims}
                    disabled={disabled}
                    onChange={selectedClaims => updateScope({ claims: selectedClaims })}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <button
          className="btn btn-link p-0"
          onClick={() =>
            update({
              scopes: apiResource.scopes.concat([
                {
                  showInDiscoveryDocument: true,
                  emphasize: false,
                  description: "",
                  displayName: "",
                  claims: [],
                  name: "",
                  required: false
                }
              ])
            })
          }
        >
          Add scope
        </button>

        <div className="row">
          <div className="col">
            <div className="text-right">
              {this.props.apiResource ? (
                <>
                  <button className="btn btn-danger" onClick={this.delete} disabled={disabled}>
                    {this.state.deleteOperation.running ? <Spinner light /> : "Delete"}
                  </button>
                  &nbsp;&nbsp;
                </>
              ) : null}
              <button
                className="btn btn-primary"
                onClick={() => this.save(hasValidationError)}
                disabled={(hasValidationError && this.state.showValidationErrors) || disabled}
              >
                {this.state.saveOperation.running ? <Spinner light /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private delete = async () => {
    this.setState({ deleteOperation: { running: true } });
    await this.props.apolloClient.mutate({
      mutation: deleteApiResourceGql,
      variables: { name: this.props.apiResource!.name }
    });

    this.props.history.replace(scopesPath);
  };

  private save = async (hasValidationErrors: boolean) => {
    if (hasValidationErrors) {
      this.setState({ showValidationErrors: true });
      return;
    }

    this.setState({ saveOperation: { running: true } });

    try {
      if (!this.props.apiResource) {
        const result: any = await this.props.apolloClient.mutate({
          mutation: createApiResourceGql,
          variables: { input: removeTypename(convertToCreateApiResource(this.state.apiResource)) }
        });
        const data: CreateApiResource = result.data;

        this.props.history.replace(generateApiResourcePath({ name: data.createApiResource!.name }));
        return;
      } else {
        await this.props.apolloClient.mutate({
          mutation: updateApiResourceGql,
          variables: {
            input: removeTypename(convertToUpdateApiResource(this.state.apiResource)),
            name: this.props.apiResource.name
          }
        });
      }

      this.setState({ saveOperation: {} });
    } catch (error) {
      this.setState({ saveOperation: { error: "Could not save the client" } });
      console.error("Failed to save client: ", error);
    }
  };

  private updateApiResource = (partialUpdate: Partial<FormApiResource>) => {
    this.setState({ apiResource: { ...this.state.apiResource, ...partialUpdate } });
  };
}

const createApiResourceGql = gql`
  mutation CreateApiResource($input: CreateApiResourceInput!) {
    createApiResource(input: $input) {
      ...ApiResourceFragment
    }
  }

  ${apiResourceFragmentGql}
`;

const deleteApiResourceGql = gql`
  mutation DeleteApiResource($name: String!) {
    deleteApiResource(name: $name)
  }
`;

const updateApiResourceGql = gql`
  mutation UpdateApiResource($name: String!, $input: UpdateApiResourceInput!) {
    updateApiResource(name: $name, input: $input) {
      ...ApiResourceFragment
    }
  }

  ${apiResourceFragmentGql}
`;

export function convertToCreateApiResource(input: FormApiResource): CreateApiResourceInput {
  const { ...create } = input;
  return {
    ...create,
    displayName: input.displayName!,
    scopes: input.scopes.map(x => ({ ...x, displayName: x.displayName! })),
    secrets: input.secrets.map(x => ({ value: x.value! }))
  };
}

export function convertToUpdateApiResource(input: FormApiResource): UpdateApiResourceInput {
  const { name, ...update } = input;
  return {
    ...update,
    secrets: input.secrets.map(x => ({ ...x, value: x.value! }))
  };
}
