import { Input } from "@ist-group-private-scope/skolid-client-components";
import * as React from "react";
import Select from "react-select";
import { SamlClientMappingRuleType } from "../../admin-gql";
import { CertificateEditor } from "../../Common/CertificateEditor";
import { Checkbox } from "../../Common/Checkbox";
import { SelectClaim } from "../../Common/SelectClaim";
import { SelectSamlMappingType } from "../../Common/SelectSamlMappingType";
import { UrisControl } from "../../Common/UrisControl";
import { FormClient } from "../types";
import { SelectSamlBinding } from "../../Common/SelectSamlBinding";

export const SamlForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const updateClient = props.updateClient;
  const updateSaml = (partialNewSaml: Partial<FormClient["samlSettings"]>) =>
    updateClient({
      samlSettings: { ...client.samlSettings, ...partialNewSaml },
    });
  const disabled = props.disabled;
  const saml = client.samlSettings;

  return (
    <div className="box">
      <div className="box-header">Base </div>
      <div className="box-body">
        {!saml.signAssertion && !saml.signSamlResponse ? (
          <div className="alert alert-warning">
            Both SAML assertion and SAML response signing has been turned off.
            This means that the client/SP cannot verify that SkolID has issued a
            SAML Assertion.
          </div>
        ) : null}

        <div className="form-group">
          <Input
            value={saml.entityId}
            onChange={(x) => updateSaml({ entityId: x })}
            label="Entity ID"
            disabled={disabled}
          />
        </div>

        <div className="form-group">
          <Input
            value={saml.assertionConsumerServiceUrl}
            onChange={(x) => updateSaml({ assertionConsumerServiceUrl: x })}
            label="Assertion consumer service url"
            disabled={disabled}
          />
        </div>

        <div className="form-group">
          <label>Assertion consumer service binding</label>
          <SelectSamlBinding
            binding={saml.assertionConsumerServiceBinding}
            onChange={(x) => updateSaml({ assertionConsumerServiceBinding: x })}
            disabled={disabled}
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={saml.wantAuthnRequestSigned}
            disabled={disabled}
            label="Require signed authentication requests (WantAuthnRequestSigned)"
            onChange={(checked) =>
              updateSaml({ wantAuthnRequestSigned: checked })
            }
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={saml.signAssertion}
            disabled={disabled}
            label="Sign Assertion"
            onChange={(checked) => updateSaml({ signAssertion: checked })}
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={saml.signSamlResponse}
            disabled={disabled}
            label="Sign SAML Response"
            onChange={(checked) => updateSaml({ signSamlResponse: checked })}
          />
        </div>

        <div className="form-group">
          <Input
            value={saml.singleLogoutServiceUrl}
            onChange={(x) => updateSaml({ singleLogoutServiceUrl: x })}
            label="Single logout service url"
            disabled={disabled}
          />
        </div>

        <div className="form-group">
          <label>Single logout service binding</label>
          <SelectSamlBinding
            binding={saml.singleLogoutServiceBinding}
            onChange={(x) => updateSaml({ singleLogoutServiceBinding: x })}
            disabled={disabled}
          />
        </div>

        <div className="form-group">
          <label>Name Id and format (Subject)</label>
          <SelectSamlNameIdFormat
            format={saml.nameIdFormat}
            onChange={(x) => updateSaml({ nameIdFormat: x })}
            disabled={disabled}
          />
          <p className="small mt-2">
            The value of the name id can be overriden with an attribute mapping
            using an empty target attribute.
          </p>
          {saml.nameIdFormat ===
          "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress" ? (
            <p className="small mt-1">
              <strong>NOTE</strong> that the value of the name id when using the emailAddress format comes
              from the user's username, NOT from the user's email.
            </p>
          ) : null}
        </div>

        <div className="form-group">
          <label>Signature algorithm</label>
          <SelectSamlSignatureAlgorithm
            signatureAlgorithm={saml.signatureAlgorithm}
            onChange={(x) => updateSaml({ signatureAlgorithm: x })}
            disabled={disabled}
          />
        </div>

        <div className="form-group">
          <label>Digest algorithm</label>
          <SelectSamlDigestAlgorithm
            digestAlgorithm={saml.digestAlgorithm}
            onChange={(x) => updateSaml({ digestAlgorithm: x })}
            disabled={disabled}
          />
        </div>

        <div className="form-group">
          <Input
            value={saml.localSigningCertificateThumbprintHint}
            onChange={(x) =>
              updateSaml({ localSigningCertificateThumbprintHint: x })
            }
            label="IdP Signing Certificate Thumbprint Hint"
            disabled={disabled}
          />
          <p className="small mt-2">
            Useful during signing key rollover when the client does not support
            IdP:s with multiple certificates. Set this field to the thumbprint
            of the next certificate when the client is switching trust to the
            new certificate. If this field is empty or if the thumbprint does
            not match any certificate the current key will be used for signing.
          </p>
        </div>
      </div>
    </div>
  );
};

export const SamlCertificatesForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const updateClient = props.updateClient;
  const updateSaml = (partialNewSaml: Partial<FormClient["samlSettings"]>) =>
    updateClient({
      samlSettings: { ...client.samlSettings, ...partialNewSaml },
    });
  const disabled = props.disabled;
  const saml = client.samlSettings;

  return (
    <div className="box">
      <div className="box-header">Certificates </div>
      <div className="box-body">
        <div className="form-group">
          <CertificateEditor
            certificates={saml.certificates}
            disabled={disabled}
            updateCertificates={(certificates) =>
              updateSaml(
                {
                  certificates,
                } as any /* When Apollo change to generate union of strings instead and then this cas can be removed */
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export const SamlMappingRulesForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const updateClient = props.updateClient;
  const updateSaml = (partialNewSaml: Partial<FormClient["samlSettings"]>) =>
    updateClient({
      samlSettings: { ...client.samlSettings, ...partialNewSaml },
    });
  const disabled = props.disabled;
  const saml = client.samlSettings;

  return (
    <div className="box">
      <div className="box-header">Attribute mappings </div>
      <div className="box-body">
        <div className="form-group">
          <ClientMappingRulesControl
            mappings={saml.mappingRules}
            disabled={disabled}
            updateMappings={(newMappings) =>
              updateSaml({ mappingRules: newMappings })
            }
          />
        </div>
      </div>
    </div>
  );
};

export const SamlValidACSUrlsForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const updateClient = props.updateClient;
  const updateSaml = (partialNewSaml: Partial<FormClient["samlSettings"]>) =>
    updateClient({
      samlSettings: { ...client.samlSettings, ...partialNewSaml },
    });
  const disabled = props.disabled;
  const saml = client.samlSettings;

  return (
    <div className="box">
      <div className="box-header">Valid asserion consumer service urls </div>
      <div className="box-body">
        <div className="form-group">
          <UrisControl
            disabled={disabled}
            updateUris={(newUris) =>
              updateSaml({ validAssertionConsumerServiceUrls: newUris })
            }
            uriName="assertion consumer url"
            uris={saml.validAssertionConsumerServiceUrls}
          />
        </div>
      </div>
    </div>
  );
};

export const SelectSamlSignatureAlgorithm = (props: {
  signatureAlgorithm: string;
  onChange: (newSignatureAlgorithm: string) => void;
  disabled?: boolean;
}) => {
  return (
    <Select
      options={[
        "http://www.w3.org/2000/09/xmldsig#rsa-sha1",
        "http://www.w3.org/2001/04/xmldsig-more#rsa-sha256",
        "http://www.w3.org/2001/04/xmldsig-more#rsa-sha384",
        "http://www.w3.org/2001/04/xmldsig-more#rsa-sha512",
      ].map((x) => ({
        label: x,
        value: x,
      }))}
      value={{
        value: props.signatureAlgorithm,
        label: props.signatureAlgorithm,
      }}
      isDisabled={props.disabled}
      onChange={(option: any) => props.onChange(option.value)}
    />
  );
};

export const SelectSamlDigestAlgorithm = (props: {
  digestAlgorithm: string;
  onChange: (newDigestAlgorithm: string) => void;
  disabled?: boolean;
}) => {
  return (
    <Select
      options={[
        "http://www.w3.org/2000/09/xmldsig#sha1",
        "http://www.w3.org/2001/04/xmlenc#sha256",
        "http://www.w3.org/2001/04/xmldsig-more#sha384",
        "http://www.w3.org/2001/04/xmlenc#sha512",
      ].map((x) => ({
        label: x,
        value: x,
      }))}
      value={{ value: props.digestAlgorithm, label: props.digestAlgorithm }}
      isDisabled={props.disabled}
      onChange={(option: any) => props.onChange(option.value)}
    />
  );
};

const supportedFormatOptions = [
  {
    label:
      "urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified (from user id)",
    value: "urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified",
  },
  {
    label:
      "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress (from username)",
    value: "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress",
  },
  {
    label:
      "urn:oasis:names:tc:SAML:2.0:nameid-format:persistent (from user id)",
    value: "urn:oasis:names:tc:SAML:2.0:nameid-format:persistent",
  },
];
export const SelectSamlNameIdFormat = (props: {
  format: string;
  onChange: (newFormat: string) => void;
  disabled?: boolean;
}) => {
  const inputFormatOptionArray = supportedFormatOptions.filter(
    (x) => x.value === props.format
  );
  return (
    <Select
      options={supportedFormatOptions}
      value={
        inputFormatOptionArray.length === 1
          ? inputFormatOptionArray[0]
          : { label: props.format, value: props.format }
      }
      isDisabled={props.disabled}
      onChange={(option: any) => props.onChange(option.value)}
    />
  );
};

type ClientMapping = FormClient["samlSettings"]["mappingRules"][0];

const ClientMappingRulesControl = (props: {
  mappings: ClientMapping[];
  disabled?: boolean;
  updateMappings: (mappings: ClientMapping[]) => void;
}) => {
  return (
    <>
      <p>
        Attribute mapping can be used to configure the SAML attributes
        using the claims from the allowed scopes. Note that an empty
        target attribute can be used to change/set the name id/subject of the SAML
        assertion.
      </p>
      {props.mappings.map((mapping, index) => {
        return (
          <div className="form-row mb-3" key={index}>
            <div className="col-3">
              <SelectSamlMappingType
                mappingType={mapping.type}
                disabled={props.disabled}
                onChange={(newType) =>
                  props.updateMappings(
                    props.mappings.map((x, i) =>
                      i === index ? { ...x, type: newType } : x
                    )
                  )
                }
              />
            </div>

            {mapping.type === SamlClientMappingRuleType.RENAME ||
            mapping.type === SamlClientMappingRuleType.COPY ? (
              <div className="col-4">
                <SelectClaim
                  selectedClaim={mapping.value}
                  disabled={props.disabled}
                  onChange={(text) =>
                    props.updateMappings(
                      props.mappings.map((x, i) =>
                        i === index ? { ...x, value: text } : x
                      )
                    )
                  }
                />
              </div>
            ) : null}

            {mapping.type !== SamlClientMappingRuleType.CLEAR ? (
              <div className="col-4">
                <Input
                  placeholder="Target attribute"
                  value={mapping.targetAttribute}
                  onChange={(text) =>
                    props.updateMappings(
                      props.mappings.map((x, i) =>
                        i === index ? { ...x, targetAttribute: text } : x
                      )
                    )
                  }
                  disabled={props.disabled}
                />
              </div>
            ) : null}

            {mapping.type === SamlClientMappingRuleType.CONSTANT ? (
              <div className="col-4">
                <Input
                  placeholder="Value"
                  value={mapping.value}
                  onChange={(text) =>
                    props.updateMappings(
                      props.mappings.map((x, i) =>
                        i === index ? { ...x, value: text } : x
                      )
                    )
                  }
                  disabled={props.disabled}
                />
              </div>
            ) : null}

            <div className="col text-right">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={() =>
                  props.updateMappings(
                    props.mappings.filter((x, i) => i !== index)
                  )
                }
                disabled={props.disabled}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          </div>
        );
      })}

      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() =>
          props.updateMappings(
            props.mappings.concat([
              {
                type: SamlClientMappingRuleType.RENAME,
                targetAttribute: "",
                value: "",
              },
            ])
          )
        }
      >
        Add mapping
      </button>
    </>
  );
};
