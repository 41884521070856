import * as React from "react";
import { Loa } from "../admin-gql";
import Select from "react-select";

export const SelectLoa = (props: { loa: Loa; onChange: (newLoa: Loa) => void; disabled?: boolean }) => {
  return (
    <Select
      options={[Loa.ZERO, Loa.ONE, Loa.TWO, Loa.THREE].map(x => ({
        label: loaDisplayNames[x],
        value: x
      }))}
      value={{ value: props.loa, label: loaDisplayNames[props.loa] }}
      isDisabled={props.disabled}
      onChange={(option: any) => props.onChange(option.value)}
    />
  );
};

const loaDisplayNames: { [x in Loa]: string } = {
  [Loa.ZERO]: "0 - No requirement",
  [Loa.ONE]: "1 - Base",
  [Loa.TWO]: "2 - High ",
  [Loa.THREE]: "3 - Very High",
  [Loa.MAX]: "3 - Very High"
};
