import { Input, Modal } from "@ist-group-private-scope/skolid-client-components";
import { PureQueryOptions } from "apollo-client";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useApolloClient } from "react-apollo";
import { SetPasswordVariables } from "../admin-gql";
import { Spinner } from "../Common/Spinner";

export const SetPasswordModal = (props: {
  onClose: () => void;
  userId: string;
  refetchQueries: Array<string | PureQueryOptions>;
}) => {
  const [password, setPassword] = useState("Demodemo1");
  const [error, setError] = useState("");
  const [executing, setExecuting] = useState(false);

  const client = useApolloClient();

  const updatePassword = async () => {
    const variables: SetPasswordVariables = {
      userId: props.userId,
      password,
    };
    try {
      setExecuting(true);

      await client.mutate({
        mutation: updatePasswordGql,
        variables,
        awaitRefetchQueries: true,
        refetchQueries: props.refetchQueries,
      });

      props.onClose();
    } catch (error) {
      setError("Failed to set password");
    } finally {
      setExecuting(false);
    }
  };

  return (
    <Modal header="Set Password" onClose={props.onClose}>
      {(close) => (
        <>
          <div className="modal-body">
            <div className="form-group">
              <Input
                value={password}
                onChange={setPassword}
                label="Password"
                validationErrorMessage={error}
                disabled={executing}
              />
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-between align-items-center">
            <button
              className="btn btn-secondary"
              onClick={close}
              disabled={executing}
            >
              Cancel
            </button>

            {executing ? <Spinner /> : null}

            <button
              className="btn btn-primary"
              onClick={updatePassword}
              disabled={executing}
            >
              Set Password
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

const updatePasswordGql = gql`
  mutation SetPassword($userId: String!, $password: String!) {
    updateUser(userId: $userId, user: { password: $password }) {
      id
    }
  }
`;
