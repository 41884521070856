import React, { useContext, useState } from "react";
import {
  TestMergeUsers,
  TestMergeUsersVariables,
  UserMergeMessageSeverity,
  MergeUsers,
  MergeUsersVariables,
} from "../admin-gql";
import {
  Modal,
  SelectUser,
  OptionUser,
  IdentityCard,
} from "@ist-group-private-scope/skolid-client-components";
import gql from "graphql-tag";
import { useQuery, useMutation, useApolloClient } from "react-apollo";
import { Spinner } from "../Common/Spinner";
import { useHistory } from "react-router-dom";
import { generateUserPath } from "../routes";
import classNames from "classnames";
import { SignContext } from "@ist-group-private-scope/web-skolid";

export const UserMergeModal = (props: {
  onClose: () => void;
  userId: string;
  organizationId: string;
}) => {
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState<OptionUser | null>(null);
  const [searchText, setSearchText] = useState("");

  const testQuery = useQuery<TestMergeUsers, TestMergeUsersVariables>(
    gql`
      query TestMergeUsers($userId1: String!, $userId2: String!) {
        user(id: $userId2) {
          id
          name
          nationalId
          tfNumber
          username
          organization {
            id
            name
            country
          }
        }

        testUserMerge(userId1: $userId1, userId2: $userId2) {
          messages {
            code
            severity
          }
        }
      }
    `,
    {
      skip: !selectedUser,
      variables: {
        userId1: props.userId,
        userId2: selectedUser ? selectedUser.id : "",
      },
    }
  );

  const [merge, mergeMutation] = useMutation<MergeUsers, MergeUsersVariables>(
    gql`
      mutation MergeUsers($userId1: String!, $userId2: String!) {
        mergeUsers(userId1: $userId1, userId2: $userId2) {
          id
        }
      }
    `,
    {
      variables: {
        userId1: props.userId,
        userId2: selectedUser ? selectedUser.id : "",
      },
      onCompleted: (result: MergeUsers) => {
        if (result.mergeUsers) {
          history.push(generateUserPath({ id: result.mergeUsers.id }));
        }
      },
    }
  );

  const disabled = testQuery.loading || mergeMutation.loading;

  const client = useApolloClient();
  const signContext = useContext(SignContext);

  return (
    <Modal header="Merge" onClose={props.onClose}>
      {(close) => (
        <>
          <div className="modal-body">
            <SelectUser
              onSelected={setSelectedUser}
              disabled={!!selectedUser}
              searchText={searchText}
              onSearchTextChange={setSearchText}
              placeholder="Select other user"
              context={{ client, signManager: signContext!, locale: "sv" }}
              organizationId={props.organizationId}
            />
            {testQuery.data && testQuery.data.user ? (
              <div>
                <div className="bg-secondary p-2 my-content">
                  <IdentityCard user={testQuery.data.user} />
                </div>
                {testQuery.data.testUserMerge.messages.map((x, index) => (
                  <div key={index} className="form-row">
                    <div
                      className={classNames("col alert", {
                        "alert-warning":
                          x.severity === UserMergeMessageSeverity.WARNING,
                        "alert-danger":
                          x.severity === UserMergeMessageSeverity.ERROR,
                      })}
                    >
                      {x.code}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {testQuery.error ? (
              <div className="alert alert-danger">Failed to verify merge</div>
            ) : null}
            {mergeMutation.error ? (
              <div className="alert alert-danger">Failed to merge users</div>
            ) : null}
          </div>
          <div className="modal-footer d-flex justify-content-between align-items-center">
            <button
              className="btn btn-secondary"
              onClick={close}
              disabled={disabled}
            >
              Cancel
            </button>

            {testQuery.loading || mergeMutation.loading ? <Spinner /> : null}

            <button
              className="btn btn-primary"
              onClick={() => merge()}
              disabled={!selectedUser || disabled}
            >
              Merge
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};
