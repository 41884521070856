/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddClient
// ====================================================

export interface AddClient_createClient_oidcSettings_clientSecrets {
  value: string | null;
}

export interface AddClient_createClient_oidcSettings {
  clientSecrets: AddClient_createClient_oidcSettings_clientSecrets[];
}

export interface AddClient_createClient {
  id: string;
  oidcSettings: AddClient_createClient_oidcSettings;
}

export interface AddClient {
  /**
   * Creates a new client in SkolID. Requires admin or client creator role.
   */
  createClient: AddClient_createClient | null;
}

export interface AddClientVariables {
  client: CreateClientInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportClients
// ====================================================

export interface ImportClients_importClients_importedClients_oidcSettings_clientSecrets {
  value: string | null;
}

export interface ImportClients_importClients_importedClients_oidcSettings {
  clientSecrets: ImportClients_importClients_importedClients_oidcSettings_clientSecrets[];
}

export interface ImportClients_importClients_importedClients {
  id: string;
  displayName: string | null;
  oidcSettings: ImportClients_importClients_importedClients_oidcSettings;
}

export interface ImportClients_importClients_importedApiResources {
  name: string;
}

export interface ImportClients_importClients {
  importedClients: ImportClients_importClients_importedClients[];
  importedApiResources: ImportClients_importClients_importedApiResources[];
}

export interface ImportClients {
  importClients: ImportClients_importClients | null;
}

export interface ImportClientsVariables {
  data: string;
  options: ImportClientsOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchClient
// ====================================================

export interface FetchClient_client_allowedIdps {
  id: string;
}

export interface FetchClient_client_properties {
  key: string;
  value: string;
}

export interface FetchClient_client_organization {
  id: string;
  name: string;
}

export interface FetchClient_client_userOrganizations {
  id: string;
  name: string;
}

export interface FetchClient_client_samlSettings_certificates {
  data: string | null;
  use: ClientSamlCertificateUse | null;
}

export interface FetchClient_client_samlSettings_mappingRules {
  type: SamlClientMappingRuleType;
  targetAttribute: string | null;
  value: string | null;
}

export interface FetchClient_client_samlSettings {
  entityId: string | null;
  assertionConsumerServiceUrl: string | null;
  assertionConsumerServiceBinding: string;
  signAssertion: boolean;
  signSamlResponse: boolean;
  certificates: FetchClient_client_samlSettings_certificates[];
  validAssertionConsumerServiceUrls: string[];
  singleLogoutServiceUrl: string | null;
  singleLogoutServiceBinding: string;
  singleLogoutServiceResponseUrl: string | null;
  mappingRules: FetchClient_client_samlSettings_mappingRules[];
  nameIdFormat: string;
  signatureAlgorithm: string;
  digestAlgorithm: string;
  wantAuthnRequestSigned: boolean;
  localSigningCertificateThumbprintHint: string | null;
}

export interface FetchClient_client_oidcSettings_clientSecrets {
  id: number;
  value: string | null;
}

export interface FetchClient_client_oidcSettings {
  redirectUris: string[];
  allowedCorsOrigins: string[];
  postLogoutRedirectUris: string[];
  clientSecrets: FetchClient_client_oidcSettings_clientSecrets[];
  allowedGrantTypes: string[];
  autoRedirectAfterLogout: boolean;
  includeNotBeforeClaim: boolean;
  requireClientSecret: boolean;
  alwaysIncludeUserClaimsInIdToken: boolean;
  requirePkce: boolean;
  allowPlainTextPkce: boolean;
  allowAccessTokensViaBrowser: boolean;
  frontChannelLogoutUri: string | null;
  frontChannelLogoutSessionRequired: boolean;
  backChannelLogoutUri: string | null;
  backChannelLogoutSessionRequired: boolean;
  allowOfflineAccess: boolean;
  accessTokenLifetime: number;
  identityTokenLifetime: number;
  authorizationCodeLifetime: number;
  absoluteRefreshTokenLifetime: number;
  slidingRefreshTokenLifetime: number;
  refreshTokenUsage: TokenUsage | null;
  refreshTokenExpiration: TokenExpiration | null;
  updateAccessTokenClaimsOnRefresh: boolean;
  accessTokenType: AccessTokenType | null;
  pairWiseSubjectSalt: string | null;
}

export interface FetchClient_client {
  id: string;
  displayName: string | null;
  description: string | null;
  clientUri: string | null;
  enabled: boolean;
  contact: string | null;
  protocolType: string;
  allowedScopes: string[];
  requireConsent: boolean;
  allowRememberConsent: boolean;
  consentLifetime: number | null;
  loaRequirement: Loa;
  sessionMaxAge: number | null;
  disableBankIdLogin: boolean;
  disableSkolidLogin: boolean;
  disableGoogleLogin: boolean;
  disableMicrosoftLogin: boolean;
  disableFrejaLogin: boolean;
  disableIdportenLogin: boolean;
  useTfNumberAsNationalIdFallback: boolean;
  assumeSkolidExtensionOnChromeOs: boolean;
  allowedIdps: FetchClient_client_allowedIdps[];
  properties: FetchClient_client_properties[];
  organization: FetchClient_client_organization | null;
  userOrganizations: FetchClient_client_userOrganizations[] | null;
  samlSettings: FetchClient_client_samlSettings;
  oidcSettings: FetchClient_client_oidcSettings;
}

export interface FetchClient {
  client: FetchClient_client | null;
}

export interface FetchClientVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateClient
// ====================================================

export interface UpdateClient_updateClient_allowedIdps {
  id: string;
}

export interface UpdateClient_updateClient_properties {
  key: string;
  value: string;
}

export interface UpdateClient_updateClient_organization {
  id: string;
  name: string;
}

export interface UpdateClient_updateClient_userOrganizations {
  id: string;
  name: string;
}

export interface UpdateClient_updateClient_samlSettings_certificates {
  data: string | null;
  use: ClientSamlCertificateUse | null;
}

export interface UpdateClient_updateClient_samlSettings_mappingRules {
  type: SamlClientMappingRuleType;
  targetAttribute: string | null;
  value: string | null;
}

export interface UpdateClient_updateClient_samlSettings {
  entityId: string | null;
  assertionConsumerServiceUrl: string | null;
  assertionConsumerServiceBinding: string;
  signAssertion: boolean;
  signSamlResponse: boolean;
  certificates: UpdateClient_updateClient_samlSettings_certificates[];
  validAssertionConsumerServiceUrls: string[];
  singleLogoutServiceUrl: string | null;
  singleLogoutServiceBinding: string;
  singleLogoutServiceResponseUrl: string | null;
  mappingRules: UpdateClient_updateClient_samlSettings_mappingRules[];
  nameIdFormat: string;
  signatureAlgorithm: string;
  digestAlgorithm: string;
  wantAuthnRequestSigned: boolean;
  localSigningCertificateThumbprintHint: string | null;
}

export interface UpdateClient_updateClient_oidcSettings_clientSecrets {
  id: number;
  value: string | null;
}

export interface UpdateClient_updateClient_oidcSettings {
  redirectUris: string[];
  allowedCorsOrigins: string[];
  postLogoutRedirectUris: string[];
  clientSecrets: UpdateClient_updateClient_oidcSettings_clientSecrets[];
  allowedGrantTypes: string[];
  autoRedirectAfterLogout: boolean;
  includeNotBeforeClaim: boolean;
  requireClientSecret: boolean;
  alwaysIncludeUserClaimsInIdToken: boolean;
  requirePkce: boolean;
  allowPlainTextPkce: boolean;
  allowAccessTokensViaBrowser: boolean;
  frontChannelLogoutUri: string | null;
  frontChannelLogoutSessionRequired: boolean;
  backChannelLogoutUri: string | null;
  backChannelLogoutSessionRequired: boolean;
  allowOfflineAccess: boolean;
  accessTokenLifetime: number;
  identityTokenLifetime: number;
  authorizationCodeLifetime: number;
  absoluteRefreshTokenLifetime: number;
  slidingRefreshTokenLifetime: number;
  refreshTokenUsage: TokenUsage | null;
  refreshTokenExpiration: TokenExpiration | null;
  updateAccessTokenClaimsOnRefresh: boolean;
  accessTokenType: AccessTokenType | null;
  pairWiseSubjectSalt: string | null;
}

export interface UpdateClient_updateClient {
  id: string;
  displayName: string | null;
  description: string | null;
  clientUri: string | null;
  enabled: boolean;
  contact: string | null;
  protocolType: string;
  allowedScopes: string[];
  requireConsent: boolean;
  allowRememberConsent: boolean;
  consentLifetime: number | null;
  loaRequirement: Loa;
  sessionMaxAge: number | null;
  disableBankIdLogin: boolean;
  disableSkolidLogin: boolean;
  disableGoogleLogin: boolean;
  disableMicrosoftLogin: boolean;
  disableFrejaLogin: boolean;
  disableIdportenLogin: boolean;
  useTfNumberAsNationalIdFallback: boolean;
  assumeSkolidExtensionOnChromeOs: boolean;
  allowedIdps: UpdateClient_updateClient_allowedIdps[];
  properties: UpdateClient_updateClient_properties[];
  organization: UpdateClient_updateClient_organization | null;
  userOrganizations: UpdateClient_updateClient_userOrganizations[] | null;
  samlSettings: UpdateClient_updateClient_samlSettings;
  oidcSettings: UpdateClient_updateClient_oidcSettings;
}

export interface UpdateClient {
  /**
   * Creates a new client in SkolID. Requires admin or client creator role.
   */
  updateClient: UpdateClient_updateClient | null;
}

export interface UpdateClientVariables {
  id: string;
  client: UpdateClientInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchClients
// ====================================================

export interface SearchClients_searchClients_edges_node_organization {
  id: string;
  name: string;
}

export interface SearchClients_searchClients_edges_node {
  id: string;
  clientId: string;
  displayName: string | null;
  organization: SearchClients_searchClients_edges_node_organization | null;
  clientUri: string | null;
}

export interface SearchClients_searchClients_edges {
  node: SearchClients_searchClients_edges_node;
  cursor: string;
}

export interface SearchClients_searchClients_pageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface SearchClients_searchClients {
  edges: SearchClients_searchClients_edges[];
  totalCount: number;
  pageInfo: SearchClients_searchClients_pageInfo;
}

export interface SearchClients {
  searchClients: SearchClients_searchClients;
}

export interface SearchClientsVariables {
  filter?: string | null;
  before?: string | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportClients
// ====================================================

export interface ExportClients {
  exportClients: string | null;
}

export interface ExportClientsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteClient
// ====================================================

export interface DeleteClient {
  /**
   * Deletes a client.
   */
  deleteClient: string | null;
}

export interface DeleteClientVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RandomString
// ====================================================

export interface RandomString {
  randomString: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportClient
// ====================================================

export interface ImportClient_importClientFromSamlMetadata {
  id: string;
}

export interface ImportClient {
  importClientFromSamlMetadata: ImportClient_importClientFromSamlMetadata;
}

export interface ImportClientVariables {
  metadata: string;
  organizationId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchClaims
// ====================================================

export interface FetchClaims {
  claims: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchGrantTypes
// ====================================================

export interface FetchGrantTypes {
  grantTypes: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchOrganizations
// ====================================================

export interface FetchOrganizations_organizations {
  id: string;
  name: string;
}

export interface FetchOrganizations {
  organizations: FetchOrganizations_organizations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchOrganizationsMulti
// ====================================================

export interface FetchOrganizationsMulti_organizations {
  id: string;
  name: string;
}

export interface FetchOrganizationsMulti {
  organizations: FetchOrganizationsMulti_organizations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchScopes
// ====================================================

export interface FetchScopes_apiResources_scopes {
  name: string;
}

export interface FetchScopes_apiResources {
  scopes: FetchScopes_apiResources_scopes[];
}

export interface FetchScopes_identityResources {
  name: string;
}

export interface FetchScopes {
  apiResources: FetchScopes_apiResources[];
  identityResources: FetchScopes_identityResources[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindSubjects
// ====================================================

export interface FindSubjects_searchUsers_nodes_organization {
  id: string;
  name: string;
}

export interface FindSubjects_searchUsers_nodes {
  id: string;
  name: string | null;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  organization: FindSubjects_searchUsers_nodes_organization;
}

export interface FindSubjects_searchUsers {
  nodes: FindSubjects_searchUsers_nodes[];
}

export interface FindSubjects_searchClients_nodes {
  id: string;
  displayName: string | null;
}

export interface FindSubjects_searchClients {
  nodes: FindSubjects_searchClients_nodes[];
}

export interface FindSubjects {
  searchUsers: FindSubjects_searchUsers;
  searchClients: FindSubjects_searchClients;
}

export interface FindSubjectsVariables {
  filter: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrganization
// ====================================================

export interface CreateOrganization_createOrganization_elevatedUsers_nodes {
  role: OrganizationRole;
  id: string;
  name: string | null;
}

export interface CreateOrganization_createOrganization_elevatedUsers {
  nodes: CreateOrganization_createOrganization_elevatedUsers_nodes[];
}

export interface CreateOrganization_createOrganization_clients {
  id: string;
  enabled: boolean;
  displayName: string | null;
  clientUri: string | null;
}

export interface CreateOrganization_createOrganization {
  id: string;
  name: string;
  issueLoa: Loa;
  country: CountryCode;
  elevatedUsers: CreateOrganization_createOrganization_elevatedUsers;
  clients: CreateOrganization_createOrganization_clients[];
}

export interface CreateOrganization {
  /**
   * Creates a new organization.
   */
  createOrganization: CreateOrganization_createOrganization | null;
}

export interface CreateOrganizationVariables {
  input: CreateOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrganization
// ====================================================

export interface UpdateOrganization_updateOrganization_elevatedUsers_nodes {
  role: OrganizationRole;
  id: string;
  name: string | null;
}

export interface UpdateOrganization_updateOrganization_elevatedUsers {
  nodes: UpdateOrganization_updateOrganization_elevatedUsers_nodes[];
}

export interface UpdateOrganization_updateOrganization_clients {
  id: string;
  enabled: boolean;
  displayName: string | null;
  clientUri: string | null;
}

export interface UpdateOrganization_updateOrganization {
  id: string;
  name: string;
  issueLoa: Loa;
  country: CountryCode;
  elevatedUsers: UpdateOrganization_updateOrganization_elevatedUsers;
  clients: UpdateOrganization_updateOrganization_clients[];
}

export interface UpdateOrganization {
  /**
   * Updates an organization.
   */
  updateOrganization: UpdateOrganization_updateOrganization | null;
}

export interface UpdateOrganizationVariables {
  id: string;
  input: UpdateOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchOrganization
// ====================================================

export interface FetchOrganization_organization_elevatedUsers_nodes {
  role: OrganizationRole;
  id: string;
  name: string | null;
}

export interface FetchOrganization_organization_elevatedUsers {
  nodes: FetchOrganization_organization_elevatedUsers_nodes[];
}

export interface FetchOrganization_organization_clients {
  id: string;
  enabled: boolean;
  displayName: string | null;
  clientUri: string | null;
}

export interface FetchOrganization_organization {
  id: string;
  name: string;
  issueLoa: Loa;
  country: CountryCode;
  elevatedUsers: FetchOrganization_organization_elevatedUsers;
  clients: FetchOrganization_organization_clients[];
}

export interface FetchOrganization {
  organization: FetchOrganization_organization | null;
}

export interface FetchOrganizationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveRole
// ====================================================

export interface RemoveRole_updateUser {
  id: string;
}

export interface RemoveRole {
  /**
   * Updates a user. This operation supports partial updates.
   */
  updateUser: RemoveRole_updateUser | null;
}

export interface RemoveRoleVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchAllOrganizations
// ====================================================

export interface FetchAllOrganizations_organizations {
  id: string;
  name: string;
  issueLoa: Loa;
}

export interface FetchAllOrganizations {
  organizations: FetchAllOrganizations_organizations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchApiResource
// ====================================================

export interface FetchApiResource_apiResource_scopes {
  name: string;
  displayName: string | null;
  description: string | null;
  required: boolean;
  emphasize: boolean;
  showInDiscoveryDocument: boolean;
  claims: string[];
}

export interface FetchApiResource_apiResource_secrets {
  id: number;
}

export interface FetchApiResource_apiResource {
  name: string;
  displayName: string | null;
  description: string | null;
  enabled: boolean;
  claims: string[];
  scopes: FetchApiResource_apiResource_scopes[];
  secrets: FetchApiResource_apiResource_secrets[];
}

export interface FetchApiResource {
  apiResource: FetchApiResource_apiResource | null;
}

export interface FetchApiResourceVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateApiResource
// ====================================================

export interface CreateApiResource_createApiResource_scopes {
  name: string;
  displayName: string | null;
  description: string | null;
  required: boolean;
  emphasize: boolean;
  showInDiscoveryDocument: boolean;
  claims: string[];
}

export interface CreateApiResource_createApiResource_secrets {
  id: number;
}

export interface CreateApiResource_createApiResource {
  name: string;
  displayName: string | null;
  description: string | null;
  enabled: boolean;
  claims: string[];
  scopes: CreateApiResource_createApiResource_scopes[];
  secrets: CreateApiResource_createApiResource_secrets[];
}

export interface CreateApiResource {
  /**
   * Creates a new api resource in SkolID.
   */
  createApiResource: CreateApiResource_createApiResource | null;
}

export interface CreateApiResourceVariables {
  input: CreateApiResourceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteApiResource
// ====================================================

export interface DeleteApiResource {
  /**
   * Deletes an api resource.
   */
  deleteApiResource: number | null;
}

export interface DeleteApiResourceVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateApiResource
// ====================================================

export interface UpdateApiResource_updateApiResource_scopes {
  name: string;
  displayName: string | null;
  description: string | null;
  required: boolean;
  emphasize: boolean;
  showInDiscoveryDocument: boolean;
  claims: string[];
}

export interface UpdateApiResource_updateApiResource_secrets {
  id: number;
}

export interface UpdateApiResource_updateApiResource {
  name: string;
  displayName: string | null;
  description: string | null;
  enabled: boolean;
  claims: string[];
  scopes: UpdateApiResource_updateApiResource_scopes[];
  secrets: UpdateApiResource_updateApiResource_secrets[];
}

export interface UpdateApiResource {
  /**
   * Creates a new identity resource in SkolID.
   */
  updateApiResource: UpdateApiResource_updateApiResource | null;
}

export interface UpdateApiResourceVariables {
  name: string;
  input: UpdateApiResourceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchIdentityResource
// ====================================================

export interface FetchIdentityResource_identityResource {
  id: string;
  name: string;
  displayName: string | null;
  description: string | null;
  enabled: boolean;
  claims: string[];
  required: boolean;
  emphasize: boolean;
  showInDiscoveryDocument: boolean;
}

export interface FetchIdentityResource {
  identityResource: FetchIdentityResource_identityResource | null;
}

export interface FetchIdentityResourceVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateIdentityResource
// ====================================================

export interface CreateIdentityResource_createIdentityResource {
  id: string;
  name: string;
  displayName: string | null;
  description: string | null;
  enabled: boolean;
  claims: string[];
  required: boolean;
  emphasize: boolean;
  showInDiscoveryDocument: boolean;
}

export interface CreateIdentityResource {
  /**
   * Creates a new identity resource in SkolID.
   */
  createIdentityResource: CreateIdentityResource_createIdentityResource | null;
}

export interface CreateIdentityResourceVariables {
  input: CreateIdentityResourceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteIdentityResource
// ====================================================

export interface DeleteIdentityResource {
  /**
   * Deletes an identity resource.
   */
  deleteIdentityResource: number | null;
}

export interface DeleteIdentityResourceVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateIdentityResource
// ====================================================

export interface UpdateIdentityResource_updateIdentityResource {
  id: string;
  name: string;
  displayName: string | null;
  description: string | null;
  enabled: boolean;
  claims: string[];
  required: boolean;
  emphasize: boolean;
  showInDiscoveryDocument: boolean;
}

export interface UpdateIdentityResource {
  /**
   * Creates a new identity resource in SkolID.
   */
  updateIdentityResource: UpdateIdentityResource_updateIdentityResource | null;
}

export interface UpdateIdentityResourceVariables {
  name: string;
  input: UpdateIdentityResourceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchApiResources
// ====================================================

export interface FetchApiResources_apiResources {
  name: string;
  displayName: string | null;
}

export interface FetchApiResources {
  apiResources: FetchApiResources_apiResources[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchIdentityResources
// ====================================================

export interface FetchIdentityResources_identityResources {
  name: string;
  displayName: string | null;
}

export interface FetchIdentityResources {
  identityResources: FetchIdentityResources_identityResources[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchAuditLog
// ====================================================

export interface FetchAuditLog_user_auditLog_edges_node {
  timestamp: any;
  activity: AuditLogActivity;
  actorUserId: string | null;
  additionalData: string | null;
  actorName: string | null;
  interface: string;
  operation: string | null;
  correlationId: string | null;
}

export interface FetchAuditLog_user_auditLog_edges {
  node: FetchAuditLog_user_auditLog_edges_node;
  cursor: string;
}

export interface FetchAuditLog_user_auditLog_pageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface FetchAuditLog_user_auditLog {
  edges: FetchAuditLog_user_auditLog_edges[];
  totalCount: number;
  pageInfo: FetchAuditLog_user_auditLog_pageInfo;
}

export interface FetchAuditLog_user {
  id: string;
  name: string | null;
  auditLog: FetchAuditLog_user_auditLog;
}

export interface FetchAuditLog {
  user: FetchAuditLog_user | null;
}

export interface FetchAuditLogVariables {
  userId: string;
  filter?: string | null;
  before?: string | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser {
  id: string;
}

export interface CreateUser {
  /**
   * Creates a new user.
   */
  createUser: CreateUser_createUser | null;
}

export interface CreateUserVariables {
  orgId: string;
  user: CreateUserInput;
  options: CreateUserOptionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IssueNewUserOrganization
// ====================================================

export interface IssueNewUserOrganization_organization {
  id: string;
  name: string;
  country: CountryCode;
}

export interface IssueNewUserOrganization {
  organization: IssueNewUserOrganization_organization | null;
}

export interface IssueNewUserOrganizationVariables {
  orgId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestMergeUsers
// ====================================================

export interface TestMergeUsers_user_organization {
  id: string;
  name: string;
  country: CountryCode;
}

export interface TestMergeUsers_user {
  id: string;
  name: string | null;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  organization: TestMergeUsers_user_organization;
}

export interface TestMergeUsers_testUserMerge_messages {
  code: string | null;
  severity: UserMergeMessageSeverity | null;
}

export interface TestMergeUsers_testUserMerge {
  messages: TestMergeUsers_testUserMerge_messages[];
}

export interface TestMergeUsers {
  user: TestMergeUsers_user | null;
  testUserMerge: TestMergeUsers_testUserMerge;
}

export interface TestMergeUsersVariables {
  userId1: string;
  userId2: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MergeUsers
// ====================================================

export interface MergeUsers_mergeUsers {
  id: string;
}

export interface MergeUsers {
  mergeUsers: MergeUsers_mergeUsers | null;
}

export interface MergeUsersVariables {
  userId1: string;
  userId2: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetPassword
// ====================================================

export interface SetPassword_updateUser {
  id: string;
}

export interface SetPassword {
  /**
   * Updates a user. This operation supports partial updates.
   */
  updateUser: SetPassword_updateUser | null;
}

export interface SetPasswordVariables {
  userId: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUser
// ====================================================

export interface DeleteUser {
  deleteUser: boolean | null;
}

export interface DeleteUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchUser
// ====================================================

export interface FetchUser_user_organization {
  id: string;
  name: string;
  country: CountryCode;
}

export interface FetchUser_user_images {
  /**
   * The width and the height of the image.
   */
  size: number;
  /**
   * The URL to the image.
   */
  url: string;
}

export interface FetchUser_user_phoneNumbers {
  id: string;
  value: string;
  use: PhoneNumberUse;
  canBeUsedForSmsCodes: boolean;
}

export interface FetchUser_user_emails {
  value: string;
  use: EmailUse | null;
  confirmed: boolean;
}

export interface FetchUser_user_icloudTokens {
  registeredAt: any;
}

export interface FetchUser_user_externalLogins {
  loginProvider: string;
  providerDisplayName: string | null;
  providerKey: string;
}

export interface FetchUser_user_devices {
  name: string;
  deviceId: string;
  loa: Loa | null;
  registeredAt: any;
  lastUsed: any | null;
}

export interface FetchUser_user_mergedIds {
  mergedId: string;
  createdAt: any;
}

export interface FetchUser_user_biometricKeys {
  biometricId: string;
  displayName: string;
  issuedAt: any;
  method: string;
  lastUsed: any | null;
  expireAt: any;
}

export interface FetchUser_user_addresses {
  streetAddress: string;
  postalCode: string;
  city: string;
  country: string | null;
  apartmentNumber: string | null;
  co: string | null;
  id: string;
  type: AddressType;
  primary: boolean;
}

export interface FetchUser_user {
  id: string;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  role: OrganizationRole;
  isSuperAdmin: boolean;
  organization: FetchUser_user_organization;
  loa: Loa;
  hasPassword: boolean;
  firstName: string | null;
  lastName: string | null;
  lastActivity: any | null;
  blocked: boolean;
  locked: boolean;
  expiresAt: any | null;
  /**
   * Images that represents the user.
   */
  images: FetchUser_user_images[];
  /**
   * All phone numbers for the user.
   */
  phoneNumbers: FetchUser_user_phoneNumbers[];
  emails: FetchUser_user_emails[];
  icloudTokens: FetchUser_user_icloudTokens[];
  name: string | null;
  externalLogins: FetchUser_user_externalLogins[];
  devices: FetchUser_user_devices[];
  mergedIds: FetchUser_user_mergedIds[];
  biometricKeys: FetchUser_user_biometricKeys[];
  addresses: FetchUser_user_addresses[];
}

export interface FetchUser {
  user: FetchUser_user | null;
}

export interface FetchUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser_organization {
  id: string;
  name: string;
  country: CountryCode;
}

export interface UpdateUser_updateUser_images {
  /**
   * The width and the height of the image.
   */
  size: number;
  /**
   * The URL to the image.
   */
  url: string;
}

export interface UpdateUser_updateUser_phoneNumbers {
  id: string;
  value: string;
  use: PhoneNumberUse;
  canBeUsedForSmsCodes: boolean;
}

export interface UpdateUser_updateUser_emails {
  value: string;
  use: EmailUse | null;
  confirmed: boolean;
}

export interface UpdateUser_updateUser_icloudTokens {
  registeredAt: any;
}

export interface UpdateUser_updateUser_externalLogins {
  loginProvider: string;
  providerDisplayName: string | null;
  providerKey: string;
}

export interface UpdateUser_updateUser_devices {
  name: string;
  deviceId: string;
  loa: Loa | null;
  registeredAt: any;
  lastUsed: any | null;
}

export interface UpdateUser_updateUser_mergedIds {
  mergedId: string;
  createdAt: any;
}

export interface UpdateUser_updateUser_biometricKeys {
  biometricId: string;
  displayName: string;
  issuedAt: any;
  method: string;
  lastUsed: any | null;
  expireAt: any;
}

export interface UpdateUser_updateUser_addresses {
  streetAddress: string;
  postalCode: string;
  city: string;
  country: string | null;
  apartmentNumber: string | null;
  co: string | null;
  id: string;
  type: AddressType;
  primary: boolean;
}

export interface UpdateUser_updateUser {
  id: string;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  role: OrganizationRole;
  isSuperAdmin: boolean;
  organization: UpdateUser_updateUser_organization;
  loa: Loa;
  hasPassword: boolean;
  firstName: string | null;
  lastName: string | null;
  lastActivity: any | null;
  blocked: boolean;
  locked: boolean;
  expiresAt: any | null;
  /**
   * Images that represents the user.
   */
  images: UpdateUser_updateUser_images[];
  /**
   * All phone numbers for the user.
   */
  phoneNumbers: UpdateUser_updateUser_phoneNumbers[];
  emails: UpdateUser_updateUser_emails[];
  icloudTokens: UpdateUser_updateUser_icloudTokens[];
  name: string | null;
  externalLogins: UpdateUser_updateUser_externalLogins[];
  devices: UpdateUser_updateUser_devices[];
  mergedIds: UpdateUser_updateUser_mergedIds[];
  biometricKeys: UpdateUser_updateUser_biometricKeys[];
  addresses: UpdateUser_updateUser_addresses[];
}

export interface UpdateUser {
  /**
   * Updates a user. This operation supports partial updates.
   */
  updateUser: UpdateUser_updateUser | null;
}

export interface UpdateUserVariables {
  userId: string;
  user: UpdateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchUsers
// ====================================================

export interface SearchUsers_searchUsers_edges_node_organization {
  id: string;
  name: string;
  country: CountryCode;
}

export interface SearchUsers_searchUsers_edges_node {
  id: string;
  name: string | null;
  organization: SearchUsers_searchUsers_edges_node_organization;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  lastActivity: any | null;
}

export interface SearchUsers_searchUsers_edges {
  node: SearchUsers_searchUsers_edges_node;
  cursor: string;
}

export interface SearchUsers_searchUsers_pageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface SearchUsers_searchUsers {
  edges: SearchUsers_searchUsers_edges[];
  totalCount: number;
  pageInfo: SearchUsers_searchUsers_pageInfo;
}

export interface SearchUsers {
  searchUsers: SearchUsers_searchUsers;
}

export interface SearchUsersVariables {
  filter?: string | null;
  before?: string | null;
  after?: string | null;
  organizationId?: string | null;
  searchUsersOptions?: SearchUsersOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientSearchResult
// ====================================================

export interface ClientSearchResult_organization {
  id: string;
  name: string;
}

export interface ClientSearchResult {
  id: string;
  clientId: string;
  displayName: string | null;
  organization: ClientSearchResult_organization | null;
  clientUri: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientFragment
// ====================================================

export interface ClientFragment_allowedIdps {
  id: string;
}

export interface ClientFragment_properties {
  key: string;
  value: string;
}

export interface ClientFragment_organization {
  id: string;
  name: string;
}

export interface ClientFragment_userOrganizations {
  id: string;
  name: string;
}

export interface ClientFragment_samlSettings_certificates {
  data: string | null;
  use: ClientSamlCertificateUse | null;
}

export interface ClientFragment_samlSettings_mappingRules {
  type: SamlClientMappingRuleType;
  targetAttribute: string | null;
  value: string | null;
}

export interface ClientFragment_samlSettings {
  entityId: string | null;
  assertionConsumerServiceUrl: string | null;
  assertionConsumerServiceBinding: string;
  signAssertion: boolean;
  signSamlResponse: boolean;
  certificates: ClientFragment_samlSettings_certificates[];
  validAssertionConsumerServiceUrls: string[];
  singleLogoutServiceUrl: string | null;
  singleLogoutServiceBinding: string;
  singleLogoutServiceResponseUrl: string | null;
  mappingRules: ClientFragment_samlSettings_mappingRules[];
  nameIdFormat: string;
  signatureAlgorithm: string;
  digestAlgorithm: string;
  wantAuthnRequestSigned: boolean;
  localSigningCertificateThumbprintHint: string | null;
}

export interface ClientFragment_oidcSettings_clientSecrets {
  id: number;
  value: string | null;
}

export interface ClientFragment_oidcSettings {
  redirectUris: string[];
  allowedCorsOrigins: string[];
  postLogoutRedirectUris: string[];
  clientSecrets: ClientFragment_oidcSettings_clientSecrets[];
  allowedGrantTypes: string[];
  autoRedirectAfterLogout: boolean;
  includeNotBeforeClaim: boolean;
  requireClientSecret: boolean;
  alwaysIncludeUserClaimsInIdToken: boolean;
  requirePkce: boolean;
  allowPlainTextPkce: boolean;
  allowAccessTokensViaBrowser: boolean;
  frontChannelLogoutUri: string | null;
  frontChannelLogoutSessionRequired: boolean;
  backChannelLogoutUri: string | null;
  backChannelLogoutSessionRequired: boolean;
  allowOfflineAccess: boolean;
  accessTokenLifetime: number;
  identityTokenLifetime: number;
  authorizationCodeLifetime: number;
  absoluteRefreshTokenLifetime: number;
  slidingRefreshTokenLifetime: number;
  refreshTokenUsage: TokenUsage | null;
  refreshTokenExpiration: TokenExpiration | null;
  updateAccessTokenClaimsOnRefresh: boolean;
  accessTokenType: AccessTokenType | null;
  pairWiseSubjectSalt: string | null;
}

export interface ClientFragment {
  id: string;
  displayName: string | null;
  description: string | null;
  clientUri: string | null;
  enabled: boolean;
  contact: string | null;
  protocolType: string;
  allowedScopes: string[];
  requireConsent: boolean;
  allowRememberConsent: boolean;
  consentLifetime: number | null;
  loaRequirement: Loa;
  sessionMaxAge: number | null;
  disableBankIdLogin: boolean;
  disableSkolidLogin: boolean;
  disableGoogleLogin: boolean;
  disableMicrosoftLogin: boolean;
  disableFrejaLogin: boolean;
  disableIdportenLogin: boolean;
  useTfNumberAsNationalIdFallback: boolean;
  assumeSkolidExtensionOnChromeOs: boolean;
  allowedIdps: ClientFragment_allowedIdps[];
  properties: ClientFragment_properties[];
  organization: ClientFragment_organization | null;
  userOrganizations: ClientFragment_userOrganizations[] | null;
  samlSettings: ClientFragment_samlSettings;
  oidcSettings: ClientFragment_oidcSettings;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationFragment
// ====================================================

export interface OrganizationFragment_elevatedUsers_nodes {
  role: OrganizationRole;
  id: string;
  name: string | null;
}

export interface OrganizationFragment_elevatedUsers {
  nodes: OrganizationFragment_elevatedUsers_nodes[];
}

export interface OrganizationFragment_clients {
  id: string;
  enabled: boolean;
  displayName: string | null;
  clientUri: string | null;
}

export interface OrganizationFragment {
  id: string;
  name: string;
  issueLoa: Loa;
  country: CountryCode;
  elevatedUsers: OrganizationFragment_elevatedUsers;
  clients: OrganizationFragment_clients[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ApiResourceFragment
// ====================================================

export interface ApiResourceFragment_scopes {
  name: string;
  displayName: string | null;
  description: string | null;
  required: boolean;
  emphasize: boolean;
  showInDiscoveryDocument: boolean;
  claims: string[];
}

export interface ApiResourceFragment_secrets {
  id: number;
}

export interface ApiResourceFragment {
  name: string;
  displayName: string | null;
  description: string | null;
  enabled: boolean;
  claims: string[];
  scopes: ApiResourceFragment_scopes[];
  secrets: ApiResourceFragment_secrets[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IdentityResourceFragment
// ====================================================

export interface IdentityResourceFragment {
  id: string;
  name: string;
  displayName: string | null;
  description: string | null;
  enabled: boolean;
  claims: string[];
  required: boolean;
  emphasize: boolean;
  showInDiscoveryDocument: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuditLogEntryFragment
// ====================================================

export interface AuditLogEntryFragment {
  timestamp: any;
  activity: AuditLogActivity;
  actorUserId: string | null;
  additionalData: string | null;
  actorName: string | null;
  interface: string;
  operation: string | null;
  correlationId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFragment
// ====================================================

export interface UserFragment_organization {
  id: string;
  name: string;
  country: CountryCode;
}

export interface UserFragment_images {
  /**
   * The width and the height of the image.
   */
  size: number;
  /**
   * The URL to the image.
   */
  url: string;
}

export interface UserFragment_phoneNumbers {
  id: string;
  value: string;
  use: PhoneNumberUse;
  canBeUsedForSmsCodes: boolean;
}

export interface UserFragment_emails {
  value: string;
  use: EmailUse | null;
  confirmed: boolean;
}

export interface UserFragment_icloudTokens {
  registeredAt: any;
}

export interface UserFragment_externalLogins {
  loginProvider: string;
  providerDisplayName: string | null;
  providerKey: string;
}

export interface UserFragment_devices {
  name: string;
  deviceId: string;
  loa: Loa | null;
  registeredAt: any;
  lastUsed: any | null;
}

export interface UserFragment_mergedIds {
  mergedId: string;
  createdAt: any;
}

export interface UserFragment_biometricKeys {
  biometricId: string;
  displayName: string;
  issuedAt: any;
  method: string;
  lastUsed: any | null;
  expireAt: any;
}

export interface UserFragment_addresses {
  streetAddress: string;
  postalCode: string;
  city: string;
  country: string | null;
  apartmentNumber: string | null;
  co: string | null;
  id: string;
  type: AddressType;
  primary: boolean;
}

export interface UserFragment {
  id: string;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  role: OrganizationRole;
  isSuperAdmin: boolean;
  organization: UserFragment_organization;
  loa: Loa;
  hasPassword: boolean;
  firstName: string | null;
  lastName: string | null;
  lastActivity: any | null;
  blocked: boolean;
  locked: boolean;
  expiresAt: any | null;
  /**
   * Images that represents the user.
   */
  images: UserFragment_images[];
  /**
   * All phone numbers for the user.
   */
  phoneNumbers: UserFragment_phoneNumbers[];
  emails: UserFragment_emails[];
  icloudTokens: UserFragment_icloudTokens[];
  name: string | null;
  externalLogins: UserFragment_externalLogins[];
  devices: UserFragment_devices[];
  mergedIds: UserFragment_mergedIds[];
  biometricKeys: UserFragment_biometricKeys[];
  addresses: UserFragment_addresses[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UsersSearchResult
// ====================================================

export interface UsersSearchResult_organization {
  id: string;
  name: string;
  country: CountryCode;
}

export interface UsersSearchResult {
  id: string;
  name: string | null;
  organization: UsersSearchResult_organization;
  /**
   * The full normalized national identification number for the user for the given country. 
   */
  nationalId: string | null;
  tfNumber: string | null;
  username: string | null;
  lastActivity: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessTokenType {
  JWT = "JWT",
  REFERENCE = "REFERENCE",
}

export enum AddressType {
  HOME = "HOME",
  OFFICIAL = "OFFICIAL",
  OTHER = "OTHER",
}

export enum AuditLogActivity {
  ACCOUNTS_MERGED = "ACCOUNTS_MERGED",
  ACTIVATED = "ACTIVATED",
  ADDRESS_ADDED = "ADDRESS_ADDED",
  ADDRESS_REMOVED = "ADDRESS_REMOVED",
  AUTHENTICATOR_ADDED = "AUTHENTICATOR_ADDED",
  AUTHENTICATOR_REMOVED = "AUTHENTICATOR_REMOVED",
  BIOMETRIC_KEY_ADDED = "BIOMETRIC_KEY_ADDED",
  BIOMETRIC_KEY_REMOVED = "BIOMETRIC_KEY_REMOVED",
  BLOCKED = "BLOCKED",
  CLIENT_CERTIFICATE_ADDED = "CLIENT_CERTIFICATE_ADDED",
  CLIENT_CERTIFICATE_REMOVED = "CLIENT_CERTIFICATE_REMOVED",
  CREDENTIALS_REVOKED = "CREDENTIALS_REVOKED",
  DEVICE_ADDED = "DEVICE_ADDED",
  DEVICE_REMOVED = "DEVICE_REMOVED",
  EMAIL_ADDED = "EMAIL_ADDED",
  EMAIL_CONFIRMED = "EMAIL_CONFIRMED",
  EMAIL_REMOVED = "EMAIL_REMOVED",
  EMAIL_UPDATED = "EMAIL_UPDATED",
  EXTERNAL_LOGIN_ADDED = "EXTERNAL_LOGIN_ADDED",
  EXTERNAL_LOGIN_REMOVED = "EXTERNAL_LOGIN_REMOVED",
  FIDO_AUTHENTICATOR_ADDED = "FIDO_AUTHENTICATOR_ADDED",
  FIDO_AUTHENTICATOR_REMOVED = "FIDO_AUTHENTICATOR_REMOVED",
  ISSUED = "ISSUED",
  I_CLOUD_TOKEN_ADDED = "I_CLOUD_TOKEN_ADDED",
  I_CLOUD_TOKEN_REMOVED = "I_CLOUD_TOKEN_REMOVED",
  PASSWORD_CHANGED = "PASSWORD_CHANGED",
  PASSWORD_RESET = "PASSWORD_RESET",
  PASSWORD_RESET_REQUESTED = "PASSWORD_RESET_REQUESTED",
  PASSWORD_SET = "PASSWORD_SET",
  PHONE_NUMBER_ADDED = "PHONE_NUMBER_ADDED",
  PHONE_NUMBER_REMOVED = "PHONE_NUMBER_REMOVED",
  RELATION_ADDED = "RELATION_ADDED",
  RELATION_REMOVED = "RELATION_REMOVED",
  SELF_REGISTRATION = "SELF_REGISTRATION",
  SET_PRIMARY_EMAIL = "SET_PRIMARY_EMAIL",
  SIGN_IN_TICKET_CREATED = "SIGN_IN_TICKET_CREATED",
  UNLOCKED = "UNLOCKED",
  UNSUCCESSFUL_U_2_F_LOGIN = "UNSUCCESSFUL_U_2_F_LOGIN",
  USERNAME_REQUESTED = "USERNAME_REQUESTED",
  USER_AUTO_CREATED = "USER_AUTO_CREATED",
  USER_CREATED = "USER_CREATED",
  USER_CREATED_BY_ADMIN = "USER_CREATED_BY_ADMIN",
  USER_CREATED_VIA_API = "USER_CREATED_VIA_API",
  USER_DELETED = "USER_DELETED",
  USER_IMPORTED = "USER_IMPORTED",
  USER_UPDATED = "USER_UPDATED",
}

export enum ClientSamlCertificateUse {
  ANY = "ANY",
  ENCRYPTION = "ENCRYPTION",
  SIGNATURE = "SIGNATURE",
}

export enum CountryCode {
  DE = "DE",
  DK = "DK",
  NO = "NO",
  SE = "SE",
}

export enum EmailUse {
  PRIVATE = "PRIVATE",
  PROFESSIONAL = "PROFESSIONAL",
  SKOLID_INTERNAL = "SKOLID_INTERNAL",
}

export enum Loa {
  MAX = "MAX",
  ONE = "ONE",
  THREE = "THREE",
  TWO = "TWO",
  ZERO = "ZERO",
}

export enum OrganizationRole {
  ADMIN = "ADMIN",
  ISSUER = "ISSUER",
  USER = "USER",
}

export enum PhoneNumberUse {
  ONLY_SMS_CODES = "ONLY_SMS_CODES",
  PRIVATE = "PRIVATE",
  PROFESSIONAL = "PROFESSIONAL",
}

export enum SamlClientMappingRuleType {
  CLEAR = "CLEAR",
  CONSTANT = "CONSTANT",
  COPY = "COPY",
  RENAME = "RENAME",
}

export enum SortDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum TokenExpiration {
  ABSOLUTE = "ABSOLUTE",
  SLIDING = "SLIDING",
}

export enum TokenUsage {
  ONE_TIME_ONLY = "ONE_TIME_ONLY",
  RE_USE = "RE_USE",
}

export enum UserMergeMessageSeverity {
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export enum UserOrderBy {
  LAST_ACTIVITY = "LAST_ACTIVITY",
  NAME = "NAME",
}

export interface AddressInput {
  id?: string | null;
  primary?: boolean | null;
  streetAddress: string;
  city: string;
  postalCode: string;
  apartmentNumber?: string | null;
  co?: string | null;
  country?: string | null;
  type: AddressType;
}

export interface ClientSamlCertificateInput {
  data?: string | null;
  use?: ClientSamlCertificateUse | null;
}

export interface ClientSamlMappingRuleInput {
  type: SamlClientMappingRuleType;
  targetAttribute?: string | null;
  value?: string | null;
}

export interface ClientSecretInput {
  id?: number | null;
  value?: string | null;
}

/**
 * An api resource represents an API and contains a set of scopes that a client can request consent to.
 */
export interface CreateApiResourceInput {
  name: string;
  displayName: string;
  description?: string | null;
  enabled?: boolean | null;
  claims?: string[] | null;
  scopes: CreateApiResourceScopeInput[];
  secrets?: CreateApiResourceSecretInput[] | null;
}

/**
 * An api resource scope.
 */
export interface CreateApiResourceScopeInput {
  name: string;
  displayName: string;
  description?: string | null;
  claims: string[];
  required?: boolean | null;
  showInDiscoveryDocument?: boolean | null;
  emphasize?: boolean | null;
}

/**
 * An api resource secret.
 */
export interface CreateApiResourceSecretInput {
  value: string;
}

export interface CreateClientInput {
  clientId?: string | null;
  displayName: string;
  frontChannelLogoutUri?: string | null;
  allowedGrantTypes?: string[] | null;
  redirectUris?: string[] | null;
  postLogoutRedirectUris?: string[] | null;
  autoRedirectAfterLogout?: boolean | null;
  includeNotBeforeClaim?: boolean | null;
  description?: string | null;
  clientUri?: string | null;
  enabled?: boolean | null;
  protocolType?: string | null;
  allowedScopes?: string[] | null;
  sessionMaxAge?: number | null;
  requireConsent?: boolean | null;
  allowRememberConsent?: boolean | null;
  consentLifetime?: number | null;
  organizationId?: string | null;
  userOrganizationIds?: string[] | null;
  contact?: string | null;
  properties?: CreateClientPropertyInput[] | null;
  allowedIdps?: string[] | null;
  loaRequirement?: Loa | null;
  disableBankIdLogin?: boolean | null;
  disableIdportenLogin?: boolean | null;
  disableSkolidLogin?: boolean | null;
  disableFrejaLogin?: boolean | null;
  disableMicrosoftLogin?: boolean | null;
  disableGoogleLogin?: boolean | null;
  useTfNumberAsNationalIdFallback?: boolean | null;
  assumeSkolidExtensionOnChromeOs?: boolean | null;
  oidcSettings?: CreateClientOidcSettingsInput | null;
  samlSettings?: CreateClientSamlSettingsInput | null;
}

export interface CreateClientOidcSettingsInput {
  allowedGrantTypes?: string[] | null;
  redirectUris?: string[] | null;
  postLogoutRedirectUris?: string[] | null;
  autoRedirectAfterLogout?: boolean | null;
  includeNotBeforeClaim?: boolean | null;
  requireClientSecret?: boolean | null;
  alwaysIncludeUserClaimsInIdToken?: boolean | null;
  requirePkce?: boolean | null;
  allowPlainTextPkce?: boolean | null;
  allowAccessTokensViaBrowser?: boolean | null;
  frontChannelLogoutUri?: string | null;
  frontChannelLogoutSessionRequired?: boolean | null;
  backChannelLogoutUri?: string | null;
  backChannelLogoutSessionRequired?: boolean | null;
  allowOfflineAccess?: boolean | null;
  accessTokenLifetime?: number | null;
  identityTokenLifetime?: number | null;
  authorizationCodeLifetime?: number | null;
  absoluteRefreshTokenLifetime?: number | null;
  refreshTokenUsage?: TokenUsage | null;
  updateAccessTokenClaimsOnRefresh?: boolean | null;
  refreshTokenExpiration?: TokenExpiration | null;
  accessTokenType?: AccessTokenType | null;
  slidingRefreshTokenLifetime?: number | null;
  pairWiseSubjectSalt?: string | null;
  allowedCorsOrigins?: string[] | null;
  clientSecrets?: ClientSecretInput[] | null;
}

export interface CreateClientPropertyInput {
  key: string;
  value: string;
}

export interface CreateClientSamlSettingsInput {
  entityId?: string | null;
  assertionConsumerServiceUrl?: string | null;
  assertionConsumerServiceBinding?: string | null;
  signAssertion?: boolean | null;
  signSamlResponse?: boolean | null;
  wantAuthnRequestSigned?: boolean | null;
  validAssertionConsumerServiceUrls?: string[] | null;
  certificates?: ClientSamlCertificateInput[] | null;
  mappingRules?: ClientSamlMappingRuleInput[] | null;
  singleLogoutServiceUrl?: string | null;
  singleLogoutServiceResponseUrl?: string | null;
  singleLogoutServiceBinding?: string | null;
  nameIdFormat?: string | null;
  signatureAlgorithm?: string | null;
  digestAlgorithm?: string | null;
  localSigningCertificateThumbprintHint?: string | null;
}

/**
 * An identity resource is a scope (group of claims) that can be requested by a client.
 */
export interface CreateIdentityResourceInput {
  name: string;
  displayName: string;
  description?: string | null;
  claims: string[];
  enabled?: boolean | null;
  required?: boolean | null;
  showInDiscoveryDocument?: boolean | null;
  emphasize?: boolean | null;
}

export interface CreateOrganizationInput {
  id?: string | null;
  name: string;
  issueLoa?: Loa | null;
  country?: CountryCode | null;
}

export interface CreateUserInput {
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  tfNumber?: string | null;
  username?: string | null;
  role?: OrganizationRole | null;
  loa?: Loa | null;
  phoneNumber?: CreateUserPhoneNumberInput | null;
  password?: string | null;
  securityLogData?: string | null;
  emails?: EmailInput[] | null;
  phoneNumbers?: PhoneNumberInput[] | null;
  addresses?: AddressInput[] | null;
  expiresAt?: any | null;
}

export interface CreateUserOptionsInput {
  sendWelcomeEmail?: boolean | null;
  silent?: boolean | null;
}

export interface CreateUserPhoneNumberInput {
  value: string;
  confirmed: boolean;
}

export interface EmailInput {
  use?: EmailUse | null;
  value: string;
  confirmed: boolean;
}

export interface ImportClientsOptionsInput {
  generateNewSecrets?: boolean | null;
}

export interface OrderOptionsInput {
  orderBy: UserOrderBy;
  sortDirection?: SortDirection | null;
}

export interface PhoneNumberInput {
  id?: string | null;
  value: string;
  use?: PhoneNumberUse | null;
  canBeUsedForSmsCodes?: boolean | null;
}

export interface SearchUsersOptionsInput {
  userOrderByOptions?: OrderOptionsInput[] | null;
  isOnlyIssued?: boolean | null;
}

/**
 * An api resource represents an API and contains a set of scopes that a client can request consent to.
 */
export interface UpdateApiResourceInput {
  displayName?: string | null;
  description?: string | null;
  enabled?: boolean | null;
  claims?: string[] | null;
  scopes?: UpdateApiResourceScopeInput[] | null;
  secrets?: UpdateApiResourceSecretInput[] | null;
}

/**
 * An api resource scope.
 */
export interface UpdateApiResourceScopeInput {
  name: string;
  displayName?: string | null;
  description?: string | null;
  claims?: string[] | null;
  required?: boolean | null;
  showInDiscoveryDocument?: boolean | null;
  emphasize?: boolean | null;
}

/**
 * An api resource secret.
 */
export interface UpdateApiResourceSecretInput {
  id?: number | null;
  value?: string | null;
}

export interface UpdateClientInput {
  displayName?: string | null;
  description?: string | null;
  clientUri?: string | null;
  enabled?: boolean | null;
  protocolType?: string | null;
  allowedScopes?: string[] | null;
  sessionMaxAge?: number | null;
  requireConsent?: boolean | null;
  allowRememberConsent?: boolean | null;
  consentLifetime?: number | null;
  organizationId?: string | null;
  userOrganizationIds?: string[] | null;
  contact?: string | null;
  properties?: CreateClientPropertyInput[] | null;
  allowedIdps?: string[] | null;
  loaRequirement?: Loa | null;
  disableBankIdLogin?: boolean | null;
  disableIdportenLogin?: boolean | null;
  disableSkolidLogin?: boolean | null;
  disableFrejaLogin?: boolean | null;
  disableMicrosoftLogin?: boolean | null;
  disableGoogleLogin?: boolean | null;
  useTfNumberAsNationalIdFallback?: boolean | null;
  assumeSkolidExtensionOnChromeOs?: boolean | null;
  oidcSettings?: UpdateClientOidcSettingsInput | null;
  samlSettings?: UpdateClientSamlSettingsInput | null;
}

export interface UpdateClientOidcSettingsInput {
  allowedGrantTypes?: string[] | null;
  redirectUris?: string[] | null;
  postLogoutRedirectUris?: string[] | null;
  autoRedirectAfterLogout?: boolean | null;
  includeNotBeforeClaim?: boolean | null;
  requireClientSecret?: boolean | null;
  alwaysIncludeUserClaimsInIdToken?: boolean | null;
  requirePkce?: boolean | null;
  allowPlainTextPkce?: boolean | null;
  allowAccessTokensViaBrowser?: boolean | null;
  frontChannelLogoutUri?: string | null;
  frontChannelLogoutSessionRequired?: boolean | null;
  backChannelLogoutUri?: string | null;
  backChannelLogoutSessionRequired?: boolean | null;
  allowOfflineAccess?: boolean | null;
  accessTokenLifetime?: number | null;
  identityTokenLifetime?: number | null;
  authorizationCodeLifetime?: number | null;
  absoluteRefreshTokenLifetime?: number | null;
  refreshTokenUsage?: TokenUsage | null;
  updateAccessTokenClaimsOnRefresh?: boolean | null;
  refreshTokenExpiration?: TokenExpiration | null;
  accessTokenType?: AccessTokenType | null;
  slidingRefreshTokenLifetime?: number | null;
  pairWiseSubjectSalt?: string | null;
  allowedCorsOrigins?: string[] | null;
  clientSecrets?: ClientSecretInput[] | null;
}

export interface UpdateClientSamlSettingsInput {
  entityId?: string | null;
  assertionConsumerServiceUrl?: string | null;
  assertionConsumerServiceBinding?: string | null;
  signAssertion?: boolean | null;
  signSamlResponse?: boolean | null;
  wantAuthnRequestSigned?: boolean | null;
  validAssertionConsumerServiceUrls?: string[] | null;
  certificates?: ClientSamlCertificateInput[] | null;
  mappingRules?: ClientSamlMappingRuleInput[] | null;
  singleLogoutServiceUrl?: string | null;
  singleLogoutServiceResponseUrl?: string | null;
  singleLogoutServiceBinding?: string | null;
  nameIdFormat?: string | null;
  signatureAlgorithm?: string | null;
  digestAlgorithm?: string | null;
  localSigningCertificateThumbprintHint?: string | null;
}

export interface UpdateIdentityResourceInput {
  displayName?: string | null;
  description?: string | null;
  claims?: string[] | null;
  enabled?: boolean | null;
  required?: boolean | null;
  showInDiscoveryDocument?: boolean | null;
  emphasize?: boolean | null;
}

export interface UpdateOrganizationInput {
  name?: string | null;
  issueLoa?: Loa | null;
}

export interface UpdateUserInput {
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  tfNumber?: string | null;
  username?: string | null;
  phoneNumbers?: PhoneNumberInput[] | null;
  emails?: EmailInput[] | null;
  password?: string | null;
  role?: OrganizationRole | null;
  addresses?: AddressInput[] | null;
  isSuperAdmin?: boolean | null;
  expiresAt?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

export interface QueryApiResourceArgs {
  name: string;
}
export interface QueryClientArgs {
  id: string;
}
export interface QueryClientsArgs {
  ids: ((string)[]) ;
}
export interface QueryExportClientsArgs {
  ids: ((string)[]) ;
}
export interface QueryGroupArgs {
  organizationId: string;
  id: string;
}
export interface QueryIdentityResourceArgs {
  name: string;
}
export interface QueryOrganizationArgs {
  id: string;
}
export interface QuerySearchClientsArgs {
  filter: string| null;
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
}
export interface QuerySearchUsersArgs {
  organizationId: string| null;
  filter: string| null;
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
  searchUsersOptions: SearchUsersOptionsInput| null;
}
export interface QueryTestUserMergeArgs {
  userId1: string;
  userId2: string;
}
export interface QueryUserArgs {
  id: string;
}
export interface QueryUserByEmailArgs {
  value: string;
  organizationId: string;
}
export interface QueryUserByNationalIdArgs {
  value: string;
  organizationId: string;
}
export interface QueryUserByTfNumberArgs {
  value: string;
  organizationId: string;
}
export interface QueryUserByUsernameArgs {
  value: string;
  organizationId: string;
}
export interface QueryUsersArgs {
  ids: ((string)[]) | null;
}
export interface QueryUsersByKeyArgs {
  key: string;
  organizationId: string;
}
export interface QueryValidatePhoneNumberArgs {
  number: string;
  country: CountryCode| null;
}

export interface ApiResource {
  claims: ((string)[]) ;
  description?: string| null;
  displayName?: string| null;
  enabled: boolean;
  name: string;
  scopes: ((ApiScope)[]) ;
  secrets: ((ApiResourceSecret)[]) ;
}

export interface ApiScope {
  claims: ((string)[]) ;
  description?: string| null;
  displayName?: string| null;
  emphasize: boolean;
  name: string;
  required: boolean;
  showInDiscoveryDocument: boolean;
}

export interface ApiResourceSecret {
  id: number;
}

export interface Client {
  allowedGrantTypes: ((string| null)[]) ;
  allowedIdps: ((Idp)[]) ;
  allowedScopes: ((string)[]) ;
  allowRememberConsent: boolean;
  assumeSkolidExtensionOnChromeOs: boolean;
  autoRedirectAfterLogout: boolean;
  clientId: string;
  clientSecrets: ((ClientSecret| null)[]) ;
  clientUri?: string| null;
  consentLifetime?: number| null;
  contact?: string| null;
  description?: string| null;
  disableBankIdLogin: boolean;
  disableFrejaLogin: boolean;
  disableGoogleLogin: boolean;
  disableIdportenLogin: boolean;
  disableMicrosoftLogin: boolean;
  disableSkolidLogin: boolean;
  displayName?: string| null;
  enabled: boolean;
  id: string;
  includeNotBeforeClaim: boolean;
  loaRequirement: Loa;
  oidcSettings: ClientOidcSettings;
  organization?: Organization| null;
  postLogoutRedirectUris: ((string| null)[]) ;
  properties: ((ClientProperty)[]) ;
  protocolType: string;
  redirectUris: ((string| null)[]) ;
  requireConsent: boolean;
  samlSettings: ClientSamlSettings;
  sessionMaxAge?: number| null;
  userOrganizations?: ((Organization)[]) | null;
  useTfNumberAsNationalIdFallback: boolean;
}

export interface Idp {
  id: string;
}

export interface ClientSecret {
  createdAt: DateTimeScalar;
  id: number;
  value?: string| null;
}

export type DateTimeScalar<T = any> = T;

export interface ClientOidcSettings {
  absoluteRefreshTokenLifetime: number;
  accessTokenLifetime: number;
  accessTokenType?: AccessTokenType| null;
  allowAccessTokensViaBrowser: boolean;
  allowedCorsOrigins: ((string)[]) ;
  allowedGrantTypes: ((string)[]) ;
  allowOfflineAccess: boolean;
  allowPlainTextPkce: boolean;
  alwaysIncludeUserClaimsInIdToken: boolean;
  authorizationCodeLifetime: number;
  autoRedirectAfterLogout: boolean;
  backChannelLogoutSessionRequired: boolean;
  backChannelLogoutUri?: string| null;
  clientSecrets: ((ClientSecret)[]) ;
  frontChannelLogoutSessionRequired: boolean;
  frontChannelLogoutUri?: string| null;
  identityTokenLifetime: number;
  includeNotBeforeClaim: boolean;
  pairWiseSubjectSalt?: string| null;
  postLogoutRedirectUris: ((string)[]) ;
  redirectUris: ((string)[]) ;
  refreshTokenExpiration?: TokenExpiration| null;
  refreshTokenUsage?: TokenUsage| null;
  requireClientSecret: boolean;
  requirePkce: boolean;
  slidingRefreshTokenLifetime: number;
  updateAccessTokenClaimsOnRefresh: boolean;
}

export interface Organization {
  clients: ((Client)[]) ;
  country: CountryCode;
  group: Group;
  id: string;
  issueLoa: Loa;
  name: string;
  users: UserConnection;
}
export interface OrganizationUsersArgs {
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
  roles: ((OrganizationRole| null)[]) | null;
}

export interface Group {
  children: GroupsConnection;
  createdAt: DateTimeScalar;
  expiresAt?: DateTimeScalar| null;
  id: string;
  members: GroupMembersConnection;
  name: string;
  organization: Organization;
  parent?: Group| null;
}
export interface GroupMembersArgs {
  filter: string| null;
  roles: ((GroupMemberRole)[]) | null;
  includeDescendants: boolean| null;
  onlyWithRelations: boolean| null;
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
}

export interface GroupsConnection {
  nodes: ((Group)[]) ;
  totalCount: number;
}

export enum GroupMemberRole {
  ADMIN = "ADMIN",
  ISSUER = "ISSUER",
  USER = "USER",
}

export interface GroupMembersConnection {
  edges: ((GroupMemberEdge)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface GroupMemberEdge {
  confirmedAt?: DateTimeScalar| null;
  group: Group;
  relations: ((GroupMemberRelationGraphType)[]) ;
  role: GroupMemberRole;
  user: User;
}

export interface GroupMemberRelationGraphType {
  description?: string| null;
  targetUser: User;
}

export interface User {
  addresses: ((UserAddress)[]) ;
  adminIdentities: ((AdminIdentityGraphType)[]) ;
  auditLog: AuditLogConnection;
  biometricKeys: ((BiometricKey)[]) ;
  blocked: boolean;
  devices: ((Device)[]) ;
  emails: ((Email)[]) ;
  expiresAt?: DateTimeScalar| null;
  externalLogins: ((ExternalLogin)[]) ;
  firstName?: string| null;
  forceTwoFactor: boolean;
  groups: UserGroupsConnection;
  hasPassword: boolean;
  icloudTokens: ((ICloudToken)[]) ;
  id: string;
  images: ((UserPhoto)[]) ;
  incomingRelations: IncomingUserRelationConnection;
  issuableByUser: boolean;
  isSuperAdmin: boolean;
  lastActivity?: DateTimeScalar| null;
  lastIssued?: DateTimeScalar| null;
  lastName?: string| null;
  loa: Loa;
  locked: boolean;
  mergedIds: ((MergedId)[]) ;
  name?: string| null;
  nationalId?: string| null;
  organization: Organization;
  phoneNumber: PhoneNumber;
  phoneNumbers: ((PhoneNumber)[]) ;
  relations: UserRelationConnection;
  role: OrganizationRole;
  tfNumber?: string| null;
  username?: string| null;
}
export interface UserAuditLogArgs {
  filter: string| null;
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
}
export interface UserGroupsArgs {
  organizationId: string| null;
  onlyWithIssueAccess: boolean| null;
  first: number| null;
  after: string| null;
  last: number| null;
  before: string| null;
}
export interface UserIssuableByUserArgs {
  userId: string| null;
}

export interface UserAddress {
  apartmentNumber?: string| null;
  city: string;
  co?: string| null;
  country?: string| null;
  createdAt: DateTimeScalar;
  id: string;
  postalCode: string;
  primary: boolean;
  streetAddress: string;
  type: AddressType;
}

export interface AdminIdentityGraphType {
  groupRole?: GroupMemberRole| null;
  id: string;
  name?: string| null;
  organization: AdminIdentityOrganizationGraphType;
  role: OrganizationRole;
}

export interface AdminIdentityOrganizationGraphType {
  country: CountryCode;
  id: string;
  issueLoa: Loa;
  name: string;
}

export interface AuditLogConnection {
  edges: ((AuditLogEntryEdge)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface AuditLogEntryEdge {
  cursor: string;
  node: AuditLogEntry;
}

export interface AuditLogEntry {
  activity: AuditLogActivity;
  actorName?: string| null;
  actorUserId?: string| null;
  additionalData?: string| null;
  correlationId?: string| null;
  interface: string;
  operation?: string| null;
  timestamp: DateTimeScalar;
}

export interface PageInfo {
  endCursor?: string| null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string| null;
}

export interface BiometricKey {
  biometricId: string;
  displayName: string;
  expireAt: DateTimeScalar;
  issuedAt: DateTimeScalar;
  lastUsed?: DateTimeScalar| null;
  method: string;
}

export interface Device {
  deviceId: string;
  lastUsed?: DateTimeScalar| null;
  loa?: Loa| null;
  name: string;
  registeredAt: DateTimeScalar;
}

export interface Email {
  confirmed: boolean;
  createdAt: DateTimeScalar;
  isConfirmed: boolean;
  isPrimary: boolean;
  primary: boolean;
  use?: EmailUse| null;
  value: string;
}

export interface ExternalLogin {
  loginProvider: string;
  providerDisplayName?: string| null;
  providerKey: string;
}

export interface UserGroupsConnection {
  edges: ((UserGroupEdge)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface UserGroupEdge {
  node: Group;
  role: GroupMemberRole;
}

export interface ICloudToken {
  registeredAt: DateTimeScalar;
}

export interface UserPhoto {
  size: number;
  url: string;
}

export interface IncomingUserRelationConnection {
  edges: ((UserRelationEdge)[]) ;
}

export interface UserRelationEdge {
  fromNode?: User| null;
  node?: User| null;
  role?: UserRelationRole| null;
  summary?: string| null;
}

export enum UserRelationRole {
  PARENT = "PARENT",
  CHILD = "CHILD",
  GENERIC = "GENERIC",
  PARTNER = "PARTNER",
}

export interface MergedId {
  createdAt: DateTimeScalar;
  mergedId: string;
}

export interface PhoneNumber {
  canBeUsedForSmsCodes: boolean;
  createdAt: DateTimeScalar;
  id: string;
  use: PhoneNumberUse;
  value: string;
}

export interface UserRelationConnection {
  edges: ((UserRelationEdge)[]) ;
}

export interface UserConnection {
  nodes: ((User)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface ClientProperty {
  key: string;
  value: string;
}

export interface ClientSamlSettings {
  assertionConsumerServiceBinding: string;
  assertionConsumerServiceUrl?: string| null;
  certificates: ((ClientSamlCertificate)[]) ;
  digestAlgorithm: string;
  entityId?: string| null;
  localSigningCertificateThumbprintHint?: string| null;
  mappingRules: ((ClientSamlMappingRule)[]) ;
  nameIdFormat: string;
  signAssertion: boolean;
  signatureAlgorithm: string;
  signSamlResponse: boolean;
  singleLogoutServiceBinding: string;
  singleLogoutServiceResponseUrl?: string| null;
  singleLogoutServiceUrl?: string| null;
  validAssertionConsumerServiceUrls: ((string)[]) ;
  wantAuthnRequestSigned: boolean;
}

export interface ClientSamlCertificate {
  data?: string| null;
  use?: ClientSamlCertificateUse| null;
}

export interface ClientSamlMappingRule {
  targetAttribute?: string| null;
  type: SamlClientMappingRuleType;
  value?: string| null;
}

export interface IdentityResource {
  claims: ((string)[]) ;
  description?: string| null;
  displayName?: string| null;
  emphasize: boolean;
  enabled: boolean;
  name: string;
  required: boolean;
  showInDiscoveryDocument: boolean;
}

export interface SearchClientsConnection {
  edges: ((SearchClientsEdge)[]) ;
  nodes: ((Client)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface SearchClientsEdge {
  cursor: string;
  node: Client;
}

export interface SearchUsersOptionsInput {
  userOrderByOptions?: ((OrderOptionsInput)[]) | null;
  isOnlyIssued?: boolean| null;
}

export interface OrderOptionsInput {
  orderBy: UserOrderBy;
  sortDirection?: SortDirection| null;
}

export interface SearchUsersConnection {
  edges: ((SearchUsersEdge)[]) ;
  nodes: ((User)[]) ;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface SearchUsersEdge {
  cursor: string;
  node: User;
}

export interface TestUserMergeResult {
  messages: ((UserMergeMessage)[]) ;
}

export interface UserMergeMessage {
  code?: string| null;
  severity?: UserMergeMessageSeverity| null;
}

export interface MutationAddAllowedClientScopesArgs {
  clientId: string;
  scopes: ((string)[]) ;
}
export interface MutationAddEmailsArgs {
  userId: string;
  emails: ((EmailInput)[]) ;
  options: AddEmailsOptionsInput;
}
export interface MutationAddGroupMembersArgs {
  organizationId: string;
  edges: ((GroupMemberEdgeInput)[]) ;
}
export interface MutationBlockUserArgs {
  userId: string;
  reason: string;
  signature: string;
}
export interface MutationConfirmEmailArgs {
  userId: string;
  email: string;
}
export interface MutationCreateAndIssueUserArgs {
  organizationId: string;
  user: CreateUserInput;
  options: CreateAndIssueUserOptionsInput;
  signature: string;
}
export interface MutationCreateApiResourceArgs {
  input: CreateApiResourceInput;
}
export interface MutationCreateClientArgs {
  input: CreateClientInput;
}
export interface MutationCreateClientSecretArgs {
  clientId: string;
}
export interface MutationCreateIdentityResourceArgs {
  input: CreateIdentityResourceInput;
}
export interface MutationCreateOrganizationArgs {
  input: CreateOrganizationInput;
}
export interface MutationCreateUserArgs {
  organizationId: string;
  user: CreateUserInput;
  options: CreateUserOptionsInput;
}
export interface MutationCreateUsersArgs {
  organizationId: string;
  users: ((CreateUserInput)[]) ;
  options: CreateUsersOptionsInput;
}
export interface MutationDeleteApiResourceArgs {
  name: string;
}
export interface MutationDeleteClientArgs {
  id: string;
}
export interface MutationDeleteIdentityResourceArgs {
  name: string;
}
export interface MutationDeleteOrganizationArgs {
  id: string;
}
export interface MutationDeleteUserArgs {
  id: string;
}
export interface MutationDeleteUserByOrganizationAdminArgs {
  id: string;
}
export interface MutationGenerateBlockUserContractArgs {
  userId: string;
  reason: string;
}
export interface MutationGenerateCreateAndIssueUserContractArgs {
  organizationId: string;
  user: CreateUserInput;
  options: CreateAndIssueUserOptionsInput;
}
export interface MutationGenerateImportAndIssueUsersContractArgs {
  organizationId: string;
  users: ((ImportUserInput)[]) ;
  options: ImportAndIssueUsersOptionsInput;
}
export interface MutationGenerateIssueUserContractArgs {
  userId: string;
  options: IssueUserOptionsInput;
}
export interface MutationGenerateIssueUsersContractArgs {
  userIds: ((string)[]) ;
  options: IssueUsersOptionsInput;
}
export interface MutationGenerateSignInTicketArgs {
  userId: string;
}
export interface MutationImportAndIssueUsersArgs {
  organizationId: string;
  users: ((ImportUserInput)[]) ;
  options: ImportAndIssueUsersOptionsInput;
  signatures: ((string)[]) | null;
}
export interface MutationImportClientFromSamlMetadataArgs {
  metadata: string;
  organizationId: string| null;
}
export interface MutationImportClientsArgs {
  data: string;
  options: ImportClientsOptionsInput;
}
export interface MutationImportGroupsArgs {
  organizationId: string;
  groups: ((GroupImportInput)[]) ;
}
export interface MutationImportUsersArgs {
  organizationId: string;
  users: ((ImportUserInput)[]) ;
}
export interface MutationIssueUserArgs {
  userId: string;
  options: IssueUserOptionsInput;
  signature: string;
}
export interface MutationIssueUsersArgs {
  userIds: ((string)[]) ;
  options: IssueUsersOptionsInput;
  signatures: ((string)[]) | null;
}
export interface MutationIssueUsersToActivationRequestsArgs {
  requests: ((IssueUsersToActivationRequestsInput)[]) ;
  options: IssueUsersOptionsInput;
  signatures: ((string)[]) ;
}
export interface MutationIssueUserToActivationRequestArgs {
  userId: string;
  activationRequestId: string;
  options: IssueUserOptionsInput;
  signature: string;
}
export interface MutationMergeUsersArgs {
  userId1: string;
  userId2: string;
}
export interface MutationRemoveClientSecretArgs {
  clientId: string;
  secretId: number;
}
export interface MutationRemoveEmailsArgs {
  userId: string;
  emails: ((string)[]) ;
}
export interface MutationRemoveGroupMembersArgs {
  organizationId: string;
  edges: ((GroupMemberEdgeIdentifierInput)[]) ;
}
export interface MutationTestImportAndIssueUsersArgs {
  organizationId: string;
  users: ((ImportUserInput)[]) ;
  options: ImportAndIssueUsersOptionsInput;
}
export interface MutationUnlockUserArgs {
  id: string;
}
export interface MutationUpdateApiResourceArgs {
  name: string;
  input: UpdateApiResourceInput;
}
export interface MutationUpdateClientArgs {
  id: string;
  input: UpdateClientInput;
}
export interface MutationUpdateIdentityResourceArgs {
  name: string;
  input: UpdateIdentityResourceInput;
}
export interface MutationUpdateOrganizationArgs {
  id: string;
  input: UpdateOrganizationInput;
}
export interface MutationUpdateUserArgs {
  userId: string;
  user: UpdateUserInput;
  options: UpdateUserOptionsInput| null;
}

export interface AddAllowedClientScopesResult {
  dummy?: boolean| null;
}

export interface EmailInput {
  use?: EmailUse| null;
  value: string;
  confirmed: boolean;
}

export interface AddEmailsOptionsInput {
  silent?: boolean| null;
}

export interface GroupMemberEdgeInput {
  userId: string;
  groupId: string;
  role: GroupMemberRole;
  expiresAt?: DateTimeScalar| null;
  confirmedAt?: DateTimeScalar| null;
}

export interface AddGroupMembersResult {
  addedMembers: ((GroupMemberEdge)[]) ;
  dummy?: boolean| null;
}

export interface CreateUserInput {
  firstName?: string| null;
  lastName?: string| null;
  nationalId?: string| null;
  tfNumber?: string| null;
  username?: string| null;
  role?: OrganizationRole| null;
  loa?: Loa| null;
  phoneNumber?: CreateUserPhoneNumberInput| null;
  password?: string| null;
  securityLogData?: string| null;
  emails?: ((EmailInput)[]) | null;
  phoneNumbers?: ((PhoneNumberInput)[]) | null;
  addresses?: ((AddressInput)[]) | null;
  expiresAt?: DateTimeScalar| null;
}

export interface CreateUserPhoneNumberInput {
  value: string;
  confirmed: boolean;
}

export interface PhoneNumberInput {
  id?: string| null;
  value: string;
  use?: PhoneNumberUse| null;
  canBeUsedForSmsCodes?: boolean| null;
}

export interface AddressInput {
  id?: string| null;
  primary?: boolean| null;
  streetAddress: string;
  city: string;
  postalCode: string;
  apartmentNumber?: string| null;
  co?: string| null;
  country?: string| null;
  type: AddressType;
}

export interface CreateAndIssueUserOptionsInput {
  method: IssueMethod;
  loa: Loa;
  silent?: boolean| null;
}

export enum IssueMethod {
  EMAIL = "EMAIL",
  EMAIL_SMS = "EMAIL_SMS",
  CODE = "CODE",
  ACTIVATION_REQUEST = "ACTIVATION_REQUEST",
}

export interface IssueUserResult {
  activationCode?: string| null;
  activationCodeExpiresAt?: DateTimeScalar| null;
  user: User;
}

export interface CreateApiResourceInput {
  name: string;
  displayName: string;
  description?: string| null;
  enabled?: boolean| null;
  claims?: ((string)[]) | null;
  scopes: ((CreateApiResourceScopeInput)[]) ;
  secrets?: ((CreateApiResourceSecretInput)[]) | null;
}

export interface CreateApiResourceScopeInput {
  name: string;
  displayName: string;
  description?: string| null;
  claims: ((string)[]) ;
  required?: boolean| null;
  showInDiscoveryDocument?: boolean| null;
  emphasize?: boolean| null;
}

export interface CreateApiResourceSecretInput {
  value: string;
}

export interface CreateClientInput {
  clientId?: string| null;
  displayName: string;
  frontChannelLogoutUri?: string| null;
  allowedGrantTypes?: ((string)[]) | null;
  redirectUris?: ((string)[]) | null;
  postLogoutRedirectUris?: ((string)[]) | null;
  autoRedirectAfterLogout?: boolean| null;
  includeNotBeforeClaim?: boolean| null;
  description?: string| null;
  clientUri?: string| null;
  enabled?: boolean| null;
  protocolType?: string| null;
  allowedScopes?: ((string)[]) | null;
  sessionMaxAge?: number| null;
  requireConsent?: boolean| null;
  allowRememberConsent?: boolean| null;
  consentLifetime?: number| null;
  organizationId?: string| null;
  userOrganizationIds?: ((string)[]) | null;
  contact?: string| null;
  properties?: ((CreateClientPropertyInput)[]) | null;
  allowedIdps?: ((string)[]) | null;
  loaRequirement?: Loa| null;
  disableBankIdLogin?: boolean| null;
  disableIdportenLogin?: boolean| null;
  disableSkolidLogin?: boolean| null;
  disableFrejaLogin?: boolean| null;
  disableMicrosoftLogin?: boolean| null;
  disableGoogleLogin?: boolean| null;
  useTfNumberAsNationalIdFallback?: boolean| null;
  assumeSkolidExtensionOnChromeOs?: boolean| null;
  oidcSettings?: CreateClientOidcSettingsInput| null;
  samlSettings?: CreateClientSamlSettingsInput| null;
}

export interface CreateClientPropertyInput {
  key: string;
  value: string;
}

export interface CreateClientOidcSettingsInput {
  allowedGrantTypes?: ((string)[]) | null;
  redirectUris?: ((string)[]) | null;
  postLogoutRedirectUris?: ((string)[]) | null;
  autoRedirectAfterLogout?: boolean| null;
  includeNotBeforeClaim?: boolean| null;
  requireClientSecret?: boolean| null;
  alwaysIncludeUserClaimsInIdToken?: boolean| null;
  requirePkce?: boolean| null;
  allowPlainTextPkce?: boolean| null;
  allowAccessTokensViaBrowser?: boolean| null;
  frontChannelLogoutUri?: string| null;
  frontChannelLogoutSessionRequired?: boolean| null;
  backChannelLogoutUri?: string| null;
  backChannelLogoutSessionRequired?: boolean| null;
  allowOfflineAccess?: boolean| null;
  accessTokenLifetime?: number| null;
  identityTokenLifetime?: number| null;
  authorizationCodeLifetime?: number| null;
  absoluteRefreshTokenLifetime?: number| null;
  refreshTokenUsage?: TokenUsage| null;
  updateAccessTokenClaimsOnRefresh?: boolean| null;
  refreshTokenExpiration?: TokenExpiration| null;
  accessTokenType?: AccessTokenType| null;
  slidingRefreshTokenLifetime?: number| null;
  pairWiseSubjectSalt?: string| null;
  allowedCorsOrigins?: ((string)[]) | null;
  clientSecrets?: ((ClientSecretInput)[]) | null;
}

export interface ClientSecretInput {
  id?: number| null;
  value?: string| null;
}

export interface CreateClientSamlSettingsInput {
  entityId?: string| null;
  assertionConsumerServiceUrl?: string| null;
  assertionConsumerServiceBinding?: string| null;
  signAssertion?: boolean| null;
  signSamlResponse?: boolean| null;
  wantAuthnRequestSigned?: boolean| null;
  validAssertionConsumerServiceUrls?: ((string)[]) | null;
  certificates?: ((ClientSamlCertificateInput)[]) | null;
  mappingRules?: ((ClientSamlMappingRuleInput)[]) | null;
  singleLogoutServiceUrl?: string| null;
  singleLogoutServiceResponseUrl?: string| null;
  singleLogoutServiceBinding?: string| null;
  nameIdFormat?: string| null;
  signatureAlgorithm?: string| null;
  digestAlgorithm?: string| null;
  localSigningCertificateThumbprintHint?: string| null;
}

export interface ClientSamlCertificateInput {
  data?: string| null;
  use?: ClientSamlCertificateUse| null;
}

export interface ClientSamlMappingRuleInput {
  type: SamlClientMappingRuleType;
  targetAttribute?: string| null;
  value?: string| null;
}

export interface CreateClientSecretResponseType {
  errorMessage?: string| null;
  secret?: ClientSecret| null;
}

export interface CreateIdentityResourceInput {
  name: string;
  displayName: string;
  description?: string| null;
  claims: ((string)[]) ;
  enabled?: boolean| null;
  required?: boolean| null;
  showInDiscoveryDocument?: boolean| null;
  emphasize?: boolean| null;
}

export interface CreateOrganizationInput {
  id?: string| null;
  name: string;
  issueLoa?: Loa| null;
  country?: CountryCode| null;
}

export interface CreateUserOptionsInput {
  sendWelcomeEmail?: boolean| null;
  silent?: boolean| null;
}

export interface CreateUsersOptionsInput {
  silent?: boolean| null;
}

export interface CreateUsersResult {
  dummy?: boolean| null;
  results: ((CreateUserResult)[]) ;
}

export interface CreateUserResult {
  error?: BusinessError| null;
  user?: User| null;
}

export interface BusinessError {
  code: string;
  message: string;
}

export interface Contract {
  data: string;
  text: string;
}

export interface ImportUserInput {
  nationalId?: string| null;
  tfNumber?: string| null;
  nationalIdOrTfNumber?: string| null;
  username?: string| null;
  firstName: string;
  lastName: string;
  phoneNumber?: string| null;
  email?: string| null;
  emails?: ((ImportUserEmailInput)[]) | null;
  password?: string| null;
  expiresAt?: DateTimeScalar| null;
}

export interface ImportUserEmailInput {
  value?: string| null;
}

export interface ImportAndIssueUsersOptionsInput {
  issueMethod: IssueMethod;
  loa: Loa;
}

export interface MultiContract {
  data: ((string)[]) ;
  text: string;
}

export interface IssueUserOptionsInput {
  method: IssueMethod;
  loa: Loa;
}

export interface IssueUsersOptionsInput {
  issueMethod: IssueMethod;
  loa: Loa;
}

export interface ImportAndIssueUsersResult {
  activationCodesExpiresAt?: DateTimeScalar| null;
  dummy?: boolean| null;
  entries: ((ImportAndIssueUserResult)[]) ;
}

export interface ImportAndIssueUserResult {
  activationCode?: string| null;
  activationCodeExpiresAt?: DateTimeScalar| null;
  errors: ((string)[]) ;
  importAction: ImportUserAction;
  importUser: ImportUser;
  user?: User| null;
}

export enum ImportUserAction {
  NOT_MODIFIED = "NOT_MODIFIED",
  UPDATE = "UPDATE",
  CREATE = "CREATE",
  ERROR = "ERROR",
  IGNORED = "IGNORED",
}

export interface ImportUser {
  email: ImportUserField;
  firstName: ImportUserField;
  lastName: ImportUserField;
  nationalId: ImportUserField;
  password: ImportUserPasswordField;
  phoneNumber: ImportUserField;
  tfNumber: ImportUserField;
  valid: boolean;
}

export interface ImportUserField {
  normalizedValue?: string| null;
  originalValue?: string| null;
  status: ImportUserFieldStatus;
}

export enum ImportUserFieldStatus {
  VALID = "VALID",
  INVALID = "INVALID",
  MISSING = "MISSING",
  CONFLICT = "CONFLICT",
}

export interface ImportUserPasswordField {
  errors: ((string)[]) ;
  status: ImportUserPasswordFieldStatus;
}

export enum ImportUserPasswordFieldStatus {
  NO_IMPORT_PASSWORD = "NO_IMPORT_PASSWORD",
  PASSWORD_IMPORT_NOT_ALLOWED = "PASSWORD_IMPORT_NOT_ALLOWED",
  BAD_PASSWORD = "BAD_PASSWORD",
  VALID = "VALID",
}

export interface ImportClientsOptionsInput {
  generateNewSecrets?: boolean| null;
}

export interface ClientImportResult {
  importedApiResources: ((ApiResource)[]) ;
  importedClients: ((Client)[]) ;
}

export interface GroupImportInput {
  children?: ((GroupChildImportInput)[]) | null;
  members?: ((GroupImportMemberInput)[]) | null;
  expiresAt?: DateTimeScalar| null;
  name?: string| null;
  id: string;
  parentId?: string| null;
}

export interface GroupChildImportInput {
  children?: ((GroupChildImportInput)[]) | null;
  members?: ((GroupImportMemberInput)[]) | null;
  expiresAt?: DateTimeScalar| null;
  name: string;
  id?: string| null;
}

export interface GroupImportMemberInput {
  user?: ImportUserInput| null;
  role: GroupMemberRole;
  expiresAt?: DateTimeScalar| null;
  responsibles?: ((ImportUserInput| null)[]) | null;
}

export interface ImportGroupsResult {
  dummy?: boolean| null;
  importedGroups: ((Group)[]) ;
}

export interface ImportUsersResult {
  dummy?: boolean| null;
  entries: ((ImportUserResult)[]) ;
}

export interface ImportUserResult {
  action: ImportUserAction;
  errors: ((string)[]) ;
  importUser: ImportUser;
  user?: User| null;
}

export interface IssueUsersResult {
  activationCodesExpiresAt?: DateTimeScalar| null;
  entries: ((ImportAndIssueUserResult)[]) ;
}

export interface IssueUsersToActivationRequestsInput {
  userId: string;
  activationRequestId: string;
}

export interface IssueUsersToActivationRequestsResult {
  users: ((User)[]) ;
}

export interface IssueUserToActivationRequestResult {
  user: User;
}

export interface GroupMemberEdgeIdentifierInput {
  userId: string;
  groupId: string;
  role: GroupMemberRole;
}

export interface RemoveGroupMembersResult {
  dummy?: boolean| null;
  removedMembers: ((GroupMemberEdge)[]) ;
}

export interface TestImportAndIssueUsersResult {
  dummy?: boolean| null;
  entries: ((ImportUserResult)[]) ;
}

export interface UpdateApiResourceInput {
  displayName?: string| null;
  description?: string| null;
  enabled?: boolean| null;
  claims?: ((string)[]) | null;
  scopes?: ((UpdateApiResourceScopeInput)[]) | null;
  secrets?: ((UpdateApiResourceSecretInput)[]) | null;
}

export interface UpdateApiResourceScopeInput {
  name: string;
  displayName?: string| null;
  description?: string| null;
  claims?: ((string)[]) | null;
  required?: boolean| null;
  showInDiscoveryDocument?: boolean| null;
  emphasize?: boolean| null;
}

export interface UpdateApiResourceSecretInput {
  id?: number| null;
  value?: string| null;
}

export interface UpdateClientInput {
  displayName?: string| null;
  description?: string| null;
  clientUri?: string| null;
  enabled?: boolean| null;
  protocolType?: string| null;
  allowedScopes?: ((string)[]) | null;
  sessionMaxAge?: number| null;
  requireConsent?: boolean| null;
  allowRememberConsent?: boolean| null;
  consentLifetime?: number| null;
  organizationId?: string| null;
  userOrganizationIds?: ((string)[]) | null;
  contact?: string| null;
  properties?: ((CreateClientPropertyInput)[]) | null;
  allowedIdps?: ((string)[]) | null;
  loaRequirement?: Loa| null;
  disableBankIdLogin?: boolean| null;
  disableIdportenLogin?: boolean| null;
  disableSkolidLogin?: boolean| null;
  disableFrejaLogin?: boolean| null;
  disableMicrosoftLogin?: boolean| null;
  disableGoogleLogin?: boolean| null;
  useTfNumberAsNationalIdFallback?: boolean| null;
  assumeSkolidExtensionOnChromeOs?: boolean| null;
  oidcSettings?: UpdateClientOidcSettingsInput| null;
  samlSettings?: UpdateClientSamlSettingsInput| null;
}

export interface UpdateClientOidcSettingsInput {
  allowedGrantTypes?: ((string)[]) | null;
  redirectUris?: ((string)[]) | null;
  postLogoutRedirectUris?: ((string)[]) | null;
  autoRedirectAfterLogout?: boolean| null;
  includeNotBeforeClaim?: boolean| null;
  requireClientSecret?: boolean| null;
  alwaysIncludeUserClaimsInIdToken?: boolean| null;
  requirePkce?: boolean| null;
  allowPlainTextPkce?: boolean| null;
  allowAccessTokensViaBrowser?: boolean| null;
  frontChannelLogoutUri?: string| null;
  frontChannelLogoutSessionRequired?: boolean| null;
  backChannelLogoutUri?: string| null;
  backChannelLogoutSessionRequired?: boolean| null;
  allowOfflineAccess?: boolean| null;
  accessTokenLifetime?: number| null;
  identityTokenLifetime?: number| null;
  authorizationCodeLifetime?: number| null;
  absoluteRefreshTokenLifetime?: number| null;
  refreshTokenUsage?: TokenUsage| null;
  updateAccessTokenClaimsOnRefresh?: boolean| null;
  refreshTokenExpiration?: TokenExpiration| null;
  accessTokenType?: AccessTokenType| null;
  slidingRefreshTokenLifetime?: number| null;
  pairWiseSubjectSalt?: string| null;
  allowedCorsOrigins?: ((string)[]) | null;
  clientSecrets?: ((ClientSecretInput)[]) | null;
}

export interface UpdateClientSamlSettingsInput {
  entityId?: string| null;
  assertionConsumerServiceUrl?: string| null;
  assertionConsumerServiceBinding?: string| null;
  signAssertion?: boolean| null;
  signSamlResponse?: boolean| null;
  wantAuthnRequestSigned?: boolean| null;
  validAssertionConsumerServiceUrls?: ((string)[]) | null;
  certificates?: ((ClientSamlCertificateInput)[]) | null;
  mappingRules?: ((ClientSamlMappingRuleInput)[]) | null;
  singleLogoutServiceUrl?: string| null;
  singleLogoutServiceResponseUrl?: string| null;
  singleLogoutServiceBinding?: string| null;
  nameIdFormat?: string| null;
  signatureAlgorithm?: string| null;
  digestAlgorithm?: string| null;
  localSigningCertificateThumbprintHint?: string| null;
}

export interface UpdateIdentityResourceInput {
  displayName?: string| null;
  description?: string| null;
  claims?: ((string)[]) | null;
  enabled?: boolean| null;
  required?: boolean| null;
  showInDiscoveryDocument?: boolean| null;
  emphasize?: boolean| null;
}

export interface UpdateOrganizationInput {
  name?: string| null;
  issueLoa?: Loa| null;
}

export interface UpdateUserInput {
  firstName?: string| null;
  lastName?: string| null;
  nationalId?: string| null;
  tfNumber?: string| null;
  username?: string| null;
  phoneNumbers?: ((PhoneNumberInput)[]) | null;
  emails?: ((EmailInput)[]) | null;
  password?: string| null;
  role?: OrganizationRole| null;
  addresses?: ((AddressInput)[]) | null;
  isSuperAdmin?: boolean| null;
  expiresAt?: DateTimeScalar| null;
}

export interface UpdateUserOptionsInput {
  silent?: boolean| null;
}

export type DateScalar<T = any> = T;

export type DateTimeOffsetScalar<T = any> = T;

export type SecondsScalar<T = any> = T;

export type MillisecondsScalar<T = any> = T;

export type DecimalScalar<T = any> = T;

export const strippers = {

  SearchUsersOptionsInput: (inp: SearchUsersOptionsInput): SearchUsersOptionsInput => {
    const obj: Partial<SearchUsersOptionsInput> = {};

    obj.userOrderByOptions = inp.userOrderByOptions ? inp.userOrderByOptions.map(v =>  strippers.OrderOptionsInput(v)) : undefined;
    obj.isOnlyIssued = inp.isOnlyIssued;

    return obj as SearchUsersOptionsInput;
  },

  OrderOptionsInput: (inp: OrderOptionsInput): OrderOptionsInput => {
    const obj: Partial<OrderOptionsInput> = {};

    obj.orderBy = inp.orderBy;
    obj.sortDirection = inp.sortDirection;

    return obj as OrderOptionsInput;
  },

  EmailInput: (inp: EmailInput): EmailInput => {
    const obj: Partial<EmailInput> = {};

    obj.use = inp.use;
    obj.value = inp.value;
    obj.confirmed = inp.confirmed;

    return obj as EmailInput;
  },

  AddEmailsOptionsInput: (inp: AddEmailsOptionsInput): AddEmailsOptionsInput => {
    const obj: Partial<AddEmailsOptionsInput> = {};

    obj.silent = inp.silent;

    return obj as AddEmailsOptionsInput;
  },

  GroupMemberEdgeInput: (inp: GroupMemberEdgeInput): GroupMemberEdgeInput => {
    const obj: Partial<GroupMemberEdgeInput> = {};

    obj.userId = inp.userId;
    obj.groupId = inp.groupId;
    obj.role = inp.role;
    obj.expiresAt = inp.expiresAt;
    obj.confirmedAt = inp.confirmedAt;

    return obj as GroupMemberEdgeInput;
  },

  CreateUserInput: (inp: CreateUserInput): CreateUserInput => {
    const obj: Partial<CreateUserInput> = {};

    obj.firstName = inp.firstName;
    obj.lastName = inp.lastName;
    obj.nationalId = inp.nationalId;
    obj.tfNumber = inp.tfNumber;
    obj.username = inp.username;
    obj.role = inp.role;
    obj.loa = inp.loa;
    obj.phoneNumber = inp.phoneNumber ? strippers.CreateUserPhoneNumberInput(inp.phoneNumber) : inp.phoneNumber;
    obj.password = inp.password;
    obj.securityLogData = inp.securityLogData;
    obj.emails = inp.emails ? inp.emails.map(v =>  strippers.EmailInput(v)) : undefined;
    obj.phoneNumbers = inp.phoneNumbers ? inp.phoneNumbers.map(v =>  strippers.PhoneNumberInput(v)) : undefined;
    obj.addresses = inp.addresses ? inp.addresses.map(v =>  strippers.AddressInput(v)) : undefined;
    obj.expiresAt = inp.expiresAt;

    return obj as CreateUserInput;
  },

  CreateUserPhoneNumberInput: (inp: CreateUserPhoneNumberInput): CreateUserPhoneNumberInput => {
    const obj: Partial<CreateUserPhoneNumberInput> = {};

    obj.value = inp.value;
    obj.confirmed = inp.confirmed;

    return obj as CreateUserPhoneNumberInput;
  },

  PhoneNumberInput: (inp: PhoneNumberInput): PhoneNumberInput => {
    const obj: Partial<PhoneNumberInput> = {};

    obj.id = inp.id;
    obj.value = inp.value;
    obj.use = inp.use;
    obj.canBeUsedForSmsCodes = inp.canBeUsedForSmsCodes;

    return obj as PhoneNumberInput;
  },

  AddressInput: (inp: AddressInput): AddressInput => {
    const obj: Partial<AddressInput> = {};

    obj.id = inp.id;
    obj.primary = inp.primary;
    obj.streetAddress = inp.streetAddress;
    obj.city = inp.city;
    obj.postalCode = inp.postalCode;
    obj.apartmentNumber = inp.apartmentNumber;
    obj.co = inp.co;
    obj.country = inp.country;
    obj.type = inp.type;

    return obj as AddressInput;
  },

  CreateAndIssueUserOptionsInput: (inp: CreateAndIssueUserOptionsInput): CreateAndIssueUserOptionsInput => {
    const obj: Partial<CreateAndIssueUserOptionsInput> = {};

    obj.method = inp.method;
    obj.loa = inp.loa;
    obj.silent = inp.silent;

    return obj as CreateAndIssueUserOptionsInput;
  },

  CreateApiResourceInput: (inp: CreateApiResourceInput): CreateApiResourceInput => {
    const obj: Partial<CreateApiResourceInput> = {};

    obj.name = inp.name;
    obj.displayName = inp.displayName;
    obj.description = inp.description;
    obj.enabled = inp.enabled;
    obj.claims = inp.claims;
    obj.scopes =  inp.scopes.map(v =>  strippers.CreateApiResourceScopeInput(v));
    obj.secrets = inp.secrets ? inp.secrets.map(v =>  strippers.CreateApiResourceSecretInput(v)) : undefined;

    return obj as CreateApiResourceInput;
  },

  CreateApiResourceScopeInput: (inp: CreateApiResourceScopeInput): CreateApiResourceScopeInput => {
    const obj: Partial<CreateApiResourceScopeInput> = {};

    obj.name = inp.name;
    obj.displayName = inp.displayName;
    obj.description = inp.description;
    obj.claims = inp.claims;
    obj.required = inp.required;
    obj.showInDiscoveryDocument = inp.showInDiscoveryDocument;
    obj.emphasize = inp.emphasize;

    return obj as CreateApiResourceScopeInput;
  },

  CreateApiResourceSecretInput: (inp: CreateApiResourceSecretInput): CreateApiResourceSecretInput => {
    const obj: Partial<CreateApiResourceSecretInput> = {};

    obj.value = inp.value;

    return obj as CreateApiResourceSecretInput;
  },

  CreateClientInput: (inp: CreateClientInput): CreateClientInput => {
    const obj: Partial<CreateClientInput> = {};

    obj.clientId = inp.clientId;
    obj.displayName = inp.displayName;
    obj.frontChannelLogoutUri = inp.frontChannelLogoutUri;
    obj.allowedGrantTypes = inp.allowedGrantTypes;
    obj.redirectUris = inp.redirectUris;
    obj.postLogoutRedirectUris = inp.postLogoutRedirectUris;
    obj.autoRedirectAfterLogout = inp.autoRedirectAfterLogout;
    obj.includeNotBeforeClaim = inp.includeNotBeforeClaim;
    obj.description = inp.description;
    obj.clientUri = inp.clientUri;
    obj.enabled = inp.enabled;
    obj.protocolType = inp.protocolType;
    obj.allowedScopes = inp.allowedScopes;
    obj.sessionMaxAge = inp.sessionMaxAge;
    obj.requireConsent = inp.requireConsent;
    obj.allowRememberConsent = inp.allowRememberConsent;
    obj.consentLifetime = inp.consentLifetime;
    obj.organizationId = inp.organizationId;
    obj.userOrganizationIds = inp.userOrganizationIds;
    obj.contact = inp.contact;
    obj.properties = inp.properties ? inp.properties.map(v =>  strippers.CreateClientPropertyInput(v)) : undefined;
    obj.allowedIdps = inp.allowedIdps;
    obj.loaRequirement = inp.loaRequirement;
    obj.disableBankIdLogin = inp.disableBankIdLogin;
    obj.disableIdportenLogin = inp.disableIdportenLogin;
    obj.disableSkolidLogin = inp.disableSkolidLogin;
    obj.disableFrejaLogin = inp.disableFrejaLogin;
    obj.disableMicrosoftLogin = inp.disableMicrosoftLogin;
    obj.disableGoogleLogin = inp.disableGoogleLogin;
    obj.useTfNumberAsNationalIdFallback = inp.useTfNumberAsNationalIdFallback;
    obj.assumeSkolidExtensionOnChromeOs = inp.assumeSkolidExtensionOnChromeOs;
    obj.oidcSettings = inp.oidcSettings ? strippers.CreateClientOidcSettingsInput(inp.oidcSettings) : inp.oidcSettings;
    obj.samlSettings = inp.samlSettings ? strippers.CreateClientSamlSettingsInput(inp.samlSettings) : inp.samlSettings;

    return obj as CreateClientInput;
  },

  CreateClientPropertyInput: (inp: CreateClientPropertyInput): CreateClientPropertyInput => {
    const obj: Partial<CreateClientPropertyInput> = {};

    obj.key = inp.key;
    obj.value = inp.value;

    return obj as CreateClientPropertyInput;
  },

  CreateClientOidcSettingsInput: (inp: CreateClientOidcSettingsInput): CreateClientOidcSettingsInput => {
    const obj: Partial<CreateClientOidcSettingsInput> = {};

    obj.allowedGrantTypes = inp.allowedGrantTypes;
    obj.redirectUris = inp.redirectUris;
    obj.postLogoutRedirectUris = inp.postLogoutRedirectUris;
    obj.autoRedirectAfterLogout = inp.autoRedirectAfterLogout;
    obj.includeNotBeforeClaim = inp.includeNotBeforeClaim;
    obj.requireClientSecret = inp.requireClientSecret;
    obj.alwaysIncludeUserClaimsInIdToken = inp.alwaysIncludeUserClaimsInIdToken;
    obj.requirePkce = inp.requirePkce;
    obj.allowPlainTextPkce = inp.allowPlainTextPkce;
    obj.allowAccessTokensViaBrowser = inp.allowAccessTokensViaBrowser;
    obj.frontChannelLogoutUri = inp.frontChannelLogoutUri;
    obj.frontChannelLogoutSessionRequired = inp.frontChannelLogoutSessionRequired;
    obj.backChannelLogoutUri = inp.backChannelLogoutUri;
    obj.backChannelLogoutSessionRequired = inp.backChannelLogoutSessionRequired;
    obj.allowOfflineAccess = inp.allowOfflineAccess;
    obj.accessTokenLifetime = inp.accessTokenLifetime;
    obj.identityTokenLifetime = inp.identityTokenLifetime;
    obj.authorizationCodeLifetime = inp.authorizationCodeLifetime;
    obj.absoluteRefreshTokenLifetime = inp.absoluteRefreshTokenLifetime;
    obj.refreshTokenUsage = inp.refreshTokenUsage;
    obj.updateAccessTokenClaimsOnRefresh = inp.updateAccessTokenClaimsOnRefresh;
    obj.refreshTokenExpiration = inp.refreshTokenExpiration;
    obj.accessTokenType = inp.accessTokenType;
    obj.slidingRefreshTokenLifetime = inp.slidingRefreshTokenLifetime;
    obj.pairWiseSubjectSalt = inp.pairWiseSubjectSalt;
    obj.allowedCorsOrigins = inp.allowedCorsOrigins;
    obj.clientSecrets = inp.clientSecrets ? inp.clientSecrets.map(v =>  strippers.ClientSecretInput(v)) : undefined;

    return obj as CreateClientOidcSettingsInput;
  },

  ClientSecretInput: (inp: ClientSecretInput): ClientSecretInput => {
    const obj: Partial<ClientSecretInput> = {};

    obj.id = inp.id;
    obj.value = inp.value;

    return obj as ClientSecretInput;
  },

  CreateClientSamlSettingsInput: (inp: CreateClientSamlSettingsInput): CreateClientSamlSettingsInput => {
    const obj: Partial<CreateClientSamlSettingsInput> = {};

    obj.entityId = inp.entityId;
    obj.assertionConsumerServiceUrl = inp.assertionConsumerServiceUrl;
    obj.assertionConsumerServiceBinding = inp.assertionConsumerServiceBinding;
    obj.signAssertion = inp.signAssertion;
    obj.signSamlResponse = inp.signSamlResponse;
    obj.wantAuthnRequestSigned = inp.wantAuthnRequestSigned;
    obj.validAssertionConsumerServiceUrls = inp.validAssertionConsumerServiceUrls;
    obj.certificates = inp.certificates ? inp.certificates.map(v =>  strippers.ClientSamlCertificateInput(v)) : undefined;
    obj.mappingRules = inp.mappingRules ? inp.mappingRules.map(v =>  strippers.ClientSamlMappingRuleInput(v)) : undefined;
    obj.singleLogoutServiceUrl = inp.singleLogoutServiceUrl;
    obj.singleLogoutServiceResponseUrl = inp.singleLogoutServiceResponseUrl;
    obj.singleLogoutServiceBinding = inp.singleLogoutServiceBinding;
    obj.nameIdFormat = inp.nameIdFormat;
    obj.signatureAlgorithm = inp.signatureAlgorithm;
    obj.digestAlgorithm = inp.digestAlgorithm;
    obj.localSigningCertificateThumbprintHint = inp.localSigningCertificateThumbprintHint;

    return obj as CreateClientSamlSettingsInput;
  },

  ClientSamlCertificateInput: (inp: ClientSamlCertificateInput): ClientSamlCertificateInput => {
    const obj: Partial<ClientSamlCertificateInput> = {};

    obj.data = inp.data;
    obj.use = inp.use;

    return obj as ClientSamlCertificateInput;
  },

  ClientSamlMappingRuleInput: (inp: ClientSamlMappingRuleInput): ClientSamlMappingRuleInput => {
    const obj: Partial<ClientSamlMappingRuleInput> = {};

    obj.type = inp.type;
    obj.targetAttribute = inp.targetAttribute;
    obj.value = inp.value;

    return obj as ClientSamlMappingRuleInput;
  },

  CreateIdentityResourceInput: (inp: CreateIdentityResourceInput): CreateIdentityResourceInput => {
    const obj: Partial<CreateIdentityResourceInput> = {};

    obj.name = inp.name;
    obj.displayName = inp.displayName;
    obj.description = inp.description;
    obj.claims = inp.claims;
    obj.enabled = inp.enabled;
    obj.required = inp.required;
    obj.showInDiscoveryDocument = inp.showInDiscoveryDocument;
    obj.emphasize = inp.emphasize;

    return obj as CreateIdentityResourceInput;
  },

  CreateOrganizationInput: (inp: CreateOrganizationInput): CreateOrganizationInput => {
    const obj: Partial<CreateOrganizationInput> = {};

    obj.id = inp.id;
    obj.name = inp.name;
    obj.issueLoa = inp.issueLoa;
    obj.country = inp.country;

    return obj as CreateOrganizationInput;
  },

  CreateUserOptionsInput: (inp: CreateUserOptionsInput): CreateUserOptionsInput => {
    const obj: Partial<CreateUserOptionsInput> = {};

    obj.sendWelcomeEmail = inp.sendWelcomeEmail;
    obj.silent = inp.silent;

    return obj as CreateUserOptionsInput;
  },

  CreateUsersOptionsInput: (inp: CreateUsersOptionsInput): CreateUsersOptionsInput => {
    const obj: Partial<CreateUsersOptionsInput> = {};

    obj.silent = inp.silent;

    return obj as CreateUsersOptionsInput;
  },

  ImportUserInput: (inp: ImportUserInput): ImportUserInput => {
    const obj: Partial<ImportUserInput> = {};

    obj.nationalId = inp.nationalId;
    obj.tfNumber = inp.tfNumber;
    obj.nationalIdOrTfNumber = inp.nationalIdOrTfNumber;
    obj.username = inp.username;
    obj.firstName = inp.firstName;
    obj.lastName = inp.lastName;
    obj.phoneNumber = inp.phoneNumber;
    obj.email = inp.email;
    obj.emails = inp.emails ? inp.emails.map(v =>  strippers.ImportUserEmailInput(v)) : undefined;
    obj.password = inp.password;
    obj.expiresAt = inp.expiresAt;

    return obj as ImportUserInput;
  },

  ImportUserEmailInput: (inp: ImportUserEmailInput): ImportUserEmailInput => {
    const obj: Partial<ImportUserEmailInput> = {};

    obj.value = inp.value;

    return obj as ImportUserEmailInput;
  },

  ImportAndIssueUsersOptionsInput: (inp: ImportAndIssueUsersOptionsInput): ImportAndIssueUsersOptionsInput => {
    const obj: Partial<ImportAndIssueUsersOptionsInput> = {};

    obj.issueMethod = inp.issueMethod;
    obj.loa = inp.loa;

    return obj as ImportAndIssueUsersOptionsInput;
  },

  IssueUserOptionsInput: (inp: IssueUserOptionsInput): IssueUserOptionsInput => {
    const obj: Partial<IssueUserOptionsInput> = {};

    obj.method = inp.method;
    obj.loa = inp.loa;

    return obj as IssueUserOptionsInput;
  },

  IssueUsersOptionsInput: (inp: IssueUsersOptionsInput): IssueUsersOptionsInput => {
    const obj: Partial<IssueUsersOptionsInput> = {};

    obj.issueMethod = inp.issueMethod;
    obj.loa = inp.loa;

    return obj as IssueUsersOptionsInput;
  },

  ImportClientsOptionsInput: (inp: ImportClientsOptionsInput): ImportClientsOptionsInput => {
    const obj: Partial<ImportClientsOptionsInput> = {};

    obj.generateNewSecrets = inp.generateNewSecrets;

    return obj as ImportClientsOptionsInput;
  },

  GroupImportInput: (inp: GroupImportInput): GroupImportInput => {
    const obj: Partial<GroupImportInput> = {};

    obj.children = inp.children ? inp.children.map(v =>  strippers.GroupChildImportInput(v)) : undefined;
    obj.members = inp.members ? inp.members.map(v =>  strippers.GroupImportMemberInput(v)) : undefined;
    obj.expiresAt = inp.expiresAt;
    obj.name = inp.name;
    obj.id = inp.id;
    obj.parentId = inp.parentId;

    return obj as GroupImportInput;
  },

  GroupChildImportInput: (inp: GroupChildImportInput): GroupChildImportInput => {
    const obj: Partial<GroupChildImportInput> = {};

    obj.children = inp.children ? inp.children.map(v =>  strippers.GroupChildImportInput(v)) : undefined;
    obj.members = inp.members ? inp.members.map(v =>  strippers.GroupImportMemberInput(v)) : undefined;
    obj.expiresAt = inp.expiresAt;
    obj.name = inp.name;
    obj.id = inp.id;

    return obj as GroupChildImportInput;
  },

  GroupImportMemberInput: (inp: GroupImportMemberInput): GroupImportMemberInput => {
    const obj: Partial<GroupImportMemberInput> = {};

    obj.user = inp.user ? strippers.ImportUserInput(inp.user) : inp.user;
    obj.role = inp.role;
    obj.expiresAt = inp.expiresAt;
    obj.responsibles = inp.responsibles ? inp.responsibles.map(v => v ? strippers.ImportUserInput(v) : v) : undefined;

    return obj as GroupImportMemberInput;
  },

  IssueUsersToActivationRequestsInput: (inp: IssueUsersToActivationRequestsInput): IssueUsersToActivationRequestsInput => {
    const obj: Partial<IssueUsersToActivationRequestsInput> = {};

    obj.userId = inp.userId;
    obj.activationRequestId = inp.activationRequestId;

    return obj as IssueUsersToActivationRequestsInput;
  },

  GroupMemberEdgeIdentifierInput: (inp: GroupMemberEdgeIdentifierInput): GroupMemberEdgeIdentifierInput => {
    const obj: Partial<GroupMemberEdgeIdentifierInput> = {};

    obj.userId = inp.userId;
    obj.groupId = inp.groupId;
    obj.role = inp.role;

    return obj as GroupMemberEdgeIdentifierInput;
  },

  UpdateApiResourceInput: (inp: UpdateApiResourceInput): UpdateApiResourceInput => {
    const obj: Partial<UpdateApiResourceInput> = {};

    obj.displayName = inp.displayName;
    obj.description = inp.description;
    obj.enabled = inp.enabled;
    obj.claims = inp.claims;
    obj.scopes = inp.scopes ? inp.scopes.map(v =>  strippers.UpdateApiResourceScopeInput(v)) : undefined;
    obj.secrets = inp.secrets ? inp.secrets.map(v =>  strippers.UpdateApiResourceSecretInput(v)) : undefined;

    return obj as UpdateApiResourceInput;
  },

  UpdateApiResourceScopeInput: (inp: UpdateApiResourceScopeInput): UpdateApiResourceScopeInput => {
    const obj: Partial<UpdateApiResourceScopeInput> = {};

    obj.name = inp.name;
    obj.displayName = inp.displayName;
    obj.description = inp.description;
    obj.claims = inp.claims;
    obj.required = inp.required;
    obj.showInDiscoveryDocument = inp.showInDiscoveryDocument;
    obj.emphasize = inp.emphasize;

    return obj as UpdateApiResourceScopeInput;
  },

  UpdateApiResourceSecretInput: (inp: UpdateApiResourceSecretInput): UpdateApiResourceSecretInput => {
    const obj: Partial<UpdateApiResourceSecretInput> = {};

    obj.id = inp.id;
    obj.value = inp.value;

    return obj as UpdateApiResourceSecretInput;
  },

  UpdateClientInput: (inp: UpdateClientInput): UpdateClientInput => {
    const obj: Partial<UpdateClientInput> = {};

    obj.displayName = inp.displayName;
    obj.description = inp.description;
    obj.clientUri = inp.clientUri;
    obj.enabled = inp.enabled;
    obj.protocolType = inp.protocolType;
    obj.allowedScopes = inp.allowedScopes;
    obj.sessionMaxAge = inp.sessionMaxAge;
    obj.requireConsent = inp.requireConsent;
    obj.allowRememberConsent = inp.allowRememberConsent;
    obj.consentLifetime = inp.consentLifetime;
    obj.organizationId = inp.organizationId;
    obj.userOrganizationIds = inp.userOrganizationIds;
    obj.contact = inp.contact;
    obj.properties = inp.properties ? inp.properties.map(v =>  strippers.CreateClientPropertyInput(v)) : undefined;
    obj.allowedIdps = inp.allowedIdps;
    obj.loaRequirement = inp.loaRequirement;
    obj.disableBankIdLogin = inp.disableBankIdLogin;
    obj.disableIdportenLogin = inp.disableIdportenLogin;
    obj.disableSkolidLogin = inp.disableSkolidLogin;
    obj.disableFrejaLogin = inp.disableFrejaLogin;
    obj.disableMicrosoftLogin = inp.disableMicrosoftLogin;
    obj.disableGoogleLogin = inp.disableGoogleLogin;
    obj.useTfNumberAsNationalIdFallback = inp.useTfNumberAsNationalIdFallback;
    obj.assumeSkolidExtensionOnChromeOs = inp.assumeSkolidExtensionOnChromeOs;
    obj.oidcSettings = inp.oidcSettings ? strippers.UpdateClientOidcSettingsInput(inp.oidcSettings) : inp.oidcSettings;
    obj.samlSettings = inp.samlSettings ? strippers.UpdateClientSamlSettingsInput(inp.samlSettings) : inp.samlSettings;

    return obj as UpdateClientInput;
  },

  UpdateClientOidcSettingsInput: (inp: UpdateClientOidcSettingsInput): UpdateClientOidcSettingsInput => {
    const obj: Partial<UpdateClientOidcSettingsInput> = {};

    obj.allowedGrantTypes = inp.allowedGrantTypes;
    obj.redirectUris = inp.redirectUris;
    obj.postLogoutRedirectUris = inp.postLogoutRedirectUris;
    obj.autoRedirectAfterLogout = inp.autoRedirectAfterLogout;
    obj.includeNotBeforeClaim = inp.includeNotBeforeClaim;
    obj.requireClientSecret = inp.requireClientSecret;
    obj.alwaysIncludeUserClaimsInIdToken = inp.alwaysIncludeUserClaimsInIdToken;
    obj.requirePkce = inp.requirePkce;
    obj.allowPlainTextPkce = inp.allowPlainTextPkce;
    obj.allowAccessTokensViaBrowser = inp.allowAccessTokensViaBrowser;
    obj.frontChannelLogoutUri = inp.frontChannelLogoutUri;
    obj.frontChannelLogoutSessionRequired = inp.frontChannelLogoutSessionRequired;
    obj.backChannelLogoutUri = inp.backChannelLogoutUri;
    obj.backChannelLogoutSessionRequired = inp.backChannelLogoutSessionRequired;
    obj.allowOfflineAccess = inp.allowOfflineAccess;
    obj.accessTokenLifetime = inp.accessTokenLifetime;
    obj.identityTokenLifetime = inp.identityTokenLifetime;
    obj.authorizationCodeLifetime = inp.authorizationCodeLifetime;
    obj.absoluteRefreshTokenLifetime = inp.absoluteRefreshTokenLifetime;
    obj.refreshTokenUsage = inp.refreshTokenUsage;
    obj.updateAccessTokenClaimsOnRefresh = inp.updateAccessTokenClaimsOnRefresh;
    obj.refreshTokenExpiration = inp.refreshTokenExpiration;
    obj.accessTokenType = inp.accessTokenType;
    obj.slidingRefreshTokenLifetime = inp.slidingRefreshTokenLifetime;
    obj.pairWiseSubjectSalt = inp.pairWiseSubjectSalt;
    obj.allowedCorsOrigins = inp.allowedCorsOrigins;
    obj.clientSecrets = inp.clientSecrets ? inp.clientSecrets.map(v =>  strippers.ClientSecretInput(v)) : undefined;

    return obj as UpdateClientOidcSettingsInput;
  },

  UpdateClientSamlSettingsInput: (inp: UpdateClientSamlSettingsInput): UpdateClientSamlSettingsInput => {
    const obj: Partial<UpdateClientSamlSettingsInput> = {};

    obj.entityId = inp.entityId;
    obj.assertionConsumerServiceUrl = inp.assertionConsumerServiceUrl;
    obj.assertionConsumerServiceBinding = inp.assertionConsumerServiceBinding;
    obj.signAssertion = inp.signAssertion;
    obj.signSamlResponse = inp.signSamlResponse;
    obj.wantAuthnRequestSigned = inp.wantAuthnRequestSigned;
    obj.validAssertionConsumerServiceUrls = inp.validAssertionConsumerServiceUrls;
    obj.certificates = inp.certificates ? inp.certificates.map(v =>  strippers.ClientSamlCertificateInput(v)) : undefined;
    obj.mappingRules = inp.mappingRules ? inp.mappingRules.map(v =>  strippers.ClientSamlMappingRuleInput(v)) : undefined;
    obj.singleLogoutServiceUrl = inp.singleLogoutServiceUrl;
    obj.singleLogoutServiceResponseUrl = inp.singleLogoutServiceResponseUrl;
    obj.singleLogoutServiceBinding = inp.singleLogoutServiceBinding;
    obj.nameIdFormat = inp.nameIdFormat;
    obj.signatureAlgorithm = inp.signatureAlgorithm;
    obj.digestAlgorithm = inp.digestAlgorithm;
    obj.localSigningCertificateThumbprintHint = inp.localSigningCertificateThumbprintHint;

    return obj as UpdateClientSamlSettingsInput;
  },

  UpdateIdentityResourceInput: (inp: UpdateIdentityResourceInput): UpdateIdentityResourceInput => {
    const obj: Partial<UpdateIdentityResourceInput> = {};

    obj.displayName = inp.displayName;
    obj.description = inp.description;
    obj.claims = inp.claims;
    obj.enabled = inp.enabled;
    obj.required = inp.required;
    obj.showInDiscoveryDocument = inp.showInDiscoveryDocument;
    obj.emphasize = inp.emphasize;

    return obj as UpdateIdentityResourceInput;
  },

  UpdateOrganizationInput: (inp: UpdateOrganizationInput): UpdateOrganizationInput => {
    const obj: Partial<UpdateOrganizationInput> = {};

    obj.name = inp.name;
    obj.issueLoa = inp.issueLoa;

    return obj as UpdateOrganizationInput;
  },

  UpdateUserInput: (inp: UpdateUserInput): UpdateUserInput => {
    const obj: Partial<UpdateUserInput> = {};

    obj.firstName = inp.firstName;
    obj.lastName = inp.lastName;
    obj.nationalId = inp.nationalId;
    obj.tfNumber = inp.tfNumber;
    obj.username = inp.username;
    obj.phoneNumbers = inp.phoneNumbers ? inp.phoneNumbers.map(v =>  strippers.PhoneNumberInput(v)) : undefined;
    obj.emails = inp.emails ? inp.emails.map(v =>  strippers.EmailInput(v)) : undefined;
    obj.password = inp.password;
    obj.role = inp.role;
    obj.addresses = inp.addresses ? inp.addresses.map(v =>  strippers.AddressInput(v)) : undefined;
    obj.isSuperAdmin = inp.isSuperAdmin;
    obj.expiresAt = inp.expiresAt;

    return obj as UpdateUserInput;
  },

  UpdateUserOptionsInput: (inp: UpdateUserOptionsInput): UpdateUserOptionsInput => {
    const obj: Partial<UpdateUserOptionsInput> = {};

    obj.silent = inp.silent;

    return obj as UpdateUserOptionsInput;
  },

}

