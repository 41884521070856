import * as React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { FetchAuditLog, FetchAuditLogVariables } from "../../admin-gql";
import { Spinner } from "../../Common/Spinner";
import { TableCaption } from "../../Common/TableCaption";
import { TypedQuery } from "../../types";

const fetchAuditLogGql = gql`
  query FetchAuditLog($userId: String!, $filter: String, $before: String, $after: String) {
    user(id: $userId) {
      id
      name
      auditLog(filter: $filter, before: $before, after: $after) {
        edges {
          node {
            ...AuditLogEntryFragment
          }
          cursor
        }
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }

  fragment AuditLogEntryFragment on AuditLogEntry {
    timestamp
    activity
    actorUserId
    additionalData
    actorName
    interface
    operation
    correlationId
  }
`;

const AuditLogQuery: TypedQuery<FetchAuditLog, FetchAuditLogVariables> = Query;

interface AuditLogState {
  after?: string | null;
  before?: string | null;
}

export class AuditLog extends React.Component<{ userId: string }, AuditLogState> {
  public state: AuditLogState = {};

  public render() {
    const props = this.props;

    return (
      <div className="flex-fill">
        <h1>Audit log</h1>
        <div className="box">
          <div className="box-body">
            <AuditLogQuery
              query={fetchAuditLogGql}
              fetchPolicy="network-only"
              variables={{
                userId: props.userId,
                filter: "",
                before: this.state.before,
                after: this.state.after,
              }}
            >
              {(result) => (
                <>
                  {result.loading ? (
                    <div className="text-center">
                      {" "}
                      <Spinner />
                    </div>
                  ) : null}

                  {result.error ? <div className="alert alert-danger">Failed to fetch the audit log</div> : null}

                  {result.data && result.data.user && result.data.user.auditLog && !result.error ? (
                    <table className="table table-fixed table-sm table-small-text">
                      <thead>
                        <tr>
                          <th style={{ width: 150 }}>Timestamp</th>
                          <th style={{ width: 160 }}>Activity</th>
                          <th style={{ width: 130 }}>Actor</th>
                          <th>Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {result
                          .data!.user!.auditLog.edges.map((x) => x.node)
                          .map((entry, index) => {
                            const nextEntry = result.data!.user!.auditLog.edges[index + 1];
                            const prevEntry = result.data!.user!.auditLog.edges[index - 1];
                            const groupWithNextEntry =
                              nextEntry && nextEntry.node.correlationId === entry.correlationId && entry.correlationId;
                            const groupPreviousEntry =
                              prevEntry && prevEntry.node.correlationId === entry.correlationId && entry.correlationId;
                            const date = new Date(entry.timestamp);
                            return (
                              <tr key={index} className={groupWithNextEntry ? "group-next-row" : undefined}>
                                <td
                                  title={
                                    entry.timestamp ? date.toLocaleDateString() + " " + date.toLocaleTimeString() : "-"
                                  }
                                  className="truncate text-right"
                                >
                                  {entry.timestamp
                                    ? (!groupPreviousEntry ? date.toLocaleDateString() : "") +
                                      " " +
                                      date.toLocaleTimeString()
                                    : "-"}
                                </td>
                                <td className="truncate" title={entry.activity}>
                                  {entry.activity}
                                </td>
                                <td className="truncate" title={"id: " + entry.actorUserId}>
                                  {entry.actorName || entry.actorUserId}
                                </td>
                                <td
                                  className="truncate"
                                  title={
                                    entry.additionalData
                                      ? JSON.stringify(JSON.parse(entry.additionalData), null, 4)
                                      : undefined
                                  }
                                >
                                  {entry.additionalData}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <TableCaption
                        connectionData={result.data.user.auditLog}
                        onBefore={this.prevPage}
                        onAfter={this.nextPage}
                      />
                    </table>
                  ) : null}
                </>
              )}
            </AuditLogQuery>
          </div>
        </div>
      </div>
    );
  }

  private nextPage = (after: string) => {
    this.setState({ after, before: null });
  };

  private prevPage = (before: string) => {
    this.setState({ before, after: null });
  };
}
