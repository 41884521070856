import * as React from "react";
// tslint:disable-next-line:no-submodule-imports
import CreatableSelect from "react-select/lib/Creatable";
import { fetchClaimsGql } from "./QueryClaims";
import { FetchClaims } from "../admin-gql";
import { Query } from "react-apollo";

export const SelectClaims = (props: {
  selectedClaims: string[];
  onChange: (selectedClaims: string[]) => void;
  disabled?: boolean;
}) => {
  return (
    <Query<FetchClaims> query={fetchClaimsGql}>
      {result => (
        <CreatableSelect
          options={
            result.data && !result.loading
              ? result.data.claims.map(x => ({ label: x, value: x }))
              : []
          }
          isMulti
          isLoading={result.loading}
          value={props.selectedClaims.map(x => ({ label: x, value: x }))}
          isClearable
          isDisabled={props.disabled}
          onChange={(options: any) => props.onChange(options.map((x: any) => x.value))}
        />
      )}
    </Query>
  );
};
