import { useForm, ComplexField } from "@ist-group/react-form-hook";
import admin2Gql from "graphql-tag";
import * as React from "react";
import { useMutation, useQuery } from "react-apollo";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  IdpDetails,
  IdpDetails_idp,
  strippers,
  UpdateIdp,
  UpdateIdpVariables,
  DeleteIdp,
  DeleteIdpVariables,
  SetIdpClaimMappingInput,
  TransferIdpClaimMappingInput,
  IdpDetails_claimMappings_SetIdpClaimMapping,
  IdpDetails_claimMappings_TransferIdpClaimMapping,
} from "../admin-gql-2";
import { useAdmin2Client } from "../App/ClientProvider";
import { ErrorScreen } from "../App/ErrorScreen";
import { LoadingScreen } from "../App/LoadingScreen";
import { NotFoundScreen } from "../App/NotFoundScreen";
import { Alert } from "../Common/Alert";
import { Button } from "../Common/Button";
import { CertificateEditor } from "../Common/CertificateEditor";
import { Checkbox } from "../Common/Checkbox";
import { FieldInput } from "../Common/FieldInput";
import { waitMinDelay } from "../Common/functions";
import { SelectOrganization } from "../Common/SelectOrganization";
import * as routes from "../routes";
import { DropDownButton } from "@ist-group-private-scope/skolid-client-components";
import { settings } from "../settings";
import { environmentSettings } from "@ist-group-private-scope/web-skolid";
import { SelectClaim } from "../Common/SelectClaim";
import { SelectSchoolRelationRoles } from "../Common/SelectSchoolRelationRole";
import { idpDetailsFragmentGql } from "./fragments";
import { UpdateIdpFromMetadataModal } from "./UpdateIdpFromMetadataModal";
import { SelectSamlBinding } from "../Common/SelectSamlBinding";

const idpDetailsGql = admin2Gql`
  query IdpDetails($id: String!) {
    idp(id: $id) {
      ...IdpDetails
    }
  }

  ${idpDetailsFragmentGql}
  `;

export const IdpDetailsScreen = (props: {}) => {
  const {
    params: { id },
  } = useRouteMatch<routes.IdpRouteParams>();

  const history = useHistory();

  const client = useAdmin2Client();
  const idpDetails = useQuery<IdpDetails>(idpDetailsGql, {
    client,
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });

  if (idpDetails.loading) {
    return <LoadingScreen />;
  }

  if (idpDetails.error) {
    return <ErrorScreen />;
  }

  if (!idpDetails.data || !idpDetails.data!.idp) {
    return <NotFoundScreen entityName="Idp" />;
  }

  return (
    <IdpForm
      idp={idpDetails.data!.idp!}
      onDeleted={() => history.push(routes.idpsPath)}
      onUpdated={() => idpDetails.refetch()}
    />
  );
};

const updateIdpGql = admin2Gql`
    mutation UpdateIdp($input: UpdateIdpInput!) {
      updateIdp(input: $input) {
        __typename
        ... on UpdateIdpSuccess {
          idp {
            ...IdpDetails
          }
        }
        ... on MutationError {
          errorMessage
        }
      }
    }

    ${idpDetailsFragmentGql}
  `;

const deleteIdpGql = admin2Gql`
    mutation DeleteIdp($id: String!) {
      deleteIdp(id: $id) {
        __typename
        ... on DeleteIdpSuccess {
          __typename
        }
        ... on MutationError {
          errorMessage
        }
      }
    }
  `;

export const IdpForm = (props: { idp: IdpDetails_idp; onDeleted: () => void; onUpdated: () => void }) => {
  const client = useAdmin2Client();
  const [showUpdateFromMetadata, setShowUpdateFromMetadata] = React.useState(false);
  const [updateIdp, updateIdpState] = useMutation<UpdateIdp, UpdateIdpVariables>(updateIdpGql, {
    client,
  });

  const [deleteIdp] = useMutation<DeleteIdp, DeleteIdpVariables>(deleteIdpGql, {
    client,
  });

  const idp = props.idp;
  const form = useForm(
    { ...props.idp, organizationId: props.idp.organization.id },
    {
      onSubmit: async (data) => {
        try {
          await waitMinDelay(
            updateIdp({
              variables: {
                input: strippers.UpdateIdpInput({
                  ...data,
                  claimMappings: data.claimMappings.map((mapping) =>
                    mapping.__typename === "SetIdpClaimMapping" ? { set: mapping } : { transfer: mapping }
                  ),
                }),
              },
            }),
            1000
          );
        } catch (error) {
          console.error("Failed to update idp: ", error);
        }
      },
      fieldValidation: {
        fields: {
          claimMappings: {
            item: {
              fields: {
                target: {
                  onChange: (val) => (!val ? "Target is required" : null),
                },
                source: {
                  onChange: (val) => (!val ? "Source is required" : null),
                },
              },
            },
          },
        },
      },
    }
  );

  const samlFields = form.fields.samlConfig.fields;
  return (
    <div className="flex-fill">
      <div className="row align-items-center">
        <h1 className="col m-0 mb-sm-content">{idp.name}</h1>
        <div className={"col-sm d-flex justify-content-md-end my-content"}>
          <a
            className="btn btn-primary"
            type="button"
            target="_blank"
            rel="noopener noreferrer"
            href={environmentSettings[settings.skolidEnvironment].url + "/" + idp.id + "/upstream-saml/metadata"}
          >
            Show local SP metadata
          </a>
          &nbsp;&nbsp;
          <div className="dropdown">
            <DropDownButton label="More" disabled={form.submitting}>
              {() => (
                <>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={async () => {
                      await deleteIdp({ variables: { id: idp.id } });
                      props.onDeleted();
                    }}
                  >
                    Delete
                  </button>
                  <button className="dropdown-item" type="button" onClick={() => setShowUpdateFromMetadata(true)}>
                    Update from metadata
                  </button>
                </>
              )}
            </DropDownButton>
          </div>
        </div>
      </div>
      <div className="box">
        <div className="box-body">
          <div className="form-group">
            <FieldInput field={form.fields.id} label="Id" readonly />
          </div>
          <div className="form-group">
            <FieldInput field={form.fields.name} label="Name" />
          </div>
          <div className="form-group">
            <label>Organization</label>
            <SelectOrganization
              organizationId={form.fields.organizationId.value}
              onChange={form.fields.organizationId.set}
            />
            {form.fields.organizationId.error && form.fields.organizationId.touched && (
              <div className="invalid-feedback d-block">{form.fields.organizationId.error}</div>
            )}
          </div>
          <div className="form-group">
            <label>Roles</label>
            <SelectSchoolRelationRoles
              roles={form.fields.roles.value}
              onChange={(newRoles) => form.fields.roles.set(newRoles)}
            />
            <p className="small mt-2">
              Leave empty for the IdP to satisfy all roles (including potentially new roles added in the future)
            </p>
          </div>
          <div className="form-group">
            <FieldInput field={samlFields.entityId} label="Entity ID" />
          </div>
          <div className="form-group">
            <FieldInput field={samlFields.singleSignOnServiceUrl} label="Singel sign on service URL" />
          </div>
          <div className="form-group">
            <label>Single sign on service binding</label>
            <SelectSamlBinding
              binding={samlFields.singleSignOnServiceBinding.value}
              onChange={samlFields.singleSignOnServiceBinding.set}
              disabled={samlFields.singleSignOnServiceBinding.disabled}
            />
          </div>

          <div className="form-group">
            <FieldInput field={samlFields.singleLogoutServiceUrl} label="Singel logout service URL" />
          </div>
          <div className="form-group">
            <label>Single logout service binding</label>
            <SelectSamlBinding
              binding={samlFields.singleLogoutServiceBinding.value}
              onChange={samlFields.singleLogoutServiceBinding.set}
              disabled={samlFields.singleLogoutServiceBinding.disabled}
            />
          </div>

          <div className="form-group">
            <Checkbox
              label="Sign authn request"
              checked={samlFields.signAuthnRequest.value}
              disabled={form.disabled}
              onChange={samlFields.signAuthnRequest.set}
            ></Checkbox>
          </div>
          <div className="form-group">
            <Checkbox
              label="Sign logout request"
              checked={samlFields.signLogoutRequest.value}
              disabled={form.disabled}
              onChange={samlFields.signLogoutRequest.set}
            ></Checkbox>
          </div>

          <div className="form-group">
            <FieldInput field={samlFields.localSigningCertificateThumbprintHint} label="Local Signing Certificate Thumbprint Hint" />
            <p className="small mt-2">
              Useful during signing key rollover when the IdP does not support SP:s with multiple certificates. Set this field to the thumbprint of the next certificate 
              when the IdP is switching trust to the new certificate. If this field is empty or if the thumbprint does not match any certificate the current
              key will be used for signing.
            </p>
          </div>

          <h3>IdP certificates</h3>
          <div className="form-group">
            <CertificateEditor
              certificates={samlFields.certificates.value}
              updateCertificates={samlFields.certificates.set}
            />
          </div>

          <h3>Claim mappings</h3>
          <p>
            Use claim mappings when additional claims are needed or when the IdP uses non default claims.{" "}
            <strong>NOTE</strong> that the LOA (acr) claim isn't automatically mapped from the IdP (due to security
            reasons) and must thus be manually mapped here.
          </p>
          <div className="form-group">
            {form.fields.claimMappings.items.map((mapping, mappingIndex) => (
              <div key={mappingIndex} className="d-flex align-items-center">
                <div className="flex-grow-1 mr-3">
                  {mapping.value.__typename === "SetIdpClaimMapping" ? (
                    <SetMappingInput field={mapping as ComplexField<IdpDetails_claimMappings_SetIdpClaimMapping>} />
                  ) : (
                    <TransferMappingInput
                      field={mapping as ComplexField<IdpDetails_claimMappings_TransferIdpClaimMapping>}
                    />
                  )}
                </div>
                <div className="d-flex flex-column">
                  <button
                    disabled={mappingIndex === 0}
                    onClick={() =>
                      form.fields.claimMappings.set(
                        arrayMove(form.fields.claimMappings.value.concat([]), mappingIndex, mappingIndex - 1)
                      )
                    }
                    className="btn mt-2"
                  >
                    <i className="fa fa-chevron-up" />
                  </button>
                  <button onClick={() => form.fields.claimMappings.remove(mappingIndex)} className="btn mt-2">
                    <i className="fa fa-trash" />
                  </button>
                  <button
                    disabled={mappingIndex === form.fields.claimMappings.value.length - 1}
                    onClick={() =>
                      form.fields.claimMappings.set(
                        arrayMove(form.fields.claimMappings.value.concat([]), mappingIndex, mappingIndex + 1)
                      )
                    }
                    className="btn mt-2"
                  >
                    <i className="fa fa-chevron-down" />
                  </button>
                </div>
              </div>
            ))}

            <div className="mt-2">
              <DropDownButton label="Add claim mapping">
                {(close) => (
                  <>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => {
                        close();
                        form.fields.claimMappings.push({
                          __typename: "SetIdpClaimMapping",
                          target: "",
                          value: "",
                        });
                      }}
                    >
                      Hard coded value
                    </button>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => {
                        close();
                        form.fields.claimMappings.push({
                          __typename: "TransferIdpClaimMapping",
                          target: "",
                          source: "",
                          matchRegex: null,
                          targetValueSubstitution: null,
                        });
                      }}
                    >
                      Transfer mapping
                    </button>
                  </>
                )}
              </DropDownButton>
            </div>
          </div>

          <div className="form-group ">
            <Alert>
              {updateIdpState.data?.updateIdp.__typename === "UpdateIdpError"
                ? updateIdpState.data.updateIdp.errorMessage
                : null}
            </Alert>
            <Alert variant="success">
              {updateIdpState.data && updateIdpState.data.updateIdp.__typename === "UpdateIdpSuccess" && !form.touched
                ? "Idp saved"
                : null}
            </Alert>
          </div>
          <div className="form-group text-right">
            <Button onClick={form.submit} loading={form.submitting} disabled={form.disabled}>
              Save
            </Button>
          </div>
        </div>
      </div>
      <UpdateIdpFromMetadataModal
        show={showUpdateFromMetadata}
        onClose={() => {
          setShowUpdateFromMetadata(false);
          props.onUpdated();
        }}
        idpId={idp.id}
      />
    </div>
  );
};

function arrayMove(arr: any[], from: number, to: number) {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
}

const validAcrExplination = (
  <div className="mt-2">
    Valid LOA values are:{" "}
    <ul>
      <li>https://skolid.se/loa1</li> <li>https://skolid.se/loa2</li> <li>https://skolid.se/loa3</li>
    </ul>
  </div>
);

const SetMappingInput = ({ field }: { field: ComplexField<SetIdpClaimMappingInput> }) => {
  return (
    <div className="mt-2 bg-secondary p-2">
      <div className="form-row">
        <div className="col">
          <label>Target claim</label>
          <SelectClaim
            selectedClaim={field.fields.target.value}
            onChange={field.fields.target.set}
            disabled={field.disabled}
          />
        </div>
        <div className="col">
          <FieldInput field={field.fields.value} label="Value"></FieldInput>
          {field.fields.target.value === "acr" ? validAcrExplination : null}
        </div>
      </div>
    </div>
  );
};

const TransferMappingInput = ({ field }: { field: ComplexField<TransferIdpClaimMappingInput> }) => {
  return (
    <div className="mt-2 bg-secondary p-2">
      <div className="form-row form-group">
        <div className="col">
          <FieldInput field={field.fields.source} label="Source"></FieldInput>
        </div>

        <div className="col">
          <label>Target claim</label>
          <SelectClaim
            selectedClaim={field.fields.target.value}
            onChange={field.fields.target.set}
            disabled={field.disabled}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <FieldInput field={field.fields.matchRegex!} label="Match regexp"></FieldInput>
        </div>

        <div className="col">
          <FieldInput field={field.fields.targetValueSubstitution!} label="Target value substitution"></FieldInput>
          <div className="mt-2">
            The variables $1, $2 etc can be used to insert regex matches into the substitution value
          </div>
          {field.fields.target.value === "acr" ? validAcrExplination : null}
        </div>
      </div>
    </div>
  );
};
