import * as React from "react";

export const Checkbox = (props: {
  checked?: boolean;
  onChange: (checked: boolean) => void;
  label: string;
  disabled?: boolean;
  inline?: boolean;
}) => {
  const id = Math.random().toString();

  return (
    <div className={"form-check " + (props.inline ? "form-check-inline" : "")}>
      <input
        className="form-check-input"
        type="checkbox"
        checked={props.checked}
        onChange={() => props.onChange(!props.checked)}
        id={id}
        disabled={props.disabled}
      />
      <label className="form-check-label" htmlFor={id}>
        {props.label}
      </label>
    </div>
  );
};
