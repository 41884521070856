import * as React from "react";
import { Input } from "@ist-group-private-scope/skolid-client-components";
import { FormClient } from "../types";
import { SelectOrganization } from "../../Common/SelectOrganization";
import { ClientFragment } from "../../admin-gql";

export const DetailsForm = (props: {
  originalClient: ClientFragment;
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const updateClient = props.updateClient;
  const disabled = props.disabled;

  return (
    <div className="box">
      <div className="box-header">Base details</div>
      <div className="box-body">
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={!!client.enabled}
              onChange={() => updateClient({ enabled: !client.enabled })}
              id="enabledCheckbox"
              disabled={disabled}
            />
            <label className="form-check-label" htmlFor="enabledCheckbox">
              Enabled
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <Input
              value={props.originalClient.id}
              label="Client Id"
              readonly
              disabled={disabled}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md">
            <Input
              value={client.displayName}
              onChange={(x) => updateClient({ displayName: x })}
              label="Display Name"
              disabled={disabled}
            />
          </div>
          <div className="form-group col-md">
            <Input
              value={client.clientUri}
              onChange={(x) => updateClient({ clientUri: x })}
              label="Client URI"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <Input
              value={client.description}
              onChange={(x) => updateClient({ description: x })}
              label="Description"
              multiline
              disabled={disabled}
            />
          </div>
          <div className="form-group col-md-6">
            <Input
              value={client.contact}
              onChange={(x) => updateClient({ contact: x })}
              label="Contact"
              multiline
              disabled={disabled}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col form-group">
            <label>Organization</label>
            <SelectOrganization
              organizationId={client.organizationId}
              disabled={disabled}
              onChange={(organizationId) =>
                updateClient({
                  organizationId,
                })
              }
            />
            <div className="mt-1 small">
              This setting will NOT limit authentication to users in a specific
              organization. See the "User Organization" setting under the
              authentication section to limit access.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
