import * as React from "react";
import permGql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { AsyncOperation } from "../types";
import { AssignDomainAccess } from "../permissons-gql";
import { Subject, SelectSubject } from "../Common/SelectSubject";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { subjectFragmentGql } from "./fragments";
import { Spinner } from "../Common/Spinner";
import { ExecutionResult } from "graphql";

interface AssignAccessModalProps {
  domainId: string;
  isOpen: boolean;
  onCancel: () => void;
  onAssigned: () => Promise<any>;
  apolloClient: ApolloClient<any>;
}

interface AssignAccessModalState {
  selectedSubject: Subject | null;
  assignOperation: AsyncOperation<any>;
}

export class AssignAccessModal extends React.Component<
  AssignAccessModalProps,
  AssignAccessModalState
> {
  constructor(props: AssignAccessModalProps) {
    super(props);

    this.state = {
      selectedSubject: null,
      assignOperation: {}
    };
  }

  public render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel}>
        <ModalHeader>Assign access</ModalHeader>
        <ModalBody>
          <SelectSubject
            onSelect={selectedSubject => this.setState({ selectedSubject })}
            showSelected
          />
          {this.state.assignOperation.error ? (
            <div className="alert alert-danger">{this.state.assignOperation.error}</div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={this.props.onCancel}
            disabled={this.state.assignOperation.running}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={this.handleAssignClick}
            disabled={this.state.assignOperation.running || !this.state.selectedSubject}
          >
            {this.state.assignOperation.running ? <Spinner light /> : "Assign"}
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private handleAssignClick = async () => {
    this.setState({ assignOperation: { running: true } });

    try {
      const response: ExecutionResult<AssignDomainAccess> = await this.props.apolloClient.mutate({
        mutation: permGql`mutation AssignDomainAccess($input: AssignRoleInput!) {
            assignRole(input: $input) {
              globalId
              subject {
                ...Subject
              }
            }
          }

          ${subjectFragmentGql}
          `,
        variables: {
          input: {
            resourceTypeId: "permissions-domain",
            domainId: "skolid-permissions",
            resourceId: this.props.domainId,
            subjectId: this.state.selectedSubject!.id,
            subjectType: this.state.selectedSubject!.type,
            roleId: "default",
            createReferences: true
          }
        }
      });

      await this.props.onAssigned();

      this.setState({
        assignOperation: { data: response.data },
        selectedSubject: null
      });
    } catch (error) {
      console.error("Failed to assign subject access: ", error);
      this.setState({ assignOperation: { error: "Failed to assign subject access" } });
    }
  };
}
