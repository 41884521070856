import * as React from "react";
import { FormClient } from "../types";
import { Input } from "@ist-group-private-scope/skolid-client-components";

export const CustomPropertiesForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const updateClient = props.updateClient;

  const clientProps = client.properties || [];
  return (
    <div className="box">
      <div className="box-header">Custom Properties</div>
      <div className="box-body">
        {clientProps.map((prop, propIndex) => (
          <div key={propIndex} className="form-row">
            <div className="form-group col-5">
              <Input
                value={prop.key}
                label="Key"
                disabled={props.disabled}
                onChange={newKey =>
                  updateClient({
                    properties: clientProps.map((x, i) => (i === propIndex ? { ...x, key: newKey } : x))
                  })
                }
              />
            </div>
            <div className="form-group col-5">
              <Input
                value={prop.value}
                label="Value"
                disabled={props.disabled}
                onChange={newValue =>
                  updateClient({
                    properties: clientProps.map((x, i) => (i === propIndex ? { ...x, value: newValue } : x))
                  })
                }
              />
            </div>
            <div className="form-group col-2 align-self-end">
              <button
                className="btn"
                disabled={props.disabled}
                onClick={() =>
                  updateClient({
                    properties: clientProps.filter((x, i) => i !== propIndex)
                  })
                }
              >
                Remove
              </button>
            </div>
          </div>
        ))}
        <button
          className="btn btn-primary"
          onClick={() => updateClient({ properties: clientProps.concat([{ key: "", value: "" }]) })}
        >
          Add
        </button>
      </div>
    </div>
  );
};
