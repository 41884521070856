import * as React from "react";
import { Client } from "../admin-gql";
import Select from "react-select";

type Optional<T> = {
  [P in keyof T]?: T[P] | null;
};

type AuthSettings = Pick<
  Client,
  | "disableBankIdLogin"
  | "disableMicrosoftLogin"
  | "disableGoogleLogin"
  | "disableFrejaLogin"
  | "disableIdportenLogin"
  | "disableSkolidLogin"
>;

const mapping: { [P in keyof AuthSettings]: string } = {
  disableBankIdLogin: "BankId",
  disableMicrosoftLogin: "Microsoft",
  disableGoogleLogin: "Google",
  disableFrejaLogin: "Freja",
  disableIdportenLogin: "Idporten",
  disableSkolidLogin: "Skolid",
};

export const SelectAuthMethods = (props: {
  authSettings: Optional<AuthSettings>;
  onChange: (newAuthSettings: AuthSettings) => void;
  disabled?: boolean;
}) => {
  return (
    <Select
      options={Object.keys(mapping).map((k: any) => ({
        label: (mapping as any)[k],
        value: k,
      }))}
      isMulti
      isClearable
      isDisabled={props.disabled}
      value={Object.keys(mapping)
        .filter((k) => !(props.authSettings as any)[k])
        .map((k: any) => ({ label: (mapping as any)[k], value: k }))}
      onChange={(options: any) =>
        props.onChange(
          Object.keys(mapping).reduce(
            (prev, key) => ({
              ...prev,
              [key]: options.every((x: any) => x.value !== key),
            }),
            {}
          ) as any
        )
      }
    />
  );
};
