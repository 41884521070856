import * as React from "react";
import { Input } from "@ist-group-private-scope/skolid-client-components";
import { FormClient } from "../types";

export const AdvancedForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const updateClient = props.updateClient;
  const disabled = props.disabled;

  return (
    <div className="box">
      <div className="box-header">Advanced</div>
      <div className="box-body">
        <div className="form-row">
          <div className="col">
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="useOrgIdAsPnumCheckbox"
                checked={!props.client.useTfNumberAsNationalIdFallback}
                title={
                  !client.organizationId
                    ? "This settings is only available if the client is associated with an organisation"
                    : ""
                }
                disabled={props.disabled || !client.organizationId}
                onChange={(ev) =>
                  updateClient({
                    useTfNumberAsNationalIdFallback: !ev.target.checked,
                  })
                }
              />
              <label className="form-check-label" htmlFor="useOrgIdAsPnumCheckbox">
                Do not use the organizations temporary national id as fallback even if it exists
              </label>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="assumeSkolidExtension"
                checked={!!props.client.assumeSkolidExtensionOnChromeOs}
                disabled={props.disabled}
                onChange={(ev) =>
                  updateClient({
                    assumeSkolidExtensionOnChromeOs: ev.target.checked,
                  })
                }
              />
              <label className="form-check-label" htmlFor="assumeSkolidExtension">
                Assume skolid extension is available on chrome os.
              </label>
            </div>
            <p className="small mt-2">
              When enabled the user will skip the second factor setup screen while activating since the skolid chrome
              extension is assumed to launched after login and will add a client certificate as a second factor to the
              user account.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
