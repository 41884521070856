import * as React from "react";
import { Query, QueryResult, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";
import { SearchClients, SearchClientsVariables, ExportClients } from "../admin-gql";
import { Spinner } from "../Common/Spinner";
import { Link, useHistory } from "react-router-dom";
import * as routes from "../routes";
import { AsyncOperation } from "../types";
import { ApolloClient, ApolloQueryResult } from "apollo-client";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ClientImportModal } from "./ClientImportModal";
import { TableCaption } from "../Common/TableCaption";
import { useSearchString } from "@ist-group-private-scope/skolid-client-components";
import { AddClientModal } from "./AddClientModal";

const searchClientsGql = gql`
  query SearchClients($filter: String, $before: String, $after: String) {
    searchClients(filter: $filter, before: $before, after: $after) {
      edges {
        node {
          ...ClientSearchResult
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }

  fragment ClientSearchResult on Client {
    id
    clientId
    displayName
    organization {
      id
      name
    }
    clientUri
  }
`;

interface ClientsScreenState {
  before?: string | null;
  after?: string | null;
  checkedClients: { [key: string]: boolean };
  exportOperation: AsyncOperation<string>;
  showImportClients?: boolean;
  importOperation: AsyncOperation<boolean>;
  importClientsText: string;
}

const initState = {
  checkedClients: {},
  exportOperation: {},
  importOperation: {},
  importClientsText: ""
};

export const ClientsScreen = (props: {}) => {
  const [state, setState] = React.useState<ClientsScreenState>({
    ...initState
  });

  const history = useHistory();

  const { searchString, debouncedSearchString, setSearchString } = useSearchString();
  const search = (str: string) => {
    setSearchString(str);
    setState(p => ({ ...p, after: null, before: null }));
  };

  const exportClients = async (client: ApolloClient<any>) => {
    setState(prev => ({
      ...prev,
      exportOperation: { running: true }
    }));

    const response: ApolloQueryResult<ExportClients> = await client.query<ExportClients>({
      query: exportClientsGql,
      variables: {
        ids: Object.keys(state.checkedClients).filter(key => state.checkedClients[key])
      }
    });

    setState(prev => ({ ...prev, exportOperation: { data: response.data.exportClients! } }));
  };

  const [showAddClient, setShowAddClient] = React.useState(false);

  return (
    <div className="flex-fill">
      <h1>Clients</h1>
      <div className="box">
        <div className="box-body">
          <div className="text-right mb-content">
            <ApolloConsumer>
              {client => (
                <button
                  disabled={
                    !Object.keys(state.checkedClients).some(key => state.checkedClients[key])
                  }
                  className="btn btn-secondary"
                  onClick={() => exportClients(client)}
                >
                  Export clients
                </button>
              )}
            </ApolloConsumer>
            &nbsp;&nbsp;
            <button
              className="btn btn-secondary"
              onClick={() => setState(prev => ({ ...prev, showImportClients: true }))}
            >
              Import clients
            </button>
            &nbsp;&nbsp;
            <button className="btn btn-primary" onClick={() => setShowAddClient(true)}>
              <i className="fa fa-plus" />
              &nbsp; Add client
            </button>
            &nbsp;&nbsp;
            <Link className="btn btn-primary" to={routes.importClientRoute}>
              <i className="fa fa-plus" />
              &nbsp; Add SAML client
            </Link>
          </div>
          <Query<SearchClients, SearchClientsVariables>
            query={searchClientsGql}
            variables={{
              filter: debouncedSearchString,
              before: state.before,
              after: state.after
            }}
            fetchPolicy="network-only"
          >
            {result => (
              <>
                <form
                  onSubmit={ev => {
                    ev.preventDefault();
                    result.refetch();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search clients"
                    value={searchString}
                    onChange={ev => search(ev.currentTarget.value)}
                  />
                </form>

                <br />

                {result.loading ? (
                  <div className="text-center">
                    <Spinner />
                  </div>
                ) : null}

                {result.error ? <div>Something went wrong!?</div> : null}

                {result.data && result.data.searchClients && !result.loading ? (
                  <>
                    <ClientsTable
                      checkedClients={state.checkedClients}
                      onCheckedChange={clientId =>
                        setState(prevState => ({
                          ...prevState,
                          checkedClients: {
                            ...prevState.checkedClients,
                            [clientId]: !prevState.checkedClients[clientId]
                          }
                        }))
                      }
                      result={result}
                      onAfter={cursor =>
                        setState(prevState => ({ ...prevState, after: cursor, before: null }))
                      }
                      onBefore={cursor =>
                        setState(prevState => ({ ...prevState, before: cursor, after: null }))
                      }
                    />
                  </>
                ) : null}

                <ClientImportModal
                  show={state.showImportClients}
                  onClose={() => {
                    setState(prev => ({ ...prev, showImportClients: false }));
                    result.refetch();
                  }}
                />
              </>
            )}
          </Query>
        </div>
      </div>
      <Modal size="lg" isOpen={Object.keys(state.exportOperation).length > 0}>
        <ModalHeader>Client export</ModalHeader>
        <ModalBody className="text-center">
          {state.exportOperation.running ? <Spinner /> : null}
          {state.exportOperation.data ? (
            <textarea
              autoFocus
              onFocus={ev => ev.target.select()}
              rows={20}
              className="w-100"
              readOnly
              style={{
                border: "1px solid gray",
                backgroundColor: "lightgray"
              }}
              value={state.exportOperation.data}
            />
          ) : null}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            disabled={state.exportOperation.running}
            onClick={() => setState(prev => ({ ...prev, exportOperation: {} }))}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
      <AddClientModal
        onClose={(newClientId?: string) => {
          setShowAddClient(false);
          if (newClientId) {
            history.push(routes.generateClientPath({ id: newClientId }));
          }
        }}
        show={showAddClient}
      />
    </div>
  );
};

const exportClientsGql = gql`
  query ExportClients($ids: [String!]!) {
    exportClients(ids: $ids)
  }
`;

const ClientsTable = (props: {
  result: QueryResult<SearchClients, SearchClientsVariables>;
  checkedClients: { [key: string]: boolean };
  onCheckedChange: (clientId: string) => void;
  onBefore: (cursor: string) => void;
  onAfter: (cursor: string) => void;
}) => {
  if (props.result.data!.searchClients!.edges.length === 0) {
    return <p>No clients</p>;
  }

  return (
    <table className="table table-hover table-sm table-fixed">
      <thead>
        <tr>
          <th style={{ width: 40 }} />
          <th>Id</th>
          <th>Name</th>
          <th>Uri</th>
          <th>Organization</th>
        </tr>
      </thead>
      <tbody>
        {props.result
          .data!.searchClients!.edges.map(x => x.node)
          .map(p => (
            <tr key={p.clientId}>
              <td>
                <input
                  type="checkbox"
                  checked={!!props.checkedClients[p.clientId]}
                  onChange={ev => props.onCheckedChange(p.clientId)}
                />
              </td>
              <td className="truncate">
                <Link to={routes.generateClientPath({ id: p.id.toString() })}>{p.clientId}</Link>
              </td>
              <td>{p.displayName}</td>
              <td className="truncate">
                {p.clientUri ? <a href={p.clientUri}>{p.clientUri}</a> : null}
              </td>
              <td className="truncate">{p.organization ? p.organization.name : null}</td>
            </tr>
          ))}
      </tbody>

      <TableCaption
        connectionData={props.result.data!.searchClients!}
        onBefore={props.onBefore}
        onAfter={props.onAfter}
      />
    </table>
  );
};
