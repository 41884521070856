import * as React from "react";
import { FetchScopes } from "../admin-gql";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Select from "react-select";

const fetchScopesGql = gql`
  query FetchScopes {
    apiResources {
      scopes {
        name
      }
    }

    identityResources {
      name
    }
  }
`;

export const SelectScopes = (props: {
  selectedScopes: string[];
  onChange: (selectedScopes: string[]) => void;
  disabled?: boolean;
}) => {
  return (
    <Query<FetchScopes> query={fetchScopesGql}>
      {result => (
        <Select
          options={
            result.data && !result.loading && result.data.apiResources
              ? result.data.apiResources
                  .reduce<string[]>((prev, curr) => prev.concat(curr.scopes.map(x => x.name)), [])
                  .concat(result.data.identityResources.map(x => x.name))
                  .map(x => ({ label: x, value: x }))
              : []
          }
          isLoading={result.loading}
          isMulti
          isDisabled={props.disabled}
          value={props.selectedScopes.map(x => ({ label: x, value: x }))}
          onChange={(options: any) => props.onChange(options.map((x: any) => x.value))}
        />
      )}
    </Query>
  );
};
