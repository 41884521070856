import {
  AssignOrganizationRoleModal,
  Input,
} from "@ist-group-private-scope/skolid-client-components";
import { environmentSettings, SignContext } from "@ist-group-private-scope/web-skolid";
import gql from "graphql-tag";
import * as React from "react";
import { useContext } from "react";
import { Query, QueryResult, useApolloClient, useMutation } from "react-apollo";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import {
  FetchOrganization,
  FetchOrganizationVariables,
  OrganizationFragment,
  OrganizationRole,
  RemoveRole,
  RemoveRoleVariables,
} from "../admin-gql";
import { LoadingScreen } from "../App/LoadingScreen";
import { ConfirmModal } from "../Permissions/DeleteModal";
import {
  generateClientPath,
  generateUserPath,
  OrganizationRouteParams,
} from "../routes";
import { settings } from "../settings";
import { TypedQuery } from "../types";
import { OrganizationFormModal } from "./OrganizationFormModal";
import { ORGANIZATION_FRAGMENT } from "./queries";

const fetchOrganizationGql = gql`
  query FetchOrganization($id: String!) {
    organization(id: $id) {
      ...OrganizationFragment
    }
  }

  ${ORGANIZATION_FRAGMENT}
`;

const FetchOrganizationQuery: TypedQuery<
  FetchOrganization,
  FetchOrganizationVariables
> = Query;

export class OrganizationScreen extends React.Component<
  RouteComponentProps<OrganizationRouteParams>,
  {}
> {
  public render() {
    return (
      <FetchOrganizationQuery
        query={fetchOrganizationGql}
        variables={{ id: this.props.match.params.id }}
      >
        {this.renderApolloQueryResult}
      </FetchOrganizationQuery>
    );
  }

  private renderApolloQueryResult = (
    result: QueryResult<FetchOrganization, FetchOrganizationVariables>
  ) => {
    if (result.loading) {
      return <LoadingScreen />;
    }

    if (result.error) {
      return <div>Something went wrong!?</div>;
    }

    if (!result.data || !result.data.organization) {
      return <h1>Organization does not exist</h1>;
    }

    return (
      <OrganizationPresentationData organization={result.data!.organization} />
    );
  };
}

interface OrganizationPresentationProps {
  organization: OrganizationFragment;
}

interface OrganizationPresentationState {
  showEditOrganization?: boolean;
}

const OrganizationPresentationData = (props: OrganizationPresentationProps) => {
  const organization = props.organization;
  const [state, setState] = React.useState<OrganizationPresentationState>({});
  const [
    showAssingOrganizationRole,
    setShowAssingOrganizationRole,
  ] = React.useState(false);
  const client = useApolloClient();
  const signManager = useContext(SignContext)!;

  return (
    <div className="flex-fill">
      <div className="row align-items-center">
        <h1 className="col m-0 mb-sm-content">
          Organization: {organization.name}
        </h1>
        <div className={"col-sm d-flex justify-content-md-end my-content"}>
          <a
            className="btn btn-primary"
            type="button"
            target="_blank"
            rel="noopener noreferrer"
            href={
              environmentSettings[settings.skolidEnvironment].url +
              "/" +
              organization.id +
              "/saml/metadata"
            }
          >
            Show IdP metadata
          </a>
          &nbsp;&nbsp;
          <button
            className="btn btn-primary"
            onClick={() => setState({ showEditOrganization: true })}
          >
            Edit
          </button>
        </div>
      </div>

      <div className="box">
        <div className="box-body">
          <div className="form-row">
            <div className="form-group col">
              <Input value={organization.id} label="Id" readonly />
            </div>

            <div className="form-group col">
              <Input value={organization.name} label="Name" readonly />
            </div>
          </div>

          <div className="form-group">
            <Input value={organization.issueLoa} label="Issue LOA" readonly />
          </div>

          <div className="form-group">
            <Input
              value={organization.country || "International"}
              label="Country"
              readonly
            />
          </div>

          <h2 className="mt-content">Privileged users</h2>
          <button
            className="btn btn-primary"
            onClick={() => setShowAssingOrganizationRole(true)}
          >
            Assign role
          </button>
          <UsersTable organization={organization} />

          <h2 className="mt-content">Clients</h2>
          <ClientsTable organization={organization} />
        </div>
      </div>

      <OrganizationFormModal
        organization={organization}
        show={state.showEditOrganization}
        onSave={() => setState({ showEditOrganization: false })}
        onCancel={() => setState({ showEditOrganization: false })}
      />

      <AssignOrganizationRoleModal
        organizationId={organization.id}
        context={{
          client,
          signManager,
          locale: "sv",
        }}
        onClose={() => setShowAssingOrganizationRole(false)}
        refetchQueries={["FetchOrganization"]}
        show={showAssingOrganizationRole}
      ></AssignOrganizationRoleModal>
    </div>
  );
};

const UsersTable = (props: { organization: OrganizationFragment }) => {
  const organization = props.organization;
  return (
    <>
      {organization.elevatedUsers.nodes.length > 0 ? (
        <table className="table table-hover table-fixed mb-0">
          <thead>
            <tr>
              <th className="truncate">Namn</th>
              <th className="truncate">Roll</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {organization.elevatedUsers.nodes.map((user) => (
              <UserRow key={user.id} user={user} />
            ))}
          </tbody>
        </table>
      ) : null}
    </>
  );
};

const removeRoleGql = gql`
  mutation RemoveRole($userId: String!) {
    updateUser(userId: $userId, user: { role: USER }) {
      id
    }
  }
`;

const UserRow = ({
  user,
}: {
  user: { id: string; name: string | null; role: OrganizationRole };
}) => {
  const [removeRole, mutation] = useMutation<RemoveRole, RemoveRoleVariables>(
    removeRoleGql,
    {
      variables: { userId: user.id },
      refetchQueries: ["FetchOrganization"],
      awaitRefetchQueries: true,
    }
  );

  const [showConfirmRemoveRole, setShowConfirmRemoveRole] = React.useState(
    false
  );

  return (
    <tr>
      <td className="truncate" title={user.name || undefined}>
        <Link to={generateUserPath({ id: user.id })}>{user.name}</Link>
      </td>
      <td className="truncate" title={user.role || undefined}>
        {user.role}
      </td>
      <td>
        <button
          className="btn btn-link"
          onClick={() => setShowConfirmRemoveRole(true)}
        >
          Unassign role
        </button>
        <ConfirmModal
          isOpen={showConfirmRemoveRole}
          onClose={() => setShowConfirmRemoveRole(false)}
          onConfirm={async () => {
            await removeRole();
            setShowConfirmRemoveRole(false);
          }}
          operation={{ running: mutation.loading, error: mutation.error }}
        />
      </td>
    </tr>
  );
};

const ClientsTable = (props: { organization: OrganizationFragment }) => {
  const organization = props.organization;
  return (
    <table className="table table-hover table-fixed mb-0">
      <thead>
        <tr>
          <th style={{ width: 100 }} className="text-center">
            Aktiverad
          </th>
          <th>Id</th>
          <th className="truncate">Namn</th>
          <th className="truncate">Uri</th>
        </tr>
      </thead>
      <tbody>
        {organization.clients.map((client) => (
          <tr key={client.id}>
            <td className="text-center">
              <input type="checkbox" readOnly checked={client.enabled} />
            </td>
            <td className="truncate" title={client.id}>
              <Link to={generateClientPath({ id: client.id })}>
                {client.id}
              </Link>
            </td>
            <td className="truncate" title={client.displayName || undefined}>
              {client.displayName}
            </td>
            <td className="truncate" title={client.clientUri || undefined}>
              {client.clientUri}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
