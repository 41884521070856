import { useForm } from "@ist-group/react-form-hook";
import {
  Modal,
  SelectOrganizationRole,
} from "@ist-group-private-scope/skolid-client-components";
import gql from "graphql-tag";
import * as React from "react";
import { useApolloClient, useMutation, useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import {
  CountryCode,
  CreateUser,
  CreateUserInput,
  CreateUserVariables,
  IssueNewUserOrganization,
  IssueNewUserOrganizationVariables,
  OrganizationRole,
  strippers,
} from "../admin-gql";
import { RequiredDeep } from "../Clients/types";
import { SelectOrganization } from "../Common/SelectOrganization";
import { Spinner } from "../Common/Spinner";
import * as routes from "../routes";
import { BasicUserForm } from "./Components/BasicUserForm";
import { BasicFormUser } from "./types";
import { createAdminNationalIdValidator } from "./validation";
import { Trans } from 'react-i18next';
import i18next from 'i18next';

export const CreateUserModal = (props: { onClose: () => void }) => {
  const client = useApolloClient();
  const history = useHistory();

  const [createUser, createUserMutation] = useMutation<
    CreateUser,
    CreateUserVariables
  >(
    gql`
      mutation CreateUser(
        $orgId: String!
        $user: CreateUserInput!
        $options: CreateUserOptionsInput!
      ) {
        createUser(organizationId: $orgId, user: $user, options: $options) {
          id
        }
      }
    `
  );

  const [organizationId, setOrganizationId] = React.useState<string | null>();

  const organizationQuery = useQuery<
    IssueNewUserOrganization,
    IssueNewUserOrganizationVariables
  >(
    gql`
      query IssueNewUserOrganization($orgId: String!) {
        organization(id: $orgId) {
          id
          name
          country
        }
      }
    `,
    {
      skip: !organizationId,
      variables: {
        orgId: organizationId!,
      },
    }
  );

  const form = useForm<
    BasicFormUser & { role: OrganizationRole },
    React.ReactNode
  >(
    {
      firstName: "",
      lastName: "",
      nationalId: "",
      tfNumber: "",
      username: "",
      emails: [],
      phoneNumbers: [],
      addresses: [],
      role: OrganizationRole.USER,
      expiresAt: null,
    },
    {
      onSubmit: async (val) => {
        const response = await createUser({
          variables: {
            orgId: organizationId!,
            user: strippers.CreateUserInput(val),
            options: {
              silent: true,
            },
          },
        });

        if (response.data && response.data.createUser) {
          history.push(
            routes.generateUserPath({ id: response.data.createUser.id })
          );
        }
      },
      fieldValidation: {
        fields: {
          nationalId: {
            // NOTE this validation will not trigger if organization is changed and there is no simple way to force this re-validation
            // the react-form-hook needs to add support for it.
            onChange: organizationQuery?.data?.organization
              ? createAdminNationalIdValidator(
                  null,
                  organizationQuery.data.organization.country,
                  organizationQuery.data.organization.id,
                  client
                )
              : undefined,
          },
        },
      },
    }
  );

  return (
    <Modal header="Create User" onClose={props.onClose}>
      {(close) => (
        <>
          <div className="modal-body">
            <div className="form-group">
              <label>Organization</label>
              <SelectOrganization
                organizationId={organizationId}
                onChange={setOrganizationId}
              />
            </div>

            {organizationQuery.data?.organization ? (
              <>
                <div className="form-group">
                  <label>Role</label>
                  <SelectOrganizationRole
                    role={form.fields.role.value}
                    onChange={form.fields.role.set}
                  />
                </div>

                <BasicUserForm
                  organization={organizationQuery.data.organization}
                  formFields={form.fields}
                  disabled={form.submitting}
                ></BasicUserForm>
              </>
            ) : null}

            {createUserMutation.error ? (
              <div className="alert alert-danger">Failed to create user </div>
            ) : null}
          </div>
          <div className="modal-footer d-flex justify-content-between align-items-center">
            <button
              className="btn btn-secondary"
              onClick={close}
              disabled={form.submitting}
            >
              {i18next.t('new.user.edit.form.cancel')}
            </button>

            {form.submitting ? <Spinner /> : null}

            <button
              className="btn btn-primary"
              onClick={form.submit}
              disabled={form.submitting || !organizationId}
            >
              {i18next.t('new.user.edit.form.next')}
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};
