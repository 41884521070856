import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { FetchGrantTypes } from "../admin-gql";
import Select from "react-select";
import { TypedQuery } from "../types";

const fetchGrantTypesGql = gql`
  query FetchGrantTypes {
    grantTypes
  }
`;

const QueryGrantTypes: TypedQuery<FetchGrantTypes> = Query;

export const SelectGrantTypes = (props: {
  selectedGrantTypes: string[];
  onChange: (selectedGrantTypes: string[]) => void;
  disabled?: boolean;
}) => {
  return (
    <QueryGrantTypes query={fetchGrantTypesGql}>
      {result => (
        <Select
          options={
            result.data && !result.loading
              ? result.data.grantTypes.map(x => ({ label: x, value: x }))
              : []
          }
          isMulti
          isLoading={result.loading}
          value={props.selectedGrantTypes.map(x => ({ label: x, value: x }))}
          isClearable
          isDisabled={props.disabled}
          onChange={(options: any) => props.onChange(options.map((x: any) => x.value))}
        />
      )}
    </QueryGrantTypes>
  );
};
