import * as React from "react";
import { ModalHeader, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Spinner } from "../Common/Spinner";
import { AsyncOperation } from "../types";

interface SaveModalProps {
  new?: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
  saveOperation: AsyncOperation<any>;
  entityName: string;
  isEntityValid: boolean;
}

export class SaveModal extends React.Component<SaveModalProps> {
  public render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel}>
        <ModalHeader>
          {this.props.new ? "New" : "Edit"} {this.props.entityName}
        </ModalHeader>
        <ModalBody>
          {this.props.children}
          {this.props.saveOperation.error ? (
            <div className="alert alert-danger">{this.props.saveOperation.error}</div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={this.props.onCancel}
            disabled={this.props.saveOperation.running}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={this.props.onSave}
            disabled={this.props.saveOperation.running || !this.props.isEntityValid}
          >
            {this.props.saveOperation.running ? (
              <Spinner light />
            ) : this.props.new ? (
              "Create"
            ) : (
              "Save"
            )}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
