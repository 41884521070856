import * as React from "react";
import { Query, ApolloConsumer, QueryResult } from "react-apollo";
import gql from "graphql-tag";
import {
  FetchIdentityResourceVariables,
  FetchIdentityResource,
  IdentityResourceFragment,
  CreateIdentityResource,
  CreateIdentityResourceInput,
  UpdateIdentityResourceInput,
  strippers
} from "../admin-gql";
import { Spinner } from "../Common/Spinner";
import { RouteComponentProps } from "react-router";
import { ResourceRouteParams, generateIdentityResourcePath, scopesPath } from "../routes";
import { ApolloClient } from "apollo-client";
import { identityResourceFragmentGql } from "./fragments";
import { History } from "history";
import { FormIdentityResource } from "./types";
import { LoadingScreen } from "../App/LoadingScreen";
import { AsyncOperation, TypedQuery } from "../types";
import { Checkbox } from "../Common/Checkbox";
import { Input } from "@ist-group-private-scope/skolid-client-components";
import { SelectClaims } from "../Common/SelectClaims";
import { removeTypename } from "../Utils/graphql";

const fetchIdentityResourceGql = gql`
  query FetchIdentityResource($name: String!) {
    identityResource(name: $name) {
      ...IdentityResourceFragment
    }
  }

  ${identityResourceFragmentGql}
`;

const FetchIdentityResourceQuery: TypedQuery<FetchIdentityResource, FetchIdentityResourceVariables> = Query;

export class IdentityResourceScreen extends React.Component<RouteComponentProps<ResourceRouteParams>, {}> {
  public render() {
    if (this.props.match.params.name.toString() === "-1") {
      return (
        <ApolloConsumer>
          {client => (
            <IdentityResourceForm history={this.props.history} apolloClient={client} identityResource={null} />
          )}
        </ApolloConsumer>
      );
    }

    return (
      <FetchIdentityResourceQuery query={fetchIdentityResourceGql} variables={{ name: this.props.match.params.name }}>
        {this.renderApolloQueryResult}
      </FetchIdentityResourceQuery>
    );
  }

  private renderApolloQueryResult = (result: QueryResult<FetchIdentityResource, FetchIdentityResourceVariables>) => {
    if (result.loading) {
      return <LoadingScreen />;
    }

    if (result.error) {
      return <div>Something went wrong!?</div>;
    }

    if (!result.data || !result.data.identityResource) {
      return <h1>Identity resource does not exist</h1>;
    }

    return (
      <IdentityResourceForm
        history={this.props.history}
        apolloClient={result.client}
        identityResource={result.data!.identityResource!}
      />
    );
  };
}

interface IdentityResourceFormProps {
  identityResource: IdentityResourceFragment | null;
  apolloClient: ApolloClient<any>;
  history: History;
}

interface IdentityResourceFormState {
  identityResource: FormIdentityResource;
  lastPropsIdentityResource: IdentityResourceFragment | null;
  saveOperation: AsyncOperation<void>;
  deleteOperation: AsyncOperation<void>;
  showValidationErrors?: boolean;
}

class IdentityResourceForm extends React.Component<IdentityResourceFormProps, IdentityResourceFormState> {
  public static getDerivedStateFromProps(
    nextProps: IdentityResourceFormProps,
    prevState: IdentityResourceFormState
  ): Partial<IdentityResourceFormState> | null {
    if (nextProps.identityResource !== prevState.lastPropsIdentityResource && nextProps.identityResource) {
      return {
        identityResource: nextProps.identityResource,
        lastPropsIdentityResource: nextProps.identityResource
      };
    } else {
      return null;
    }
  }

  constructor(props: IdentityResourceFormProps) {
    super(props);
    this.state = {
      saveOperation: {},
      deleteOperation: {},
      lastPropsIdentityResource: props.identityResource,
      identityResource: props.identityResource || {
        enabled: true,
        name: "",
        claims: [],
        description: "",
        displayName: "",
        emphasize: false,
        required: false,
        showInDiscoveryDocument: true
      }
    };
  }

  public render() {
    const identityResource = this.state.identityResource;
    const disabled = this.state.saveOperation.running || this.state.deleteOperation.running;
    const update = this.updateIdentityResource;

    const nameValidationError = !identityResource.name ? "Name required" : null;
    const displayNameValidationError = !identityResource.displayName ? "Display name required" : null;
    const hasValidationError = !!(nameValidationError || displayNameValidationError);
    const isNew = !this.props.identityResource;

    return (
      <div className="flex-fill">
        <div className="row">
          <div className="col">
            <h1>{!this.props.identityResource ? "Create" : "Update"} Identity resource</h1>
            {this.state.saveOperation.error ? (
              <div className="alert alert-danger">{this.state.saveOperation.error}</div>
            ) : null}
          </div>
        </div>

        <div className="box">
          <div className="box-body">
            <div className="form-group">
              <Checkbox
                checked={identityResource.enabled}
                disabled={disabled}
                onChange={checked => update({ enabled: checked })}
                label="Enabled"
              />
            </div>

            <div className="form-row">
              <div className="form-group col">
                <Input
                  value={identityResource.name}
                  label="Name"
                  onChange={text => update({ name: text })}
                  disabled={disabled || !isNew}
                  validationErrorMessage={nameValidationError}
                  forceShowValidationError={this.state.showValidationErrors}
                />
              </div>

              <div className="form-group col">
                <Input
                  value={identityResource.displayName}
                  label="Display Name"
                  onChange={text => update({ displayName: text })}
                  disabled={disabled}
                  validationErrorMessage={displayNameValidationError}
                  forceShowValidationError={this.state.showValidationErrors}
                />
              </div>
            </div>

            <div className="form-group">
              <Input
                value={identityResource.description}
                label="Description"
                onChange={text => update({ description: text })}
                disabled={disabled}
              />
            </div>

            <div className="form-group">
              <label>Claims</label>
              <SelectClaims
                selectedClaims={identityResource.claims}
                disabled={disabled}
                onChange={selectedClaims => update({ claims: selectedClaims })}
              />
            </div>

            <div className="form-group">
              <Checkbox
                inline
                checked={identityResource.required}
                disabled={disabled}
                label="Required"
                onChange={checked => update({ required: checked })}
              />

              <Checkbox
                inline
                checked={identityResource.emphasize}
                disabled={disabled}
                label="Emphasize"
                onChange={checked => update({ emphasize: checked })}
              />

              <Checkbox
                inline
                checked={identityResource.showInDiscoveryDocument}
                disabled={disabled}
                label="Show in metadata"
                onChange={checked => update({ showInDiscoveryDocument: checked })}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="text-right">
              {this.props.identityResource ? (
                <>
                  <button className="btn btn-danger" onClick={this.delete} disabled={disabled}>
                    {this.state.deleteOperation.running ? <Spinner light /> : "Delete"}
                  </button>
                  &nbsp;&nbsp;
                </>
              ) : null}
              <button
                className="btn btn-primary"
                onClick={() => this.save(hasValidationError)}
                disabled={(hasValidationError && this.state.showValidationErrors) || disabled}
              >
                {this.state.saveOperation.running ? <Spinner light /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private delete = async () => {
    this.setState({ deleteOperation: { running: true } });
    await this.props.apolloClient.mutate({
      mutation: deleteIdentityResourceGql,
      variables: { name: this.props.identityResource!.name }
    });

    this.props.history.replace(scopesPath);
  };

  private save = async (hasValidationErrors: boolean) => {
    if (hasValidationErrors) {
      this.setState({ showValidationErrors: true });
      return;
    }

    this.setState({ saveOperation: { running: true } });

    try {
      if (!this.props.identityResource) {
        const result: any = await this.props.apolloClient.mutate({
          mutation: createIdentityResourceGql,
          variables: {
            input: removeTypename(convertToCreateIdentityResource(this.state.identityResource))
          }
        });
        const data: CreateIdentityResource = result.data;

        this.props.history.replace(generateIdentityResourcePath({ name: data.createIdentityResource!.name }));
        return;
      } else {
        await this.props.apolloClient.mutate({
          mutation: updateIdentityResourceGql,
          variables: {
            input: removeTypename(convertToUpdateIdentityResource(this.state.identityResource)),
            name: this.props.identityResource.name
          }
        });
      }

      this.setState({ saveOperation: {} });
    } catch (error) {
      this.setState({ saveOperation: { error: "Could not save the client" } });
      console.error("Failed to save client: ", error);
    }
  };

  private updateIdentityResource = (partialUpdate: Partial<FormIdentityResource>) => {
    this.setState({ identityResource: { ...this.state.identityResource, ...partialUpdate } });
  };
}

const createIdentityResourceGql = gql`
  mutation CreateIdentityResource($input: CreateIdentityResourceInput!) {
    createIdentityResource(input: $input) {
      ...IdentityResourceFragment
    }
  }

  ${identityResourceFragmentGql}
`;

const deleteIdentityResourceGql = gql`
  mutation DeleteIdentityResource($name: String!) {
    deleteIdentityResource(name: $name)
  }
`;

const updateIdentityResourceGql = gql`
  mutation UpdateIdentityResource($name: String!, $input: UpdateIdentityResourceInput!) {
    updateIdentityResource(name: $name, input: $input) {
      ...IdentityResourceFragment
    }
  }

  ${identityResourceFragmentGql}
`;

export function convertToCreateIdentityResource(input: FormIdentityResource): CreateIdentityResourceInput {
  const { ...create } = input;
  return {
    ...create,
    displayName: input.displayName!
  };
}

export function convertToUpdateIdentityResource(input: FormIdentityResource): UpdateIdentityResourceInput {
  return strippers.UpdateIdentityResourceInput(input);
}
