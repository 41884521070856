import React from "react";
import { Route } from "react-router-dom";
import * as routes from "../routes";
import { IdpListScreen } from "./IdpListScreen";
import { IdpDetailsScreen } from "./IdpDetailsScreen";

export const IdpsRouter = () => {
  return (
    <>
      <Route exact path={routes.idpsPath} component={IdpListScreen} />
      <Route exact path={routes.idpRoute} component={IdpDetailsScreen} />
    </>
  );
};
