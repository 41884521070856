import React from "react";
import { PrimitiveField, NullPrimitiveField } from "@ist-group/react-form-hook";
import { Input, InputProps } from "@ist-group-private-scope/skolid-client-components";

export type FieldInputProps = {
  field: PrimitiveField<string> | NullPrimitiveField<null> | NullPrimitiveField<undefined>;
  label: string;
} & Omit<InputProps, "value">;

export function FieldInput({ field, label, ...innerProps }: FieldInputProps) {
  return (
    <Input
      validationErrorMessage={field.error}
      forceShowValidationError={field.touched}
      value={field.value}
      onBlur={field.touch}
      onChange={field.set}
      label={label}
      disabled={field.disabled}
      {...innerProps}
    />
  );
}
