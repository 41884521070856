import gql from "graphql-tag";

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on Organization {
    id
    name
    issueLoa
    country
    elevatedUsers: users(roles: [ADMIN, ISSUER]) {
      nodes {
        role
        id
        name
      }
    }
    clients {
      id
      enabled
      displayName
      clientUri
    }
  }
`;
