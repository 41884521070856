import * as React from "react";

export const NumberInput = (props: {
  value?: number | null;
  onChange: (newNumber: number) => void;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
}) => (
  <input
    type="text"
    className="form-control"
    disabled={props.disabled}
    onChange={ev => props.onChange(parseInt(ev.target.value, 10))}
    placeholder={props.placeholder}
    value={props.value || ""}
  />
);
