import * as React from "react";
import { Query } from "react-apollo";
import permGql from "graphql-tag";
import { ResourceTypes, ResourceTypes_domain_resourceTypes } from "../permissons-gql";
import Select from "react-select";
import { PermissionClientConsumer } from "../App/ClientProvider";
import { TypedQuery } from "../types";

const fetchResourceTypesGql = permGql`
  query ResourceTypes($domainId: String!) {
    domain(id: $domainId) {
      globalId
      id
      resourceTypes {
        globalId
        id
        displayName
      }
    }
  }
`;

export type ResourceType = ResourceTypes_domain_resourceTypes;

const QueryResourceTypes: TypedQuery<ResourceTypes> = Query;

export const SelectResourceType = (props: {
  domainId: string;
  selectedResourceTypeId?: string | null;
  onSelect: (selectedResourceType: ResourceType | null) => void;
  disabled?: boolean;
}) => {
  return (
    <PermissionClientConsumer>
      {({ permissionClient }) => (
        <QueryResourceTypes
          query={fetchResourceTypesGql}
          client={permissionClient}
          variables={{ domainId: props.domainId }}
        >
          {result => (
            <Select
              noOptionsMessage={() => "No roles"}
              options={result.data && !result.loading ? result.data.domain!.resourceTypes : []}
              getOptionValue={(o: any) => o.id}
              getOptionLabel={(o: any) => o.displayName || o.id}
              isLoading={result.loading}
              isDisabled={result.loading || props.disabled}
              value={
                result.data && !result.loading && result.data.domain && props.selectedResourceTypeId
                  ? result.data!.domain!.resourceTypes.filter(
                      x => x.id === props.selectedResourceTypeId
                    )[0]
                  : null
              }
              onChange={(option: any) => props.onSelect(option)}
            />
          )}
        </QueryResourceTypes>
      )}
    </PermissionClientConsumer>
  );
};
