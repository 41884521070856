import * as React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { AddClientForm } from "./AddClientForm";
import { ClientType } from "./types";
import gql from "graphql-tag";
import { Spinner } from "../Common/Spinner";
import { useMutation } from "react-apollo";
import { isClientValid } from "./validation";
import {
  CreateClientInput,
  AddClient,
  AddClientVariables,
  Loa,
} from '../admin-gql';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

interface AddClientModalProps {
  onClose: (newClientId?: string) => void;
  show?: boolean;
}

interface AddClientModalState {
  client: CreateClientInput;
  forceShowValidationErrors?: boolean;
  clientType?: ClientType;
}

const grantMap: { [P in ClientType]: string } = {
  "oidc-client-cred": "client_credentials",
  "oidc-auth-code": "authorization_code",
  "oidc-auth-code-classic": "authorization_code",
  "oidc-keycloak": "authorization_code",
};

const initState: AddClientModalState = {
  client: {
    clientId: "",
    clientUri: "",
    description: "",
    displayName: "",
    contact: "",
    oidcSettings: {
      redirectUris: [],
      postLogoutRedirectUris: [],
      allowedCorsOrigins: [],
    },
    requireConsent: false,
    allowedScopes: [],
    loaRequirement: Loa.TWO,
    disableBankIdLogin: true,
    disableGoogleLogin: true,
    disableMicrosoftLogin: true,
    disableFrejaLogin: true,
    disableIdportenLogin: true,
    useTfNumberAsNationalIdFallback: true,
  },
  clientType: undefined,
  forceShowValidationErrors: false,
};

const addClientGql = gql`
  mutation AddClient($client: CreateClientInput!) {
    createClient(input: $client) {
      id
      oidcSettings {
        clientSecrets {
          value
        }
      }
    }
  }
`;

export const AddClientModal = (props: AddClientModalProps) => {
  return (
    <Modal onClosed={props.onClose} isOpen={props.show} size="lg">
      <Content {...props} />
    </Modal>
  );
};

const Content = (props: AddClientModalProps) => {
  const [state, setState] = React.useState<AddClientModalState>(initState);

  const [addClient, addClientMutation] = useMutation<
    AddClient,
    AddClientVariables
  >(addClientGql, {
    variables: {
      client: {
        ...state.client,
        oidcSettings: {
          ...state.client.oidcSettings,
          allowedGrantTypes: state.clientType
            ? [grantMap[state.clientType]]
            : [],
          clientSecrets:
            state.clientType === ClientType.PublicClient
              ? []
              : state.client.oidcSettings?.clientSecrets,
        },
      },
    },
    onCompleted: (data) => {
      if (
        data.createClient &&
        data.createClient.oidcSettings.clientSecrets.length === 0
      ) {
        props.onClose(data.createClient.id);
      }
    },
  });

  const handleCreateClicked = () => {
    if (!state.clientType || !isClientValid(state.client, state.clientType)) {
      setState((p) => ({ ...p, forceShowValidationErrors: true }));
      return;
    }

    addClient();
  };
  return (
    <>
      <ModalHeader>{i18next.t('new.client.modal.title')}</ModalHeader>
      {addClientMutation.data && addClientMutation.data.createClient ? (
        <>
          <ModalBody>
            <div className="form-group">
              <label>Klientid</label>
              <input
                className="form-control"
                value={addClientMutation.data.createClient.id}
                readOnly
              />
            </div>
            <div className="form-group">
              <label>Hemlighet</label>
              <input
                className="form-control"
                value={
                  addClientMutation.data.createClient.oidcSettings.clientSecrets
                    .length > 0
                    ? addClientMutation.data.createClient.oidcSettings
                        .clientSecrets[0].value!
                    : ""
                }
                readOnly
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <button
              className="btn btn-secondary"
              onClick={() =>
                props.onClose(addClientMutation.data!.createClient!.id)
              }
            >
              Stäng
            </button>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalBody>
            <AddClientForm
              onClientUpdate={(x) =>
                setState((p) => ({ ...p, client: { ...p.client, ...x } }))
              }
              client={state.client}
              loading={addClientMutation.loading}
              forceShowValidationErrors={state.forceShowValidationErrors}
              onClientTypeChange={(newType) =>
                setState((p) => ({ ...p, clientType: newType }))
              }
              clientType={state.clientType}
            />

            {addClientMutation.error ? (
              <div className="alert alert-danger">
                Tyvärr uppstod ett fel när klienten skulle skapas
              </div>
            ) : null}
          </ModalBody>

          <ModalFooter>
            <button
              className="btn btn-secondary"
              onClick={() => props.onClose()}
            >
              {i18next.t('new.client.modal.cancel')}
            </button>

            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={
                state.forceShowValidationErrors &&
                !!state.clientType &&
                !isClientValid(state.client, state.clientType)
              }
            >
              {addClientMutation.loading ? (
                <Spinner />
              ) : (
                <Trans i18nKey="new.client.modal.confirm" />
              )}
            </button>
          </ModalFooter>
        </>
      )}
    </>
  );
};
