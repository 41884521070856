import * as React from "react";
import { SamlClientMappingRuleType } from "../admin-gql";
import Select from "react-select";

export const SelectSamlMappingType = (props: {
  mappingType: SamlClientMappingRuleType;
  onChange: (newMappingType: SamlClientMappingRuleType) => void;
  disabled?: boolean;
}) => {
  return (
    <Select
      options={[
        SamlClientMappingRuleType.RENAME,
        SamlClientMappingRuleType.CLEAR,
        SamlClientMappingRuleType.CONSTANT,
        SamlClientMappingRuleType.COPY
      ].map(x => ({
        label: x,
        value: x
      }))}
      value={{ value: props.mappingType, label: props.mappingType }}
      isDisabled={props.disabled}
      onChange={(option: any) => props.onChange(option.value)}
    />
  );
};
