import { UrisControl } from "../../Common/UrisControl";
import * as React from "react";
import { Input } from "@ist-group-private-scope/skolid-client-components";
import { FormClient } from "../types";
import { SelectGrantTypes } from "../../Common/SelectGrantTypes";
import { NumberInput } from "../../Common/NumberInput";
import Select from "react-select";
import { AccessTokenType, TokenExpiration, TokenUsage } from "../../admin-gql";
import { Checkbox } from "../../Common/Checkbox";
import { ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";

export type OidcSettingsType = FormClient["oidcSettings"];

export const OidcBaseForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const oidcSettings = client.oidcSettings;
  const updateClient = props.updateClient;
  const updateOidc = (partial: Partial<OidcSettingsType>) =>
    updateClient({ oidcSettings: { ...client.oidcSettings, ...partial } });

  return (
    <div className="box">
      <div className="box-header">Base details</div>
      <div className="box-body">
        <div className="form-group">
          <label>Allowed grant types</label>
          <SelectGrantTypes
            disabled={props.disabled}
            selectedGrantTypes={oidcSettings.allowedGrantTypes}
            onChange={selectedGrantTypes => updateOidc({ allowedGrantTypes: selectedGrantTypes })}
          />
        </div>
      </div>
    </div>
  );
};

export const OidcClientSecretsForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const oidcSettings = client.oidcSettings;
  const updateClient = props.updateClient;
  const updateOidc = (partial: Partial<OidcSettingsType>) =>
    updateClient({ oidcSettings: { ...client.oidcSettings, ...partial } });

  return (
    <div className="box">
      <div className="box-header">Client Secrets</div>
      <div className="box-body">
        <div className="form-group">
          <ClientSecretsControl
            secrets={oidcSettings.clientSecrets}
            updateSecrets={newSecrets => updateOidc({ clientSecrets: newSecrets })}
          />
        </div>
      </div>
    </div>
  );
};

export const OidcAccessTokenForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const oidc = client.oidcSettings;
  const updateClient = props.updateClient;
  const updateOidc = (partial: Partial<OidcSettingsType>) =>
    updateClient({ oidcSettings: { ...client.oidcSettings, ...partial } });
  const disabled = props.disabled;

  return (
    <div className="box">
      <div className="box-header">Access token</div>
      <div className="box-body">
        <div className="form-group">
          <Checkbox
            checked={oidc.allowAccessTokensViaBrowser}
            disabled={disabled}
            onChange={checked => updateOidc({ allowAccessTokensViaBrowser: checked })}
            label="Allow access tokens via browser"
          />
        </div>

        <div className="form-group">
          <label>Access token lifetime (seconds)</label>
          <NumberInput
            value={props.client.oidcSettings.accessTokenLifetime}
            disabled={disabled}
            required
            onChange={newNumber => updateOidc({ accessTokenLifetime: newNumber })}
            placeholder="Access token lifetime"
          />
        </div>

        <div className="form-group">
          <label>Access token type</label>
          <Select
            options={[AccessTokenType.JWT, AccessTokenType.REFERENCE].map(x => ({
              label: x,
              value: x
            }))}
            value={{ value: client.oidcSettings.accessTokenType, label: client.oidcSettings.accessTokenType }}
            isDisabled={props.disabled}
            onChange={(option: any) => updateOidc({ accessTokenType: option.value })}
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={oidc.updateAccessTokenClaimsOnRefresh}
            disabled={disabled}
            onChange={checked => updateOidc({ updateAccessTokenClaimsOnRefresh: checked })}
            label="Update access token claims on refresh"
          />
        </div>
      </div>
    </div>
  );
};

export const OidcRefreshTokenForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const oidc = client.oidcSettings;
  const updateClient = props.updateClient;
  const updateOidc = (partial: Partial<OidcSettingsType>) =>
    updateClient({ oidcSettings: { ...client.oidcSettings, ...partial } });
  const disabled = props.disabled;
  const refreshTokenDisabled = disabled || !oidc.allowOfflineAccess;

  return (
    <div className="box">
      <div className="box-header">Refresh token</div>
      <div className="box-body">
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={oidc.allowOfflineAccess}
              onChange={() => updateOidc({ allowOfflineAccess: !oidc.allowOfflineAccess })}
              id="offlineAccessCheckbox"
              disabled={props.disabled}
            />
            <label className="form-check-label" htmlFor="offlineAccessCheckbox">
              Enable (Allow offline access)
            </label>
          </div>
        </div>

        <div className="form-group">
          <label>Absolute refresh token lifetime (seconds)</label>
          <NumberInput
            value={oidc.absoluteRefreshTokenLifetime}
            disabled={refreshTokenDisabled}
            required
            onChange={newNumber => updateOidc({ absoluteRefreshTokenLifetime: newNumber })}
            placeholder="Absolute refresh token lifetime"
          />
        </div>

        <div className="form-group">
          <label>Refresh token expiration</label>
          <Select
            options={[TokenExpiration.ABSOLUTE, TokenExpiration.SLIDING].map(x => ({
              label: x,
              value: x
            }))}
            value={{ value: oidc.refreshTokenExpiration, label: oidc.refreshTokenExpiration }}
            isDisabled={refreshTokenDisabled}
            onChange={(option: any) => updateOidc({ refreshTokenExpiration: option.value })}
          />
        </div>

        <div className="form-group">
          <label>Refresh token usage</label>
          <Select
            options={[TokenUsage.ONE_TIME_ONLY, TokenUsage.RE_USE].map(x => ({
              label: x,
              value: x
            }))}
            value={{ value: oidc.refreshTokenUsage, label: oidc.refreshTokenUsage }}
            isDisabled={refreshTokenDisabled}
            onChange={(option: any) => updateOidc({ refreshTokenUsage: option.value })}
          />
        </div>

        <div className="form-group">
          <label>Sliding refresh token lifetime (seconds)</label>
          <NumberInput
            value={oidc.slidingRefreshTokenLifetime}
            disabled={refreshTokenDisabled}
            required
            onChange={newNumber => updateOidc({ slidingRefreshTokenLifetime: newNumber })}
            placeholder="Sliding refresh token lifetime"
          />
        </div>
      </div>
    </div>
  );
};

export const OidcOtherForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const oidc = client.oidcSettings!;
  const updateClient = props.updateClient;
  const updateOidc = (partial: Partial<OidcSettingsType>) => updateClient({ oidcSettings: { ...oidc, ...partial } });
  const disabled = props.disabled;

  return (
    <div className="box">
      <div className="box-header">Other settings</div>
      <div className="box-body">
        <div className="form-group">
          <label>Authoriztion code lifetime (seconds)</label>
          <NumberInput
            value={oidc.authorizationCodeLifetime}
            disabled={disabled}
            required
            onChange={newNumber => updateOidc({ authorizationCodeLifetime: newNumber })}
            placeholder="Authorization code lifetime"
          />
        </div>

        <div className="form-group">
          <label>Identity token lifetime (seconds)</label>
          <NumberInput
            value={oidc.identityTokenLifetime}
            disabled={disabled}
            required
            onChange={newNumber => updateOidc({ identityTokenLifetime: newNumber })}
            placeholder="Identity token lifetime"
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={oidc.alwaysIncludeUserClaimsInIdToken}
            disabled={disabled}
            onChange={checked => updateOidc({ alwaysIncludeUserClaimsInIdToken: checked })}
            label="Always include user claims in id token"
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={oidc.allowPlainTextPkce}
            disabled={disabled}
            onChange={checked => updateOidc({ allowPlainTextPkce: checked })}
            label="Allow plain text pkce"
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={oidc.frontChannelLogoutSessionRequired}
            disabled={disabled}
            onChange={checked => updateOidc({ frontChannelLogoutSessionRequired: checked })}
            label="Front channel logout session required"
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={oidc.backChannelLogoutSessionRequired}
            disabled={disabled}
            onChange={checked => updateOidc({ backChannelLogoutSessionRequired: checked })}
            label="Back channel logout session required"
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={oidc.requireClientSecret}
            disabled={disabled}
            onChange={checked => updateOidc({ requireClientSecret: checked })}
            label="Require client secret"
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={oidc.requirePkce}
            disabled={disabled}
            onChange={checked => updateOidc({ requirePkce: checked })}
            label="Require pkce"
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={oidc.autoRedirectAfterLogout}
            disabled={disabled}
            onChange={checked => updateOidc({ autoRedirectAfterLogout: checked })}
            label="Auto redirect after logout"
          />
        </div>

        <div className="form-group">
          <Checkbox
            checked={oidc.includeNotBeforeClaim}
            disabled={disabled}
            onChange={checked => updateOidc({ includeNotBeforeClaim: checked })}
            label="Include not before claim (NBF)"
          />
        </div>

        <div className="form-group">
          <label>Pair wise subject salt</label>
          <Input
            value={oidc.pairWiseSubjectSalt}
            disabled={disabled}
            onChange={value => updateOidc({ pairWiseSubjectSalt: value })}
            placeholder="Pair wise subject salt"
          />
        </div>
      </div>
    </div>
  );
};

export const OidcUrisForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const oidc = client.oidcSettings!;
  const updateClient = props.updateClient;
  const updateOidc = (partial: Partial<OidcSettingsType>) => updateClient({ oidcSettings: { ...oidc, ...partial } });

  return (
    <div className="box">
      <div className="box-header">URI:s</div>
      <div className="box-body">
        <div className="form-group">
          <Input
            value={oidc.frontChannelLogoutUri || ""}
            label="Front channel logout URI"
            onChange={uri => updateOidc({ frontChannelLogoutUri: uri })}
            disabled={props.disabled}
          />
        </div>

        <div className="form-group">
          <Input
            value={oidc.backChannelLogoutUri || ""}
            label="Back channel logout URI"
            onChange={uri => updateOidc({ backChannelLogoutUri: uri })}
            disabled={props.disabled}
          />
        </div>

        <h3 className="my-3">Redirect URI:s</h3>
        <UrisControl
          disabled={props.disabled}
          updateUris={newUris => updateOidc({ redirectUris: newUris })}
          uriName="Redirect URI"
          uris={oidc.redirectUris}
        />

        <h3 className="my-3">Post logout redirect URI:s</h3>
        <UrisControl
          disabled={props.disabled}
          updateUris={newUris => updateOidc({ postLogoutRedirectUris: newUris })}
          uriName="Post logout redirect URI"
          uris={oidc.postLogoutRedirectUris}
        />

        <h3 className="my-3">Cors Origins</h3>
        <UrisControl
          disabled={props.disabled}
          updateUris={newUris => updateOidc({ allowedCorsOrigins: newUris })}
          uriName="Cors origin"
          uris={oidc.allowedCorsOrigins}
        />
      </div>
    </div>
  );
};

type ClientSecret = FormClient["oidcSettings"]["clientSecrets"][0];

const ClientSecretsControl = (props: {
  secrets: ClientSecret[];
  disabled?: boolean;
  updateSecrets: (secrets: ClientSecret[]) => void;
}) => (
  <>
    {" "}
    {props.secrets.map((secret, index) => {
      const readonly = secret.id !== 0;
      return (
        <div className="form-group" key={index}>
          <Input
            placeholder="Secret"
            value={readonly ? "*********" : secret.value}
            readonly={readonly}
            onChange={text =>
              props.updateSecrets(props.secrets.map((x, i) => (i === index ? { ...x, value: text } : x)))
            }
            disabled={props.disabled}
          >
            <ApolloConsumer>
              {apolloClient => (
                <>
                  {!readonly ? (
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={async () => {
                        const response = await apolloClient.query<{ randomString: string }>({
                          query: randomStringGql,
                          fetchPolicy: "network-only"
                        });
                        props.updateSecrets(
                          props.secrets.map((x, i) => (i === index ? { ...x, value: response.data.randomString } : x))
                        );
                      }}
                      disabled={props.disabled}
                    >
                      <i className="fa fa-random" />
                    </button>
                  ) : null}

                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={() => props.updateSecrets(props.secrets.filter((x, i) => i !== index))}
                    disabled={props.disabled}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </>
              )}
            </ApolloConsumer>
          </Input>
        </div>
      );
    })}
    <button
      type="button"
      className="btn btn-link p-0"
      onClick={() => props.updateSecrets(props.secrets.concat([{ id: 0, value: "" }]))}
      disabled={(props.secrets.length > 0 && props.secrets[props.secrets.length - 1].value === "") || props.disabled}
    >
      Add secret
    </button>
  </>
);

const randomStringGql = gql`
  query RandomString {
    randomString
  }
`;
