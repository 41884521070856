import { ApolloClient } from "apollo-client";
import * as React from "react";
import * as oidc from "oidc-client";
import { ApolloLink, concat } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
// tslint:disable-next-line:no-submodule-imports
import { DefaultOptions } from "apollo-client/ApolloClient";
// tslint:disable-next-line:no-submodule-imports
import { defaultDataIdFromObject } from "apollo-cache-inmemory/lib/inMemoryCache";
import { environmentSettings, Environment } from "@ist-group-private-scope/web-skolid";
import admin2FragmentTypes from "../graphql-schemas/admin-schema-2-fragments-types.json";

export interface ApiProviderProps {
  environment: Environment;
  user?: oidc.User | null;
  onNotAuthorized?: () => void;
  onNeedsReauthentication?: () => void;
  onNotAuthenticated?: () => void;
  children: (apis: Apis) => React.ReactNode;
  defaultOptions?: DefaultOptions;
}

export interface ApiProviderState {
  adminClient: ApolloClient<any>;
  admin2Client: ApolloClient<any>;
  permissionClient: ApolloClient<any>;
}

export type Apis = ApiProviderState;

// The environment property may not change after initial mount
export class ApiProvider extends React.Component<ApiProviderProps, ApiProviderState> {
  constructor(props: ApiProviderProps) {
    super(props);

    const authLink = new ApolloLink((operation, next) => {
      operation.setContext((context: any) => {
        const user = this.props.user;
        return {
          headers: {
            ...context.headers,
            authorization: user ? "Bearer " + user.access_token : null
          }
        };
      });

      return next!(operation);
    });

    const logoutLink = onError(error => {
      console.log(error);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((gqlError: any) => {
          if (gqlError.code) {
            switch (gqlError.code) {
              case "not_authorized":
                if (this.props.onNotAuthorized) {
                  this.props.onNotAuthorized();
                }
                break;

              case "not_authenticated":
                if (this.props.onNotAuthenticated) {
                  this.props.onNotAuthenticated();
                }
                break;

              case "needs_reauthentication":
                if (this.props.onNeedsReauthentication) {
                  this.props.onNeedsReauthentication();
                }
                break;
            }
          }
        });
      }
    });

    const permissionClient = new ApolloClient({
      link: logoutLink.concat(
        concat(
          authLink,
          new HttpLink({
            uri: environmentSettings[this.props.environment].apiUrl + "/graphql-permission"
          })
        )
      ),
      cache: new InMemoryCache({
        dataIdFromObject: (object: any) => {
          if (object.globalId) {
            return object.globalId;
          } else {
            if (permissionEntitiesWithGlobalId.indexOf(object.__typename) !== -1) {
              console.warn("Missing global id for entity: ", object);
            }

            return defaultDataIdFromObject({ ...object, id: undefined });
          }
        }
      }),
      defaultOptions: props.defaultOptions
    });

    const adminClient = new ApolloClient({
      link: logoutLink.concat(
        concat(
          authLink,
          new HttpLink({
            uri: environmentSettings[this.props.environment].apiUrl + "/graphql-admin"
          })
        )
      ),
      cache: new InMemoryCache(),
      defaultOptions: props.defaultOptions
    });

    const admin2FragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: admin2FragmentTypes
    });
    const admin2Client = new ApolloClient({
      link: logoutLink.concat(
        concat(
          authLink,
          new HttpLink({
            uri: environmentSettings[this.props.environment].apiUrl + "/admin"
          })
        )
      ),
      cache: new InMemoryCache({ fragmentMatcher: admin2FragmentMatcher }),
      defaultOptions: props.defaultOptions
    });

    this.state = { adminClient, permissionClient, admin2Client };
  }

  public render() {
    return this.props.children(this.state);
  }
}

const permissionEntitiesWithGlobalId = ["Subject", "Role", "Resource", "ResourceType", "RoleAssignment"];
