import {
  ClientFragment,
  CreateClientInput,
  UpdateClientInput,
  UpdateClientOidcSettingsInput,
  CreateClientOidcSettingsInput,
  UpdateClientSamlSettingsInput
} from "../admin-gql";

export type RequiredDeep<T> = {
  [P in keyof T]-?: RequiredDeep<Exclude<T[P], undefined>>;
};

export type RequiredNonNull<T> = {
  [P in keyof T]-?: Exclude<T[P], null | undefined>;
};

export type PickNonNullable<T, K extends keyof T> = {
  [P in K]-?: Exclude<T[P], null | undefined>;
};

export type FormOidcSettings = RequiredDeep<UpdateClientOidcSettingsInput> & {
  backChannelLogoutSessionRequired: boolean;
  allowPlainTextPkce: boolean;
  frontChannelLogoutSessionRequired: boolean;
  alwaysIncludeUserClaimsInIdToken: boolean;
  requireClientSecret: boolean;
  requirePkce: boolean;
  autoRedirectAfterLogout: boolean;
  includeNotBeforeClaim: boolean;
  clientSecrets: NonNullable<UpdateClientOidcSettingsInput["clientSecrets"]>[0];
  allowOfflineAccess: boolean;
  updateAccessTokenClaimsOnRefresh: boolean;
  allowAccessTokensViaBrowser: boolean;
  allowedGrantTypes: NonNullable<UpdateClientOidcSettingsInput["allowedGrantTypes"]>;
};

export type FormSamlSettings = RequiredDeep<Omit<UpdateClientSamlSettingsInput, "emailAddressNameIdDomain">> &
  PickNonNullable<
    UpdateClientSamlSettingsInput,
    | "certificates"
    | "nameIdFormat"
    | "singleLogoutServiceBinding"
    | "signSamlResponse"
    | "assertionConsumerServiceBinding"
    | "signAssertion"
    | "signatureAlgorithm"
    | "digestAlgorithm"
    | "mappingRules"
    | "wantAuthnRequestSigned"
  >;

export type FormClient = RequiredDeep<Omit<UpdateClientInput, "oidcSettings" | "samlSettings">> & {
  oidcSettings: FormOidcSettings;
  samlSettings: FormSamlSettings;
  allowedIdps: string[];
};

export enum ClientType {
  Keycloak = "oidc-keycloak",
  PublicClient = "oidc-auth-code",
  ConfidentialClient = "oidc-auth-code-classic",
  ClientCred = "oidc-client-cred"
}
