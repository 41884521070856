import { generatePath as genereatePathRRD } from "react-router-dom";

export const clientsPath = "/clients";
export const usersPath = "/users";

export const generatePath = (route: string, params: any) => genereatePathRRD(route, params);

export const clientRoute = "/client/:id";
export interface ClientRouteParams {
  id: string;
}
export const generateClientPath = (params: ClientRouteParams) => generatePath(clientRoute, params);

export const clientOidcRoute = `${clientRoute}/oidc`;
export const clientSamlRoute = `${clientRoute}/saml`;
export const clientOtherRoute = `${clientRoute}/other`;
export const clientPropsRoute = `${clientRoute}/props`;
export const newClientPath = "/client/-1";

export const importClientRoute = "/import-client";

export const scopesPath = "/scopes";
export const apiResourceRoute = "/api-resource/:name";
export interface ResourceRouteParams {
  name: string;
}
export const generateApiResourcePath = (params: ResourceRouteParams) =>
  generatePath(apiResourceRoute, params);

export const identityResourceRoute = "/identity-resource/:name";
export const generateIdentityResourcePath = (params: ResourceRouteParams) =>
  generatePath(identityResourceRoute, params);

export const newApiResourcePath = generateApiResourcePath({ name: "-1" });
export const newIdentityResourcePath = generateIdentityResourcePath({ name: "-1" });

// User
export const userRoute = "/user/:id";
export interface UserRouteParams {
  id: string;
}
export const generateUserPath = (params: UserRouteParams) => generatePath(userRoute, params);

export const userPermissionRoute = `${userRoute}/permissions`;
export const generateUserPermissionsPath = (params: UserRouteParams) =>
  generatePath(userPermissionRoute, params);
export const userAuditRoute = `${userRoute}/audit`;
export const generateUserAuditPath = (params: UserRouteParams) =>
  generatePath(userAuditRoute, params);

// Organization
export const organizationsPath = "/organizations";
export const organizationRoute = "/organization/:id";
export interface OrganizationRouteParams {
  id: string;
}
export const generateOrganizationPath = (params: OrganizationRouteParams) =>
  generatePath(organizationRoute, params);

export const newOrganizationPath = generateOrganizationPath({ id: "-1" });

// Idp
export const idpsPath = "/idps";
export const idpRoute = "/idps/:id";
export interface IdpRouteParams {
  id: string;
}
export const generateIdpPath = (params: IdpRouteParams) => generatePath(idpRoute, params);

// Permissions
export const permDomains = "/perm-domains";
export const permDomainResourcesRoute = "/perm-domain/:domainId/resources";
export const permDomainSettingsRoute = "/perm-domain/:domainId/settings";
export const permDomainRoute = "/perm-domain/:domainId";
export const permDomainResourceRoute =
  "/perm-domain/:domainId/resource/:resourceTypeId/:resourceId";

export interface PermDomainRouteParams {
  domainId: string;
}

export interface PermDomainResourceRouteParams {
  domainId: string;
  resourceTypeId: string;
  resourceId: string;
}

export const generatePermDomainPath = (params: PermDomainRouteParams) =>
  generatePath(permDomainRoute, params);

export const generatePermDomainSettingsPath = (params: PermDomainRouteParams) =>
  generatePath(permDomainSettingsRoute, params);

export const generatePermDomainResourcesPath = (params: PermDomainRouteParams) =>
  generatePath(permDomainResourcesRoute, params);

export const generatePermDomainResourcePath = (params: PermDomainResourceRouteParams) =>
  generatePath(permDomainResourceRoute, params);
