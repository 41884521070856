import React from "react";
import { Spinner } from "./Spinner";

export const Button = (props: {
  children: React.ReactNode;
  loading?: boolean;
  onClick: () => void;
  disabled?: boolean;
}) => (
  <div className="text-right">
    <button className="btn btn-primary" onClick={props.onClick} disabled={props.disabled}>
      {props.loading ? <Spinner light /> : props.children}
    </button>
  </div>
);
