import { FormClient, ClientType } from "./types";
import * as _ from "lodash";
import { CreateClientInput } from "../admin-gql";
import i18next from 'i18next';

export const validators: { [key: string]: (client: FormClient | CreateClientInput) => undefined | string } = {

  displayName: client => (client.displayName ? undefined : i18next.t('new.client.modal.nameIsMandatory')),
  description: client => (client.description ? undefined : i18next.t('new.client.modal.descriptionIsMandatory')),
  contact: client => (client.contact ? undefined : i18next.t('new.client.modal.contactIsMandatory')),
  uri: client => (client.clientUri ? undefined : i18next.t('new.client.modal.uriIsMandatory'))
};

const validatorsByType = {
  [ClientType.PublicClient]: validators,
  [ClientType.ConfidentialClient]: validators,
  [ClientType.Keycloak]: validators,
  [ClientType.ClientCred]: _.omit(validators, ["uri"])
};

export function isClientValid(client: FormClient | CreateClientInput, clientType: ClientType) {
  return Object.keys(validatorsByType[clientType]).every(key => !validatorsByType[clientType][key](client));
}
