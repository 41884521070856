import * as React from "react";
import { PrimitiveField, FormFields } from "@ist-group/react-form-hook";
import {
  Input,
  PhoneNumberControl,
  EmailControl,
  DropDownButton,
  validateNationalId,
  DateInput,
} from "@ist-group-private-scope/skolid-client-components";
import { BasicFormUser } from "../types";
import { CountryCode, PhoneNumberUse, EmailUse } from "../../admin-gql";
import { generateUserPath } from "../../routes";
import { useApolloClient } from "react-apollo";
import { Trans } from 'react-i18next';
import i18next from 'i18next';

export const BasicUserForm = ({
  disabled,
  readonly,
  ...props
}: {
  formFields: FormFields<BasicFormUser, React.ReactNode>;
  readonly?: boolean;
  disabled?: boolean;
  organization: { id: string; country: CountryCode };
}) => {
  const client = useApolloClient();

  const formUser = props.formFields;
  return (
    <>
      <div className="form-row">
        <div className="form-group col-md">
          <NationalIdInput
            value={formUser.nationalId.value}
            readonly={readonly}
            country={props.organization.country}
            disabled={disabled}
            onChange={(val) => {
              formUser.nationalId.set(val);

              if (validateNationalId(val, props.organization.country)) {
                // If national id has valid form we can touch it to allow any other validation errors to show (ie. already taken)
                formUser.nationalId.touch();
              }
            }}
            touched={formUser.nationalId.touched}
            loading={formUser.nationalId.validating}
            error={formUser.nationalId.error}
            onBlur={formUser.nationalId.touch}
            forceShowValidationError={formUser.nationalId.touched}
          />
        </div>

        {!readonly || formUser.tfNumber.value ? (
          <div className="form-group col-md">
            <Input
              label={i18next.t('user.form.temporarySocialSecurityNumber')}
              placeholder={i18next.t('user.form.temporarySocialSecurityNumber')}
              value={formUser.tfNumber.value}
              readonly={readonly}
              disabled={disabled}
              onChange={formUser.tfNumber.set}
            ></Input>
          </div>
        ) : null}
      </div>

      {!readonly || formUser.username.value ? (
        <div className="form-group">
          <Input
            label={i18next.t('user.form.userName')}
            placeholder={i18next.t('user.form.userName')}
            value={formUser.username.value}
            readonly={readonly}
            disabled={disabled}
            onChange={formUser.username.set}
          ></Input>
        </div>
      ) : null}

      <div className="form-row">
        <div className="form-group col-md-6">
          <FieldInput
            field={formUser.firstName}
            label={i18next.t('user.form.firstName')}
            readonly={readonly}
          />
        </div>
        <div className="form-group col-md-6">
          <FieldInput
            field={formUser.lastName}
            label={i18next.t('user.form.surname')}
            readonly={readonly}
          />
        </div>
      </div>

      {formUser.expiresAt.value ? (
        <div className="form-group">
          <DateInput
            readonly
            disabled
            date={formUser.expiresAt.value}
            label="Expires at"
          />
          {/* See SKOLID-618 for more details about expanded support for expiration */}
          <div className="small">
            Currently only set upon user import and only used to clean-up the
            account if it is untouched for a period after expiration.
          </div>
        </div>
      ) : null}

      {formUser.phoneNumbers.items.length > 0 ? (
        <h4>
          <Trans i18nKey="user.form.phoneNumber" />
        </h4>
      ) : null}
      {formUser.phoneNumbers.items.map((num, index) => (
        <PhoneNumberControl
          key={index}
          phoneNumber={num.value}
          onChange={(newPhone) => num.set(newPhone)}
          readonly={readonly}
          disabled={disabled}
          onRemove={() => formUser.phoneNumbers.remove(index)}
          forceShowValidationErrors={num.touched}
          client={client}
        />
      ))}

      {!readonly ? (
        <div className="form-group">
          <button
            className="btn btn-link p-0"
            onClick={() =>
              formUser.phoneNumbers.push({
                id: null,
                value: "",
                canBeUsedForSmsCodes: true,
                use: PhoneNumberUse.PRIVATE,
              })
            }
          >
            {i18next.t('user.form.addPhoneNumber')}
          </button>
        </div>
      ) : null}

      {formUser.emails.items.length > 0 ? (
        <h4>
          <Trans i18nKey="user.form.emailAddresses" />
        </h4>
      ) : null}
      {formUser.emails.items.map((email, index) => (
        <EmailControl
          key={index}
          organizationId={props.organization.id}
          client={client}
          email={email.value}
          onChange={email.set}
          onGenerateLinkToUser={(id) => generateUserPath({ id })}
          onRemove={() => formUser.emails.remove(index)}
          userId={formUser.id && formUser.id.value}
          disabled={disabled}
          readonly={readonly}
        />
      ))}
      {!readonly ? (
        <div className="form-group">
          <button
            className="btn btn-link p-0"
            onClick={() =>
              formUser.emails.push({
                value: "",
                use: EmailUse.PRIVATE,
                confirmed: true,
                new: true,
              })
            }
          >
            {i18next.t('user.form.addEmailAddresses')}
          </button>
        </div>
      ) : null}
    </>
  );
};

const NationalIdInput = ({
  value,
  readonly,
  country,
  disabled,
  onChange,
  onBlur,
  loading,
  error,
  forceShowValidationError,
}: {
  value: string;
  country: CountryCode;
  loading?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  existingUserId?: string;
  error: any;
  forceShowValidationError?: boolean;
  touched?: boolean;
  onChange: (val: string) => void;
  onBlur: () => void;
}) => {
  return (
    <Input
      placeholder={i18next.t('user.form.socialSecurityNumber')}
      value={value}
      readonly={readonly}
      label={i18next.t('user.form.socialSecurityNumber')}
      disabled={disabled}
      onChange={onChange}
      loading={loading}
      onBlur={onBlur}
      validationErrorMessage={error}
      forceShowValidationError={forceShowValidationError}
    >
      <div className="input-group-text">{country}</div>
    </Input>
  );
};

const FieldInput = ({
  field,
  readonly,
  label,
}: {
  field: PrimitiveField<string, React.ReactNode>;
  readonly?: boolean;
  label?: string;
}) => (
  <Input
    value={field.value}
    disabled={field.disabled}
    readonly={readonly}
    forceShowValidationError={field.touched}
    label={label}
    loading={field.validating}
    onChange={field.set}
    validationErrorMessage={field.error}
    onBlur={field.touch}
  ></Input>
);
