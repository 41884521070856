import * as React from "react";
import { ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";
import { ImportClients_importClients } from "../admin-gql";
import { Spinner } from "../Common/Spinner";
import { AsyncOperation } from "../types";
import { ApolloClient } from "apollo-client";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

interface ClientImportModalState {
  importOperation: AsyncOperation<ImportClients_importClients>;
  importClientsText: string;
}

export class ClientImportModal extends React.Component<
  { show?: boolean; onClose: () => void },
  ClientImportModalState
> {
  public state: ClientImportModalState = {
    importOperation: {},
    importClientsText: ""
  };

  public render() {
    const importNotStarted = Object.keys(this.state.importOperation).length === 0;

    return (
      <div>
        <Modal size="lg" isOpen={this.props.show}>
          <ModalHeader>Client import</ModalHeader>
          <ModalBody>
            {importNotStarted ? (
              <textarea
                rows={10}
                disabled={this.state.importOperation.running}
                className="form-control"
                value={this.state.importClientsText}
                onChange={ev => this.setState({ importClientsText: ev.target.value })}
              />
            ) : null}

            {this.state.importOperation.data ? (
              <>
                <h3>Imported clients</h3>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>New secret</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.importOperation.data!.importedClients.map(client => (
                      <tr key={client.id}>
                        <td>{client.id}</td>
                        <td>{client.oidcSettings.clientSecrets[0].value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {this.state.importOperation.data.importedApiResources.length > 0 ? (
                  <>
                    <h3 className="mt-5">Imported api resources</h3>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.importOperation.data!.importedApiResources.map(res => (
                          <tr key={res.name}>
                            <td>{res.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : null}
              </>
            ) : null}

            {this.state.importOperation.error ? (
              <div className="alert alert-danger mt-3">{this.state.importOperation.error}</div>
            ) : null}

            {this.state.importOperation.running ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-between flex-fill">
              <button
                className="btn btn-primary"
                disabled={this.state.importOperation.running}
                onClick={() => {
                  this.setState({
                    importOperation: {},
                    importClientsText: ""
                  });
                  this.props.onClose();
                }}
              >
                Close
              </button>

              {!this.state.importOperation.data && !this.state.importOperation.error ? (
                <ApolloConsumer>
                  {apolloClient => (
                    <button
                      className="btn btn-primary"
                      disabled={this.state.importOperation.running}
                      onClick={() => this.importClients(apolloClient)}
                    >
                      Import
                    </button>
                  )}
                </ApolloConsumer>
              ) : null}
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  private importClients = async (client: ApolloClient<any>) => {
    this.setState({
      importOperation: { running: true }
    });

    try {
      const response: any = await client.mutate({
        mutation: importClientsGql,
        variables: {
          data: this.state.importClientsText,
          options: { generateNewSecrets: true }
        }
      });

      this.setState({ importOperation: { data: response.data.importClients! } });
    } catch (error) {
      const errorMessage =
        error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : "";
      this.setState({ importOperation: { error: "Failed to import clients: " + errorMessage } });
    }
  };
}

const importClientsGql = gql`
  mutation ImportClients($data: String!, $options: ImportClientsOptionsInput!) {
    importClients(data: $data, options: $options) {
      importedClients {
        id
        displayName
        oidcSettings {
          clientSecrets {
            value
          }
        }
      }
      importedApiResources {
        name
      }
    }
  }
`;
