import * as React from "react";
import { Query } from "react-apollo";
import permGql from "graphql-tag";
import {
  GetResource,
  GetResourceVariables,
  RoleAssignment
} from "../permissons-gql";
import { LoadingScreen } from "../App/LoadingScreen";
import { RouteComponentProps } from "react-router";
import { PermDomainResourceRouteParams } from "../routes";
import { AssignRoleModal } from "./AssignRoleModal";
import { roleAssignmentFragmentGql } from "./fragments";
import { PermissionsTable } from "./PermissionTable";
import { SaveResourceModal } from "./SaveResourceModal";
import { ConfirmModal } from "./DeleteModal";
import { UnassignRoleMutation, unassignRoleGql } from "./commonGql";
import { TypedQuery } from "../types";

const fetchResourceGql = permGql`
  query GetResource($domainId: String!, $resourceId: String!, $resourceTypeId: String!) {
    domain(id: $domainId) {
      globalId
      id
      displayName
      resource(id: $resourceId, typeId: $resourceTypeId) {
        globalId
        id
        displayName
        type {
          globalId
          id
          displayName
        }
        roleAssignments {
          nodes {
            ...RoleAssignment
          }
        }
      }
    }
  }

  ${roleAssignmentFragmentGql}
`;

const ResourceQuery: TypedQuery<GetResource, GetResourceVariables> = Query;

interface ResourceScreenState {
  showAssignRole?: boolean | null;
  showEditResource?: boolean | null;
  removeRoleAssignment?: RoleAssignment | null;
}

export class ResourceScreen extends React.Component<
  RouteComponentProps<PermDomainResourceRouteParams>,
  ResourceScreenState
> {
  public state: ResourceScreenState = {};

  public render() {
    const props = this.props;
    const domainId = decodeURIComponent(props.match.params.domainId);
    const resourceId = decodeURIComponent(props.match.params.resourceId);
    const resourceTypeId = decodeURIComponent(props.match.params.resourceTypeId);

    const resourceQueryProps = {
      query: fetchResourceGql,
      variables: { domainId, resourceId, resourceTypeId }
    };

    console.log('Resourceid', resourceId);

    return (
      <div className="flex-fill">
        <ResourceQuery {...resourceQueryProps}>
          {response => (
            <>
              {response.loading ? <LoadingScreen /> : null}
              {response.error ? <div>Something went wrong!?</div> : null}
              {!response.loading && response.data && !response.data.domain ? (
                <div>No such domain</div>
              ) : null}
              {!response.loading && response.data && !response.data.domain?.resource ? (
                <div>No such resource</div>
              ) : null}
              {!response.loading && response.data && response.data.domain?.resource ? (
                <>
                  <h1>Resource: {response.data.domain!.resource!.displayName}</h1>
                  <div className="box">
                    <div className="box-body">
                      <div className="text-right">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.setState({ showEditResource: true })}
                        >
                          Edit resource
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary"
                          onClick={() => this.setState({ showAssignRole: true })}
                        >
                          Assign role
                        </button>
                      </div>

                      <PermissionsTable
                        roleAssignments={response.data!.domain!.resource!.roleAssignments.nodes}
                        onUnassign={assignment => {
                          this.setState({ removeRoleAssignment: assignment });
                        }}
                      />
                    </div>

                    <AssignRoleModal
                      domainId={domainId}
                      isOpen={!!this.state.showAssignRole}
                      resource={{ id: resourceId, typeId: resourceTypeId }}
                      onClose={async (saved: boolean) => {
                        if (saved) {
                          await response.refetch();
                        }

                        this.setState({ showAssignRole: null });
                      }}
                    />

                    <SaveResourceModal
                      domainId={domainId}
                      isOpen={!!this.state.showEditResource}
                      onClose={() => this.setState({ showEditResource: false })}
                      initialResource={response.data!.domain!.resource}
                      refetchQueries={[
                        { query: fetchResourceGql, variables: this.props.match.params }
                      ]}
                    />

                    <UnassignRoleMutation mutation={unassignRoleGql}>
                      {(mutate, mutationResult) => (
                        <ConfirmModal
                          isOpen={!!this.state.removeRoleAssignment}
                          onClose={() => this.setState({ removeRoleAssignment: null })}
                          operation={{
                            running: mutationResult.loading,
                            error: mutationResult.error
                          }}
                          onConfirm={async () => {
                            await mutate({
                              variables: {
                                input: {
                                  ...this.props.match.params,
                                  roleId: this.state.removeRoleAssignment!.role.id,
                                  subjectId: this.state.removeRoleAssignment!.subject.id,
                                  subjectType: this.state.removeRoleAssignment!.subject.type
                                }
                              },
                              refetchQueries: [resourceQueryProps]
                            });
                            this.setState({ removeRoleAssignment: null })
                          }}
                        />
                      )}
                    </UnassignRoleMutation>
                  </div>
                </>
              ) : null}
            </>
          )}
        </ResourceQuery>
      </div>
    );
  }
}
