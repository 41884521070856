import gql from "graphql-tag";

export const apiResourceFragmentGql = gql`
  fragment ApiResourceFragment on ApiResource {
    name
    displayName
    description
    enabled
    claims
    scopes {
      name
      displayName
      description
      required
      emphasize
      showInDiscoveryDocument
      claims
    }
    secrets {
      id
    }
  }
`;

export const identityResourceFragmentGql = gql`
  fragment IdentityResourceFragment on IdentityResource {
    id:name
    name
    displayName
    description
    enabled
    claims
    required
    emphasize
    showInDiscoveryDocument
  }
`;
