import * as React from "react";
import { Query } from "react-apollo";
import permGql from "graphql-tag";
import { GetRoles_domain_roles, GetRoles } from "../permissons-gql";
import Select from "react-select";
import { TypedQuery } from "../types";

const fetchRolesGql = permGql`
  query GetRoles($domainId: String!) {
    domain(id: $domainId) {
      globalId
      id
      roles {
        globalId
        id
        displayName
        highSecurity
      }
    }
  }
`;

export type PermissionRole = GetRoles_domain_roles;

const QueryRoles: TypedQuery<GetRoles> = Query;

export const SelectPermissionRole = (props: {
  domainId: string;
  roleId: string | null;
  onChange: (selectedRole: PermissionRole | null) => void;
  disabled?: boolean;
}) => {
  return (
    <QueryRoles query={fetchRolesGql} variables={{ domainId: props.domainId }}>
      {(result) => (
        <Select
          noOptionsMessage={() => "No roles"}
          options={
            result.data && !result.loading ? result.data.domain!.roles : []
          }
          getOptionValue={(o: any) => o.id}
          getOptionLabel={(o: any) => o.displayName || o.id}
          isLoading={result.loading}
          value={
            (result.data &&
              result.data.domain &&
              result.data.domain.roles.filter(
                (x) => x.id === props.roleId
              )[0]) ||
            null
          }
          isDisabled={result.loading || props.disabled}
          onChange={(option: any) => props.onChange(option)}
        />
      )}
    </QueryRoles>
  );
};
