import { useSearchString } from "@ist-group-private-scope/skolid-client-components";
import admin2Gql from "graphql-tag";
import * as React from "react";
import { QueryResult, useQuery } from "react-apollo";
import { Link, useHistory } from "react-router-dom";
import { ListIdps } from "../admin-gql-2";
import { useAdmin2Client } from "../App/ClientProvider";
import { ShowModalButton } from "../Common/ShowModalButton";
import { Spinner } from "../Common/Spinner";
import { TableCaption } from "../Common/TableCaption";
import * as routes from "../routes";
import { CreateIdpModal } from "./CreateIdpModal";

const idpListGql = admin2Gql`
  query ListIdps($filter: String, $before: String, $after: String) {
    idpList(filter: $filter, before: $before, after: $after) {
      edges {
        node {
          ...IdpListEntry
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }

  fragment IdpListEntry on Idp {
    id
    name
    organization {
      id
      name
    }
  }
`;

interface PaginationState {
  before?: string | null;
  after?: string | null;
}

export const IdpListScreen = (props: {}) => {
  const history = useHistory();

  const [pagination, setPagination] = React.useState<PaginationState>({});

  const { searchString, debouncedSearchString, setSearchString } = useSearchString();
  const search = (str: string) => {
    setSearchString(str);
    setPagination({});
  };

  const client = useAdmin2Client();
  const idpList = useQuery<ListIdps>(idpListGql, {
    client,
    fetchPolicy: "cache-and-network",
    variables: {
      filter: debouncedSearchString,
      ...pagination
    }
  });

  return (
    <div className="flex-fill">
      <h1>Idps</h1>
      <div className="box">
        <div className="box-body">
          <div className="text-right mb-content">
            <ShowModalButton text="Add Identity Provider">
              {(shown, hide) => (
                <CreateIdpModal
                  onClose={newIdpId => {
                    hide();
                    if (newIdpId) {
                      history.push(routes.generateIdpPath({ id: newIdpId }));
                    }
                  }}
                  show={shown}
                />
              )}
            </ShowModalButton>
          </div>
          <form
            onSubmit={ev => {
              ev.preventDefault();
              idpList.refetch();
            }}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Search idps"
              value={searchString}
              onChange={ev => search(ev.currentTarget.value)}
            />
          </form>

          <br />

          {idpList.loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : null}

          {idpList.error ? <div>Something went wrong!?</div> : null}

          {idpList.data && idpList.data.idpList && !idpList.loading ? (
            <IdpsTable
              result={idpList}
              onAfter={cursor => setPagination({ after: cursor, before: null })}
              onBefore={cursor => setPagination({ after: null, before: cursor })}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const IdpsTable = (props: {
  result: QueryResult<ListIdps>;
  onBefore: (cursor: string) => void;
  onAfter: (cursor: string) => void;
}) => {
  if (props.result.data!.idpList!.edges!.length === 0) {
    return <p>No idps</p>;
  }

  return (
    <table className="table table-hover table-sm table-fixed">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Organization</th>
        </tr>
      </thead>
      <tbody>
        {props.result
          .data!.idpList!.edges!.map(x => x.node)
          .map(p => (
            <tr key={p.id}>
              <td className="truncate">
                <Link to={routes.generateIdpPath({ id: p.id.toString() })}>{p.id}</Link>
              </td>
              <td>{p.name}</td>
              <td className="truncate">{p.organization ? p.organization.name : null}</td>
            </tr>
          ))}
      </tbody>

      <TableCaption connectionData={props.result.data!.idpList!} onBefore={props.onBefore} onAfter={props.onAfter} />
    </table>
  );
};
