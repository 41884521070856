import * as React from "react";
import { Trans } from 'react-i18next';
import i18next from 'i18next';

export const TableCaption = (props: {
  onBefore?: (cursor: string) => void;
  onAfter?: (cursor: string) => void;
  connectionData: {
    totalCount: number;
    edges: any[] | null;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      endCursor: string | null;
      startCursor: string | null;
    };
  };
}) => (
  <caption className="text-center">
    {props.connectionData.edges && props.connectionData.edges.length > 0 ? (
      <>
        <Trans
          i18nKey="table.caption.DisplayHits"
          values={{
            length: props.connectionData.edges.length,
            totalCount: props.connectionData.totalCount,
          }}
        />
      </>
    ) : null}
    {props.connectionData.edges &&
    props.connectionData.edges.length < props.connectionData.totalCount ? (
      <>
        <br />

        {props.onBefore ? (
          <button
            disabled={!props.connectionData.pageInfo.hasPreviousPage}
            className="btn btn-link"
            onClick={() =>
              props.onBefore!(props.connectionData.pageInfo.startCursor!)
            }
          >
            &lt;
          </button>
        ) : null}

        {props.onAfter ? (
          <button
            disabled={!props.connectionData.pageInfo.hasNextPage}
            className="btn btn-link"
            onClick={() =>
              props.onAfter!(props.connectionData.pageInfo.endCursor!)
            }
          >
            &gt;
          </button>
        ) : null}
      </>
    ) : null}
  </caption>
);
