import * as React from "react";
import istLogoUrl from "./ist-logo.png";

export const Footer = () => (
  <footer className="footer">
    <img className="mx-1 footer-logo" src={istLogoUrl} alt="Logo" />
    <div className="footer-body">
      <span className="mx-1 d-none d-md-inline">
        SkolID är en tjänst från <strong>IST</strong>.
      </span>
      <span className="mx-1">© IST AB 2018.</span>
      <span className="mx-1 d-none d-md-inline">
        <a href="https://skolid.se/om-tjansten">Läs mer Om tjänsten</a> •{" "}
        <a href="https://skolid.se/cookie-info">Information om kakor (cookies)</a>
      </span>
      <span className="mx-1 d-inline d-md-none">
        <a href="https://skolid.se/om-tjansten">Om tjänsten</a> •{" "}
        <a href="https://skolid.se/cookie-info">Om kakor</a>
      </span>
    </div>
  </footer>
);
