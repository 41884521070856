export const removeTypename = <T extends any>(value: T): T => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map((v: any) => removeTypename(v));
  } else if (typeof value === "object") {
    const newObj: any = {};
    Object.keys(value).forEach(key => {
      const v = value[key];
      if (key !== "__typename") {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};
