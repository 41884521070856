import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Input } from "@ist-group-private-scope/skolid-client-components";
import { ApolloConsumer } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { generateClientPath } from "../routes";
import gql from "graphql-tag";
import { ImportClient } from "../admin-gql";
import { Spinner } from "../Common/Spinner";
import { AsyncOperation } from "../types";
import { SelectOrganization } from "../Common/SelectOrganization";

export interface ImportClientScreenState {
  importText: string;
  importOperation: AsyncOperation<any>;
  organizationId: string | null;
}

export class ImportClientScreen extends React.Component<
  RouteComponentProps<any>,
  ImportClientScreenState
> {
  constructor(props: any) {
    super(props);
    this.state = { importText: "", importOperation: {}, organizationId: null };
  }

  public render() {
    const disabled = this.state.importOperation.running;

    return (
      <div className="flex-fill">
        <h1>Import SAML client</h1>
        <div className="box">
          <div className="box-body">
            <div className="form-group">
              <label>Organization</label>
              <SelectOrganization
                organizationId={this.state.organizationId}
                disabled={disabled}
                onChange={(organizationId) =>
                  this.setState({
                    organizationId,
                  })
                }
              />
              <div className="mt-1 small">
                When the client is used for authentication the organization
                determines which accounts to use. If no organization is set then
                users from all organizations may be used to login (multi
                tenant).
              </div>
            </div>

            <div className="form-group">
              <Input
                value={this.state.importText}
                multiline
                rows={10}
                disabled={disabled}
                placeholder="SAML Metadata"
                onChange={(newText) => this.setState({ importText: newText })}
              />
            </div>
            <div className="form-group text-right">
              <ApolloConsumer>
                {(apolloClient) => (
                  <button
                    className="btn btn-primary"
                    onClick={() => this.import(apolloClient)}
                  >
                    {this.state.importOperation.running ? (
                      <Spinner light />
                    ) : (
                      "Import"
                    )}
                  </button>
                )}
              </ApolloConsumer>
            </div>
            {this.state.importOperation.error ? (
              <div className="alert alert-danger">
                {this.state.importOperation.error}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  private import = async (apolloClient: ApolloClient<any>) => {
    this.setState({ importOperation: { running: true } });

    try {
      const result: any = await apolloClient.mutate({
        mutation: importClientGql,
        variables: {
          metadata: this.state.importText,
          organizationId: this.state.organizationId,
        },
      });
      const data: ImportClient = result.data;

      this.setState({ importOperation: {} });

      this.props.history.replace(
        generateClientPath({
          id: data.importClientFromSamlMetadata!.id.toString(),
        })
      );
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        if (error.graphQLErrors[0].code === "invalid_saml_metadata") {
          this.setState({ importOperation: { error: "Invalid metadata." } });
        } else if (error.graphQLErrors[0].code === "unique_key_violation") {
          this.setState({
            importOperation: {
              error: "A client with the given id already exists.",
            },
          });
        } else {
          this.setState({
            importOperation: { error: "Failed to import the client." },
          });
        }
      } else {
        this.setState({
          importOperation: { error: "Failed to import the client." },
        });
      }

      console.error("Failed to import client: ", error);
    }
  };
}

const importClientGql = gql`
  mutation ImportClient($metadata: String!, $organizationId: String) {
    importClientFromSamlMetadata(
      metadata: $metadata
      organizationId: $organizationId
    ) {
      id
    }
  }
`;
