import * as React from "react";
// tslint:disable-next-line:no-submodule-imports
import CreatableSelect from "react-select/lib/Creatable";
import { fetchClaimsGql } from "./QueryClaims";
import { FetchClaims } from "../admin-gql";
import { Query } from "react-apollo";

export const SelectClaim = (props: {
  selectedClaim: string | null;
  onChange: (selectedClaims: string) => void;
  disabled?: boolean;
}) => {
  return (
    <Query<FetchClaims> query={fetchClaimsGql}>
      {result => (
        <CreatableSelect
          options={
            result.data && !result.loading ? result.data.claims.map(x => ({ label: getDisplayName(x), value: x })) : []
          }
          isLoading={result.loading}
          value={{
            label: getDisplayName(props.selectedClaim),
            value: props.selectedClaim
          }}
          isClearable
          isDisabled={props.disabled}
          onChange={(option: any) => props.onChange(option ? option.value : null)}
        />
      )}
    </Query>
  );
};

function getDisplayName(claimType: string | null) {
  return (claimType && claimTypeDisplayName[claimType]) || claimType;
}

const claimTypeDisplayName: { [key: string]: string } = {
  acr: "LOA (acr)"
};
