import * as React from "react";
import { SchoolRelationRole } from "../admin-gql-2";
import Select from "react-select";

export const SelectSchoolRelationRoles = (props: {
  roles: SchoolRelationRole[];
  onChange: (newRoles: SchoolRelationRole[]) => void;
  disabled?: boolean;
}) => {
  return (
    <Select
      options={(Object.keys(SchoolRelationRole) as any).map((x: SchoolRelationRole) => ({
        label: roleNames[x],
        value: x
      }))}
      value={props.roles.map(role => ({ value: role, label: roleNames[role] }))}
      isMulti
      isDisabled={props.disabled}
      onChange={(options: any) => props.onChange(options.map((x: any) => x.value))}
    />
  );
};

const roleNames: { [x in SchoolRelationRole]: string } = {
  [SchoolRelationRole.ADMIN]: "Admin",
  [SchoolRelationRole.GUARDIAN]: "Guardian",
  [SchoolRelationRole.STUDENT]: "Student",
  [SchoolRelationRole.TEACHER]: "Teacher"
};
