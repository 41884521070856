import React from "react";
import classNames from "classnames";

export const Alert = ({
  variant = "danger",
  hidden,
  children
}: {
  children: React.ReactNode;
  variant?: "danger" | "warning" | "info" | "success";
  hidden?: boolean;
}) =>
  !hidden && children ? (
    <div className={classNames("alert", variant ? "alert-" + variant : null)}>{children}</div>
  ) : null;
