import * as React from "react";
import { FormClient } from "../types";
import { ApolloConsumer } from "react-apollo";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import { Spinner } from "../../Common/Spinner";
import { clientsPath } from "../../routes";
import { History } from "history";
import { AsyncOperation } from "../../types";
import { ClientFragment } from "../../admin-gql";

interface DeleteClientFormProps {
  history: History;
  client: FormClient;
  originalClient: ClientFragment;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}

interface DeleteClientFormState {
  deleteOperation: AsyncOperation<any>;
}

export class DeleteClientForm extends React.Component<DeleteClientFormProps, DeleteClientFormState> {
  constructor(props: any) {
    super(props);
    this.state = { deleteOperation: {} };
  }

  public render() {
    return (
      <div className="box">
        <div className="box-header">Remove</div>
        <div className="box-body">
          <div className="form-group">
            <ApolloConsumer>
              {apolloClient => (
                <button
                  disabled={this.props.disabled || this.state.deleteOperation.running}
                  className="btn btn-danger"
                  onClick={() => this.remove(apolloClient)}
                >
                  {this.state.deleteOperation.running ? <Spinner light /> : "Remove client"}
                </button>
              )}
            </ApolloConsumer>
          </div>
          {this.state.deleteOperation.error ? (
            <div className="alert alert-danger">{this.state.deleteOperation.error}</div>
          ) : null}
        </div>
      </div>
    );
  }

  private remove = async (apolloClient: ApolloClient<any>) => {
    try {
      this.setState({ deleteOperation: { running: true } });

      await apolloClient.mutate({
        mutation: gql`
          mutation DeleteClient($clientId: String!) {
            deleteClient(id: $clientId)
          }
        `,
        variables: {
          clientId: this.props.originalClient.id
        }
      });

      this.props.history.replace(clientsPath);
    } catch (error) {
      this.setState({ deleteOperation: { error: "Failed to delete client" } });
      console.error("Failed to delete client: ", error);
    }
  };
}
