import { Mutation } from "react-apollo";
import permGql from "graphql-tag";
import { UnassignRole, UnassignRoleVariables } from "../permissons-gql";
import { TypedMutation } from "../types";

export const unassignRoleGql = permGql`
  mutation UnassignRole($input: UnassignRoleInput!) {
    unassignRole(input: $input)
  }
`;

export const UnassignRoleMutation: TypedMutation<UnassignRole, UnassignRoleVariables> = Mutation;
