import { useForm } from "@ist-group/react-form-hook";
import {
  AddressControl,
  DropDownButton,
  formatDate,
  FormattedDateTime,
  Input,
  IssueModal,
  Modal,
  SelectOrganizationRole,
  DateInput,
  loaDisplayNames,
} from "@ist-group-private-scope/skolid-client-components";
import { SignContext, SignManager } from "@ist-group-private-scope/web-skolid";
import ApolloClient from "apollo-client";
import gql from "graphql-tag";
import React, { useContext, useEffect } from "react";
import { useApolloClient, useMutation, useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import {
  AddressType,
  DeleteUser,
  DeleteUserVariables,
  FetchUser,
  FetchUserVariables,
  FetchUser_user,
  Loa,
  strippers,
  UpdateUserVariables,
} from "../admin-gql";
import { LoadingScreen } from "../App/LoadingScreen";
import { Checkbox } from "../Common/Checkbox";
import { Spinner } from "../Common/Spinner";
import {
  generateUserAuditPath,
  generateUserPermissionsPath,
  usersPath,
} from "../routes";
import { settings } from "../settings";
import { BasicUserForm } from "./Components/BasicUserForm";
import { FormGroup } from "./Components/FormGroup";
import { UserMergeModal } from "./MergeUsersModal";
import { SetPasswordModal } from "./SetPasswordModal";
import { FormUser } from "./types";
import { UserMoreDetails } from "./UserMoreDetails";
import { createAdminNationalIdValidator } from "./validation";
import i18next from 'i18next';
import { environmentSettings } from "@ist-group-private-scope/web-skolid";

export const UserDetailsScreen = (props: { userId: string }) => {
  const client = useApolloClient();
  const history = useHistory();
  const signManager = useContext(SignContext)!;
  const userQuery = useQuery<FetchUser, FetchUserVariables>(fetchUserGql, {
    variables: {
      id: props.userId,
    },
  });

  const user = userQuery.data && userQuery.data.user;

  const [showIssueModal, setShowIssueModal] = React.useState(false);
  const [showMergeModal, setShowMergeModal] = React.useState(false);
  const [showSetPasswordModal, setShowSetPasswordModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [operationError, setOperationError] = React.useState<string | null>(
    null
  );

  const form = useForm<FormUser | null, string | React.ReactNode>(
    convertToFormUser(user),
    {
      onSubmit: async (values) => {
        try {
          await saveUser(user!.id, values, client);
          setEditing(false);
        } catch (error) {
          console.log("Failed to save user: ", error);
          setOperationError("Failed to to save user");
        }
      },
      fieldValidation: {
        fields: {
          nationalId: {
            onChange: user
              ? createAdminNationalIdValidator(
                user.id,
                user.organization.country,
                user.organization.id,
                client
              )
              : undefined,
          },
        },
      },
    }
  );

  useEffect(
    () => {
      form.set(convertToFormUser(user));
    },
    // eslint-disable-next-line
    [user]
  );

  useEffect(
    () => {
      setEditing(false);
      setShowIssueModal(false);
      setShowMergeModal(false);
    },
    // eslint-disable-next-line
    [user?.id]
  );

  const formUser = form.fields;

  if (userQuery.loading) {
    return <LoadingScreen />;
  }

  if (userQuery.error) {
    return <div className="alert alert-error">Something went wrong</div>;
  }

  if (!user || !formUser) {
    return <h1>User does not exist</h1>;
  }

  const readonly = !editing;
  const disabled = form.submitting;

  return (
    <div className="flex-fill">
      <div className="row align-items-center">
        <h1 className="col m-0 mb-sm-content">
          {`${user.firstName || ""} ${user.lastName || ""}`.trim() ||
            "Account information"}
        </h1>
        <div className={"col-sm d-flex justify-content-md-end my-content"}>
          <button
            className="btn btn-primary"
            type="button"
            disabled={disabled || editing}
            title={
              editing
                ? 'Spara eller återställ ändringarna i formuläret för att kunna dela ut kontot'
                : 'Dela ut används för att ge kontot till en användare.'
            }
            onClick={() => setShowIssueModal(true)}
          >
            Issue
          </button>
          &nbsp; &nbsp;
          <div
            className="dropdown"
            title={
              disabled
                ? 'Spara eller återställ ändringarna i formuläret för att kunna hantera kontot'
                : undefined
            }
          >
            <DropDownButton label="More" disabled={disabled || editing}>
              {() => (
                <>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      history.push(generateUserAuditPath({ id: user.id }))
                    }
                  >
                    Show log
                  </button>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      history.push(generateUserPermissionsPath({ id: user.id }))
                    }
                  >
                    Show permissions
                  </button>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => setShowMergeModal(true)}
                  >
                    Merge with...
                  </button>
                  {settings.demoFeatures ? (
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => setShowSetPasswordModal(true)}
                    >
                      Set password...
                    </button>
                  ) : null}
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete...
                  </button>
                </>
              )}
            </DropDownButton>
          </div>
        </div>
      </div>
      <div className="box">
        <div className="box-header">
          {i18next.t('user.screen.accountInformation')}
        </div>
        <div className="box-body">
          {user.isSuperAdmin ? (
            <div className="alert alert-info">Global IST Administrator</div>
          ) : null}

          {user.locked ? (
            <div className="alert alert-warning">Temporarily locked</div>
          ) : null}

          {user.blocked ? (
            <div className="alert alert-warning">Blocked</div>
          ) : null}

          {!user.hasPassword ? (
            <div className="alert alert-info">No password set</div>
          ) : null}

          {user.images.length > 0 ? (
            <img
              alt="thumbnail"
              src={user.images[0].url}
              className="img-thumbnail mb-3"
              style={{ width: 100, height: 100 }}
            />
          ) : null}

          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="loa">{i18next.t('user.form.levelOfTrust')}</label>
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                value={loaDisplayNames[user.loa].name}
                id="loa"
              />
            </div>

            <div className="form-group col">
              <label htmlFor="lastActivity">
                {i18next.t('user.form.lastUsed')}
              </label>
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                value={
                  user.lastActivity
                    ? new Date(user.lastActivity).toLocaleDateString()
                    : "-"
                }
                id="lastActivity"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md">
              <Input value={user.organization.name} label="Organisation" />
            </div>

            <div className="form-group col-md">
              <label>{i18next.t('user.form.role')}</label>
              <SelectOrganizationRole
                readonly={readonly}
                disabled={disabled}
                role={formUser.role.value}
                onChange={formUser.role.set}
              />
            </div>
          </div>

          <BasicUserForm
            organization={user.organization}
            formFields={form.fields!}
            readonly={readonly}
            disabled={disabled}
          />

          {formUser.addresses.items.length > 0 ? <h4>Addresses</h4> : null}
          {formUser.addresses.items.map((address, index) => (
            <AddressControl
              key={index}
              address={address.value}
              onChange={address.set}
              onRemove={() => formUser.addresses.remove(index)}
              disabled={disabled}
              readonly={readonly}
            />
          ))}

          {!readonly ? (
            <div className="form-group">
              <button
                className="btn btn-link p-0"
                onClick={() =>
                  formUser.addresses.push({
                    city: "",
                    postalCode: "",
                    streetAddress: "",
                    type: AddressType.HOME,
                    country: "",
                    apartmentNumber: "",
                    co: "",
                    primary: false,
                    id: null,
                  })
                }
              >
                Add address
              </button>
            </div>
          ) : null}

          {user.icloudTokens.length > 0 ? (
            <FormGroup header="ICloud tokens">
              <table className="table table-hover table-sm table-fixed mb-0 mt-3">
                <thead>
                  <tr>
                    <th>Number</th>
                    <th>Registered</th>
                  </tr>
                </thead>

                <tbody>
                  {user.icloudTokens.map((token, index) => (
                    <tr key={index}>
                      <td className="truncate">{index + 1}</td>
                      <td className="truncate">
                        <FormattedDateTime
                          date={new Date(token.registeredAt)}
                        ></FormattedDateTime>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </FormGroup>
          ) : null}

          {user.externalLogins.length > 0 ? (
            <FormGroup header="External logins">
              {user.externalLogins.map((extLogin, index) => (
                <div className="form-group" key={index}>
                  <Input value={extLogin.providerKey}>
                    <span className="input-group-text">
                      {extLogin.loginProvider}
                    </span>
                  </Input>
                </div>
              ))}
            </FormGroup>
          ) : null}

          {user.devices.length > 0 ? (
            <FormGroup header="Devices">
              <table className="table table-hover table-sm table-fixed mb-0 mt-3">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>LOA</th>
                    <th>Registered</th>
                    <th>Last used</th>
                  </tr>
                </thead>

                <tbody>
                  {user.devices.map((device, index) => (
                    <tr key={index}>
                      <td className="truncate">{device.deviceId}</td>
                      <td className="truncate">{device.name}</td>
                      <td className="truncate">{device.loa}</td>
                      <td className="truncate">
                        <FormattedDateTime
                          date={new Date(device.registeredAt)}
                        ></FormattedDateTime>
                      </td>
                      <td className="truncate">
                        {device.lastUsed ? (
                          <FormattedDateTime
                            date={new Date(device.lastUsed)}
                          ></FormattedDateTime>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </FormGroup>
          ) : null}

          {user.mergedIds.length > 0 ? (
            <FormGroup header="Merged ids">
              <table className="table table-hover table-sm table-fixed mb-0 mt-3">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>

                <tbody>
                  {user.mergedIds.map((mergedId, index) => (
                    <tr key={index}>
                      <td className="truncate">{mergedId.mergedId}</td>
                      <td className="truncate">
                        <FormattedDateTime
                          date={new Date(mergedId.createdAt)}
                        ></FormattedDateTime>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </FormGroup>
          ) : null}

          {user.biometricKeys.length > 0 ? (
            <FormGroup header="Biometric keys">
              <table className="table table-hover table-sm table-fixed mb-0 mt-3">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Display name</th>
                    <th>Expire at</th>
                    <th>Last used</th>
                  </tr>
                </thead>

                <tbody>
                  {user.biometricKeys.map((bio, index) => {
                    var expireAt = new Date(bio.expireAt);
                    const expired = expireAt < new Date();
                    return (
                      <tr
                        key={index}
                        className={expired ? "table-warning" : ""}
                      >
                        <td className="truncate">{bio.biometricId}</td>
                        <td className="truncate">{bio.displayName}</td>
                        <td className={"truncate"}>
                          <FormattedDateTime
                            date={expireAt}
                          ></FormattedDateTime>
                          {expired ? " (Expired)" : null}
                        </td>
                        <td className={"truncate"}>
                          {bio.lastUsed ? (
                            <FormattedDateTime
                              date={new Date(bio.lastUsed)}
                            ></FormattedDateTime>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </FormGroup>
          ) : null}

          <div className="form-group">
            <Checkbox
              checked={formUser.isSuperAdmin.value}
              disabled={disabled || readonly}
              onChange={formUser.isSuperAdmin.set}
              label="Global IST Administrator"
            />
          </div>

          <div className="text-right">
            {!editing ? (
              <button
                className="btn btn-primary"
                type="submit"
                disabled={disabled || editing}
                onClick={() => setEditing(true)}
              >
                Edit
              </button>
            ) : (
              <>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setEditing(false);
                    form.set(convertToFormUser(user));
                  }}
                >
                  Cancel
                </button>
                &nbsp;&nbsp;
                <button className="btn btn-primary" onClick={form.submit}>
                  Save
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <UserMoreDetails userId={user.id} />
      {showIssueModal ? (
        <IssueModal
          defaultLoa={null}
          context={{
            locale: "sv",
            client,
            signManager,
          }}
          loaLimit={Loa.THREE}
          onClose={() => {
            setShowIssueModal(false);
            userQuery.refetch();
          }}
          user={user}
          skolidUrl={environmentSettings[settings.skolidEnvironment].url}
        />
      ) : null}
      {showMergeModal ? (
        <UserMergeModal
          onClose={() => setShowMergeModal(false)}
          userId={user.id}
          organizationId={user.organization.id}
        />
      ) : null}
      {showSetPasswordModal ? (
        <SetPasswordModal
          onClose={() => setShowSetPasswordModal(false)}
          userId={user.id}
          refetchQueries={["FetchUser"]}
        />
      ) : null}
      <ProgressModal
        error={operationError}
        progressing={form.submitting}
        onClearError={() => setOperationError(null)}
      />
      {showDeleteModal ? (
        <DeleteUserModal
          onCancel={() => setShowDeleteModal(false)}
          onDeleted={() => history.push(usersPath)}
          userId={user.id}
        ></DeleteUserModal>
      ) : null}
    </div>
  );
};

const DeleteUserModal = (props: {
  userId: string;
  onCancel: () => void;
  onDeleted: () => void;
}) => {
  const [deleteUser, deleteUserMutation] = useMutation<
    DeleteUser,
    DeleteUserVariables
  >(
    gql`
      mutation DeleteUser($userId: String!) {
        deleteUser(id: $userId)
      }
    `,
    {
      variables: {
        userId: props.userId,
      },
      onCompleted: props.onDeleted,
    }
  );

  const disabled = deleteUserMutation.loading;
  return (
    <Modal onClose={props.onCancel} header="Delete user" small>
      {(close) => (
        <>
          <div className="modal-body">
            {deleteUserMutation.error ? (
              <div className="alert alert-danger">Failed to delete user</div>
            ) : (
              "Are you sure?"
            )}
          </div>
          <div className="modal-footer d-flex justify-content-between align-items-center">
            <button
              className="btn btn-secondary"
              onClick={close}
              disabled={disabled}
            >
              Cancel
            </button>

            {deleteUserMutation.loading ? <Spinner /> : null}

            <button
              className="btn btn-danger"
              onClick={() => deleteUser()}
              disabled={disabled}
            >
              Delete
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export const ProgressModal = (props: {
  error?: string | null;
  progressing?: boolean;
  onClearError: () => void;
}) => {
  const [delayedProgressing, setDelayedProgressing] = React.useState(
    props.progressing
  );

  // Delay hide
  React.useEffect(() => {
    const id = setTimeout(() => setDelayedProgressing(props.progressing), 2000);
    return () => clearTimeout(id);
  }, [props.progressing]);

  return delayedProgressing || props.progressing || props.error ? (
    <div
      className="position-fixed align-items-center d-flex justify-content-center"
      style={{
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        background: "rgba(255, 255, 255, 0.53)",
      }}
    >
      {!props.error || props.progressing ? (
        <Spinner />
      ) : (
        <div className="box" style={{ width: 300 }}>
          <div className="box-header box-header-danger d-flex justify-content-between flex-row">
            <span>Error</span>
            <button
              type="button"
              className="btn"
              style={{ fontSize: 24, color: "white" }}
              onClick={props.onClearError}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="box-body">{props.error}</div>
        </div>
      )}
    </div>
  ) : null;
};

export const UserFragmentGql = gql`
  fragment UserFragment on User {
    id
    nationalId
    tfNumber
    username
    role
    isSuperAdmin
    organization {
      id
      name
      country
    }
    loa
    hasPassword
    firstName
    lastName
    lastActivity
    blocked
    locked
    expiresAt
    images {
      size
      url
    }
    phoneNumbers {
      id
      value
      use
      canBeUsedForSmsCodes
    }
    emails {
      value
      use
      confirmed
    }
    icloudTokens {
      registeredAt
    }
    name
    externalLogins {
      loginProvider
      providerDisplayName
      providerKey
    }
    devices {
      name
      deviceId
      loa
      registeredAt
      lastUsed
    }
    mergedIds {
      mergedId
      createdAt
    }
    biometricKeys {
      biometricId
      displayName
      issuedAt
      method
      lastUsed
      expireAt
    }
    addresses {
      streetAddress
      postalCode
      city
      country
      apartmentNumber
      co
      id
      type
      primary
    }
  }
`;

const fetchUserGql = gql`
  query FetchUser($id: String!) {
    user(id: $id) {
      ...UserFragment
    }
  }

  ${UserFragmentGql}
`;

const updateUserGql = gql`
  mutation UpdateUser($userId: String!, $user: UpdateUserInput!) {
    updateUser(userId: $userId, user: $user, options: {}) {
      ...UserFragment
    }
  }

  ${UserFragmentGql}
`;

async function saveUser(
  id: string,
  values: FormUser | null,
  client: ApolloClient<any>
) {
  const variables: UpdateUserVariables = {
    userId: id,
    user: strippers.UpdateUserInput(values!),
  };
  await client.mutate<any, UpdateUserVariables>({
    mutation: updateUserGql,
    variables,
  });
}

function convertToFormUser(
  user: FetchUser_user | null | undefined
): FormUser | null {
  return user
    ? {
        ...user,
        nationalId: user.nationalId ?? "",
        firstName: user.firstName ?? "",
        lastName: user.lastName ?? "",
        tfNumber: user.tfNumber ?? "",
        username: user.username ?? "",
        role: user.role,
      }
    : null;
}
