/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectIdpsList
// ====================================================

export interface SelectIdpsList_idpList_nodes {
  id: string;
  name: string;
}

export interface SelectIdpsList_idpList {
  /**
   * A flattened list of the nodes.
   */
  nodes: SelectIdpsList_idpList_nodes[] | null;
}

export interface SelectIdpsList {
  idpList: SelectIdpsList_idpList | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateIdpFromMetadata
// ====================================================

export interface CreateIdpFromMetadata_createIdpFromMetadata_CreateIdpFromMetadataSuccess_idp {
  id: string;
}

export interface CreateIdpFromMetadata_createIdpFromMetadata_CreateIdpFromMetadataSuccess {
  __typename: "CreateIdpFromMetadataSuccess";
  idp: CreateIdpFromMetadata_createIdpFromMetadata_CreateIdpFromMetadataSuccess_idp;
}

export interface CreateIdpFromMetadata_createIdpFromMetadata_CreateIdpFromMetadataError {
  __typename: "CreateIdpFromMetadataError";
  errorMessage: string;
}

export type CreateIdpFromMetadata_createIdpFromMetadata = CreateIdpFromMetadata_createIdpFromMetadata_CreateIdpFromMetadataSuccess | CreateIdpFromMetadata_createIdpFromMetadata_CreateIdpFromMetadataError;

export interface CreateIdpFromMetadata {
  createIdpFromMetadata: CreateIdpFromMetadata_createIdpFromMetadata;
}

export interface CreateIdpFromMetadataVariables {
  input: CreateIdpFromMetadataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateIdp
// ====================================================

export interface CreateIdp_createIdp_CreateIdpSuccess_idp {
  id: string;
}

export interface CreateIdp_createIdp_CreateIdpSuccess {
  __typename: "CreateIdpSuccess";
  idp: CreateIdp_createIdp_CreateIdpSuccess_idp;
}

export interface CreateIdp_createIdp_CreateIdpError {
  __typename: "CreateIdpError";
  errorMessage: string;
}

export type CreateIdp_createIdp = CreateIdp_createIdp_CreateIdpSuccess | CreateIdp_createIdp_CreateIdpError;

export interface CreateIdp {
  createIdp: CreateIdp_createIdp;
}

export interface CreateIdpVariables {
  input: CreateIdpInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IdpDetails
// ====================================================

export interface IdpDetails_idp_organization {
  id: string;
  name: string;
}

export interface IdpDetails_idp_claimMappings_SetIdpClaimMapping {
  __typename: "SetIdpClaimMapping";
  target: string;
  value: string;
}

export interface IdpDetails_idp_claimMappings_TransferIdpClaimMapping {
  __typename: "TransferIdpClaimMapping";
  target: string;
  matchRegex: string | null;
  source: string;
  targetValueSubstitution: string | null;
}

export type IdpDetails_idp_claimMappings = IdpDetails_idp_claimMappings_SetIdpClaimMapping | IdpDetails_idp_claimMappings_TransferIdpClaimMapping;

export interface IdpDetails_idp_samlConfig_certificates {
  data: string | null;
  use: IdpSamlCertificateUse;
}

export interface IdpDetails_idp_samlConfig {
  certificates: IdpDetails_idp_samlConfig_certificates[];
  entityId: string;
  signAuthnRequest: boolean;
  signLogoutRequest: boolean;
  singleLogoutServiceUrl: string | null;
  singleSignOnServiceUrl: string;
  singleSignOnServiceBinding: string;
  singleLogoutServiceBinding: string;
  localSigningCertificateThumbprintHint: string | null;
}

export interface IdpDetails_idp {
  id: string;
  name: string;
  created: any;
  updated: any;
  organization: IdpDetails_idp_organization;
  roles: SchoolRelationRole[];
  claimMappings: IdpDetails_idp_claimMappings[];
  samlConfig: IdpDetails_idp_samlConfig;
}

export interface IdpDetails {
  idp: IdpDetails_idp | null;
}

export interface IdpDetailsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateIdp
// ====================================================

export interface UpdateIdp_updateIdp_UpdateIdpSuccess_idp_organization {
  id: string;
  name: string;
}

export interface UpdateIdp_updateIdp_UpdateIdpSuccess_idp_claimMappings_SetIdpClaimMapping {
  __typename: "SetIdpClaimMapping";
  target: string;
  value: string;
}

export interface UpdateIdp_updateIdp_UpdateIdpSuccess_idp_claimMappings_TransferIdpClaimMapping {
  __typename: "TransferIdpClaimMapping";
  target: string;
  matchRegex: string | null;
  source: string;
  targetValueSubstitution: string | null;
}

export type UpdateIdp_updateIdp_UpdateIdpSuccess_idp_claimMappings = UpdateIdp_updateIdp_UpdateIdpSuccess_idp_claimMappings_SetIdpClaimMapping | UpdateIdp_updateIdp_UpdateIdpSuccess_idp_claimMappings_TransferIdpClaimMapping;

export interface UpdateIdp_updateIdp_UpdateIdpSuccess_idp_samlConfig_certificates {
  data: string | null;
  use: IdpSamlCertificateUse;
}

export interface UpdateIdp_updateIdp_UpdateIdpSuccess_idp_samlConfig {
  certificates: UpdateIdp_updateIdp_UpdateIdpSuccess_idp_samlConfig_certificates[];
  entityId: string;
  signAuthnRequest: boolean;
  signLogoutRequest: boolean;
  singleLogoutServiceUrl: string | null;
  singleSignOnServiceUrl: string;
  singleSignOnServiceBinding: string;
  singleLogoutServiceBinding: string;
  localSigningCertificateThumbprintHint: string | null;
}

export interface UpdateIdp_updateIdp_UpdateIdpSuccess_idp {
  id: string;
  name: string;
  created: any;
  updated: any;
  organization: UpdateIdp_updateIdp_UpdateIdpSuccess_idp_organization;
  roles: SchoolRelationRole[];
  claimMappings: UpdateIdp_updateIdp_UpdateIdpSuccess_idp_claimMappings[];
  samlConfig: UpdateIdp_updateIdp_UpdateIdpSuccess_idp_samlConfig;
}

export interface UpdateIdp_updateIdp_UpdateIdpSuccess {
  __typename: "UpdateIdpSuccess";
  idp: UpdateIdp_updateIdp_UpdateIdpSuccess_idp;
}

export interface UpdateIdp_updateIdp_UpdateIdpError {
  __typename: "UpdateIdpError";
  errorMessage: string;
}

export type UpdateIdp_updateIdp = UpdateIdp_updateIdp_UpdateIdpSuccess | UpdateIdp_updateIdp_UpdateIdpError;

export interface UpdateIdp {
  updateIdp: UpdateIdp_updateIdp;
}

export interface UpdateIdpVariables {
  input: UpdateIdpInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteIdp
// ====================================================

export interface DeleteIdp_deleteIdp_DeleteIdpSuccess {
  __typename: "DeleteIdpSuccess";
}

export interface DeleteIdp_deleteIdp_DeleteIdpError {
  __typename: "DeleteIdpError";
  errorMessage: string;
}

export type DeleteIdp_deleteIdp = DeleteIdp_deleteIdp_DeleteIdpSuccess | DeleteIdp_deleteIdp_DeleteIdpError;

export interface DeleteIdp {
  deleteIdp: DeleteIdp_deleteIdp;
}

export interface DeleteIdpVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListIdps
// ====================================================

export interface ListIdps_idpList_edges_node_organization {
  id: string;
  name: string;
}

export interface ListIdps_idpList_edges_node {
  id: string;
  name: string;
  organization: ListIdps_idpList_edges_node_organization;
}

export interface ListIdps_idpList_edges {
  /**
   * The item at the end of the edge.
   */
  node: ListIdps_idpList_edges_node;
  /**
   * A cursor for use in pagination.
   */
  cursor: string;
}

export interface ListIdps_idpList_pageInfo {
  /**
   * Indicates whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
  /**
   * Indicates whether more edges exist prior the set defined by the clients arguments.
   */
  hasPreviousPage: boolean;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface ListIdps_idpList {
  /**
   * A list of edges.
   */
  edges: ListIdps_idpList_edges[] | null;
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: ListIdps_idpList_pageInfo;
}

export interface ListIdps {
  idpList: ListIdps_idpList | null;
}

export interface ListIdpsVariables {
  filter?: string | null;
  before?: string | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateIdpFromMetadata
// ====================================================

export interface UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_organization {
  id: string;
  name: string;
}

export interface UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_claimMappings_SetIdpClaimMapping {
  __typename: "SetIdpClaimMapping";
  target: string;
  value: string;
}

export interface UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_claimMappings_TransferIdpClaimMapping {
  __typename: "TransferIdpClaimMapping";
  target: string;
  matchRegex: string | null;
  source: string;
  targetValueSubstitution: string | null;
}

export type UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_claimMappings = UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_claimMappings_SetIdpClaimMapping | UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_claimMappings_TransferIdpClaimMapping;

export interface UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_samlConfig_certificates {
  data: string | null;
  use: IdpSamlCertificateUse;
}

export interface UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_samlConfig {
  certificates: UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_samlConfig_certificates[];
  entityId: string;
  signAuthnRequest: boolean;
  signLogoutRequest: boolean;
  singleLogoutServiceUrl: string | null;
  singleSignOnServiceUrl: string;
  singleSignOnServiceBinding: string;
  singleLogoutServiceBinding: string;
  localSigningCertificateThumbprintHint: string | null;
}

export interface UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp {
  id: string;
  name: string;
  created: any;
  updated: any;
  organization: UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_organization;
  roles: SchoolRelationRole[];
  claimMappings: UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_claimMappings[];
  samlConfig: UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp_samlConfig;
}

export interface UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess {
  __typename: "UpdateIdpFromMetadataSuccess";
  idp: UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess_idp;
}

export interface UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataError {
  __typename: "UpdateIdpFromMetadataError";
  errorMessage: string;
}

export type UpdateIdpFromMetadata_updateIdpFromMetadata = UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataSuccess | UpdateIdpFromMetadata_updateIdpFromMetadata_UpdateIdpFromMetadataError;

export interface UpdateIdpFromMetadata {
  updateIdpFromMetadata: UpdateIdpFromMetadata_updateIdpFromMetadata;
}

export interface UpdateIdpFromMetadataVariables {
  input: UpdateIdpFromMetadataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MoreUserDetails
// ====================================================

export interface MoreUserDetails_user_relations_to {
  id: string;
  name: string | null;
}

export interface MoreUserDetails_user_relations {
  role: UserRelationRole;
  to: MoreUserDetails_user_relations_to;
}

export interface MoreUserDetails_user_incomingRelations_from {
  id: string;
  name: string | null;
}

export interface MoreUserDetails_user_incomingRelations {
  role: UserRelationRole;
  from: MoreUserDetails_user_incomingRelations_from;
}

export interface MoreUserDetails_user_authenticators {
  activatedAt: any;
  deviceId: string;
  id: string;
  lastAuthentication: any | null;
  legacyPin: boolean;
  name: string;
}

export interface MoreUserDetails_user_clientCertificates {
  certificateThumbprint: string;
  createdAt: any;
  deviceInformation: string | null;
  displayName: string;
  lastUsed: any;
}

export interface MoreUserDetails_user {
  id: string;
  relations: MoreUserDetails_user_relations[];
  incomingRelations: MoreUserDetails_user_incomingRelations[];
  authenticators: MoreUserDetails_user_authenticators[];
  clientCertificates: MoreUserDetails_user_clientCertificates[];
}

export interface MoreUserDetails {
  user: MoreUserDetails_user | null;
}

export interface MoreUserDetailsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IdpDetails
// ====================================================

export interface IdpDetails_organization {
  id: string;
  name: string;
}

export interface IdpDetails_claimMappings_SetIdpClaimMapping {
  __typename: "SetIdpClaimMapping";
  target: string;
  value: string;
}

export interface IdpDetails_claimMappings_TransferIdpClaimMapping {
  __typename: "TransferIdpClaimMapping";
  target: string;
  matchRegex: string | null;
  source: string;
  targetValueSubstitution: string | null;
}

export type IdpDetails_claimMappings = IdpDetails_claimMappings_SetIdpClaimMapping | IdpDetails_claimMappings_TransferIdpClaimMapping;

export interface IdpDetails_samlConfig_certificates {
  data: string | null;
  use: IdpSamlCertificateUse;
}

export interface IdpDetails_samlConfig {
  certificates: IdpDetails_samlConfig_certificates[];
  entityId: string;
  signAuthnRequest: boolean;
  signLogoutRequest: boolean;
  singleLogoutServiceUrl: string | null;
  singleSignOnServiceUrl: string;
  singleSignOnServiceBinding: string;
  singleLogoutServiceBinding: string;
  localSigningCertificateThumbprintHint: string | null;
}

export interface IdpDetails {
  id: string;
  name: string;
  created: any;
  updated: any;
  organization: IdpDetails_organization;
  roles: SchoolRelationRole[];
  claimMappings: IdpDetails_claimMappings[];
  samlConfig: IdpDetails_samlConfig;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IdpListEntry
// ====================================================

export interface IdpListEntry_organization {
  id: string;
  name: string;
}

export interface IdpListEntry {
  id: string;
  name: string;
  organization: IdpListEntry_organization;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum IdpSamlCertificateUse {
  ANY = "ANY",
  ENCRYPTION = "ENCRYPTION",
  SIGNATURE = "SIGNATURE",
}

export enum SchoolRelationRole {
  ADMIN = "ADMIN",
  GUARDIAN = "GUARDIAN",
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
}

export enum UserRelationRole {
  CHILD = "CHILD",
  GENERIC = "GENERIC",
  PARENT = "PARENT",
  PARTNER = "PARTNER",
}

export interface CreateIdpFromMetadataInput {
  id: string;
  metadata: string;
  name: string;
  organizationId: string;
  roles?: SchoolRelationRole[] | null;
}

export interface CreateIdpInput {
  claimMappings?: (IdpClaimMappingInput | null)[] | null;
  id?: string | null;
  name: string;
  organizationId: string;
  roles?: SchoolRelationRole[] | null;
  samlConfig?: IdpSamlConfigInput | null;
}

export interface IdpClaimMappingInput {
  set?: SetIdpClaimMappingInput | null;
  transfer?: TransferIdpClaimMappingInput | null;
}

export interface IdpSamlCertificateInput {
  data?: string | null;
}

export interface IdpSamlConfigInput {
  certificates?: (IdpSamlCertificateInput | null)[] | null;
  entityId?: string | null;
  localSigningCertificateThumbprintHint?: string | null;
  signAuthnRequest: boolean;
  signLogoutRequest: boolean;
  singleLogoutServiceBinding: string;
  singleLogoutServiceUrl?: string | null;
  singleSignOnServiceBinding: string;
  singleSignOnServiceUrl?: string | null;
}

export interface SetIdpClaimMappingInput {
  target: string;
  value: string;
}

export interface TransferIdpClaimMappingInput {
  matchRegex?: string | null;
  source: string;
  target: string;
  targetValueSubstitution?: string | null;
}

export interface UpdateIdpFromMetadataInput {
  id: string;
  metadata: string;
}

export interface UpdateIdpInput {
  claimMappings?: (IdpClaimMappingInput | null)[] | null;
  id: string;
  name: string;
  organizationId: string;
  roles?: SchoolRelationRole[] | null;
  samlConfig?: IdpSamlConfigInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

export interface QueryActivationRequestArgs {
  id: string;
}
export interface QueryCanOnboardUserArgs {
  onboardingToken: string;
  userId: string;
}
export interface QueryIdpArgs {
  id: string;
}
export interface QueryIdpListArgs {
  after: string| null;
  before: string| null;
  filter: string| null;
  first: PaginationAmountScalar| null;
  last: PaginationAmountScalar| null;
}
export interface QueryUserArgs {
  id: string;
}
export interface QueryUsersArgs {
  ids: ((string)[]) ;
}
export interface QueryUsersByEmailArgs {
  emails: ((string)[]) ;
  organizationId: string;
}

export interface ActivationRequest {
  id: string;
  status: ActivationRequestStatus;
}

export enum ActivationRequestStatus {
  WAITING = "WAITING",
  EXPIRED = "EXPIRED",
  ISSUED = "ISSUED",
}

export interface Idp {
  claimMappings: ((IdpClaimMapping)[]) ;
  created: DateTimeScalar;
  id: string;
  name: string;
  organization: Organization;
  roles: ((SchoolRelationRole)[]) ;
  samlConfig: IdpSamlConfig;
  updated: DateTimeScalar;
}

export interface IdpClaimMapping {
  target: string;
}

export type DateTimeScalar<T = any> = T;

export interface Organization {
  id: string;
  name: string;
}

export interface IdpSamlConfig {
  certificates: ((IdpSamlCertificate)[]) ;
  entityId: string;
  localSigningCertificateThumbprintHint?: string| null;
  signAuthnRequest: boolean;
  signLogoutRequest: boolean;
  singleLogoutServiceBinding: string;
  singleLogoutServiceUrl?: string| null;
  singleSignOnServiceBinding: string;
  singleSignOnServiceUrl: string;
}

export interface IdpSamlCertificate {
  data?: string| null;
  use: IdpSamlCertificateUse;
}

export type PaginationAmountScalar<T = any> = T;

export interface IdpConnection {
  edges?: ((IdpEdge)[]) | null;
  nodes?: ((Idp)[]) | null;
  pageInfo: PageInfo;
  totalCount: number;
}

export interface IdpEdge {
  cursor: string;
  node: Idp;
}

export interface PageInfo {
  endCursor?: string| null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string| null;
}

export interface User {
  authenticators: ((UserAuthenticator)[]) ;
  clientCertificates: ((UserClientCertificate)[]) ;
  emails: ((UserEmail)[]) ;
  firstName?: string| null;
  groups: ((GroupMemberEdge)[]) ;
  id: string;
  incomingRelations: ((UserRelation)[]) ;
  lastName?: string| null;
  name?: string| null;
  nationalId?: string| null;
  relations: ((UserRelation)[]) ;
}
export interface UserEmailsArgs {
  includeUnconfirmed: boolean;
}

export interface UserAuthenticator {
  activatedAt: DateTimeScalar;
  deviceId: string;
  id: string;
  lastAuthentication?: DateTimeScalar| null;
  legacyPin: boolean;
  name: string;
}

export interface UserClientCertificate {
  certificateThumbprint: string;
  createdAt: DateTimeScalar;
  deviceInformation?: string| null;
  displayName: string;
  lastUsed: DateTimeScalar;
}

export interface UserEmail {
  confirmed: boolean;
  use: EmailUse;
  value: string;
}

export enum EmailUse {
  PRIVATE = "PRIVATE",
  PROFESSIONAL = "PROFESSIONAL",
  SKOLID_INTERNAL = "SKOLID_INTERNAL",
}

export interface GroupMemberEdge {
  createdAt: DateTimeScalar;
  expiresAt?: DateTimeScalar| null;
  group?: Group| null;
  groupId?: string| null;
  organization?: Organization| null;
  organizationId?: string| null;
  relations?: ((GroupMemberRelation| null)[]) | null;
  role: GroupMemberRole;
  updatedAt: DateTimeScalar;
  user?: User| null;
  userId?: string| null;
}

export interface Group {
  children?: ((Group| null)[]) | null;
  createdAt: DateTimeScalar;
  expiresAt?: DateTimeScalar| null;
  id?: string| null;
  members?: ((GroupMemberEdge| null)[]) | null;
  name?: string| null;
  organization?: Organization| null;
  organizationId?: string| null;
  parent?: Group| null;
  parentId?: string| null;
}

export interface GroupMemberRelation {
  groupId?: string| null;
  groupMember?: GroupMemberEdge| null;
  organizationId?: string| null;
  relationDescription?: string| null;
  targetUser?: User| null;
  targetUserId?: string| null;
  userId?: string| null;
}

export enum GroupMemberRole {
  ADMIN = "ADMIN",
  ISSUER = "ISSUER",
  USER = "USER",
}

export interface UserRelation {
  from: User;
  role: UserRelationRole;
  to: User;
}

export interface MutationAddEmailsArgs {
  input: AddEmailsInput;
}
export interface MutationAddRelationsArgs {
  input: AddRelationsInput;
}
export interface MutationCreateIdpArgs {
  input: CreateIdpInput;
}
export interface MutationCreateIdpFromMetadataArgs {
  input: CreateIdpFromMetadataInput;
}
export interface MutationCreateUsersArgs {
  input: CreateUsersInput;
}
export interface MutationDeleteIdpArgs {
  id: string;
}
export interface MutationGenerateOnboardingTokenArgs {
  input: GenerateOnboardingTokenInput;
}
export interface MutationRemoveEmailsArgs {
  input: RemoveEmailsInput;
}
export interface MutationRemoveRelationsArgs {
  input: RemoveRelationsInput;
}
export interface MutationUpdateIdpArgs {
  input: UpdateIdpInput;
}
export interface MutationUpdateIdpFromMetadataArgs {
  input: UpdateIdpFromMetadataInput;
}

export interface AddEmailsInput {
  emails: ((EmailInput)[]) ;
  silent?: boolean| null;
  userId: string;
}

export interface EmailInput {
  confirmed?: boolean| null;
  use: EmailUse;
  value: string;
}

export type AddEmailsResultUnion = AddEmailsSuccess | AddEmailsError;

export interface AddEmailsSuccess {
  user: User;
}

export interface AddEmailsError {
  errorMessage: string;
  errors: ((EmailInputError)[]) ;
}

export interface MutationError {
  errorMessage: string;
}

export interface EmailInputError {
  errorCode: EmailInputErrorCode;
  errorMessage: string;
  index: PaginationAmountScalar;
}

export enum EmailInputErrorCode {
  INVALID_INPUT = "INVALID_INPUT",
  ALREADY_TAKEN = "ALREADY_TAKEN",
}

export interface AddRelationsInput {
  toUsers: ((RelationInput)[]) ;
  userId: string;
}

export interface RelationInput {
  role: UserRelationRole;
  userId: string;
}

export interface AddRelationsResult {
  user: User;
}

export interface CreateIdpInput {
  claimMappings?: ((IdpClaimMappingInput| null)[]) | null;
  id?: string| null;
  name: string;
  organizationId: string;
  roles?: ((SchoolRelationRole)[]) | null;
  samlConfig?: IdpSamlConfigInput| null;
}

export interface IdpClaimMappingInput {
  set?: SetIdpClaimMappingInput| null;
  transfer?: TransferIdpClaimMappingInput| null;
}

export interface SetIdpClaimMappingInput {
  target: string;
  value: string;
}

export interface TransferIdpClaimMappingInput {
  matchRegex?: string| null;
  source: string;
  target: string;
  targetValueSubstitution?: string| null;
}

export interface IdpSamlConfigInput {
  certificates?: ((IdpSamlCertificateInput| null)[]) | null;
  entityId?: string| null;
  localSigningCertificateThumbprintHint?: string| null;
  signAuthnRequest: boolean;
  signLogoutRequest: boolean;
  singleLogoutServiceBinding: string;
  singleLogoutServiceUrl?: string| null;
  singleSignOnServiceBinding: string;
  singleSignOnServiceUrl?: string| null;
}

export interface IdpSamlCertificateInput {
  data?: string| null;
}

export type CreateIdpResultUnion = CreateIdpSuccess | CreateIdpError;

export interface CreateIdpSuccess {
  idp: Idp;
}

export interface CreateIdpError {
  code: CreateIdpErrorCode;
  errorMessage: string;
}

export enum CreateIdpErrorCode {
  CONFLICT = "CONFLICT",
}

export interface CreateIdpFromMetadataInput {
  id: string;
  metadata: string;
  name: string;
  organizationId: string;
  roles?: ((SchoolRelationRole)[]) | null;
}

export type CreateIdpFromMetadataResultUnion = CreateIdpFromMetadataSuccess | CreateIdpFromMetadataError;

export interface CreateIdpFromMetadataSuccess {
  idp: Idp;
}

export interface CreateIdpFromMetadataError {
  code: CreateIdpFromMetadataErrorCode;
  errorMessage: string;
}

export enum CreateIdpFromMetadataErrorCode {
  METADATA_ERROR = "METADATA_ERROR",
  CONFLICT = "CONFLICT",
}

export interface CreateUsersInput {
  organizationId: string;
  users: ((UserInput)[]) ;
}

export interface UserInput {
  emails?: ((EmailInput)[]) | null;
  firstName?: string| null;
  lastName?: string| null;
  relations?: ((RelationInput)[]) | null;
}

export type CreateUsersResultUnion = CreateUsersSuccess | CreateUsersError;

export interface CreateUsersSuccess {
  users: ((User)[]) ;
}

export interface CreateUsersError {
  userErrors: ((UserInputError)[]) ;
}

export interface UserInputError {
  emailErrors: ((EmailInputError)[]) ;
  index: PaginationAmountScalar;
}

export type DeleteIdpResultUnion = DeleteIdpSuccess | DeleteIdpError;

export interface DeleteIdpSuccess {
  idpId: string;
}

export interface DeleteIdpError {
  errorMessage: string;
}

export interface GenerateOnboardingTokenInput {
  email?: string| null;
  userId: string;
}

export interface GenerateOnboardingTokenResult {
  token?: string| null;
}

export interface RemoveEmailsInput {
  emails: ((string)[]) ;
  userId: string;
}

export interface RemoveEmailsResult {
  user: User;
}

export interface RemoveRelationsInput {
  toUserIds: ((string)[]) ;
  userId: string;
}

export interface RemoveRelationsResult {
  user: User;
}

export interface UpdateIdpInput {
  claimMappings?: ((IdpClaimMappingInput| null)[]) | null;
  id: string;
  name: string;
  organizationId: string;
  roles?: ((SchoolRelationRole)[]) | null;
  samlConfig?: IdpSamlConfigInput| null;
}

export type UpdateIdpResultUnion = UpdateIdpSuccess | UpdateIdpError;

export interface UpdateIdpSuccess {
  idp: Idp;
}

export interface UpdateIdpError {
  errorMessage: string;
}

export interface UpdateIdpFromMetadataInput {
  id: string;
  metadata: string;
}

export type UpdateIdpFromMetadataResultUnion = UpdateIdpFromMetadataSuccess | UpdateIdpFromMetadataError;

export interface UpdateIdpFromMetadataSuccess {
  idp: Idp;
}

export interface UpdateIdpFromMetadataError {
  errorMessage: string;
}

export interface SetIdpClaimMapping {
  target: string;
  value: string;
}

export interface TransferIdpClaimMapping {
  matchRegex?: string| null;
  source: string;
  target: string;
  targetValueSubstitution?: string| null;
}

export type MultiplierPathScalar<T = any> = T;

export const strippers = {

  AddEmailsInput: (inp: AddEmailsInput): AddEmailsInput => {
    const obj: Partial<AddEmailsInput> = {};

    obj.emails =  inp.emails.map(v =>  strippers.EmailInput(v));
    obj.silent = inp.silent;
    obj.userId = inp.userId;

    return obj as AddEmailsInput;
  },

  EmailInput: (inp: EmailInput): EmailInput => {
    const obj: Partial<EmailInput> = {};

    obj.confirmed = inp.confirmed;
    obj.use = inp.use;
    obj.value = inp.value;

    return obj as EmailInput;
  },

  AddRelationsInput: (inp: AddRelationsInput): AddRelationsInput => {
    const obj: Partial<AddRelationsInput> = {};

    obj.toUsers =  inp.toUsers.map(v =>  strippers.RelationInput(v));
    obj.userId = inp.userId;

    return obj as AddRelationsInput;
  },

  RelationInput: (inp: RelationInput): RelationInput => {
    const obj: Partial<RelationInput> = {};

    obj.role = inp.role;
    obj.userId = inp.userId;

    return obj as RelationInput;
  },

  CreateIdpInput: (inp: CreateIdpInput): CreateIdpInput => {
    const obj: Partial<CreateIdpInput> = {};

    obj.claimMappings = inp.claimMappings ? inp.claimMappings.map(v => v ? strippers.IdpClaimMappingInput(v) : v) : undefined;
    obj.id = inp.id;
    obj.name = inp.name;
    obj.organizationId = inp.organizationId;
    obj.roles = inp.roles;
    obj.samlConfig = inp.samlConfig ? strippers.IdpSamlConfigInput(inp.samlConfig) : inp.samlConfig;

    return obj as CreateIdpInput;
  },

  IdpClaimMappingInput: (inp: IdpClaimMappingInput): IdpClaimMappingInput => {
    const obj: Partial<IdpClaimMappingInput> = {};

    obj.set = inp.set ? strippers.SetIdpClaimMappingInput(inp.set) : inp.set;
    obj.transfer = inp.transfer ? strippers.TransferIdpClaimMappingInput(inp.transfer) : inp.transfer;

    return obj as IdpClaimMappingInput;
  },

  SetIdpClaimMappingInput: (inp: SetIdpClaimMappingInput): SetIdpClaimMappingInput => {
    const obj: Partial<SetIdpClaimMappingInput> = {};

    obj.target = inp.target;
    obj.value = inp.value;

    return obj as SetIdpClaimMappingInput;
  },

  TransferIdpClaimMappingInput: (inp: TransferIdpClaimMappingInput): TransferIdpClaimMappingInput => {
    const obj: Partial<TransferIdpClaimMappingInput> = {};

    obj.matchRegex = inp.matchRegex;
    obj.source = inp.source;
    obj.target = inp.target;
    obj.targetValueSubstitution = inp.targetValueSubstitution;

    return obj as TransferIdpClaimMappingInput;
  },

  IdpSamlConfigInput: (inp: IdpSamlConfigInput): IdpSamlConfigInput => {
    const obj: Partial<IdpSamlConfigInput> = {};

    obj.certificates = inp.certificates ? inp.certificates.map(v => v ? strippers.IdpSamlCertificateInput(v) : v) : undefined;
    obj.entityId = inp.entityId;
    obj.localSigningCertificateThumbprintHint = inp.localSigningCertificateThumbprintHint;
    obj.signAuthnRequest = inp.signAuthnRequest;
    obj.signLogoutRequest = inp.signLogoutRequest;
    obj.singleLogoutServiceBinding = inp.singleLogoutServiceBinding;
    obj.singleLogoutServiceUrl = inp.singleLogoutServiceUrl;
    obj.singleSignOnServiceBinding = inp.singleSignOnServiceBinding;
    obj.singleSignOnServiceUrl = inp.singleSignOnServiceUrl;

    return obj as IdpSamlConfigInput;
  },

  IdpSamlCertificateInput: (inp: IdpSamlCertificateInput): IdpSamlCertificateInput => {
    const obj: Partial<IdpSamlCertificateInput> = {};

    obj.data = inp.data;

    return obj as IdpSamlCertificateInput;
  },

  CreateIdpFromMetadataInput: (inp: CreateIdpFromMetadataInput): CreateIdpFromMetadataInput => {
    const obj: Partial<CreateIdpFromMetadataInput> = {};

    obj.id = inp.id;
    obj.metadata = inp.metadata;
    obj.name = inp.name;
    obj.organizationId = inp.organizationId;
    obj.roles = inp.roles;

    return obj as CreateIdpFromMetadataInput;
  },

  CreateUsersInput: (inp: CreateUsersInput): CreateUsersInput => {
    const obj: Partial<CreateUsersInput> = {};

    obj.organizationId = inp.organizationId;
    obj.users =  inp.users.map(v =>  strippers.UserInput(v));

    return obj as CreateUsersInput;
  },

  UserInput: (inp: UserInput): UserInput => {
    const obj: Partial<UserInput> = {};

    obj.emails = inp.emails ? inp.emails.map(v =>  strippers.EmailInput(v)) : undefined;
    obj.firstName = inp.firstName;
    obj.lastName = inp.lastName;
    obj.relations = inp.relations ? inp.relations.map(v =>  strippers.RelationInput(v)) : undefined;

    return obj as UserInput;
  },

  GenerateOnboardingTokenInput: (inp: GenerateOnboardingTokenInput): GenerateOnboardingTokenInput => {
    const obj: Partial<GenerateOnboardingTokenInput> = {};

    obj.email = inp.email;
    obj.userId = inp.userId;

    return obj as GenerateOnboardingTokenInput;
  },

  RemoveEmailsInput: (inp: RemoveEmailsInput): RemoveEmailsInput => {
    const obj: Partial<RemoveEmailsInput> = {};

    obj.emails = inp.emails;
    obj.userId = inp.userId;

    return obj as RemoveEmailsInput;
  },

  RemoveRelationsInput: (inp: RemoveRelationsInput): RemoveRelationsInput => {
    const obj: Partial<RemoveRelationsInput> = {};

    obj.toUserIds = inp.toUserIds;
    obj.userId = inp.userId;

    return obj as RemoveRelationsInput;
  },

  UpdateIdpInput: (inp: UpdateIdpInput): UpdateIdpInput => {
    const obj: Partial<UpdateIdpInput> = {};

    obj.claimMappings = inp.claimMappings ? inp.claimMappings.map(v => v ? strippers.IdpClaimMappingInput(v) : v) : undefined;
    obj.id = inp.id;
    obj.name = inp.name;
    obj.organizationId = inp.organizationId;
    obj.roles = inp.roles;
    obj.samlConfig = inp.samlConfig ? strippers.IdpSamlConfigInput(inp.samlConfig) : inp.samlConfig;

    return obj as UpdateIdpInput;
  },

  UpdateIdpFromMetadataInput: (inp: UpdateIdpFromMetadataInput): UpdateIdpFromMetadataInput => {
    const obj: Partial<UpdateIdpFromMetadataInput> = {};

    obj.id = inp.id;
    obj.metadata = inp.metadata;

    return obj as UpdateIdpFromMetadataInput;
  },

}

