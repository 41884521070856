import React from "react";
import Select from "react-select";

export const SelectSamlBinding = (props: {
  binding: string;
  onChange: (newBinding: string) => void;
  disabled?: boolean;
}) => {
  return (
    <Select
      options={[
        "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST",
        "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect",
      ].map((x) => ({
        label: x,
        value: x,
      }))}
      value={{ value: props.binding, label: props.binding }}
      isDisabled={props.disabled}
      onChange={(option: any) => props.onChange(option.value)}
    />
  );
};
