import * as React from "react";
import gql from "graphql-tag";
import { FindSubjects, FindSubjects_searchClients_nodes, FindSubjects_searchUsers_nodes } from "../admin-gql";
// tslint:disable-next-line:no-submodule-imports
import AsyncSelect from "react-select/lib/Async";
import { ApolloClient } from "apollo-client";
import classNames from "classnames";
import { AdminClientConsumer } from "../App/ClientProvider";

const findSubjectsGql = gql`
  query FindSubjects($filter: String!) {
    searchUsers(filter: $filter) {
      nodes {
        id
        name
        nationalId
        organization {
          id
          name
        }
      }
    }

    searchClients(filter: $filter) {
      nodes {
        id
        displayName
      }
    }
  }
`;

type ClientSubject = FindSubjects_searchClients_nodes & { type: "client" };
type UserSubject = FindSubjects_searchUsers_nodes & { type: "user" };

export type Subject = ClientSubject | UserSubject;

interface SelectSubjectState {
  loading?: boolean;
}

export interface SelectSubjectProps {
  onSelect: (subject: Subject) => void;
  showSelected?: boolean;
  disabled?: boolean;
}

export class SelectSubject extends React.Component<SelectSubjectProps, SelectSubjectState> {
  public state: SelectSubjectState = {};

  public render() {
    return (
      <AdminClientConsumer>
        {({ adminClient: client }) => (
          <AsyncSelect
            openMenuOnFocus={false}
            openMenuOnClick={false}
            value={this.props.showSelected ? undefined : null}
            noOptionsMessage={() => "No subjects"}
            components={{
              DropdownIndicator: null,
              Option: (props: any) => <Option {...props} />
            }}
            loadOptions={(inputValue: string) => this.searchSubjects(inputValue, client)}
            onChange={(option: any) => option.value && this.props.onSelect(option.value)}
            isLoading={this.state.loading}
            isDisabled={this.props.disabled}
            placeholder="Search..."
            formatGroupLabel={(group: any) => <div>{group.label}</div>}
          />
        )}
      </AdminClientConsumer>
    );
  }

  private searchSubjects = async (filter: string, client: ApolloClient<any>) => {
    this.setState({ loading: true });
    const response = await client.query<FindSubjects>({
      query: findSubjectsGql,
      variables: { filter }
    });

    this.setState({ loading: false });

    return [
      {
        label: "Users",
        options: response.data.searchUsers.nodes.map<{ value: UserSubject }>(x => ({
          value: { ...x, type: "user" },
          label: `${x.name} (${x.organization.name})`
        }))
      },
      {
        label: "Clients",
        options: response.data.searchClients.nodes.map<{ value: ClientSubject }>(x => ({
          value: { ...x, type: "client" },
          label: x.displayName
        }))
      }
    ];
  };
}

export const Option = (props: any) => {
  const option: ClientSubject | UserSubject = props.value;

  return (
    <div
      className={classNames("px-3 py-2 d-flex justify-content-between", {
        "bg-secondary": props.isFocused
      })}
      onClick={() => props.selectOption(props)}
    >
      {option.type === "client" ? (
        <>
          <div>{option.displayName}</div>
          <div className="text-black-50">{option.id} </div>
        </>
      ) : (
        <>
          <div>{option.name} ({option.organization.name})</div>
          <div className="text-black-50">{option.nationalId} </div>
        </>
      )}
    </div>
  );
};
