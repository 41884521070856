import React, { useState } from "react";
import { Button } from "../Common/Button";
import { useDebounced } from "../Common/hooks";

export const ShowModalButton = React.memo(
  (props: { text: string; children: (shown: boolean, hide: () => void) => React.ReactNode }) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    // Render child dialog a little longer than it is open to allow a fade effect
    const showDialogDelayed = useDebounced(showDialog, 1000);

    return (
      <>
        <Button onClick={() => setShowDialog(true)}>{props.text}</Button>
        {showDialog || showDialogDelayed ? props.children(showDialog, () => setShowDialog(false)) : null}
      </>
    );
  }
);
