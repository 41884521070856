import * as React from "react";
import permGql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { AsyncOperation } from "../types";
import { SaveResourceType, GetDomainSettings_domain_resourceTypes } from "../permissons-gql";
import { SaveModal } from "./SaveModal";
import { ExecutionResult } from "graphql";

interface SaveResourceTypeModalProps {
  domainId: string;
  isOpen: boolean;
  onClose: () => void;
  onResourceTypeSaved: () => Promise<any>;
  apolloClient: ApolloClient<any>;
  initialResourceType?: GetDomainSettings_domain_resourceTypes;
}

interface SaveResourceTypeModalState {
  id: string;
  displayName: string;
  saveOperation: AsyncOperation<any>;
  prevInitialResourceType?: GetDomainSettings_domain_resourceTypes;
}

export class SaveResourceTypeModal extends React.Component<
  SaveResourceTypeModalProps,
  SaveResourceTypeModalState
> {
  public static getDerivedStateFromProps(
    props: SaveResourceTypeModalProps,
    state: SaveResourceTypeModalState
  ) {
    if (props.initialResourceType !== state.prevInitialResourceType) {
      return {
        id: props.initialResourceType ? props.initialResourceType.id : "",
        displayName: props.initialResourceType ? props.initialResourceType.displayName || "" : "",
        prevInitialResourceType: props.initialResourceType
      };
    }
    return null;
  }

  constructor(props: SaveResourceTypeModalProps) {
    super(props);

    this.state = {
      id: "",
      displayName: "",
      saveOperation: {}
    };
  }

  public render() {
    return (
      <SaveModal
        isOpen={this.props.isOpen}
        entityName="resource type"
        isEntityValid={this.state.id.length > 0 && this.state.displayName.length > 0}
        new={!this.props.initialResourceType}
        onCancel={this.handleCancel}
        onSave={this.handleSaveResourceType}
        saveOperation={this.state.saveOperation}
      >
        <div className="form-group">
          <label className="form-label">Id</label>
          <input
            type="text"
            className="form-control"
            value={this.state.id}
            readOnly={!!this.props.initialResourceType}
            disabled={this.state.saveOperation.running}
            onChange={ev => this.setState({ id: ev.currentTarget.value })}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            value={this.state.displayName}
            disabled={this.state.saveOperation.running}
            onChange={ev => this.setState({ displayName: ev.currentTarget.value })}
          />
        </div>
      </SaveModal>
    );
  }

  private handleCancel = () => {
    this.setState({ displayName: "", id: "", saveOperation: {} });
    this.props.onClose();
  };

  private handleSaveResourceType = async () => {
    this.setState({ saveOperation: { running: true } });

    try {
      const response: ExecutionResult<SaveResourceType> = await this.props.apolloClient.mutate({
        mutation: permGql`mutation SaveResourceType($input: SaveResourceTypeInput!) {
            saveResourceType(input: $input) {
              globalId
              id
              displayName
            }
          }`,
        variables: {
          input: {
            domainId: this.props.domainId,
            id: this.state.id,
            displayName: this.state.displayName
          }
        }
      });

      await this.props.onResourceTypeSaved();

      this.setState({
        saveOperation: { data: response.data },
        id: "",
        displayName: ""
      });

      this.props.onClose();
    } catch (error) {
      console.error("Failed to save resource type: ", error);
      this.setState({ saveOperation: { error: "Failed to save resource type" } });
    }
  };
}
