import { Input } from "@ist-group-private-scope/skolid-client-components";
import * as React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";

export type CertificateUse = "ANY" | "ENCRYPTION" | "SIGNATURE";

export type Certificate = { data: string | null; use: CertificateUse | null; new?: boolean };

const useValues = ["ANY", "ENCRYPTION", "SIGNATURE"] as const;

export const CertificateEditor = (props: {
  certificates: Certificate[];
  disabled?: boolean;
  updateCertificates: (certificates: Certificate[]) => void;
}) => (
  <>
    {props.certificates.map((certificate, index) => {
      return (
        <div className="form-group" key={index}>
          <Input
            placeholder="Certificate"
            value={certificate.data}
            readonly={!certificate.new}
            multiline
            rows={5}
            onChange={text =>
              props.updateCertificates(props.certificates.map((x, i) => (i === index ? { ...x, data: text } : x)))
            }
            disabled={props.disabled}
          >
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>{certificate.use}</DropdownToggle>
              <DropdownMenu>
                {useValues.map(use => (
                  <DropdownItem
                    key={use}
                    onClick={() =>
                      props.updateCertificates(props.certificates.map((x, i) => (i === index ? { ...x, use } : x)))
                    }
                  >
                    {use}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={() => props.updateCertificates(props.certificates.filter((x, i) => i !== index))}
              disabled={props.disabled}
            >
              <i className="fa fa-trash" />
            </button>
          </Input>
        </div>
      );
    })}
    <button
      type="button"
      className="btn btn-link p-0"
      onClick={() => props.updateCertificates(props.certificates.concat([{ new: true, data: "", use: "SIGNATURE" }]))}
      disabled={
        (props.certificates.length > 0 && props.certificates[props.certificates.length - 1].data === "") ||
        props.disabled
      }
    >
      Add certificate
    </button>
  </>
);
