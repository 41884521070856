import React from "react";

export const useDebounced = <TValue extends any>(val: TValue, delay: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(val);

  React.useEffect(() => {
    const timoutId = setTimeout(() => {
      setDebouncedValue(val);
    }, delay);

    return () => {
      clearTimeout(timoutId);
    };
  }, [val, delay]);

  return debouncedValue;
};
