import { useAdmin2Client, FormattedDateTime } from "@ist-group-private-scope/skolid-client-components";
import admin2Gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo";
import {
  MoreUserDetails,
  MoreUserDetailsVariables,
  MoreUserDetails_user_relations,
  MoreUserDetails_user_incomingRelations,
  MoreUserDetails_user_clientCertificates,
  MoreUserDetails_user_authenticators,
} from "../admin-gql-2";
import { Spinner } from "../Common/Spinner";
import { FormGroup } from "./Components/FormGroup";
import { Link } from "react-router-dom";
import { userRoute, generateUserPath } from "../routes";

export const UserMoreDetails = (props: { userId: string }) => {
  const client = useAdmin2Client();

  const userQuery = useQuery<MoreUserDetails, MoreUserDetailsVariables>(moreQueryGql, {
    variables: {
      id: props.userId,
    },
    client,
  });

  if (userQuery.loading) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  if (userQuery.error) {
    return <div className="alert alert-error">Something went wrong...</div>;
  }

  const user = userQuery.data?.user;
  if (!user) {
    return <>User not found</>;
  }

  return (
    <div className="box">
      <div className="box-header">More details</div>
      <div className="box-body">
        <RelationsList relations={user.relations} />
        <IncomingRelationsList relations={user.incomingRelations} />
        <ClientCertificateList certificates={user.clientCertificates} />
        <AuthenticatorList authenticators={user.authenticators} />
      </div>
    </div>
  );
};

export const ClientCertificateList = ({
  certificates: certs,
}: {
  certificates: MoreUserDetails_user_clientCertificates[];
}) => {
  return (
    <FormGroup header="Client Certificates (Chromebooks)">
      {certs.length === 0 ? (
        <em>No certificates</em>
      ) : (
        <table className="table table-hover table-sm table-fixed mb-0 mt-3">
          <thead>
            <tr>
              <th>Thumbprint</th>
              <th>Display Name</th>
              <th>Last Used</th>
              <th>Created At</th>
            </tr>
          </thead>

          <tbody>
            {certs.map((cert, index) => (
              <tr key={index}>
                <td className="truncate" title={cert.certificateThumbprint}>
                  {cert.certificateThumbprint}
                </td>
                <td className="truncate" title={"Device info: " + (cert.deviceInformation ?? "<none>")}>
                  {cert.displayName}
                </td>
                <td className="truncate">
                  <FormattedDateTime date={cert.lastUsed}></FormattedDateTime>
                </td>
                <td className="truncate">
                  <FormattedDateTime date={cert.createdAt}></FormattedDateTime>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FormGroup>
  );
};

export const AuthenticatorList = ({
  authenticators: authenticators,
}: {
  authenticators: MoreUserDetails_user_authenticators[];
}) => {
  return (
    <FormGroup header="Authenticators (Mobile SkolID App)">
      {authenticators.length === 0 ? (
        <em>No authenticators</em>
      ) : (
        <table className="table table-hover table-sm table-fixed mb-0 mt-3">
          <thead>
            <tr>
              <th>Id</th>
              <th>Device id</th>
              <th style={{ width: 100 }}>Legacy</th>
              <th>Last Authentication</th>
              <th>Activated</th>
            </tr>
          </thead>

          <tbody>
            {authenticators.map((authenticator, index) => (
              <tr key={index}>
                <td className="truncate" title={authenticator.id}>
                  {authenticator.id}
                </td>
                <td className="truncate" title={authenticator.deviceId}>
                  {authenticator.deviceId}
                </td>
                <td className="truncate">{authenticator.legacyPin ? "Legacy" : ""}</td>
                <td className="truncate">
                  <FormattedDateTime date={authenticator.lastAuthentication}></FormattedDateTime>
                </td>
                <td className="truncate">
                  <FormattedDateTime date={authenticator.activatedAt}></FormattedDateTime>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FormGroup>
  );
};

export const RelationsList = ({ relations }: { relations: MoreUserDetails_user_relations[] }) => {
  return (
    <FormGroup header="Relations">
      <p>
        Relations <strong>from</strong> this user to other users.
      </p>
      {relations.length === 0 ? (
        <em>No relations</em>
      ) : (
        <table className="table table-hover table-sm table-fixed mb-0 mt-3">
          <thead>
            <tr>
              <th>User id</th>
              <th>Name</th>
              <th>Role</th>
            </tr>
          </thead>

          <tbody>
            {relations.map((relation, index) => (
              <tr key={index}>
                <td className="truncate">{relation && relation.to.id}</td>
                <td className="truncate">
                  <UserLink userId={relation.to.id}>{relation.to.name}</UserLink>
                </td>
                <td className="truncate">{relation.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FormGroup>
  );
};

export const IncomingRelationsList = ({ relations }: { relations: MoreUserDetails_user_incomingRelations[] }) => {
  return (
    <FormGroup header="Incoming Relations">
      <p>
        Relations <strong>to</strong> this user from other users.
      </p>
      {relations.length === 0 ? (
        <em>No incoming relations</em>
      ) : (
        <table className="table table-hover table-sm table-fixed mb-0 mt-3">
          <thead>
            <tr>
              <th>User id</th>
              <th>Name</th>
              <th>Role</th>
            </tr>
          </thead>

          <tbody>
            {relations.map((relation, index) => (
              <tr key={index}>
                <td className="truncate">{relation && relation.from.id}</td>
                <td className="truncate">
                  <UserLink userId={relation.from.id}>{relation.from.name}</UserLink>
                </td>
                <td className="truncate">{relation.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FormGroup>
  );
};

export const UserLink = (props: { userId: string; children: React.ReactNode }) => (
  <Link to={generateUserPath({ id: props.userId })}>{props.children ? props.children : "(no name)"}</Link>
);

const moreQueryGql = admin2Gql`
  query MoreUserDetails($id: String!) {
    user(id: $id) {
      id
      relations {
        role
        to {
          id
          name
        }
      }
      incomingRelations {
        role
        from {
          id
          name
        }
      }
      authenticators {
        activatedAt
        deviceId
        id
        lastAuthentication
        legacyPin
        name
      }
      clientCertificates {
        certificateThumbprint
        createdAt
        deviceInformation
        displayName
        lastUsed
      }
    }
  }
`;
