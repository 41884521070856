import * as React from 'react';
import * as oidc from 'oidc-client';
import { Link, NavLink } from 'react-router-dom';
import * as routes from '../routes';
import skolidLogo from '../images/skolid.png';
import en from '../images/en.png';
import sv from '../images/sv.png';
import de from '../images/de.png';
import no from '../images/no.png';
import {
  Collapse,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export interface HeaderProps {
  user?: oidc.User | null;
  onLoginClick: () => void;
  onLogoutClick: () => void;
}

const languageLogo: { [key: string]: any } = {
  en: { logo: en },
  sv: { logo: sv },
  de: { logo: de },
  nb: { logo: no },
};

export class Header extends React.Component<
  HeaderProps,
  { menuOpen: boolean }
> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = { menuOpen: false };
  }

  public render() {
    const props = this.props;
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white mb-5">
        <div className="container">
          <Link className="navbar-brand p-0" to="/">
            <img src={skolidLogo} style={{ height: 35 }} alt="SkolID Logo" />{' '}
            <h3
              style={{
                display: 'inline',
                verticalAlign: 'middle',
                margin: '0 15px',
              }}
            >
              Superadmin
            </h3>
          </Link>

          {props.user ? (
            <>
              <NavbarToggler
                className="mb-3 mt-3"
                onClick={() =>
                  this.setState({ menuOpen: !this.state.menuOpen })
                }
              />

              <Collapse navbar isOpen={this.state.menuOpen}>
                <ul className="navbar-nav mr-auto" onClick={this.hideMenu}>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={routes.clientsPath}
                    >
                      Clients
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={routes.usersPath}
                    >
                      Users
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={routes.scopesPath}
                    >
                      Scopes
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={routes.permDomains}
                    >
                      Permissions
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={routes.organizationsPath}
                    >
                      Organizations
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={routes.idpsPath}
                    >
                      Idps
                    </NavLink>
                  </li>
                </ul>

                <ul className="navbar-nav ml-auto">
                  <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    <LanguageSwitcher />
                  </div>

                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      {props.user.profile.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={props.onLogoutClick}>
                        Logout
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </ul>
              </Collapse>
            </>
          ) : (
            <>
              {
                // Hack to get hight right
              }
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    &nbsp;
                  </a>
                </li>

                <li
                  role="separator"
                  className="nav-item dropdown-divider d-lg-none"
                />
              </ul>
            </>
          )}
        </div>
      </nav>
    );
  }

  private hideMenu = () => {
    this.setState({ menuOpen: false });
  };
}

const DropdownItemAction = (props: { children: any; onClick: () => void }) => {
  return (
    <DropdownItem
      onClick={() => {
        props.onClick();
      }}
    >
      {props.children}
    </DropdownItem>
  );
};

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  var currentLng = i18n.resolvedLanguage;

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <img
          src={languageLogo[currentLng]?.logo}
          style={{ height: 11, marginBottom: 2, marginRight: 5 }}
          alt={currentLng}
        />
        {i18next.t('header.' + currentLng)}
      </DropdownToggle>
      <DropdownMenu>
        {Object.keys(languageLogo).map((lng, index) => (
          <DropdownItemAction
            key={index}
            onClick={() => {
              i18n.changeLanguage(lng);
            }}
          >
            <img
              src={languageLogo[lng]?.logo}
              style={{ height: 11, marginBottom: 2, marginRight: 5 }}
              alt={lng}
            />
            {i18next.t('header.' + lng)}
          </DropdownItemAction>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
