import * as React from "react";
import { Query } from "react-apollo";
import permGql from "graphql-tag";
import {
  GetRoleAssignments,
  GetRoleAssignmentsVariables,
  GetRoleAssignments_domain_roleAssignments_nodes
} from "../permissons-gql";
import { LoadingScreen } from "../App/LoadingScreen";
import * as routes from "../routes";
import { RouteComponentProps } from "react-router-dom";
import { roleAssignmentFragmentGql } from "./fragments";
import { UnassignRoleMutation, unassignRoleGql } from "./commonGql";
import { ConfirmModal } from "./DeleteModal";
import { AssignRoleModal } from "./AssignRoleModal";
import { TypedQuery } from "../types";

const fetchRoleAssignmentsGql = permGql`
  query GetRoleAssignments($domainId: String!) {
    domain(id: $domainId) {
      globalId
      id
      displayName
      roleAssignments(withoutResources: false) {
        nodes {
          ...RoleAssignment
          resource {
            globalId
            id
            displayName
            type {
              globalId
              id
              displayName
            }
          }
        }
      }
    }
  }

  ${roleAssignmentFragmentGql}
`;

const DomainRoleAssignmentsQuery: TypedQuery<GetRoleAssignments, GetRoleAssignmentsVariables> = Query;

interface RoleAssignmentsViewState {
  removeRoleAssignment?: GetRoleAssignments_domain_roleAssignments_nodes | null;
  showAssignRole?: boolean | null;
}

export class RoleAssignmentsView extends React.Component<
  RouteComponentProps<routes.PermDomainRouteParams>,
  RoleAssignmentsViewState
> {
  public state: RoleAssignmentsViewState = {};

  public render() {
    const props = this.props;
    const domainId = props.match.params.domainId;
    const fetchRoleAssignmentsQueryProps = {
      query: fetchRoleAssignmentsGql,
      variables: { domainId }
    };

    return (
      <div className="flex-fill">
        <DomainRoleAssignmentsQuery
          {...fetchRoleAssignmentsQueryProps}
          fetchPolicy="cache-and-network"
        >
          {response => (
            <div className="box">
              <div className="box-header">Role assignments</div>
              <div className="box-body">
                <div className="text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.setState({ showAssignRole: true })}
                  >
                    Assign role
                  </button>
                </div>

                {response.loading ? <LoadingScreen /> : null}
                {response.error ? <div>Something went wrong!?</div> : null}
                {!response.loading && response.data && !response.data.domain ? (
                  <div>No such domain</div>
                ) : null}
                {response.data && response.data.domain ? (
                  <AssignmentsTable
                    roleAssignments={response.data!.domain!.roleAssignments.nodes}
                    onUnassign={assignment => {
                      this.setState({ removeRoleAssignment: assignment });
                    }}
                  />
                ) : null}
              </div>

              <UnassignRoleMutation mutation={unassignRoleGql}>
                {(mutation, mutationResult) => (
                  <ConfirmModal
                    isOpen={!!this.state.removeRoleAssignment}
                    onClose={() => this.setState({ removeRoleAssignment: null })}
                    operation={{ error: mutationResult.error, running: mutationResult.loading }}
                    operationName="Unassign"
                    onConfirm={async () => {
                      const assignment = this.state.removeRoleAssignment!;
                      await mutation({
                        variables: {
                          input: {
                            ...this.props.match.params,
                            resourceId: assignment.resource ? assignment.resource.id : undefined,
                            resourceTypeId: assignment.resource
                              ? assignment.resource.type.id
                              : undefined,
                            subjectType: assignment.subject.type,
                            subjectId: assignment.subject.id,
                            roleId: assignment.role.id
                          }
                        }
                      });
                      await response.refetch();
                      this.setState({ removeRoleAssignment: null });
                    }}
                  />
                )}
              </UnassignRoleMutation>

              <AssignRoleModal
                domainId={domainId}
                isOpen={!!this.state.showAssignRole}
                onClose={async (saved: any) => {
                  if (saved) {
                    await response.refetch();
                  }

                  this.setState({ showAssignRole: false });
                }}
              />
            </div>
          )}
        </DomainRoleAssignmentsQuery>
      </div>
    );
  }
}

export const AssignmentsTable = (props: {
  roleAssignments: GetRoleAssignments_domain_roleAssignments_nodes[];
  onUnassign: (assignment: GetRoleAssignments_domain_roleAssignments_nodes) => void;
}) => (
  <table className="table table-fixed">
    <thead>
      <tr>
        <th>Subject name (Organization)</th>
        <th style={{ width: 75 }}>Subject type</th>
        <th>Resource</th>
        <th>Resource Type</th>
        <th style={{ width: 120 }}>Role</th>
        <th style={{ width: 40 }} />
      </tr>
    </thead>
    <tbody>
      {props.roleAssignments.map(ass => (
        <tr key={ass.globalId}>
          <td className="truncate" title={"id: " + ass.subject.id + (ass.subject.organization ? ` (org: ${ass.subject.organization.id})` : null)}>
            {ass.subject.displayName || ass.subject.id} {ass.subject.organization ? <>({ass.subject.organization.name})</> : null}
          </td>
          <td className="truncate">{ass.subject.type}</td>
          <td className="truncate" title={"id: " + (ass.resource ? ass.resource.id : "")}>
            {ass.resource ? ass.resource.displayName || ass.resource.id : null}
          </td>
          <td className="truncate" title={"type-id: " + (ass.resource ? ass.resource.type.id : "")}>
            {ass.resource ? ass.resource.type.displayName || ass.resource.type.id : null}
          </td>
          <td className="truncate">{ass.role.displayName || ass.role.id}</td>
          <td className="text-right">
            <button className="no-btn" onClick={() => props.onUnassign(ass)}>
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
