import * as React from "react";
import permGql from "graphql-tag";
import { ApolloProvider, Query } from "react-apollo";
import { PermissionClientConsumer } from "../App/ClientProvider";
import { RouteComponentProps, Route, Switch } from "react-router";
import * as routes from "../routes";
import { ResourceScreen } from "./ResourceScreen";
import { ResourcesView } from "./ResourcesView";
import { NavLink } from "react-router-dom";
import { DomainSettingsScreen } from "./DomainSettingsScreen";
import { RoleAssignmentsView } from "./AssignmentsView";

export const DomainScreen = (
  props: RouteComponentProps<routes.PermDomainRouteParams>
) => (
  <PermissionClientConsumer>
    {({ permissionClient }) => (
      <ApolloProvider client={permissionClient}>
        <Switch>
          <Route
            path={routes.permDomainResourceRoute}
            component={ResourceScreen}
          />
          <Route path={routes.permDomainRoute}>
            <div className="flex-fill">
              <h1>
                Domain: <DomainName {...props} />
              </h1>
              <div className="row">
                <div className="col-2 mb-content">
                  <DomainMenu {...props} />
                </div>
                <div className="col">
                  <Switch>
                    <Route
                      path={routes.permDomainSettingsRoute}
                      component={DomainSettingsScreen}
                    />
                    <Route
                      path={routes.permDomainResourcesRoute}
                      component={ResourcesView}
                    />
                    <Route
                      path={routes.permDomainRoute}
                      component={RoleAssignmentsView}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </ApolloProvider>
    )}
  </PermissionClientConsumer>
);

const DomainMenu = (
  props: RouteComponentProps<routes.PermDomainRouteParams>
) => (
  <div className="nav flex-column nav-pills">
    <NavLink
      activeClassName="active"
      exact
      className="nav-link p-2"
      to={routes.generatePermDomainPath({
        domainId: props.match.params.domainId,
      })}
    >
      Role assignments
    </NavLink>
    <NavLink
      activeClassName="active"
      exact
      className="nav-link p-2"
      to={routes.generatePermDomainResourcesPath({
        domainId: props.match.params.domainId,
      })}
    >
      Resources
    </NavLink>
    <NavLink
      activeClassName="active"
      exact
      className="nav-link p-2"
      to={routes.generatePermDomainSettingsPath({
        domainId: props.match.params.domainId,
      })}
    >
      Settings
    </NavLink>
  </div>
);

const DomainName = (
  props: RouteComponentProps<routes.PermDomainRouteParams>
) => (
  <Query
    query={permGql`query GetDomainName($domainId: String!) { domain(id: $domainId) { globalId id displayName } }`}
    variables={props.match.params}
  >
    {(result: any) =>
      (result.data && result.data.domain && result.data.domain.displayName) ||
      null
    }
  </Query>
);
