import * as React from "react";
import permGql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { PermissionRole, AsyncOperation } from "../types";
import { CreateRole, CreateRoleVariables } from "../permissons-gql";
import { SaveModal } from "./SaveModal";
import { Checkbox } from "../Common/Checkbox";
import { ExecutionResult } from "graphql";

export interface SaveRoleModalProps {
  domainId: string;
  initialRole?: PermissionRole | null;
  isOpen: boolean;
  onClose: () => void;
  onRoleSaved: () => void;
  apolloClient: ApolloClient<any>;
}

interface SaveRoleModalState {
  id: string;
  displayName: string;
  highSecurity: boolean;
  saveOperation: AsyncOperation<CreateRole>;
  prevInitialRole?: PermissionRole | null;
}

export class SaveRoleModal extends React.Component<SaveRoleModalProps, SaveRoleModalState> {
  public static getDerivedStateFromProps(props: SaveRoleModalProps, state: SaveRoleModalState) {
    if (props.initialRole !== state.prevInitialRole) {
      return {
        id: props.initialRole ? props.initialRole.id : "",
        displayName: props.initialRole ? props.initialRole.displayName || "" : "",
        prevInitialRole: props.initialRole,
        highSecurity: props.initialRole ? props.initialRole.highSecurity : false
      };
    }
    return null;
  }

  constructor(props: SaveRoleModalProps) {
    super(props);

    this.state = {
      id: props.initialRole ? props.initialRole.id : "",
      displayName: props.initialRole ? props.initialRole.displayName || "" : "",
      highSecurity: false,
      saveOperation: {}
    };
  }

  public render() {
    return (
      <SaveModal
        isOpen={this.props.isOpen}
        entityName="role"
        isEntityValid={this.state.id.length > 0 && this.state.displayName.length > 0}
        new={!this.props.initialRole}
        onCancel={this.handleCancel}
        onSave={this.handleSaveRole}
        saveOperation={this.state.saveOperation}
      >
        <div className="form-group">
          <label className="form-label">Id</label>
          <input
            type="text"
            className="form-control"
            value={this.state.id}
            readOnly={!!this.props.initialRole}
            disabled={this.state.saveOperation.running}
            onChange={ev => this.setState({ id: ev.currentTarget.value })}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            value={this.state.displayName}
            disabled={this.state.saveOperation.running}
            onChange={ev => this.setState({ displayName: ev.currentTarget.value })}
          />
        </div>

        <Checkbox
          checked={this.state.highSecurity}
          disabled={this.state.saveOperation.running}
          label="High security"
          onChange={x => this.setState({ highSecurity: x })}
        />
      </SaveModal>
    );
  }

  private handleCancel = () => {
    this.setState({ displayName: "", id: "", saveOperation: {} });
    this.props.onClose();
  };

  private handleSaveRole = async () => {
    this.setState({ saveOperation: { running: true } });

    try {
      const response: ExecutionResult<CreateRole> = await this.props.apolloClient.mutate<
        CreateRole,
        CreateRoleVariables
      >({
        mutation: permGql`mutation CreateRole($input: SaveRoleInput!) {
            saveRole(input: $input) {
              globalId
              id
              displayName
              highSecurity
            }
          }`,
        variables: {
          input: {
            domainId: this.props.domainId,
            id: this.state.id,
            displayName: this.state.displayName,
            highSecurity: this.state.highSecurity
          }
        }
      });

      await this.props.onRoleSaved();

      this.setState({
        saveOperation: { data: response.data! },
        id: "",
        displayName: ""
      });

      this.props.onClose();
    } catch (error) {
      console.error("Failed to create new role: ", error);
      this.setState({ saveOperation: { error: "Failed to save role" } });
    }
  };
}
