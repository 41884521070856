import { useForm } from "@ist-group/react-form-hook";
import admin2Gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  CreateIdp,
  CreateIdpFromMetadataInput,
  CreateIdpVariables,
  CreateIdpFromMetadata,
  CreateIdpFromMetadataVariables
} from "../admin-gql-2";
import { useAdmin2Client } from "../App/ClientProvider";
import { Alert } from "../Common/Alert";
import { Button } from "../Common/Button";
import { FieldInput } from "../Common/FieldInput";
import { waitMinDelay } from "../Common/functions";
import { SelectOrganization } from "../Common/SelectOrganization";
import { nameToId } from "../Utils/misc";
import { SelectSchoolRelationRoles } from "../Common/SelectSchoolRelationRole";
import _ from "lodash";

interface CreateIdpModalProps {
  onClose: (newIdpId?: string) => void;
  show?: boolean;
}

const createIdpFromMetadataGql = admin2Gql`
  mutation CreateIdpFromMetadata($input: CreateIdpFromMetadataInput!) {
    createIdpFromMetadata(input: $input) {
      __typename

      ...on CreateIdpFromMetadataSuccess {
        idp {
          id
        }
      }

      ...on CreateIdpFromMetadataError {
        errorMessage
      }
    }
  }
`;

const createIdpGql = admin2Gql`
  mutation CreateIdp($input: CreateIdpInput!) {
    createIdp(input: $input) {
      __typename

      ...on CreateIdpSuccess {
        idp {
          id
        }
      }

      ...on CreateIdpError {
        errorMessage
      }
    }
  }
`;

export const CreateIdpModal = (props: CreateIdpModalProps) => {
  return (
    <Modal onClosed={props.onClose} isOpen={props.show} size="lg">
      <Content {...props} />
    </Modal>
  );
};

const requiredOnChange = {
  onChange: (val: any) => !val && "Required field"
};

const Content = (props: CreateIdpModalProps) => {
  const client = useAdmin2Client();
  const [createError, setCreateError] = React.useState("");
  const [createIdpFromMetadata] = useMutation<CreateIdpFromMetadata, CreateIdpFromMetadataVariables>(
    createIdpFromMetadataGql,
    {
      client
    }
  );
  const [createIdp] = useMutation<CreateIdp, CreateIdpVariables>(createIdpGql, {
    client
  });

  const form = useForm<CreateIdpFromMetadataInput>(
    { metadata: "", organizationId: "", name: "", id: "", roles: [] },
    {
      fieldValidation: {
        fields: {
          organizationId: requiredOnChange,
          name: requiredOnChange
        }
      },
      onSubmit: async data => {
        if (data.metadata) {
          const res = await waitMinDelay(createIdpFromMetadata({ variables: { input: data } }), 1000);
          if (res.data && res.data.createIdpFromMetadata.__typename === "CreateIdpFromMetadataSuccess") {
            props.onClose(res.data.createIdpFromMetadata.idp.id);
          } else if (res.data && res.data.createIdpFromMetadata.__typename === "CreateIdpFromMetadataError") {
            setCreateError(res.data.createIdpFromMetadata.errorMessage);
          }
        } else {
          const res = await waitMinDelay(createIdp({ variables: { input: _.omit(data, "metadata") } }), 1000);
          if (res.data) {
            if (res.data.createIdp.__typename === "CreateIdpSuccess") {
              props.onClose(res.data.createIdp.idp.id);
            } else {
              setCreateError(res.data.createIdp.errorMessage);
            }
          } else {
            setCreateError("Unknown error");
          }
        }
      }
    }
  );

  React.useEffect(() => {
    const newId = nameToId(form.fields.name.value);
    if (newId != form.fields.id.value) {
      form.fields.id.set(newId);
    }
  }, [form.fields.name.value, form.fields.id]);

  return (
    <>
      <ModalHeader>Create Identity Provider</ModalHeader>
      <ModalBody>
        <div className="form-group">
          <FieldInput field={form.fields.name} label="Name" />
        </div>
        <div className="form-group">
          <FieldInput field={form.fields.id} label="Id" readonly />
        </div>
        <div className="form-group">
          <label>Organization</label>
          <SelectOrganization
            organizationId={form.fields.organizationId.value}
            onChange={form.fields.organizationId.set}
          />
          {form.fields.organizationId.error && form.fields.organizationId.touched && (
            <div className="invalid-feedback d-block">{form.fields.organizationId.error}</div>
          )}
        </div>
        <div className="form-group">
          <label>Roles</label>
          <SelectSchoolRelationRoles
            roles={form.fields.roles!.value!}
            onChange={newRoles => form.fields.roles!.set(newRoles)}
          />
          <p className="small mt-2">
            Leave empty for the IdP to satisfy all roles (including potentially new roles added in the future)
          </p>
        </div>
        <div className="form-group">
          <FieldInput field={form.fields.metadata} label="Metadata" multiline />
        </div>
        <div className="form-group ">
          <Alert>{createError}</Alert>
        </div>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-secondary" onClick={() => props.onClose()}>
          Cancel
        </button>

        <Button onClick={form.submit} disabled={form.disabled} loading={form.submitting}>
          Create
        </Button>
      </ModalFooter>
    </>
  );
};
