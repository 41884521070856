import * as React from "react";

export const ErrorScreen = () => (
  <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
    <h1>SkolID Kontoadmin</h1>
    <div className="container row justify-content-center">
      <div className="col-md-6">
        <div className="box">
          <div className="box-header box-header-danger">Hoppsan!</div>
          <div className="box-body">
            <p>
              Tyvärr har ett oväntat fel uppstått och applikationen kan inte köra vidare. Om
              problemet kvarstår så kontakta din systemadministratör.
            </p>
            <button className="btn btn-link p-0" onClick={() => window.location.reload()}>
              Ladda om applikationen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);
