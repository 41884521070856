import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import {
  FetchAllOrganizations,
  FetchAllOrganizations_organizations,
} from "../admin-gql";
import { Spinner } from "../Common/Spinner";
import { Link } from "react-router-dom";
import * as routes from "../routes";
import { OrganizationFormModal } from "./OrganizationFormModal";
import { TypedQuery } from "../types";

const fetchOrganizationsGql = gql`
  query FetchAllOrganizations {
    organizations {
      id
      name
      issueLoa
    }
  }
`;

const FetchOrganizationsQuery: TypedQuery<FetchAllOrganizations> = Query;

interface OrganizationsScreenState {
  showAddOrganization?: boolean;
}

export class OrganizationsScreen extends React.Component<
  any,
  OrganizationsScreenState
> {
  public state: OrganizationsScreenState = {};

  public render() {
    return (
      <div className="flex-fill">
        <h1>Organizations</h1>
        <div className="box">
          <div className="box-body">
            <div className="text-right mb-content">
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ showAddOrganization: true })}
              >
                <i className="fa fa-plus" />
                &nbsp; Add Organization
              </button>
            </div>
            <FetchOrganizationsQuery
              query={fetchOrganizationsGql}
              fetchPolicy="network-only"
            >
              {(result) => (
                <>
                  {result.loading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : null}

                  {result.error ? <div>Something went wrong!?</div> : null}

                  {result.data &&
                  result.data.organizations &&
                  !result.loading ? (
                    <OrganizationsTable
                      organizations={result.data.organizations}
                    />
                  ) : null}
                </>
              )}
            </FetchOrganizationsQuery>
          </div>
        </div>

        <OrganizationFormModal
          organization={null}
          show={this.state.showAddOrganization}
          onSave={(org: any) =>
            this.props.history.replace(
              routes.generateOrganizationPath({ id: org.id })
            )
          }
          onCancel={() => this.setState({ showAddOrganization: false })}
        />
      </div>
    );
  }
}

const OrganizationsTable = (props: {
  organizations: FetchAllOrganizations_organizations[];
}) => {
  if (props.organizations.length === 0) {
    return <p>No organizations</p>;
  }

  return (
    <table className="table table-hover table-sm">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Issue level</th>
        </tr>
      </thead>
      <tbody>
        {props.organizations.map((org) => (
          <tr key={org.id}>
            <td>
              <Link to={routes.generateOrganizationPath({ id: org.id })}>
                {org.id}
              </Link>
            </td>
            <td>{org.name}</td>
            <td>{org.issueLoa}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
