import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import { History } from "history";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { Route, RouteComponentProps } from "react-router";
import { NavLink } from "react-router-dom";
import {
  ClientFragment,
  FetchClient,
  FetchClientVariables,
  strippers,
} from "../admin-gql";
import { LoadingScreen } from "../App/LoadingScreen";
import { Spinner } from "../Common/Spinner";
import {
  clientOidcRoute,
  clientOtherRoute,
  clientPropsRoute,
  clientRoute,
  ClientRouteParams,
  clientSamlRoute,
  generatePath,
} from "../routes";
import { AsyncOperation } from "../types";
import { AdvancedForm } from "./Forms/AdvancedForm";
import { AuthenticationSettingsForm } from "./Forms/AuthenticationSettingsForm";
import { ConsentForm } from "./Forms/ConsentForm";
import { CustomPropertiesForm } from "./Forms/CustomPropertiesForm";
import { DeleteClientForm } from "./Forms/DeleteClientForm";
import { DetailsForm } from "./Forms/DetailsForm";
import {
  OidcAccessTokenForm,
  OidcBaseForm,
  OidcClientSecretsForm,
  OidcOtherForm,
  OidcRefreshTokenForm,
  OidcUrisForm,
} from "./Forms/OidcForm";
import {
  SamlCertificatesForm,
  SamlForm,
  SamlMappingRulesForm,
  SamlValidACSUrlsForm,
} from "./Forms/SamlForm";
import { clientFragmentGql } from "./fragments";
import { FormClient } from "./types";

const fetchClientGql = gql`
  query FetchClient($id: String!) {
    client(id: $id) {
      ...ClientFragment
    }
  }

  ${clientFragmentGql}
`;

// tslint:disable-next-line:max-classes-per-file
export class ClientScreen extends React.Component<
  RouteComponentProps<ClientRouteParams>,
  {}
> {
  public render() {
    return (
      <Query<FetchClient, FetchClientVariables>
        query={fetchClientGql}
        variables={{
          id: decodeURIComponent(this.props.match.params.id) as any,
        }}
      >
        {this.renderApolloQueryResult}
      </Query>
    );
  }

  private renderApolloQueryResult = (
    result: QueryResult<FetchClient, FetchClientVariables>
  ) => {
    if (result.loading) {
      return <LoadingScreen />;
    }

    if (result.error) {
      return <div>Something went wrong!?</div>;
    }

    if (!result.data || !result.data.client) {
      return <h1>Client does not exist</h1>;
    }

    return (
      <ClientForm
        history={this.props.history}
        apolloClient={result.client}
        client={result.data!.client!}
      />
    );
  };
}

interface ClientFormProps {
  client: ClientFragment;
  apolloClient: ApolloClient<any>;
  history: History;
}

interface ClientFormState {
  client: FormClient;
  lastPropsClient: ClientFragment | null;
  saveOperation: AsyncOperation<void>;
}

function createFormClientFromClient(client: ClientFragment): FormClient {
  return {
    ...client,
    organizationId: client.organization?.id ?? null,
    userOrganizationIds: client.userOrganizations ? client.userOrganizations.map(x => x.id) : null,
    samlSettings: client.samlSettings,
    allowedIdps: client.allowedIdps.map((x) => x.id),
  };
}

// tslint:disable-next-line:max-classes-per-file
export class ClientForm extends React.Component<
  ClientFormProps,
  ClientFormState
> {
  public static getDerivedStateFromProps(
    nextProps: ClientFormProps,
    prevState: ClientFormState
  ): Partial<ClientFormState> | null {
    if (nextProps.client !== prevState.lastPropsClient && nextProps.client) {
      return {
        client: createFormClientFromClient(nextProps.client),
        lastPropsClient: nextProps.client,
      };
    } else {
      return null;
    }
  }

  constructor(props: ClientFormProps) {
    super(props);
    this.state = {
      saveOperation: {},
      lastPropsClient: props.client,
      client: createFormClientFromClient(props.client),
    };
  }

  public render() {
    const clientId = this.props.client.id;
    const client = this.state.client;
    const disabled = this.state.saveOperation.running;
    const subFormProps = {
      disabled,
      client,
      updateClient: this.updateClient,
      originalClient: this.props.client,
    };

    return (
      <div className="flex-fill">
        <div className="row">
          <div className="offset-md-3 col">
            <h1>Update client</h1>
            {this.state.saveOperation.error ? (
              <div className="alert alert-danger">
                {this.state.saveOperation.error}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-content">
            <div className="nav flex-column nav-pills">
              <MenuItem
                route={clientRoute}
                name="Base details"
                clientId={clientId}
              />
              <MenuItem
                route={clientOidcRoute}
                name="OpenID Connect"
                clientId={clientId}
              />
              <MenuItem
                route={clientSamlRoute}
                name="Saml"
                clientId={clientId}
              />
              <MenuItem
                route={clientPropsRoute}
                name="Custom Properties"
                clientId={clientId}
              />
              <MenuItem
                route={clientOtherRoute}
                name="Other"
                clientId={clientId}
              />
            </div>
          </div>
          <div className="col-md">
            <Route
              exact
              path={clientRoute}
              render={() => (
                <>
                  <DetailsForm {...subFormProps} />
                  <ConsentForm {...subFormProps} />
                  <AuthenticationSettingsForm {...subFormProps} />
                  <AdvancedForm {...subFormProps} />
                </>
              )}
            />

            <Route
              path={clientOidcRoute}
              render={() => (
                <>
                  <OidcBaseForm {...subFormProps} />
                  <OidcClientSecretsForm {...subFormProps} />
                  <OidcUrisForm {...subFormProps} />
                  <OidcAccessTokenForm {...subFormProps} />
                  <OidcRefreshTokenForm {...subFormProps} />
                  <OidcOtherForm {...subFormProps} />
                </>
              )}
            />

            <Route
              path={clientSamlRoute}
              render={() => (
                <>
                  <SamlForm {...subFormProps} />
                  <SamlCertificatesForm {...subFormProps} />
                  <SamlValidACSUrlsForm {...subFormProps} />
                  <SamlMappingRulesForm {...subFormProps} />
                </>
              )}
            />

            <Route
              path={clientPropsRoute}
              render={() => (
                <>
                  <CustomPropertiesForm {...subFormProps} />
                </>
              )}
            />

            <Route
              path={clientOtherRoute}
              render={() => (
                <>
                  <DeleteClientForm
                    {...subFormProps}
                    history={this.props.history}
                  />
                </>
              )}
            />

            <div className="text-right">
              <button className="btn btn-primary" onClick={this.save}>
                {this.state.saveOperation.running ? <Spinner light /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private save = async () => {
    this.setState({ saveOperation: { running: true } });

    try {
      await this.props.apolloClient.mutate({
        mutation: updateClientGql,
        variables: {
          client: {
            ...strippers.UpdateClientInput(this.state.client),
          },
          id: this.props.client.id,
        },
      });

      this.setState({ saveOperation: {} });
    } catch (error) {
      this.setState({ saveOperation: { error: "Could not save the client" } });
      console.error("Failed to save client: ", error);
    }
  };

  private updateClient = (partialUpdate: Partial<FormClient>) => {
    this.setState({ client: { ...this.state.client, ...partialUpdate } });
  };
}

const MenuItem = (props: { route: string; clientId: string; name: string }) => (
  <NavLink
    activeClassName="active"
    exact
    className="nav-link p-2"
    to={generatePath(props.route, { id: props.clientId })}
  >
    {props.name}
  </NavLink>
);

const updateClientGql = gql`
  mutation UpdateClient($id: String!, $client: UpdateClientInput!) {
    updateClient(id: $id, input: $client) {
      ...ClientFragment
    }
  }

  ${clientFragmentGql}
`;
