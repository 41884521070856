import * as React from "react";
import permGql from "graphql-tag";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { SelectPermissionRole } from "../Common/SelectPermissionRole";
import { SelectSubject, Subject } from "../Common/SelectSubject";
import { AsyncOperation } from "../types";
import { Spinner } from "../Common/Spinner";
import { WithApolloClient, withApollo } from "react-apollo";
import { AssignRoleVariables, SubjectType } from "../permissons-gql";
import { SelectResource } from "./SelectResource";

export interface AssignRoleModalProps {
  isOpen?: boolean;
  domainId: string;
  onClose: (saved: boolean) => Promise<void>;
  resource?: { id: string; typeId: string } | null;
}

export interface AssignRoleModalState {
  selectedSubjects: Subject[];
  selectedRoleId: string | null;
  assignOperation: AsyncOperation<any>;
  selectedResource?: { id: string; typeId: string } | null;
}

class AssignRoleModalWithClient extends React.Component<
  WithApolloClient<AssignRoleModalProps>,
  AssignRoleModalState
> {
  public state: AssignRoleModalState = {
    assignOperation: {},
    selectedSubjects: [],
    selectedRoleId: null,
  };

  public render() {
    const disabled = !!this.state.assignOperation.running;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.onClose(false)}
      >
        <ModalHeader>Assign roles</ModalHeader>
        <ModalBody>
          <h3>Role</h3>
          <SelectPermissionRole
            domainId={this.props.domainId}
            roleId={this.state.selectedRoleId}
            disabled={disabled}
            onChange={(newRole) =>
              this.setState({ selectedRoleId: newRole && newRole.id })
            }
          />

          <h3 className="mt-3">Subjects</h3>
          <SelectSubject
            onSelect={(newSubject) =>
              this.setState({
                selectedSubjects: this.state.selectedSubjects.concat([
                  newSubject,
                ]),
              })
            }
            disabled={disabled}
          />

          <table className="table my-2 mx-1">
            <tbody>
              {this.state.selectedSubjects.map((sub) => (
                <tr key={sub.id} className="py-2">
                  {sub.type === "client" ? (
                    <>
                      <td>
                        {sub.displayName}
                        <br />
                        <span className="text-black-50">{sub.id}</span>
                      </td>
                      <td className="text-right align-middle">
                        <button
                          className="btn btn-link p-0"
                          onClick={() =>
                            this.setState({
                              selectedSubjects: this.state.selectedSubjects.filter(
                                (x) => x.id !== sub.id
                              ),
                            })
                          }
                        >
                          Remove
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        {sub.name} ({sub.organization.name})
                        <br />
                        <span className="text-black-50">{sub.nationalId}</span>
                      </td>
                      <td className="text-right align-middle">
                        <button
                          className="btn btn-link p-0"
                          onClick={() =>
                            this.setState({
                              selectedSubjects: this.state.selectedSubjects.filter(
                                (x) => x.id !== sub.id
                              ),
                            })
                          }
                        >
                          Remove
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          <h3 className="mt-3">Resource</h3>
          <SelectResource
            selectedResource={
              this.props.resource || this.state.selectedResource
            }
            disabled={disabled || !!this.props.resource}
            onSelect={(res) => this.setState({ selectedResource: res })}
            domainId={this.props.domainId}
          />

          {this.state.assignOperation.error ? (
            <div className="alert alert-danger">
              {this.state.assignOperation.error}
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={() => this.props.onClose(false)}
            disabled={disabled}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            disabled={
              disabled ||
              !this.state.selectedRoleId ||
              this.state.selectedSubjects.length === 0
            }
            onClick={this.handleAssignClick}
          >
            {this.state.assignOperation.running ? <Spinner light /> : "Assign"}
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private handleAssignClick = async () => {
    this.setState({ assignOperation: { running: true } });

    for (const subject of this.state.selectedSubjects) {
      try {
        await this.props.client.mutate<any, AssignRoleVariables>({
          mutation: permGql`
            mutation AssignRole($input: AssignRoleInput!) {
              assignRole(input: $input) {
                globalId
                subject {
                  globalId
                  id
                  displayName
                }
                role {
                  globalId
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              domainId: this.props.domainId,
              subjectId: subject.id,
              subjectType:
                subject.type === "client"
                  ? SubjectType.CLIENT
                  : SubjectType.USER,
              resourceId:
                (this.props.resource && this.props.resource.id) ||
                (this.state.selectedResource && this.state.selectedResource.id),
              resourceTypeId:
                (this.props.resource && this.props.resource.typeId) ||
                (this.state.selectedResource &&
                  this.state.selectedResource.typeId),
              roleId: this.state.selectedRoleId!,
              createReferences: true,
            },
          },
        });
      } catch (error) {
        console.error("Failed to create new role: ", error);
        this.setState({ assignOperation: { error: "Failed to assign role" } });
        return;
      }
    }

    await this.props.onClose(true);
    this.setState({
      assignOperation: { data: true },
      selectedResource: undefined,
      selectedRoleId: null,
      selectedSubjects: [],
    });
  };
}

export const AssignRoleModal: any = withApollo(
  AssignRoleModalWithClient as any
) as any;
