import * as React from "react";
import permGql from "graphql-tag";
import { PureQueryOptions } from "apollo-client";
import { AsyncOperation } from "../types";
import { SaveResource, Resource } from "../permissons-gql";
import { SaveModal } from "./SaveModal";
import { SelectResourceType } from "./SelectResourceType";
import { WithApolloClient, withApollo } from "react-apollo";
import { ExecutionResult } from "graphql";

interface SaveResourceModalProps {
  domainId: string;
  isOpen: boolean;
  onClose: () => void;
  initialResource?: Resource | null;
  refetchQueries: PureQueryOptions[];
}

interface SaveResourceModalState {
  id: string;
  displayName: string;
  saveOperation: AsyncOperation<any>;
  prevInitialResource?: Resource;
  selectedResourceTypeId?: string | null;
}

class SaveResourceModalWithClient extends React.Component<
  WithApolloClient<SaveResourceModalProps>,
  SaveResourceModalState
> {
  public static getDerivedStateFromProps(
    props: SaveResourceModalProps,
    state: SaveResourceModalState
  ) {
    if (props.initialResource !== state.prevInitialResource) {
      return {
        id: props.initialResource ? props.initialResource.id : "",
        displayName: props.initialResource ? props.initialResource.displayName || "" : "",
        selectedResourceTypeId: props.initialResource ? props.initialResource.type.id : null,
        prevInitialResource: props.initialResource
      };
    }
    return null;
  }

  constructor(props: WithApolloClient<SaveResourceModalProps>) {
    super(props);

    this.state = {
      id: "",
      displayName: "",
      saveOperation: {}
    };
  }

  public render() {
    return (
      <SaveModal
        isOpen={this.props.isOpen}
        entityName="resource"
        isEntityValid={
          this.state.id.length > 0 &&
          this.state.displayName.length > 0 &&
          !!this.state.selectedResourceTypeId
        }
        new={!this.props.initialResource}
        onCancel={this.handleCancel}
        onSave={this.handleSaveResource}
        saveOperation={this.state.saveOperation}
      >
        <div className="form-group">
          <label className="form-label">Type</label>
          <SelectResourceType
            disabled={!!this.props.initialResource}
            domainId={this.props.domainId}
            selectedResourceTypeId={this.state.selectedResourceTypeId}
            onSelect={rt => this.setState({ selectedResourceTypeId: rt && rt.id })}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Id</label>
          <input
            type="text"
            className="form-control"
            value={this.state.id}
            readOnly={!!this.props.initialResource}
            disabled={this.state.saveOperation.running}
            onChange={ev => this.setState({ id: ev.currentTarget.value })}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            value={this.state.displayName}
            disabled={this.state.saveOperation.running}
            onChange={ev => this.setState({ displayName: ev.currentTarget.value })}
          />
        </div>
      </SaveModal>
    );
  }

  private handleCancel = () => {
    this.setState({ displayName: "", id: "", saveOperation: {} });
    this.props.onClose();
  };

  private handleSaveResource = async () => {
    this.setState({ saveOperation: { running: true } });

    try {
      const response: ExecutionResult<SaveResource> = await this.props.client.mutate({
        mutation: permGql`mutation SaveResource($input: SaveResourceInput!) {
            saveResource(input: $input) {
              globalId
              id
              displayName
              type {
                globalId
                id
              }
            }
          }`,
        variables: {
          input: {
            domainId: this.props.domainId,
            id: this.state.id,
            displayName: this.state.displayName,
            typeId: this.state.selectedResourceTypeId!
          }
        },
        refetchQueries: this.props.refetchQueries,
        awaitRefetchQueries: true
      });

      this.setState({
        saveOperation: { data: response.data },
        id: "",
        displayName: ""
      });

      this.props.onClose();
    } catch (error) {
      console.error("Failed to save resource type: ", error);
      this.setState({ saveOperation: { error: "Failed to save resource type" } });
    }
  };
}

export const SaveResourceModal = withApollo(SaveResourceModalWithClient as any) as any;
