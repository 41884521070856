import * as React from "react";
import { Input } from "@ist-group-private-scope/skolid-client-components";

export const UrisControl = (props: {
  uris: string[] | null | undefined;
  disabled?: boolean;
  uriName: string;
  updateUris: (uris: string[]) => void;
}) => {
  const uris = props.uris || [];
  return (
    <>
      {uris.map((uri, index) => (
        <div className="form-group" key={index}>
          <Input
            placeholder={props.uriName}
            value={uri}
            onChange={text => props.updateUris(uris.map((x, i) => (i === index ? text : x)))}
            disabled={props.disabled}
          >
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={() => props.updateUris(uris.filter((x, i) => i !== index))}
              disabled={props.disabled}
            >
              <i className="fa fa-trash" />
            </button>
          </Input>
        </div>
      ))}
      <button
        type="button"
        className="btn btn-link p-0"
        onClick={() => props.updateUris(uris.concat([""]))}
        disabled={(uris.length > 0 && uris[uris.length - 1] === "") || props.disabled}
      >
        Add {props.uriName}
      </button>
    </>
  );
};
