import * as React from "react";
import { Query } from "react-apollo";
import permGql from "graphql-tag";
import { GetDomains } from "../permissons-gql";
import { PermissionClientConsumer } from "../App/ClientProvider";
import { ApolloClient } from "apollo-client";
import { LoadingScreen } from "../App/LoadingScreen";
import { Link } from "react-router-dom";
import { generatePermDomainPath } from "../routes";
import { TypedQuery } from "../types";

const fetchAllDomainsGql = permGql`
  query GetDomains {
    domains {
      globalId
      id
      displayName
    }
  }
`;

const DomainsQuery: TypedQuery<GetDomains> = Query;

export const DomainListScreen = () => (
  <PermissionClientConsumer>
    {({ permissionClient }) => <DomainListScreenInternal permissionClient={permissionClient} />}
  </PermissionClientConsumer>
);

interface DomainListScreenInternalProps {
  permissionClient: ApolloClient<any>;
}

class DomainListScreenInternal extends React.Component<DomainListScreenInternalProps> {
  public render() {
    const props = this.props;

    return (
      <div className="flex-fill">
        <h1>Domains</h1>
        <div className="box">
          <div className="box-body">
            <DomainsQuery client={props.permissionClient} query={fetchAllDomainsGql}>
              {response => (
                <>
                  {response.loading ? <LoadingScreen /> : null}
                  {response.error ? <div>Something went wrong!?</div> : null}
                  {response.data && response.data.domains ? (
                    <>
                      <table className="table table-hover table-sm">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {response.data.domains.map(d => (
                            <tr key={d.id}>
                              <td>
                                <Link to={generatePermDomainPath({ domainId: d.id })}>{d.id}</Link>
                              </td>
                              <td>{d.displayName}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : null}
                </>
              )}
            </DomainsQuery>
          </div>
        </div>
      </div>
    );
  }
}
