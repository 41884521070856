import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import {
  NationalId,
  useSearchOptions
} from "@ist-group-private-scope/skolid-client-components";
import gql from "graphql-tag";
import { SearchUsers, SearchUsersVariables } from "../admin-gql";
import { Spinner } from "../Common/Spinner";
import { Link } from "react-router-dom";
import * as routes from "../routes";
import { TableCaption } from "../Common/TableCaption";
import { TypedQuery } from "../types";
import { CreateUserModal } from "./CreateUserModal";
import { Trans } from 'react-i18next';
import { SelectOrganization } from "../Common/SelectOrganization";
import { Checkbox } from "../Common/Checkbox";
import Select from "react-select";

const searchUsersGql = gql`
  query SearchUsers($filter: String, $before: String, $after: String, $organizationId: String, $searchUsersOptions: SearchUsersOptionsInput) {
    searchUsers(
      filter: $filter
      before: $before
      after: $after
      last: 15
      first: 15
      searchUsersOptions: $searchUsersOptions,
      organizationId: $organizationId
    ) {
      edges {
        node {
          ...UsersSearchResult
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }

  fragment UsersSearchResult on User {
    id
    name
    organization {
      id
      name
      country
    }
    nationalId
    tfNumber
    username
    lastActivity
  }
`;

const SearchUsersQuery: TypedQuery<SearchUsers, SearchUsersVariables> = Query;

interface UsersScreenState {
  before?: string | null;
  after?: string | null;
}

const orderByAndSort = [
  {
    label: "Name asc",
    value: "NAME ASCENDING"
  },
  {
    label: "Name desc",
    value: "NAME DESCENDING"
  },
  {
    label: "Last activity asc",
    value: "LAST_ACTIVITY ASCENDING"
  },
  {
    label: "Last activity desc",
    value: "LAST_ACTIVITY DESCENDING"
  },
]

export const UsersScreen = () => {
  const [state, setState] = React.useState<UsersScreenState>({});
  const {
    searchString,
    organizationId,
    isOnlyIssued,
    orderByOption,
    debouncedSearchString,
    setSearchString,
    setOrgazationId,
    setIsOnlyIssued,
    setOrderByOption
  } = useSearchOptions();
  const [showNewUserModal, setShowNewUserModal] = React.useState(false);

  const search = (str: string) => {
    setSearchString(str);
    setState((p) => ({ ...p, after: null, before: null }));
  };

  const searchForOrganization = (id: string | null, name: string | null) => {
    setOrgazationId(id);
    setState((p) => ({ ...p, after: null, before: null }));
  };

  const searchForIsOnlyIssued = (checked: boolean) => {
    setIsOnlyIssued(checked);
    setState((p) => ({ ...p, after: null, before: null }));
  };

  const searchForOrderBy = (option: any) => {
    setOrderByOption(option);
    setState((p) => ({ ...p, after: null, before: null }));
  };

  const getOrderBy = (x: any) => {
    var arr = x?.value?.split(" ");
    if (arr != null) {
      var v: any = null;
      v = {
        orderBy: arr[0],
        sortDirection: arr[1]
      };
      return [v];
    }
    return [];
  };

  return (
    <div className="flex-fill">
      {showNewUserModal ? (
        <CreateUserModal onClose={() => setShowNewUserModal(false)} />
      ) : null}
      <div className="row align-items-center">
        <h1 className="col m-0 mb-3">Users</h1>
        <div className="col">
          <div className="text-right">
            <button
              className="btn btn-primary ml-1 mt-1"
              onClick={() => setShowNewUserModal(true)}
            >
              <Trans i18nKey="user.lookup.screen.issueNewUser" />
            </button>
          </div>
        </div>
      </div>

      <div className="box">
        <div className="box-body">
          <SearchUsersQuery
            query={searchUsersGql}
            variables={{
              filter: debouncedSearchString,
              before: state.before,
              after: state.after,
              organizationId: organizationId,
              searchUsersOptions: {
                isOnlyIssued: isOnlyIssued,
                userOrderByOptions: getOrderBy(orderByOption)
              }
            }}
            fetchPolicy="network-only"
          >
            {(result) => (
              <>
                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();
                    result.refetch();
                  }}
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search users"
                      value={searchString}
                      onChange={(ev) => search(ev.currentTarget.value)}
                    />
                  </div>

                  <div className="form-row">
                    <div className="form-group col">
                      <SelectOrganization
                        organizationId={organizationId}
                        onChange={searchForOrganization}
                        placeholder={"Select organization"}
                      />
                    </div>

                    <div className="form-group col">
                      <Select
                        options={orderByAndSort.map(x => ({
                          label: x.label,
                          value: x.value
                        }))}
                        value={orderByOption}
                        isClearable={true}
                        onChange={(option: any) => searchForOrderBy(option)}
                        placeholder={"Select sort option"}
                      />

                    </div>
                  </div>

                  <div className="form-group">
                    <Checkbox
                      checked={isOnlyIssued}
                      onChange={(checked) => searchForIsOnlyIssued(checked)}
                      label="Only issued users"
                    />
                  </div>
                </form>

                <br />

                {result.loading ? (
                  <div className="text-center">
                    <Spinner />
                  </div>
                ) : null}

                {result.error ? <div>Something went wrong!?</div> : null}

                {result.data && result.data.searchUsers && !result.loading ? (
                  <UsersTable
                    result={result}
                    onAfter={(cursor) =>
                      setState({ after: cursor, before: null })
                    }
                    onBefore={(cursor) =>
                      setState({ before: cursor, after: null })
                    }
                  />
                ) : null}
              </>
            )}
          </SearchUsersQuery>
        </div>
      </div>
    </div>
  );
};

const UsersTable = (props: {
  result: QueryResult<SearchUsers, SearchUsersVariables>;
  onBefore: (cursor: string) => void;
  onAfter: (cursor: string) => void;
}) => {
  if (props.result.data!.searchUsers!.edges.length === 0) {
    return <p>No users</p>;
  }

  return (
    <table className="table table-hover table-sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>National Id</th>
          <th>Organization</th>
          <th>Last activity</th>
        </tr>
      </thead>
      <tbody>
        {props.result
          .data!.searchUsers!.edges.map((x) => x.node)
          .map((user) => (
            <tr key={user.id}>
              <td>
                <Link to={routes.generateUserPath({ id: user.id.toString() })}>
                  {user.name || "(no name)"}
                </Link>
              </td>
              <td>
                {user.nationalId ? (
                  <NationalId
                    nationalId={user.nationalId}
                    country={user.organization.country}
                  />
                ) : user.tfNumber ? (
                  `TF: ${user.tfNumber}`
                ) : null}
              </td>
              <td>
                {user.organization.name} ({user.organization.country})
              </td>
              <td>
                {user.lastActivity
                  ? new Date(user.lastActivity).toLocaleDateString()
                  : "-"}
              </td>
            </tr>
          ))}
      </tbody>

      <TableCaption
        connectionData={props.result.data!.searchUsers!}
        onAfter={props.onAfter}
        onBefore={props.onBefore}
      />
    </table>
  );
};
