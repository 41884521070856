import admin2Gql from "graphql-tag";

export const idpDetailsFragmentGql = admin2Gql`
  fragment IdpDetails on Idp {
    id
    name
    created
    updated
    organization {
      id
      name
    }
    roles
    claimMappings {
      __typename
      target
      ...on SetIdpClaimMapping {
        value
      }
      ...on TransferIdpClaimMapping {
        matchRegex
        source
        targetValueSubstitution
      }
    }
    samlConfig {
      certificates {
        data
        use
      }
      entityId
      signAuthnRequest
      signLogoutRequest
      singleLogoutServiceUrl
      singleSignOnServiceUrl
      singleSignOnServiceBinding
      singleLogoutServiceBinding
      localSigningCertificateThumbprintHint
    }
  }
`;
