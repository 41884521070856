import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { FetchOrganizations } from "../admin-gql";
import Select from "react-select";
import { TypedQuery } from "../types";

const fetchOrganizationsGql = gql`
  query FetchOrganizationsMulti {
    organizations {
      id
      name
    }
  }
`;

const QueryOrganizations: TypedQuery<FetchOrganizations> = Query;

export const SelectOrganizationMulti = (props: {
    organizationIds: string[] | null | undefined;
    onChange: (selectedIds: string[] | null) => void;
    disabled?: boolean;
    readonly?: boolean;
    notClearable?: boolean;
}) => {
    return (
        <QueryOrganizations query={fetchOrganizationsGql} fetchPolicy="cache-and-network">
            {result => (
                <Select
                    options={
                        result.data && !result.loading ? result.data.organizations.map(x => ({ label: x.name, value: x.id })) : []
                    }
                    isLoading={result.loading}
                    isMulti
                    value={
                        result.data && result.data.organizations ? calcValue(props.organizationIds, result.data.organizations) : []
                    }
                    isClearable={!props.notClearable}
                    isDisabled={props.disabled || props.readonly}
                    onChange={(options: any) => props.onChange(options.map((x: any) => x.value))}
                />
            )}
        </QueryOrganizations>
    );
};

function calcValue(selectedIds: string[] | null | undefined, organizations: Array<{ id: string; name: string }>) {
    return selectedIds ? organizations.filter(x => selectedIds.indexOf(x.id) !== -1)
        .map(foundOrg => {
            return {
                value: foundOrg.id,
                label: foundOrg.name
            }
        }) : [];
}
