import * as React from "react";

export const NeedsReauthenticationScreen = () => (
  <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
    <div className="container row justify-content-center">
      <div className="col-md-6">
        <div className="box">
          <div className="box-header box-header-danger">Needs to re-authenticate!</div>
          <div className="box-body">
            <p>You must re-authenticate yourself to continue to use the .</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
