/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRoles
// ====================================================

export interface GetRoles_domain_roles {
  globalId: string;
  id: string;
  displayName: string | null;
  highSecurity: boolean;
}

export interface GetRoles_domain {
  globalId: string;
  id: string;
  roles: GetRoles_domain_roles[];
}

export interface GetRoles {
  domain: GetRoles_domain | null;
}

export interface GetRolesVariables {
  domainId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignDomainAccess
// ====================================================

export interface AssignDomainAccess_assignRole_subject_organization {
  id: string;
  name: string;
}

export interface AssignDomainAccess_assignRole_subject {
  globalId: string;
  id: string;
  displayName: string | null;
  type: SubjectType;
  organization: AssignDomainAccess_assignRole_subject_organization | null;
}

export interface AssignDomainAccess_assignRole {
  globalId: string;
  subject: AssignDomainAccess_assignRole_subject;
}

export interface AssignDomainAccess {
  assignRole: AssignDomainAccess_assignRole | null;
}

export interface AssignDomainAccessVariables {
  input: AssignRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRoleAssignments
// ====================================================

export interface GetRoleAssignments_domain_roleAssignments_nodes_subject_organization {
  id: string;
  name: string;
}

export interface GetRoleAssignments_domain_roleAssignments_nodes_subject {
  globalId: string;
  id: string;
  displayName: string | null;
  type: SubjectType;
  organization: GetRoleAssignments_domain_roleAssignments_nodes_subject_organization | null;
}

export interface GetRoleAssignments_domain_roleAssignments_nodes_role {
  globalId: string;
  id: string;
  displayName: string | null;
  highSecurity: boolean;
}

export interface GetRoleAssignments_domain_roleAssignments_nodes_resource_type {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface GetRoleAssignments_domain_roleAssignments_nodes_resource {
  globalId: string;
  id: string;
  displayName: string | null;
  type: GetRoleAssignments_domain_roleAssignments_nodes_resource_type;
}

export interface GetRoleAssignments_domain_roleAssignments_nodes {
  globalId: string;
  subject: GetRoleAssignments_domain_roleAssignments_nodes_subject;
  role: GetRoleAssignments_domain_roleAssignments_nodes_role;
  resource: GetRoleAssignments_domain_roleAssignments_nodes_resource | null;
}

export interface GetRoleAssignments_domain_roleAssignments {
  nodes: GetRoleAssignments_domain_roleAssignments_nodes[];
}

export interface GetRoleAssignments_domain {
  globalId: string;
  id: string;
  displayName: string;
  roleAssignments: GetRoleAssignments_domain_roleAssignments;
}

export interface GetRoleAssignments {
  domain: GetRoleAssignments_domain | null;
}

export interface GetRoleAssignmentsVariables {
  domainId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignRole
// ====================================================

export interface AssignRole_assignRole_subject {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface AssignRole_assignRole_role {
  globalId: string;
  id: string;
}

export interface AssignRole_assignRole {
  globalId: string;
  subject: AssignRole_assignRole_subject;
  role: AssignRole_assignRole_role;
}

export interface AssignRole {
  assignRole: AssignRole_assignRole | null;
}

export interface AssignRoleVariables {
  input: AssignRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnassignRole
// ====================================================

export interface UnassignRole {
  unassignRole: boolean | null;
}

export interface UnassignRoleVariables {
  input: UnassignRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDomains
// ====================================================

export interface GetDomains_domains {
  globalId: string;
  id: string;
  displayName: string;
}

export interface GetDomains {
  domains: GetDomains_domains[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDomainName
// ====================================================

export interface GetDomainName_domain {
  globalId: string;
  id: string;
  displayName: string;
}

export interface GetDomainName {
  domain: GetDomainName_domain | null;
}

export interface GetDomainNameVariables {
  domainId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDomainSettings
// ====================================================

export interface GetDomainSettings_domain_roles {
  globalId: string;
  id: string;
  displayName: string | null;
  highSecurity: boolean;
}

export interface GetDomainSettings_domain_resourceTypes {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface GetDomainSettings_domain {
  id: string;
  globalId: string;
  roles: GetDomainSettings_domain_roles[];
  resourceTypes: GetDomainSettings_domain_resourceTypes[];
}

export interface GetDomainSettings_permissionsDomain_resource_roleAssignments_nodes_subject_organization {
  id: string;
  name: string;
}

export interface GetDomainSettings_permissionsDomain_resource_roleAssignments_nodes_subject {
  globalId: string;
  id: string;
  type: SubjectType;
  displayName: string | null;
  organization: GetDomainSettings_permissionsDomain_resource_roleAssignments_nodes_subject_organization | null;
}

export interface GetDomainSettings_permissionsDomain_resource_roleAssignments_nodes {
  globalId: string;
  subject: GetDomainSettings_permissionsDomain_resource_roleAssignments_nodes_subject;
}

export interface GetDomainSettings_permissionsDomain_resource_roleAssignments {
  nodes: GetDomainSettings_permissionsDomain_resource_roleAssignments_nodes[];
}

export interface GetDomainSettings_permissionsDomain_resource {
  id: string;
  globalId: string;
  roleAssignments: GetDomainSettings_permissionsDomain_resource_roleAssignments;
}

export interface GetDomainSettings_permissionsDomain {
  id: string;
  globalId: string;
  resource: GetDomainSettings_permissionsDomain_resource | null;
}

export interface GetDomainSettings {
  domain: GetDomainSettings_domain | null;
  permissionsDomain: GetDomainSettings_permissionsDomain | null;
}

export interface GetDomainSettingsVariables {
  domainId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveRoles
// ====================================================

export interface RemoveRoles {
  deleteRole: boolean | null;
}

export interface RemoveRolesVariables {
  id: string;
  domainId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnassignDomainAccess
// ====================================================

export interface UnassignDomainAccess {
  unassignRole: boolean | null;
}

export interface UnassignDomainAccessVariables {
  input: UnassignRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveResourceType
// ====================================================

export interface RemoveResourceType {
  deleteResourceType: boolean | null;
}

export interface RemoveResourceTypeVariables {
  id: string;
  domainId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetResource
// ====================================================

export interface GetResource_domain_resource_type {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface GetResource_domain_resource_roleAssignments_nodes_subject_organization {
  id: string;
  name: string;
}

export interface GetResource_domain_resource_roleAssignments_nodes_subject {
  globalId: string;
  id: string;
  displayName: string | null;
  type: SubjectType;
  organization: GetResource_domain_resource_roleAssignments_nodes_subject_organization | null;
}

export interface GetResource_domain_resource_roleAssignments_nodes_role {
  globalId: string;
  id: string;
  displayName: string | null;
  highSecurity: boolean;
}

export interface GetResource_domain_resource_roleAssignments_nodes {
  globalId: string;
  subject: GetResource_domain_resource_roleAssignments_nodes_subject;
  role: GetResource_domain_resource_roleAssignments_nodes_role;
}

export interface GetResource_domain_resource_roleAssignments {
  nodes: GetResource_domain_resource_roleAssignments_nodes[];
}

export interface GetResource_domain_resource {
  globalId: string;
  id: string;
  displayName: string | null;
  type: GetResource_domain_resource_type;
  roleAssignments: GetResource_domain_resource_roleAssignments;
}

export interface GetResource_domain {
  globalId: string;
  id: string;
  displayName: string;
  resource: GetResource_domain_resource | null;
}

export interface GetResource {
  domain: GetResource_domain | null;
}

export interface GetResourceVariables {
  domainId: string;
  resourceId: string;
  resourceTypeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetResources
// ====================================================

export interface GetResources_domain_resources_nodes_type {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface GetResources_domain_resources_nodes {
  globalId: string;
  id: string;
  displayName: string | null;
  type: GetResources_domain_resources_nodes_type;
}

export interface GetResources_domain_resources {
  nodes: (GetResources_domain_resources_nodes | null)[];
}

export interface GetResources_domain {
  globalId: string;
  id: string;
  displayName: string;
  resources: GetResources_domain_resources;
}

export interface GetResources {
  domain: GetResources_domain | null;
}

export interface GetResourcesVariables {
  domainId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteResource
// ====================================================

export interface DeleteResource {
  deleteResource: boolean | null;
}

export interface DeleteResourceVariables {
  id: string;
  domainId: string;
  typeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveResource
// ====================================================

export interface SaveResource_saveResource_type {
  globalId: string;
  id: string;
}

export interface SaveResource_saveResource {
  globalId: string;
  id: string;
  displayName: string | null;
  type: SaveResource_saveResource_type;
}

export interface SaveResource {
  saveResource: SaveResource_saveResource | null;
}

export interface SaveResourceVariables {
  input: SaveResourceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveResourceType
// ====================================================

export interface SaveResourceType_saveResourceType {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface SaveResourceType {
  saveResourceType: SaveResourceType_saveResourceType | null;
}

export interface SaveResourceTypeVariables {
  input: SaveResourceTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRole
// ====================================================

export interface CreateRole_saveRole {
  globalId: string;
  id: string;
  displayName: string | null;
  highSecurity: boolean;
}

export interface CreateRole {
  saveRole: CreateRole_saveRole | null;
}

export interface CreateRoleVariables {
  input: SaveRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSelectResources
// ====================================================

export interface GetSelectResources_domain_resources_nodes_type {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface GetSelectResources_domain_resources_nodes {
  globalId: string;
  id: string;
  displayName: string | null;
  type: GetSelectResources_domain_resources_nodes_type;
}

export interface GetSelectResources_domain_resources {
  nodes: (GetSelectResources_domain_resources_nodes | null)[];
}

export interface GetSelectResources_domain {
  globalId: string;
  id: string;
  resources: GetSelectResources_domain_resources;
}

export interface GetSelectResources {
  domain: GetSelectResources_domain | null;
}

export interface GetSelectResourcesVariables {
  domainId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResourceTypes
// ====================================================

export interface ResourceTypes_domain_resourceTypes {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface ResourceTypes_domain {
  globalId: string;
  id: string;
  resourceTypes: ResourceTypes_domain_resourceTypes[];
}

export interface ResourceTypes {
  domain: ResourceTypes_domain | null;
}

export interface ResourceTypesVariables {
  domainId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FetchUserPermissions
// ====================================================

export interface FetchUserPermissions_subject_roleAssignments_nodes_domain {
  globalId: string;
  id: string;
  displayName: string;
}

export interface FetchUserPermissions_subject_roleAssignments_nodes_resource_type {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface FetchUserPermissions_subject_roleAssignments_nodes_resource {
  globalId: string;
  id: string;
  displayName: string | null;
  type: FetchUserPermissions_subject_roleAssignments_nodes_resource_type;
}

export interface FetchUserPermissions_subject_roleAssignments_nodes_role {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface FetchUserPermissions_subject_roleAssignments_nodes {
  globalId: string;
  domain: FetchUserPermissions_subject_roleAssignments_nodes_domain;
  resource: FetchUserPermissions_subject_roleAssignments_nodes_resource | null;
  role: FetchUserPermissions_subject_roleAssignments_nodes_role;
}

export interface FetchUserPermissions_subject_roleAssignments {
  nodes: FetchUserPermissions_subject_roleAssignments_nodes[];
}

export interface FetchUserPermissions_subject {
  globalId: string;
  roleAssignments: FetchUserPermissions_subject_roleAssignments;
}

export interface FetchUserPermissions {
  subject: FetchUserPermissions_subject | null;
}

export interface FetchUserPermissionsVariables {
  subjectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Subject
// ====================================================

export interface Subject_organization {
  id: string;
  name: string;
}

export interface Subject {
  globalId: string;
  id: string;
  displayName: string | null;
  type: SubjectType;
  organization: Subject_organization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Resource
// ====================================================

export interface Resource_type {
  globalId: string;
  id: string;
  displayName: string | null;
}

export interface Resource {
  globalId: string;
  id: string;
  displayName: string | null;
  type: Resource_type;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RoleAssignment
// ====================================================

export interface RoleAssignment_subject_organization {
  id: string;
  name: string;
}

export interface RoleAssignment_subject {
  globalId: string;
  id: string;
  displayName: string | null;
  type: SubjectType;
  organization: RoleAssignment_subject_organization | null;
}

export interface RoleAssignment_role {
  globalId: string;
  id: string;
  displayName: string | null;
  highSecurity: boolean;
}

export interface RoleAssignment {
  globalId: string;
  subject: RoleAssignment_subject;
  role: RoleAssignment_role;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SubjectType {
  CLIENT = "CLIENT",
  CUSTOM = "CUSTOM",
  USER = "USER",
}

export interface AssignRoleInput {
  domainId: string;
  subjectId: string;
  subjectType: SubjectType;
  roleId: string;
  resourceId?: string | null;
  resourceTypeId?: string | null;
  createReferences?: boolean | null;
}

export interface SaveResourceInput {
  id: string;
  domainId: string;
  typeId: string;
  displayName?: string | null;
}

export interface SaveResourceTypeInput {
  id: string;
  domainId: string;
  displayName?: string | null;
  resources?: (SaveResourceTypeResourceInput | null)[] | null;
}

export interface SaveResourceTypeResourceInput {
  id: string;
  displayName?: string | null;
}

export interface SaveRoleInput {
  id: string;
  domainId: string;
  displayName?: string | null;
  highSecurity?: boolean | null;
}

export interface UnassignRoleInput {
  domainId: string;
  subjectId: string;
  subjectType: SubjectType;
  roleId: string;
  resourceId?: string | null;
  resourceTypeId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
