import * as React from "react";
import { FormClient } from "../types";
import { NumberInput } from "../../Common/NumberInput";
import { SelectScopes } from "../../Common/SelectScopes";

export const ConsentForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const client = props.client;
  const updateClient = props.updateClient;
  const disabled = props.disabled;

  return (
    <div className="box">
      <div className="box-header">Consent</div>
      <div className="box-body">
        <div className="form-row">
          <div className="form-group col">
            <label>Allowed Scopes</label>
            <SelectScopes
              selectedScopes={client.allowedScopes || []}
              onChange={(selectedScopes) => updateClient({ allowedScopes: selectedScopes })}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              id="requireConsent"
              type="checkbox"
              checked={client.requireConsent ?? true}
              onChange={() => updateClient({ requireConsent: !client.requireConsent })}
              disabled={disabled}
            />
            <label className="form-check-label" htmlFor="requireConsent">
              Require consent
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              id="rememberConsent"
              type="checkbox"
              checked={client.allowRememberConsent ?? true}
              onChange={() => updateClient({ allowRememberConsent: !client.allowRememberConsent })}
              disabled={disabled}
            />
            <label className="form-check-label" htmlFor="rememberConsent">
              Allow remember consent
            </label>
          </div>
        </div>

        <div className="form-group">
          <label>Consent lifetime (seconds)</label>
          <NumberInput
            value={props.client.consentLifetime}
            disabled={disabled}
            required
            onChange={(newNumber) => updateClient({ consentLifetime: newNumber })}
            placeholder="Consent lifetime"
          />
        </div>
      </div>
    </div>
  );
};
