import * as React from "react";
import { Route, useRouteMatch } from "react-router";
import { userRoute, userPermissionRoute, userAuditRoute, UserRouteParams } from "../routes";
import { AuditLog } from "./Components/AuditLog";
import { Permissions } from "./Components/Permissions";
import { UserDetailsScreen } from "./UserDetailsScreen";

export const UserScreen = () => {
  const match = useRouteMatch<UserRouteParams>();
  return (
    <>
      <Route exact path={userRoute} render={() => <UserDetailsScreen userId={match.params.id} />} />

      <Route exact path={userAuditRoute} render={() => <AuditLog userId={match.params.id} />} />
      
      <Route
        exact
        path={userPermissionRoute}
        render={() => <Permissions userId={match.params.id} />}
      />
    </>
  );
};
