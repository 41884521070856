import * as React from "react";
import {
  validateNationalId,
  checkNationalId,
} from "@ist-group-private-scope/skolid-client-components";
import { ApolloClient } from "apollo-client";
import { Link } from "react-router-dom";
import { generateUserPath } from "../routes";
import { CountryCode } from "../admin-gql";

export const createAdminNationalIdValidator = (
  userId: string | undefined | null,
  country: CountryCode,
  organizationId: string,
  client: ApolloClient<any>
) => async (val: string) => {
  if (!val) {
    return;
  }

  if (!validateNationalId(val, country)) {
    return "Ogiltigt personnummer";
  }

  const res = await checkNationalId(val, organizationId, userId, client);
  if (res) {
    if (res.type === "AlreadyTaken") {
      return (
        <>
          Det finns redan{" "}
          <Link to={generateUserPath({ id: res.byUserId })}>ett konto</Link> med
          personnummret.
        </>
      );
    } else {
      return "Misslyckades med att validera";
    }
  }

  return null;
};
