export function nameToId(name: string) {
  return (
    name &&
    name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^A-Za-z0-9\- ]/g, "")
      .replace(/ /g, "-")
      .toLowerCase()
  );
}
