import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import permGql from "graphql-tag";
import {
  GetDomainSettings,
  UnassignDomainAccess,
  GetDomainSettings_domain_roles,
  GetDomainSettings_domain_resourceTypes
} from "../permissons-gql";
import { PermissionClientConsumer } from "../App/ClientProvider";
import { ApolloClient } from "apollo-client";
import { LoadingScreen } from "../App/LoadingScreen";
import { RouteComponentProps } from "react-router-dom";
import { PermDomainRouteParams } from "../routes";
import { SaveRoleModal } from "./SaveRoleModal";
import { AsyncOperation, TypedQuery } from "../types";
import { SaveResourceTypeModal } from "./SaveResourceTypeModal";
import { ConfirmModal } from "./DeleteModal";
import { AssignAccessModal } from "./AssignAccessModal";
import { ExecutionResult } from "graphql";

const fetchDomainSettingsGql = permGql`
  query GetDomainSettings($domainId: String!) {
    domain(id: $domainId) {
      id 
      globalId

      roles {
        globalId
        id
        displayName
        highSecurity
      }

      resourceTypes {
        globalId
        id
        displayName
      }
    }

    permissionsDomain: domain(id: "skolid-permissions") {
      id
      globalId

      resource(id: $domainId, typeId: "permissions-domain") {
        id
        globalId

        roleAssignments {
          nodes {
            globalId
            subject {
              globalId
              id
              type
              displayName
              organization {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const DomainSettingsQuery: TypedQuery<GetDomainSettings> = Query;

export const DomainSettingsScreen = (props: RouteComponentProps<PermDomainRouteParams>) => (
  <PermissionClientConsumer>
    {({ permissionClient }) => (
      <DomainSettingsQuery
        client={permissionClient}
        query={fetchDomainSettingsGql}
        variables={{ domainId: props.match.params.domainId }}
      >
        {result => (
          <DomainSettingsScreenInternal
            permissionClient={permissionClient}
            domainId={props.match.params.domainId}
            queryResult={result}
          />
        )}
      </DomainSettingsQuery>
    )}
  </PermissionClientConsumer>
);

interface DomainSettingsScreenInternalProps {
  permissionClient: ApolloClient<any>;
  domainId: string;
  queryResult: QueryResult<GetDomainSettings>;
}

interface DomainSettingsScreenState {
  // Role
  removeRoleId?: string | null;
  removeRoleOperation: AsyncOperation<any>;
  showAddRole?: boolean;
  editRole?: GetDomainSettings_domain_roles;

  // Resource types
  showAddResourceType?: boolean;
  removeResourceTypeOperation: AsyncOperation<any>;
  removeResourceTypeId?: string;
  editResourceType?: GetDomainSettings_domain_resourceTypes;

  // Access
  showAssignAccess?: boolean;
  unassignAccessOperation: AsyncOperation<any>;
  unassignAccessSubject?: { id: string; type: string };
}

class DomainSettingsScreenInternal extends React.Component<DomainSettingsScreenInternalProps> {
  public state: DomainSettingsScreenState = {
    removeRoleOperation: {},
    removeResourceTypeOperation: {},
    unassignAccessOperation: {}
  };

  public render() {
    const props = this.props;
    const response = this.props.queryResult;

    if (response.error) {
      return <div className="alert alert-danger">Something went wrong!?</div>;
    }

    if (response.loading || !response.data) {
      return (
        <div className="box">
          <div className="box-body">
            <LoadingScreen />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="flex-fill">
          <div className="box">
            <div className="box-header">Settings</div>
            <div className="box-body">
              <h3>Domain access</h3>
              <button className="btn btn-primary" onClick={() => this.setState({ showAssignAccess: true })}>
                Assign access
              </button>

              <table className="table table-hover table-sm mt-3">
                <thead>
                  <tr>
                    <th>Subject Name</th>
                    <th>Subject Type</th>
                    <th>Subject id</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {(response.data.permissionsDomain!.resource
                    ? response.data.permissionsDomain!.resource!.roleAssignments.nodes
                    : []
                  ).map(assignment => (
                    <tr key={assignment.subject.id}>
                      <td className="truncate" title={"id: " + assignment.subject.id + (assignment.subject.organization ? ` (org: ${assignment.subject.organization.id})` : null)}>
                      {assignment.subject.displayName || assignment.subject.id} {assignment.subject.organization ? <>({assignment.subject.organization.name})</> : null}
                    </td>
                      <td>{assignment.subject.type}</td>
                      <td>{assignment.subject.id}</td>
                      <th className="text-right">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => this.setState({ unassignAccessSubject: assignment.subject })}
                        >
                          Unassign
                        </button>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="box mt-content">
            <div className="box-header">Roles</div>
            <div className="box-body">
              <button className="btn btn-primary" onClick={() => this.setState({ showAddRole: true })}>
                Create new role
              </button>

              <table className="table table-hover table-sm mt-3">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>High Security</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {response.data.domain!.roles.map(role => (
                    <tr key={role.id}>
                      <td>{role.id}</td>
                      <td>{role.displayName}</td>
                      <td>{role.highSecurity ? "Yes" : ""}</td>
                      <th className="text-right">
                        <button className="btn btn-info btn-sm" onClick={() => this.setState({ editRole: role })}>
                          Edit
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => this.setState({ removeRoleId: role.id })}
                        >
                          Remove
                        </button>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="box mt-content">
            <div className="box-header">Resource types</div>
            <div className="box-body">
              <button className="btn btn-primary" onClick={() => this.setState({ showAddResourceType: true })}>
                Create new resource type
              </button>
              <table className="table table-hover table-sm mt-3">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {response.data.domain!.resourceTypes.map(resourceType => (
                    <tr key={resourceType.id}>
                      <td>{resourceType.id}</td>
                      <td>{resourceType.displayName}</td>
                      <th className="text-right">
                        <button
                          className="btn btn-info btn-sm"
                          onClick={() => this.setState({ editResourceType: resourceType })}
                        >
                          Edit
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => this.setState({ removeResourceTypeId: resourceType.id })}
                        >
                          Remove
                        </button>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ConfirmModal
          operation={this.state.removeRoleOperation}
          isOpen={this.state.removeRoleId !== null && this.state.removeRoleId !== undefined}
          onClose={() => this.setState({ removeRoleId: null })}
          onConfirm={this.handleRemoveRole}
        />

        <ConfirmModal
          isOpen={this.state.removeResourceTypeId !== null && this.state.removeResourceTypeId !== undefined}
          onClose={() => this.setState({ removeResourceTypeId: null })}
          onConfirm={this.handleRemoveResourceType}
          operation={this.state.removeResourceTypeOperation}
        />

        <ConfirmModal
          isOpen={this.state.unassignAccessSubject !== null && this.state.unassignAccessSubject !== undefined}
          onClose={() => this.setState({ unassignAccessSubject: null })}
          onConfirm={this.handleUnassignConfirm}
          operation={this.state.unassignAccessOperation}
          operationName="Unassign"
        />

        <SaveRoleModal
          apolloClient={props.permissionClient}
          domainId={props.domainId}
          initialRole={this.state.editRole}
          isOpen={!!this.state.showAddRole || !!this.state.editRole}
          onClose={() => this.setState({ showAddRole: null, editRole: null })}
          onRoleSaved={async () => {
            if (!this.state.editRole) {
              await this.props.queryResult.refetch();
            }
          }}
        />

        <AssignAccessModal
          apolloClient={props.permissionClient}
          domainId={props.domainId}
          isOpen={!!this.state.showAssignAccess}
          onCancel={() => this.setState({ showAssignAccess: false })}
          onAssigned={async () => {
            await this.props.queryResult.refetch();
            this.setState({ showAssignAccess: false });
          }}
        />

        <SaveResourceTypeModal
          apolloClient={props.permissionClient}
          domainId={props.domainId}
          isOpen={!!this.state.showAddResourceType}
          onClose={() => this.setState({ showAddResourceType: !this.state.showAddResourceType })}
          onResourceTypeSaved={async () => {
            await this.props.queryResult.refetch();
            this.setState({ showAddResourceType: !this.state.showAddResourceType });
          }}
        />

        <SaveResourceTypeModal
          apolloClient={props.permissionClient}
          initialResourceType={this.state.editResourceType}
          domainId={props.domainId}
          isOpen={!!this.state.showAddResourceType || !!this.state.editResourceType}
          onClose={() => this.setState({ showAddResourceType: null, editResourceType: null })}
          onResourceTypeSaved={async () => {
            if (!this.state.editResourceType) {
              await this.props.queryResult.refetch();
            }
          }}
        />
      </>
    );
  }

  private handleRemoveRole = async () => {
    this.setState({ removeRoleOperation: { running: true } });

    try {
      await this.props.permissionClient.mutate({
        mutation: permGql`
        mutation RemoveRoles($id: String!, $domainId: String!) {
          deleteRole(id: $id, domainId: $domainId)
        }
        `,
        variables: {
          id: this.state.removeRoleId,
          domainId: this.props.domainId
        }
      });

      await this.props.queryResult.refetch();

      this.setState({ removeRoleOperation: {}, removeRoleId: null });
    } catch (error) {
      console.error("Failed to remove role: ", error);
      this.setState({ removeRoleOperation: { error } });
    }
  };

  private handleUnassignConfirm = async () => {
    this.setState({ unassignAccessOperation: { running: true } });

    try {
      const response: ExecutionResult<UnassignDomainAccess> = await this.props.permissionClient.mutate({
        mutation: permGql`mutation UnassignDomainAccess($input: UnassignRoleInput!) {
            unassignRole(input: $input)
          }
          `,
        variables: {
          input: {
            resourceTypeId: "permissions-domain",
            domainId: "skolid-permissions",
            resourceId: this.props.domainId,
            subjectId: this.state.unassignAccessSubject!.id,
            subjectType: this.state.unassignAccessSubject!.type,
            roleId: "default"
          }
        }
      });

      await this.props.queryResult.refetch();

      this.setState({
        unassignAccessOperation: { data: response.data },
        unassignAccessSubject: null
      });
    } catch (error) {
      console.error("Failed to unassign subject access: ", error);
      this.setState({ unassignAccessOperation: { error: "Failed to unassign subject access" } });
    }
  };

  private handleRemoveResourceType = async () => {
    this.setState({ removeResourceTypeOperation: { running: true } });

    try {
      await this.props.permissionClient.mutate({
        mutation: permGql`
        mutation RemoveResourceType($id: String!, $domainId: String!) {
          deleteResourceType(id: $id, domainId: $domainId)
        }
        `,
        variables: {
          id: this.state.removeResourceTypeId,
          domainId: this.props.domainId
        }
      });

      await this.props.queryResult.refetch();

      this.setState({ removeResourceTypeOperation: {}, removeResourceTypeId: null });
    } catch (error) {
      console.error("Failed to remove resource type: ", error);
      this.setState({ removeResourceTypeOperation: { error } });
    }
  };
}
