import "@ist-group/bootstrap-theme-home/dist/index.css";
import "./index.css";
import "babel-polyfill";
import * as React from "react";
import { render } from "react-dom";
import App from "./App/App";
import * as Sentry from "@sentry/react";
import { settings } from "./settings";
import { SessionProvider } from "@ist-group-private-scope/web-skolid";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./i18n";

Sentry.init({
    dsn: "https://e30b6d3a915240feb3cefdf9fa69e4cb@sentry.ist.com/24",
    environment: settings.skolidEnvironment,
});

render(
  <React.Suspense fallback="Loading...">
    <Router>
      <Route>
        {props => (
          <SessionProvider
            autoLogin
            clientId={settings.clientId}
            environment={settings.skolidEnvironment}
            scope="openid profile roles skolid-admin.full-access"
            localSessionTimeout={settings.localSessionTimeoutInSeconds}
            navigate={props.history.replace}
          >
            {sessionState => <Route>{routeProps => <App sessionState={sessionState} {...routeProps} />}</Route>}
          </SessionProvider>
        )}
      </Route>
    </Router>
  </React.Suspense>,
  document.getElementById('root')
);

// Enable service worker when they are more stable: https://github.com/facebookincubator/create-react-app/issues/2554
// Currently the browser will render the old app after it has been updated which we can't tolerate.
// More stuff that needs to be considered when enabling: https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#offline-first-considerations
// registerServiceWorker();
