import { RoleAssignment, RoleAssignment_role } from "../permissons-gql";
import * as React from "react";

export const PermissionsTable = (props: {
  roleAssignments: (RoleAssignment)[] | null;
  onUnassign: (assignment: RoleAssignment) => void;
}) => {
  if (!props.roleAssignments || props.roleAssignments.length === 0) {
    return <p>No permissions</p>;
  }

  // Group roles on subjects
  const assignmentsByRole = props.roleAssignments.reduce<{
    [key: string]: {
      assignments: RoleAssignment[];
      role: RoleAssignment_role;
    };
  }>((bag, curr) => {
    if (!bag[curr.role.id]) {
      bag[curr.role.id] = { assignments: [], role: curr.role };
    }

    bag[curr.role.id].assignments.push(curr);
    return bag;
  }, {});

  return (
    <>
      {Object.keys(assignmentsByRole)
        .map(x => assignmentsByRole[x])
        .map(group => (
          <div key={group.role.id}>
            <h3>Role: {group.role.displayName}</h3>
            <table className="table table-hover table-sm table-fixed mb-content">
              <thead>
                <tr>
                  <th>Subject Name</th>
                  <th>Subject Id</th>
                  <th>Type</th>
                  <th style={{ width: 40 }} />
                </tr>
              </thead>
              <tbody>
                {group.assignments.map(ass => (
                  <tr key={ass.subject.id}>
                    <td className="truncate" title={"id: " + ass.subject.id + (ass.subject.organization ? ` (org: ${ass.subject.organization.id})` : null)}>
                      {ass.subject.displayName || ass.subject.id} {ass.subject.organization ? <>({ass.subject.organization.name})</> : null}
                    </td>
                    <td className="truncate" title={ass.subject.id}>
                      {ass.subject.id}
                    </td>
                    <td>{ass.subject.type || ass.subject.type}</td>
                    <td className="text-right">
                      <button
                        className="no-btn"
                        onClick={() => props.onUnassign(ass)}
                        title="Unassign role"
                      >
                        <i className="fa fa-unlink" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
    </>
  );
};
