import * as React from "react";
import { ApolloClient } from "apollo-client";
import { settings } from "../settings";
import * as oidc from "oidc-client";
import { ApolloProvider } from "react-apollo";
import { ApiProvider } from "./ApiProvider";
import { Admin2ClientContext, useAdmin2Client } from "@ist-group-private-scope/skolid-client-components";

export interface PermissionClientContextState {
  permissionClient: ApolloClient<any>;
}
const PermissionClientContext = React.createContext<PermissionClientContextState>({} as any);
export const PermissionClientConsumer = PermissionClientContext.Consumer;

export interface AdminClientContextState {
  adminClient: ApolloClient<any>;
}
const AdminClientContext = React.createContext<AdminClientContextState>({} as any);
export const AdminClientConsumer = AdminClientContext.Consumer;

export interface ClientProviderProps {
  user: oidc.User;
  onNotAuthorized: () => void;
  onNeedsReauthentication: () => void;
}

export class ClientProvider extends React.Component<ClientProviderProps> {
  public render() {
    return (
      <ApiProvider
        user={this.props.user}
        onNotAuthorized={this.props.onNotAuthorized}
        onNeedsReauthentication={this.props.onNeedsReauthentication}
        environment={settings.skolidEnvironment}
      >
        {(apis) => (
          <ApolloProvider client={apis.adminClient as any}>
            <Admin2ClientContext.Provider value={apis.admin2Client}>
              <AdminClientContext.Provider value={apis as any}>
                <PermissionClientContext.Provider value={apis as any}>
                  {this.props.children}
                </PermissionClientContext.Provider>
              </AdminClientContext.Provider>
            </Admin2ClientContext.Provider>
          </ApolloProvider>
        )}
      </ApiProvider>
    );
  }
}

export { useAdmin2Client };
