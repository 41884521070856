import * as React from "react";
import permGql from "graphql-tag";
import { Query } from "react-apollo";
import { Spinner } from "../../Common/Spinner";
import { PermissionClientConsumer } from "../../App/ClientProvider";
import { FetchUserPermissions, FetchUserPermissionsVariables } from "../../permissons-gql";
import { TypedQuery } from "../../types";

const fetchPermissionsGql = permGql`
  query FetchUserPermissions($subjectId: String!) {
    subject(id: $subjectId, type: USER) {
      globalId
      roleAssignments {
        nodes {
          globalId
          domain {
            globalId
            id
            displayName
          }
          resource {
            globalId
            id
            displayName
            type {
              globalId
              id
              displayName
            }
          }
          role {
            globalId
            id
            displayName
          }
        }
      }
    }
  }
`;

const PermissionsQuery: TypedQuery<FetchUserPermissions, FetchUserPermissionsVariables> = Query;

export const Permissions = (props: { userId: string }) => {
  return (
    <div className="flex-fill">
      <h1>Permissions</h1>
      <div className="box">
        <div className="box-body">
          <PermissionClientConsumer>
            {({ permissionClient }) => (
              <PermissionsQuery
                query={fetchPermissionsGql}
                client={permissionClient}
                variables={{ subjectId: props.userId }}
              >
                {result => (
                  <>
                    {result.loading ? (
                      <div className="text-center">
                        <Spinner />
                      </div>
                    ) : null}

                    {result.error ? <div className="alert alert-danger">Failed to fetch the permissions</div> : null}

                    {result.data &&
                    !result.loading &&
                    !result.error &&
                    (!result.data.subject || result.data.subject.roleAssignments.nodes.length === 0) ? (
                      <p>The user has no permissions</p>
                    ) : null}

                    {result.data && result.data.subject && !result.error ? (
                      <table className="table table-fixed">
                        <thead>
                          <tr>
                            <th>Domain</th>
                            <th>Resource</th>
                            <th>Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          {result.data!.subject!.roleAssignments.nodes.map((entry, index) => (
                            <tr key={index}>
                              <td title={"id: " + entry.domain.id}>{entry.domain.displayName || entry.domain.id}</td>
                              {entry.resource ? (
                                <td title={"id: " + entry.resource.type.id + " " + entry.resource.id}>
                                  {entry.resource.type.displayName || entry.resource.type.id}{" "}
                                  {entry.resource.displayName || entry.resource.id}
                                </td>
                              ) : (
                                <td />
                              )}
                              <td title={"id: " + entry.role.id}>{entry.role.displayName || entry.role.id}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : null}
                  </>
                )}
              </PermissionsQuery>
            )}
          </PermissionClientConsumer>
        </div>
      </div>
    </div>
  );
};
