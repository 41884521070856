import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { FetchApiResources, FetchIdentityResources } from "../admin-gql";
import { Spinner } from "../Common/Spinner";
import { Link } from "react-router-dom";
import * as routes from "../routes";
import { TypedQuery } from "../types";

const fetchApiResourcesGql = gql`
  query FetchApiResources {
    apiResources {
      name
      displayName
    }
  }
`;

const fetchIdentityResourcesGql = gql`
  query FetchIdentityResources {
    identityResources {
      name
      displayName
    }
  }
`;

const FetchApiResourcesQuery: TypedQuery<FetchApiResources> = Query;

const FetchIdentityResourcesQuery: TypedQuery<FetchIdentityResources> = Query;

export class ScopesScreen extends React.Component {
  public render() {
    return (
      <div className="flex-fill">
        <h1>Identity Resources</h1>
        <div className="box">
          <div className="box-body">
            <div className="text-right mb-content">
              <Link className="btn btn-primary" to={routes.newIdentityResourcePath}>
                <i className="fa fa-plus" />
                &nbsp; Add identity resource
              </Link>
            </div>
            <FetchIdentityResourcesQuery
              query={fetchIdentityResourcesGql}
              fetchPolicy="network-only"
            >
              {result => (
                <>
                  {result.loading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : null}

                  {result.error ? <div>Something went wrong!?</div> : null}

                  {result.data && result.data.identityResources && !result.loading ? (
                    <ResourcesTable resources={result.data.identityResources} />
                  ) : null}
                </>
              )}
            </FetchIdentityResourcesQuery>
          </div>
        </div>

        <h1>API Resources</h1>
        <div className="box">
          <div className="box-body">
            <div className="text-right mb-content">
              <Link className="btn btn-primary" to={routes.newApiResourcePath}>
                <i className="fa fa-plus" />
                &nbsp; Add API resource
              </Link>
            </div>
            <FetchApiResourcesQuery query={fetchApiResourcesGql} fetchPolicy="network-only">
              {result => (
                <>
                  {result.loading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : null}

                  {result.error ? <div>Something went wrong!?</div> : null}

                  {result.data && result.data.apiResources && !result.loading ? (
                    <ResourcesTable resources={result.data.apiResources} apiResource />
                  ) : null}
                </>
              )}
            </FetchApiResourcesQuery>
          </div>
        </div>
      </div>
    );
  }
}

const ResourcesTable = (props: {
  resources: { name: string; displayName: string | null }[];
  apiResource?: boolean;
}) => {
  if (props.resources.length === 0) {
    return <p>No resources</p>;
  }

  return (
    <table className="table table-hover table-sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Display Name</th>
        </tr>
      </thead>
      <tbody>
        {props.resources.map(p => (
          <tr key={p.name}>
            <td>
              <Link
                to={
                  props.apiResource
                    ? routes.generateApiResourcePath({ name: p.name })
                    : routes.generateIdentityResourcePath({ name: p.name })
                }
              >
                {p.name}
              </Link>
            </td>
            <td>{p.displayName}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
