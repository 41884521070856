import * as React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { FetchOrganizations } from "../admin-gql";
import Select from "react-select";
import { TypedQuery } from "../types";

const fetchOrganizationsGql = gql`
  query FetchOrganizations {
    organizations {
      id
      name
    }
  }
`;

const QueryOrganizations: TypedQuery<FetchOrganizations> = Query;

export const SelectOrganization = (props: {
  organizationId: string | null | undefined;
  onChange: (id: string | null, name: string | null) => void;
  disabled?: boolean;
  readonly?: boolean;
  notClearable?: boolean;
  placeholder?: string;
}) => {
  return (
    <QueryOrganizations query={fetchOrganizationsGql} fetchPolicy="cache-and-network">
      {result => (
        <Select
          options={
            result.data && !result.loading ? result.data.organizations.map(x => ({ label: x.name, value: x.id })) : []
          }
          isLoading={result.loading}
          value={
            result.data && result.data.organizations ? calcValue(props.organizationId, result.data.organizations) : null
          }
          isClearable={!props.notClearable}
          isDisabled={props.disabled || props.readonly}
          onChange={(option: any) => props.onChange(option ? option.value : null, option ? option.label : null)}
          placeholder={props.placeholder}
        />
      )}
    </QueryOrganizations>
  );
};

function calcValue(organizationId: string | null | undefined, organizations: Array<{ id: string; name: string }>) {
  const foundOrg = organizations.find(x => x.id === organizationId);
  return organizationId && foundOrg
    ? {
      value: organizationId,
      label: foundOrg.name
    }
    : null;
}
