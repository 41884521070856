import * as React from "react";
import admin2Gql from "graphql-tag";
import { Query, useQuery } from "react-apollo";
import Select from "react-select";
import { useAdmin2Client } from "../App/ClientProvider";
import { SelectIdpsList } from "../admin-gql-2";

export const SelectIdps = (props: {
  selectedIdps: string[];
  onChange: (selectedIdps: string[]) => void;
  disabled?: boolean;
}) => {
  const client = useAdmin2Client();
  const idps = useQuery<SelectIdpsList>(
    admin2Gql`query SelectIdpsList {
      idpList {
        nodes {
          id
          name
        }
      }
  }`,
    {
      client
    }
  );

  const idpOptions =
    idps.data && !idps.loading && idps.data.idpList && idps.data.idpList.nodes
      ? idps.data.idpList.nodes.map(x => ({ label: x.name, value: x.id }))
      : [];

  const value = props.selectedIdps.map(x => idpOptions.find(y => y.value === x) || { label: x, value: x });

  return (
    <Select
      options={idpOptions}
      isLoading={idps.loading}
      isMulti
      isDisabled={props.disabled}
      value={value}
      onChange={(options: any) => props.onChange(options.map((x: any) => x.value))}
    />
  );
};
