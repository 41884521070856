import * as React from "react";
import { FormClient } from "../types";
import { SelectLoa } from "../../Common/SelectLoa";
import { UncontrolledTooltip } from "reactstrap";
import { NumberInput } from "../../Common/NumberInput";
import { SelectAuthMethods } from "../../Common/SelectLoginMethods";
import { SelectIdps } from "../../Common/SelectIdps";
import { Loa } from "../../admin-gql";
import { SelectOrganizationMulti } from "../../Common/SelectOrganizationMulti";

export const AuthenticationSettingsForm = (props: {
  client: FormClient;
  updateClient: (partial: Partial<FormClient>) => void;
  disabled?: boolean;
}) => {
  const updateClient = props.updateClient;
  const disabled = props.disabled;

  return (
    <div className="box">
      <div className="box-header">Authentication Settings</div>
      <div className="box-body">
        <div className="form-row">
          <div className="col form-group">
            <label>User Organizations</label>
            <SelectOrganizationMulti
              organizationIds={props.client.userOrganizationIds}
              disabled={disabled}
              onChange={(userOrganizationIds) =>
                updateClient({
                  userOrganizationIds,
                })
              }
            />
            <div className="mt-1 small">
              Limits authentication to users in the given organizations.
              Leave empty to allow users from all organizations to login (multi-tenant).
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col">
            <label>LOA requirement</label>
            <SelectLoa
              onChange={(newLoaRequirement) =>
                updateClient({ loaRequirement: newLoaRequirement })
              }
              loa={props.client.loaRequirement || Loa.ONE}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col">
            <label id="session-label">Session max age (seconds)</label>
            <UncontrolledTooltip target="session-label">
              Session max age is a compliment to the LOA requirement but does
              not override the constraint set by it. For instance if the LOA
              requirement is 1 and the session max age is set to 3 days a user
              would still be required to login after about a day since LOA 1
              requires it.
            </UncontrolledTooltip>
            <NumberInput
              value={props.client.sessionMaxAge}
              disabled={disabled}
              onChange={(newNumber) =>
                updateClient({ sessionMaxAge: newNumber })
              }
              placeholder="Session max age"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col">
            <label id="session-label">Login methods</label>
            <SelectAuthMethods
              authSettings={props.client}
              onChange={(authSettings) => updateClient(authSettings)}
            />
          </div>
        </div>

        <div className="form-group">
          <label id="session-label">Allowed IdP:s</label>
          <SelectIdps
            selectedIdps={props.client.allowedIdps}
            onChange={(idps) => updateClient({ allowedIdps: idps })}
          />
        </div>
      </div>
    </div>
  );
};
