import { Input } from "@ist-group-private-scope/skolid-client-components";
import { ExecutionResult } from "graphql";
import gql from "graphql-tag";
import * as React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  CountryCode,
  CreateOrganization,
  Loa,
  OrganizationFragment,
  strippers,
  UpdateOrganization,
} from "../admin-gql";
import { Spinner } from "../Common/Spinner";
import { AsyncOperation } from "../types";
import { ORGANIZATION_FRAGMENT } from "./queries";

type FormOrganization = {
  id: string;
  name: string;
  issueLoa: Loa;
  country: CountryCode;
};

interface OrganizationFormModalProps {
  organization: OrganizationFragment | null;
  show?: boolean;
  onSave: (org: OrganizationFragment) => void;
  onCancel: () => void;
}

interface OrganizationFormModalState {
  organization: FormOrganization;
  saveOperation: AsyncOperation<void>;
  showValidationErrors?: boolean;
}

class OrganizationFormModalWithStuff extends React.Component<
  WithApolloClient<OrganizationFormModalProps>,
  OrganizationFormModalState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      saveOperation: {},
      organization: props.organization || {
        id: "",
        name: "",
        issueLoa: Loa.TWO,
      },
    };
  }

  public render() {
    const organization = this.state.organization;
    const disabled = this.state.saveOperation.running;
    const update = this.updateOrganization;

    const isNew = !this.props.organization;
    const idValidationError = !organization.id ? "Id required" : null;
    const nameValidationError = !organization.name ? "Name required" : null;
    const hasValidationError =
      !!(nameValidationError || idValidationError) ||
      !this.state.organization.country;

    return (
      <Modal isOpen={this.props.show} size="lg">
        <ModalHeader>
          {!this.props.organization ? "Create" : "Update"} organization
        </ModalHeader>
        {this.props.show ? (
          <ModalBody>
            <div className="row">
              <div className="col">
                {this.state.saveOperation.error ? (
                  <div className="alert alert-danger">
                    {this.state.saveOperation.error}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col">
                <Input
                  value={organization.id}
                  label="Id"
                  onChange={(text) => update({ id: text })}
                  disabled={disabled}
                  readonly={!!this.props.organization}
                  validationErrorMessage={idValidationError}
                  forceShowValidationError={this.state.showValidationErrors}
                />
              </div>

              <div className="form-group col">
                <Input
                  value={organization.name}
                  label="Name"
                  onChange={(text) => update({ name: text })}
                  disabled={disabled}
                  validationErrorMessage={nameValidationError}
                  forceShowValidationError={this.state.showValidationErrors}
                />
              </div>
            </div>

            <div className="form-group">
              <label>Issue LOA</label>
              <Select
                options={[
                  ["Loa 1", Loa.ONE],
                  ["Loa 2", Loa.TWO],
                  ["Loa 3", Loa.THREE],
                ].map((x) => ({
                  label: x[0],
                  value: x[1],
                }))}
                value={{
                  value: this.state.organization.issueLoa,
                  label: this.state.organization.issueLoa,
                }}
                isDisabled={disabled}
                onChange={(option: any) =>
                  this.updateOrganization({
                    issueLoa: option.value,
                  })
                }
              />
            </div>

            <div className="form-group">
              <label>Country</label>
              <Select
                options={Object.keys(CountryCode)
                  .map((x) => [x, x])
                  .map((x) => ({
                    label: x[0],
                    value: x[1],
                  }))}
                value={{
                  value: this.state.organization.country,
                  label:
                    this.state.organization.country || "- Select Country -",
                }}
                isDisabled={disabled || !isNew}
                onChange={(option: any) =>
                  this.updateOrganization({
                    country: option.value,
                  })
                }
              />
            </div>
          </ModalBody>
        ) : null}
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => this.save(hasValidationError)}
            disabled={
              (hasValidationError && this.state.showValidationErrors) ||
              disabled
            }
          >
            {this.state.saveOperation.running ? <Spinner light /> : "Save"}
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private save = async (hasValidationErrors: boolean) => {
    if (hasValidationErrors) {
      this.setState({ showValidationErrors: true });
      return;
    }

    this.setState({ saveOperation: { running: true } });

    try {
      if (!this.props.organization) {
        const result: any = await this.props.client.mutate({
          mutation: createOrganizationGql,
          variables: {
            input: strippers.CreateOrganizationInput(this.state.organization),
          },
        });
        const data: CreateOrganization = result.data;
        this.setState({ saveOperation: {} });
        this.props.onSave(data.createOrganization!);
      } else {
        const result: ExecutionResult<UpdateOrganization> = await this.props.client.mutate(
          {
            mutation: updateOrganizationGql,
            variables: {
              input: strippers.UpdateOrganizationInput(this.state.organization),
              id: this.props.organization.id,
            },
          }
        );
        this.setState({ saveOperation: {} });
        this.props.onSave(result.data!.updateOrganization!);
      }
    } catch (error) {
      this.setState({
        saveOperation: { error: "Could not save the organization" },
      });
      console.error("Failed to save organization: ", error);
    }
  };

  private updateOrganization = (partialUpdate: Partial<FormOrganization>) => {
    this.setState({
      organization: { ...this.state.organization, ...partialUpdate },
    });
  };
}

const createOrganizationGql = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      ...OrganizationFragment
    }
  }

  ${ORGANIZATION_FRAGMENT}
`;

const updateOrganizationGql = gql`
  mutation UpdateOrganization($id: String!, $input: UpdateOrganizationInput!) {
    updateOrganization(id: $id, input: $input) {
      ...OrganizationFragment
    }
  }

  ${ORGANIZATION_FRAGMENT}
`;

export const OrganizationFormModal = withApollo<OrganizationFormModalProps>(
  OrganizationFormModalWithStuff
);
